import { extendObservable, computed } from 'mobx';

export default class Template {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    extendObservable(this, {
      id: +input.id || 0,
      key: input.key || input.template_key || 'XXX',
      title: input.title || '<Unknown Template>',
      description: input.description || '',
      subject: input.subject || 'none',
      alias: input.alias,
      displayed: !!input.displayed,
      public: input.public,
      state: input.state,
      moduleData: input.template_modules || [],
      resources: input.resources || [],
    });
  }

  @computed get modulesAndGroups() {
    const { curriculum } = this.rootStore;

    return this.moduleData.map((data) => {
      if (data.module_key) {
        return curriculum.modulesByKey[data.module_key];
      }
      if (data.module_group_key) {
        return curriculum.moduleGroupsByKey[data.module_group_key];
      }
      return undefined;
    }).filter((v) => !!v);
  }

  @computed get moduleKeys() {
    return this.modulesAndGroups.reduce((acc, item) => {
      if (item.type === 'ModuleGroup') {
        acc.push(...item.moduleKeys);
      } else { // item.type === 'Module'
        acc.push(item.key);
      }
      return acc;
    }, []);
  }

  @computed get isCustomNotLegacy() {
    return !this.public && this.title.indexOf('Legacy') === -1;
  }

  @computed get isCustomLegacy() {
    return !this.public && this.title.indexOf('Legacy') !== -1;
  }

  @computed get isHealth() {
    return this.public && this.subject === 'health';
  }

  @computed get isBusiness() {
    return this.public && this.subject === 'business';
  }

  @computed get templateKey() {
    return this.key;
  }
}
