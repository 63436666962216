import {
  extendObservable, computed, action, observable,
} from 'mobx';
import moment from 'moment';
import Assignment from 'models/Assignment';

class Course {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    const { status } = input;
    const upgradeJobId = status?.startsWith('upgrading,')
      ? status.split(',')[1]
      : '';

    extendObservable(this, {
      assignmentCount: input.assignments_count,
      canResumeExam: input.allow_exam_continue,
      canResumeQuiz: input.allow_quiz_continue,
      canResumeTest: input.allow_test_continue,
      deletedAt: input.deletedAt || input.deleted_at || 0,
      dueDateAction: input.due_date_action || 'CLOSE',
      enrollmentCode: input.enrollment_code || '',
      enrollmentEnabled: input.enrollment_enabled,
      eWorksheets: input.enable_electronic_worksheets,
      facultyId: +input.faculty_id || 0,
      gradeCalculation: input.calculation_method || 'LAST',
      hideQuestions: input.hide_questions,
      id: +input.id || 0,
      keyboardingGlobal: null,
      keyboardingCourse: null,
      lastStudentActivity: input.last_student_activity,
      lessonBlock: input.must_complete_lessons,
      ownerName: input.owner_name || '',
      pacingModuleTest: input.pacing_module_test,
      pacingModuleOrder: input.pacing_module_order,
      preTest: input.enable_pre_test,
      providers: input.provisioned_providers || [],
      quizAttempts: input.quiz_attempts || 3,
      quizChallenge: input.enable_quiz_challenge,
      quizChallengeAP: input.enable_quiz_challenge_ap,
      quizChallengeMT: input.enable_quiz_challenge_mt,
      quizChallengePH: input.enable_quiz_challenge_ph,
      quizRetryTime: input.quiz_retry_time != null ? +input.quiz_retry_time : 60,
      section: input.course_id,
      sharedTemplate: input.shared_template,
      sharedWith: input.sharedWith || [],
      siteId: +input.site_id || 0,
      showAnswers: input.show_answers || 'NEVER',
      studentCount: input.student_count,
      template: input.template,
      title: input.title || '',
      allowExportWorksheets: input.allow_export_worksheets,
      enableBeta: input.enable_beta,
      version: input.version,
      pendingIntegration: '',
      pendingIntegrationCourse: 0,
      processingIntegration: false,
      upgradeJobId,
      migrationStatus: input.icev_migration_status,
      icevCourseCode: input.icev_teacher_course_code,
      icevMigratedDate: input.icev_migrated_at,
    });

    if (input.share_list) {
      this.sharedWith = input.share_list.map((t) => +t.id);
    }
  }

  @computed get autoCloseQuiz() {
    return !this.canResumeQuiz;
  }

  @computed get autoCloseTest() {
    return !this.canResumeTest;
  }

  @computed get autoCloseExam() {
    return !this.canResumeExam;
  }

  @computed get isShared() {
    const { auth } = this.rootStore;

    return this.facultyId !== auth.user.id;
  }

  @computed get archived() {
    return !!this.deletedAt;
  }

  @computed get assignments() {
    const { assignments } = this.rootStore;
    return assignments.list
      .filter((assignment) => assignment.courseId === this.id)
      .sort((a, b) => a.sequence - b.sequence);
  }

  @computed get assignmentsById() {
    return this.assignments.reduce(
      (result, assignment) => {
        // eslint-disable-next-line no-param-reassign
        result[assignment.id] = assignment;
        return result;
      },
      {},
    );
  }

  @computed get assignmentsByModuleKey() {
    const map = observable.map();

    this.assignments.forEach((assignment) => {
      if (assignment.moduleKey) {
        map.set(assignment.moduleKey, assignment);
      }
    });

    return map;
  }

  @computed get gradeAssignments() {
    return this.assignments.filter((assignment) => {
      if (assignment.removed) return false;
      if (assignment.gradeEntries.length === 0) return false;

      return true;
    });
  }

  @computed get moduleAssignments() {
    return this.assignments.filter((a) => a.type === 1);
  }

  @computed get students() {
    const { students } = this.rootStore;
    const inCourse = students.byCourseId.get(this.id) || [];

    return inCourse.sort((a, b) => {
      if (a.formatName == b.formatName) return 0; // eslint-disable-line
      return a.formatName > b.formatName ? 1 : -1;
    });
  }

  @computed get signedInToday() {
    const { students } = this.rootStore;
    const inCourse = students.byCourseId.get(this.id) || [];
    const cutoff = moment()
      .startOf('day');

    return inCourse.filter((s) => s.lastLogon && moment(s.lastLogon) > cutoff).length;
  }

  @computed get signedInPast7() {
    const { students } = this.rootStore;

    const inCourse = students.byCourseId.get(this.id) || [];
    const cutoff = moment()
      .add(-7, 'day');
    return inCourse.filter((s) => s.lastLogon && moment(s.lastLogon) > cutoff).length;
  }

  @computed get grades() {
    const { grades } = this.rootStore;

    return grades.list.filter((grade) => grade.courseId === this.id && grade.status === 2);
  }

  @computed get work() {
    const { grades } = this.rootStore;

    return grades.list.filter((grade) => grade.courseId === this.id);
  }

  @computed get hours() {
    return this.assignments.reduce((a, b) => a + b.hours, 0);
  }

  // Provide for the district templates in the catalog
  @computed get templateKey() {
    return `DT${this.id}`;
  }

  // Provide for the district templates in the catalog
  @computed get modulesAndGroups() {
    const { curriculum } = this.rootStore;
    return this.moduleKeys.map((key) => curriculum.modulesByKey[key])
      .filter((module) => !!module);
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get linkedProviderCourse() {
    return false; // TODO Implement actual value
  }

  @computed get previewUrl() {
    const { auth } = this.rootStore;
    return auth.previewUrl(this.id);
  }

  @computed get needsBetaUpdate() {
    const {
      auth: {
        betaActive,
        user,
      },
    } = this.rootStore;

    return betaActive
      && this.facultyId === user.id
      && !this.enableBeta
      && !this.upgradeJobId;
  }

  @computed get shouldShowBetaUpdate() {
    const { auth: { user } } = this.rootStore;

    return this.needsBetaUpdate
      && !user.suppressUpdate;
  }

  @computed get courseMigrated() {
    // 2 = Fully migrated; 3 = Custom Exams Ready for Migration
    return (this.migrationStatus === 2 || this.migrationStatus === 3);
  }

  @computed get courseMigrationNotSupported() {
    const UNSUPPORTED_STATUSES = [0, 1, 4];

    return UNSUPPORTED_STATUSES.includes(this.migrationStatus);
  }

  findStudent = (id) => this.students.find((s) => s.id === Number(id));

  // update student count
  @action updateStudentCount(count) {
    this.studentCount = count;
  }

  // update provider data when provisioned
  @action updateProvider(providerData) {
    this.providers = providerData;
  }

  @action updateOption(prop, name, value) {
    if (!this.id) return Promise.resolve();

    const { WebAPI } = this.rootStore;
    const old = this[prop];
    this[prop] = value;

    return WebAPI.patch(
      `teacher/courses/${this.id}`,
      {
        course: {
          [name]: value,
        },
      },
    )
      .catch(action('optionRollback', (err) => {
        this[prop] = old;
        return Promise.reject(err);
      }));
  }

  @action rename(newName) {
    this.updateOption('title', 'title', newName);
  }

  @action setDistrictTemplate(value) {
    this.updateOption('sharedTemplate', 'shared_template', value);
  }

  @action setEnrollmentStatus(on) {
    this.updateOption(
      'enrollmentEnabled',
      'enrollment_enabled',
      on,
    );
  }

  @action setEnableBeta(on) {
    this.updateOption(
      'enableBeta',
      'enable_beta',
      on,
    );
  }

  @action setQuizAttempts(val) {
    this.updateOption(
      'quizAttempts',
      'quiz_attempts',
      +val || 3,
    );
  }

  @action setQuizRetryTime(val) {
    const newVal = parseInt(val, 10);

    this.updateOption(
      'quizRetryTime',
      'quiz_retry_time',
      !Number.isNaN(newVal) ? newVal : 60,
    );
  }

  @action setPreTestEnabled(on) {
    this.updateOption(
      'preTest',
      'enable_pre_test',
      !!on,
    );
  }

  @action setQuizChallengeEnabled(on) {
    this.updateOption(
      'quizChallenge',
      'enable_quiz_challenge',
      !!on,
    );

    if (!!on && !this.quizChallengeAP && !this.quizChallengeMT && !this.quizChallengePH) {
      this.updateOption(
        'quizChallengeAP',
        'enable_quiz_challenge_ap',
        true,
      ).then(() => {
        this.updateOption(
          'quizChallengeMT',
          'enable_quiz_challenge_mt',
          true,
        );
      });
    }
  }

  @action setQuizChallengeEnabledAP(on) {
    this.updateOption(
      'quizChallengeAP',
      'enable_quiz_challenge_ap',
      !!on,
    );
  }

  @action setQuizChallengeEnabledMT(on) {
    this.updateOption(
      'quizChallengeMT',
      'enable_quiz_challenge_mt',
      !!on,
    );
  }

  @action setQuizChallengeEnabledPH(on) {
    this.updateOption(
      'quizChallengePH',
      'enable_quiz_challenge_ph',
      !!on,
    );
  }

  @action setWorksheetType(type) {
    this.updateOption(
      'eWorksheets',
      'enable_electronic_worksheets',
      (type === 'electronic'),
    );
  }

  @action setWorksheetStudentExport(on) {
    this.updateOption(
      'allowExportWorksheets',
      'allow_export_worksheets',
      !!on,
    );
  }

  @action setHideQuestions(on) {
    this.updateOption(
      'hideQuestions',
      'hide_questions',
      !!on,
    );
  }

  @action setShowAnswers(type) {
    const accepted = ['NEVER', 'IMMEDIATLY', 'ONE-HOUR', 'ONE-DAY'];
    this.updateOption(
      'showAnswers',
      'show_answers',
      accepted.includes(type) ? type : 'NEVER',
    );
  }

  @action setGradeCalculation(type) {
    const accepted = ['LAST', 'HIGHEST', 'AVERAGE'];
    this.updateOption(
      'gradeCalculation',
      'calculation_method',
      accepted.includes(type) ? type : 'LAST',
    );
  }

  @action setDueDateAction(type) {
    const accepted = ['NOTHING', 'CLOSE'];
    this.updateOption(
      'dueDateAction',
      'due_date_action',
      accepted.includes(type) ? type : 'CLOSE',
    );
  }

  @action setLessonBlock(on) {
    this.updateOption(
      'lessonBlock',
      'must_complete_lessons',
      !!on,
    );
  }

  @action setPacingModuleTest(on) {
    this.updateOption(
      'pacingModuleTest',
      'pacing_module_test',
      !!on,
    );
  }

  @action setPacingModuleOrder(on) {
    this.updateOption(
      'pacingModuleOrder',
      'pacing_module_order',
      !!on,
    );
  }

  @action setCanResume(type, on) {
    const accepted = ['Quiz', 'Test', 'Exam'];
    if (!accepted.includes(type)) return;

    this.updateOption(
      `canResume${type}`,
      `allow_${type.toLowerCase()}_continue`,
      !!on,
    );
  }

  @computed get moduleKeys() {
    return this.assignments.filter((a) => a.typeName?.includes('module'))
      .map((a) => a.moduleKey);
  }

  @computed get hasLearnosity() {
    return this.enableBeta;
  }

  @action addModules(moduleKeys) {
    const promises = [];

    // Handle Adds
    moduleKeys.forEach((mk) => {
      if (!this.hasAssignment(mk)) {
        promises.push(this.addAssignment(mk));
      }
    });
    // Handle Removes
    this.assignments.forEach((a) => {
      if (a.type === 1 && !moduleKeys.find((mk) => mk === a.moduleKey)) {
        promises.push(this.removeAssignment(a));
      }
    });

    return Promise.all(promises)
      .then(() => {
        this.finishAddModules(moduleKeys);
      });
  }

  @action finishAddModules(moduleKeys) {
    const order = moduleKeys.map((m, index) => {
      const assignment = this.assignments.find((a) => a.moduleKey === m);
      assignment.sequence = index;
      return {
        id: assignment.id,
        sequence: index,
      };
    });
    this.sortAssignments(order);
  }

  @action addModule(module) {
    const { assignments } = this.rootStore;

    assignments.add(module);
  }

  @action addAssignment(module) {
    const {
      assignments,
      WebAPI,
    } = this.rootStore;
    const moduleKey = Object(module) === module ? module.key : module;

    if (!this.id) {
      assignments.add({
        id: -Math.round(Math.random() * 10000),
        courseId: this.id,
        module_key: moduleKey,
        sequence: this.assignments.length,
      });

      return Promise.resolve();
    }

    return WebAPI.post(
      `teacher/courses/${this.id}/assignments`,
      {
        assignment: {
          module_key: moduleKey,
          sequence: this.assignments.length,
        },
      },
    )
      .then((result) => {
        assignments.add(result);
        this.assignmentCount += 1;
      });
  }

  @action removeAssignment(assignment) { // eslint-disable-line
    const {
      assignments,
      WebAPI,
    } = this.rootStore;

    if (!this.id) {
      assignments.remove(assignment.id);
      return Promise.resolve();
    }

    if (!assignment || !(assignment instanceof Assignment)) {
      throw new Error('removeAssignment requires an Assignment as parameter');
    }

    return WebAPI.delete(`teacher/courses/${this.id}/assignments/${assignment.id}`)
      .then(() => {
        assignments.remove(assignment.id);
        this.assignmentCount -= 1;
      });
  }

  hasAssignment(key) {
    return !!this.assignments.find((a) => a.moduleKey === key);
  }

  // True if it's an exam or the entry isn't deleted
  hasAssignmentEntry(moduleKey, unitKey) {
    const assignment = this.assignments.find((a) => a.moduleKey === moduleKey);
    if (!assignment) {
      return false;
    }
    if (assignment.isExam) {
      return true;
    }
    const entry = assignment.entries.find((e) => e.unitKey === unitKey);
    return entry && !entry.removed;
  }

  logAssignments = (list) => {
    const assignmentList = list.map((a) => {
      if (a) {
        return { assignment_id: a.id };
      }
      return 'NULL';
    });
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(assignmentList));
    window.Rollbar.error('Null Assignment While Sorting', { assignmentList });
  };

  @action moveAssignment(from, to) {
    // eslint-disable-next-line no-console
    console.log(`Move Assignment: ${from} ${to}`);
    const list = [...this.assignments];

    // Check for nulls in the list - It happens with customers
    // occasionally and this will help us debug
    if (list.indexOf(null) >= 0 || list.indexOf(undefined) >= 0) {
      this.logAssignments(list);
    }

    const assignment = list.splice(from, 1)[0]; // Remove from  original spot
    list.splice(to <= from ? to : to - 1, 0, assignment); // Add to new spot
    // eslint-disable-next-line no-param-reassign
    list.forEach((a, i) => { a.sequence = i; }); // Update sequence

    if (!this.id) return Promise.resolve();
    const order = list.map(({
      id,
      sequence,
    }) => ({
      id,
      sequence,
    }));
    return this.sortAssignments(order);
  }

  @action sortAssignments(order) {
    const { WebAPI } = this.rootStore;

    return WebAPI.post(`teacher/courses/${this.id}/assignments/sort`, { order });
  }

  @action createExam(exam) {
    if (!this.id) return Promise.resolve();

    const {
      assignments,
      WebAPI,
    } = this.rootStore;
    const options = { version: this.hasLearnosity ? 'v2' : 'v1' };
    const params = exam.customized
      ? {
        assignment: {
          exam_title: exam.title,
          exam_customized: true,
        },
      }
      : {
        assignment: {
          exam_title: exam.title,
          exam_module_keys: exam.modules,
          exam_questions: exam.numQuestions,
        },
      };

    return WebAPI.post(
      `teacher/courses/${this.id}/assignments`,
      params,
      options,
    )
      .then((result) => {
        assignments.add(result);
        return Promise.resolve(result);
      });
  }

  @action copyExam(exam) {
    const courseId = this.id;
    const {
      assignments,
      WebAPI,
    } = this.rootStore;
    const version = this.hasLearnosity ? 'v2' : 'v1';
    const options = { version };
    const title = exam.courseId === courseId
      ? `Copy of ${exam.title}`
      : exam.title;

    return WebAPI.post(
      `teacher/courses/${courseId}/assignments`,
      {
        assignment: {
          copy_exam_id: exam.id,
          new_title: title,
        },
      },
      options,
    )
      .then((result) => {
        const loadNewAssignment = (assignmentId) => (
          assignments.loadByCourseAndId(courseId, assignmentId)
        );
        const jobId = result.job_id;
        const assignmentId = result.assignment_id;

        if (assignmentId) { // legacy exams don't use jobs
          return loadNewAssignment(assignmentId);
        }

        if (jobId) { // new exams use jobs
          return WebAPI.pollJob(jobId, 1500, 600000)
            .then(({ assignment_id: newAssignmentId }) => {
              if (newAssignmentId) {
                return loadNewAssignment(newAssignmentId);
              }

              return Promise.reject(new Error('Exam copy returned no Assignment id'));
            });
        }

        return Promise.reject(new Error('Exam Copy failed'));
      });
  }

  @action
  async addExistingStudent(student) {
    const {
      students,
      WebAPI,
    } = this.rootStore;
    const studentId = Object(student) === student ? student.id : student;

    const data = await WebAPI.post(
      `teacher/courses/${this.id}/students`,
      { student_id: studentId },
    );

    this.studentCount += 1;

    return students.add({
      course: this.id,
      ...data,
    });
  }

  @action
  async addNewStudent(input) {
    const {
      students,
      WebAPI,
    } = this.rootStore;
    const data = await WebAPI.post(
      `teacher/courses/${this.id}/students`,
      { student: input },
    );

    this.studentCount += 1;

    return students.add({
      course: this.id,
      ...data,
    });
  }

  @action editStudent(student, params) {
    const { WebAPI } = this.rootStore;
    const studentId = Object(student) === student ? student.id : +student;

    if (params.password) {
      // eslint-disable-next-line no-param-reassign
      params.password_confirmation = params.password;
    }

    return WebAPI.patch(
      `teacher/courses/${this.id}/students/${studentId}`,
      { student: params },
    )
      .then(action('updateStudent', () => {
        // eslint-disable-next-line no-param-reassign
        student.firstName = params.first_name || student.firstName;
        // eslint-disable-next-line no-param-reassign
        student.lastName = params.last_name || student.lastName;
        // eslint-disable-next-line no-param-reassign
        student.email = params.email || student.email;
      }));
  }

  @action moveStudent(student, newCourse) {
    if (typeof newCourse !== 'number') throw new Error('Not yet supported');

    const { WebAPI } = this.rootStore;

    return WebAPI.post(
      `teacher/courses/${this.id}/students/${student.id}/move`,
      {
        new_course_id: newCourse,
      },
    );
  }

  @action changeStudentPassword(student, newPass) {
    const { WebAPI } = this.rootStore;

    return WebAPI.patch(
      `teacher/courses/${this.id}/students/${student.id}`,
      {
        student: {
          password: newPass,
          password_confirmation: newPass,
        },
      },
    );
  }

  @action removeStudent(student) {
    const {
      students,
      WebAPI,
    } = this.rootStore;

    return WebAPI.delete(
      `teacher/courses/${this.id}/students/${student.id}`,
    )
      .then(() => {
        students.removeFromCourse(this.id, student);
        this.studentCount -= 1;
      });
  }

  @action grantSharing(teacher) {
    const { WebAPI } = this.rootStore;
    const teacherId = teacher === Object(teacher) ? +teacher.id : +teacher;

    return WebAPI.post(
      `teacher/courses/${this.id}/share_courses`,
      { faculty_id: teacherId },
    )
      .then(action(() => {
        this.sharedWith.push(teacherId);
      }));
  }

  @action revokeSharing(teacher) {
    const { WebAPI } = this.rootStore;
    const teacherId = teacher === Object(teacher) ? +teacher.id : +teacher;

    return WebAPI.delete(
      `teacher/courses/${this.id}/share_courses/${teacherId}`,
    )
      .then(action(() => {
        this.sharedWith = this.sharedWith.filter((id) => id !== teacherId);
      }));
  }

  @action globalKeyboardingLeaders() {
    if (!this.keyboardingGlobal) {
      const url = 'gaming/leaderboard/global';
      const { WebAPI } = this.rootStore;

      WebAPI.get(url)
        .then((results) => {
          this.keyboardingGlobal = results.leaders;
        });
    }

    return this.keyboardingGlobal;
  }

  @action keyboardingLeaders() {
    if (!this.keyboardingCourse) {
      const url = `gaming/leaderboard/${this.id}`;
      const { WebAPI } = this.rootStore;

      WebAPI.get(url)
        .then((results) => {
          this.keyboardingCourse = results.leaders;
        });
    }

    return this.keyboardingCourse;
  }

  @action getQuizChallengeResults(type) {
    const { games } = this.rootStore;

    return games.getQuizChallengeResults(type, this);
  }

  @action generateCertificates(module = 'ALL', minimumGrade = 90, student = 'ALL') {
    const studentId = (student !== 'ALL' ? +student : null);
    const minimum = minimumGrade;
    const { WebAPI } = this.rootStore;

    return WebAPI.post(
      `teacher/courses/${this.id}/reports/student_certificate`,
      {
        module,
        minimum,
        student_id: studentId,
      },
    )
      .then((result) => WebAPI.pollJob(result.job_id));
  }

  @action generateExcel(type, options = {}) {
    const { WebAPI } = this.rootStore;

    const {
      assignment,
      display,
      calculation,
    } = options;
    if (type === 'grades') {
      return WebAPI.get(
        `teacher/courses/${this.id}/reports/grades_by_module.xls`,
        {
          assignment_id: assignment,
          display,
          calculation,
          new: true,
        },
        { binary: true },
      );
    }
    if (type === 'time online') {
      return WebAPI.get(
        `teacher/courses/${this.id}/reports/student_time_online.xls`,
        {
          all_courses: !!options.allCourses,
          start_date: options.startDate,
          end_date: options.endDate,
        },
        { binary: true },
      );
    }
    if (type === 'prepost') {
      return WebAPI.get(
        `teacher/courses/${this.id}/reports/prepost.xls`,
        { display },
        { binary: true },
      );
    }

    return Promise.reject(new Error('No such report'));
  }

  @action generateWorksheets(params) {
    const { WebAPI } = this.rootStore;
    return WebAPI.get(`teacher/courses/${this.id}/worksheets/export`, params)
      .then((result) => WebAPI.pollJob(result.job_id));
  }

  @action generateLTICartridge() {
    const { WebAPI } = this.rootStore;
    return WebAPI.get(`teacher/courses/${this.id}/assignments/cartridge`)
      .then((result) => WebAPI.pollJob(result.job_id));
  }

  @action generateQuestionAnalysis() {
    const { WebAPI } = this.rootStore;

    return WebAPI.get(
      `teacher/courses/${this.id}/reports/question_analysis`,
    )
      .then((result) => WebAPI.pollJob(result.job_id, 1500, 120000));
  }

  @action
  async getStudentTimeOnline(start, end) {
    const { WebAPI } = this.rootStore;

    const data = await WebAPI.get(
      `teacher/courses/${this.id}/reports/student_time_online`,
      {
        all_courses: false,
        start_date: moment(start)
          .format(),
        end_date: moment(end)
          .format(),
      },
    );

    return data;
  }

  @action initiateIntegration(provider) {
    this.pendingIntegration = provider;
  }

  @action cancelIntegration() {
    this.pendingIntegration = '';
  }

  @action startUpgrade() {
    const { courses } = this.rootStore;
    return courses.upgradeCourse(this.id);
  }

  // get roster providers based on course id
  // TODO: write tests
  async getCourseProviders() {
    try {
      const { WebAPI } = this.rootStore;
      const providers = await WebAPI.get(`teacher/courses/${this.id}/rosters`);

      return providers;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return error;
    }
  }

  @computed get isRostered() {
    return this.providers.length > 0;
  }

  // get job after selecting provider based on course id and provider
  // TODO: write tests
  async getCourseProviderJob(provider) {
    try {
      const { WebAPI } = this.rootStore;
      const job = await WebAPI.get(`teacher/courses/${this.id}/rosters?provider=${provider}`);

      return job;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
  }

  // poll job and get roster data based on job id
  // TODO: write tests
  getProviderJobData(jobId) {
    const { WebAPI } = this.rootStore;

    return WebAPI.pollJob(jobId);
  }

  // provision class
  // TODO: write tests
  provisionClass(provider, id, courseId) {
    const { WebAPI } = this.rootStore;

    return WebAPI.post(
      `teacher/courses/${courseId}/rosters`,
      {
        provider,
        section_id: id,
      },
    )
      .then((result) => WebAPI.pollJob(result.job_id));
  }

  // sync class data
  refreshProviderData(courseId) {
    const { WebAPI } = this.rootStore;

    return WebAPI.put(`teacher/courses/${courseId}/rosters`);
  }

  getLessonStatusReport(courseId, enrollmentId) {
    const { WebAPI } = this.rootStore;
    if (enrollmentId) {
      return WebAPI.get(`teacher/courses/${courseId}/reports/lesson_status?enrollment_id=${enrollmentId}`);
    }
    return WebAPI.get(`teacher/courses/${courseId}/reports/lesson_status`);
  }

  getLessonStatusReportData(jobId) {
    const { WebAPI } = this.rootStore;
    return WebAPI.pollJob(jobId);
  }
}

export default Course;
