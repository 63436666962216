import {
  computed, extendObservable, action, toJS, runInAction,
} from 'mobx';
import moment from 'moment';
import defaultAvatar from 'data/img/avatar.png';

export default class User {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    let parsedOptions;
    try {
      parsedOptions = JSON.parse(input.options);
    } catch (e) {
      parsedOptions = {};
    }

    extendObservable(this, {
      id: +input.id || 0,
      firstName: input.firstName || input.first_name || 'Unknown',
      lastName: input.lastName || input.last_name || 'User',
      email: input.email || '',
      type: input.type || 'FACULTY',
      role: input.role || 'INSTRUCTOR',
      skin: input.skin || null,
      subjects: input.subjects || [],
      downloads: input.downloads || 0,
      createdAt: input.created_at,
      hmac: input.hmac || '',
      avatar: input.avatar,
      options: parsedOptions || {
        showGrades: true,
        showInProgress: true,
        showWorksheets: false,
      },
      logons30: input.logons30 || +input.logon_count_30,
      studentLogons30: input.students30 || +input.student_logon_30,
      studentLogons90: input.students90 || +input.student_logon_90,
      trialScore: input.trialScore || +input.trial_score,
      courseCount: input.courseCount || +input.course_count,
      customName: input.custom_name,
      mobileNumber: input.mobileNumber || input.mobile_phone,
      mobileVerified: input.mobileVerified || input.mobile_verified,
      singleSignOnConnections: input.sso || [],
      persona: input.persona,
    });

    // PLAT-286 Force the ShowGrades setting - one teacher had it turned off
    // from a previous iteration of the gradebook. The setting should
    // eventually be refactored out. TODO
    this.options.showGrades = true;

    if (input.avatar_content_type) {
      this.avatar = {
        content_type: input.avatar_content_type,
        file_name: input.avatar_file_name,
        file_size: input.avatar_file_size,
        medium_url: input.avatar_medium_url,
        original_url: input.avatar_original_url,
        thumb_url: input.avatar_thumb_url,
        tiny_url: input.avatar_tiny_url,
      };
    }
  }

  @computed get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed get created() {
    if (this.createdAt) return new Date(this.createdAt).valueOf();
    return Date.now();
  }

  @computed get avatarUrl() {
    if (!this.avatar) return defaultAvatar;
    return this.avatar.original_url;
  }

  @computed get isAdmin() {
    return this.role === 'ADMIN' || this.role === 'AES';
  }

  @computed get suppressUpdate() {
    const { hideUpdateUntil = moment(0, 'H') } = this.options;
    return moment().isBefore(hideUpdateUntil);
  }

  @computed get userHasUpgraded() {
    return this.rootStore.courses.hasUpgraded;
  }

  @action updateProfile(params) {
    const { WebAPI } = this.rootStore;

    return WebAPI.patch('teacher/profile', { faculty: params })
      .then(action((res) => {
        this.firstName = res.first_name;
        this.lastName = res.last_name;
        this.customName = res.custom_name;
      }));
  }

  @action setFirstName(val) {
    this.firstName = val;
    return this.updateProfile({ first_name: val });
  }

  @action setLastName(val) {
    this.lastName = val;
    return this.updateProfile({ last_name: val });
  }

  @action setOption(key, val) {
    const { options = {} } = this;
    this.options = { ...options, [key]: val };
    return this.updateProfile({ options: JSON.stringify(this.options) });
  }

  @action getOption(key) {
    if (!this.options) {
      this.options = {};
    }
    return this.options[key];
  }

  @action updatePersona(persona) {
    this.persona = persona;
    this.updateProfile({ persona });
  }

  @action setSkin(skin) {
    const accepted = ['health', 'bcit'];
    if (skin !== 'null' && accepted.includes(skin)) {
      this.skin = skin;
    } else {
      this.skin = null;
    }
    return this.updateProfile({ skin: this.skin });
  }

  @action setSubjects(subjects) {
    if (!Array.isArray(toJS(subjects))) {
      this.subjects = [];
    } else {
      this.subjects = subjects;
    }
    return this.updateProfile({ subjects: this.subjects.length ? this.subjects.join(',') : null });
  }

  @action updateAvatar(file) {
    const { WebAPI } = this.rootStore;
    const form = new FormData();
    form.append('faculty[avatar]', file);

    return WebAPI.put('teacher/profile/avatar', form)
      .then(action((result) => {
        if (result.avatar_content_type) {
          this.avatar = {
            content_type: result.avatar_content_type,
            file_name: result.avatar_file_name,
            file_size: result.avatar_file_size,
            medium_url: result.avatar_medium_url,
            original_url: result.avatar_original_url,
            thumb_url: result.avatar_thumb_url,
            tiny_url: result.avatar_tiny_url,
          };
        }
      }));
  }

  @action addDownload(url, module, resourceParam, isAnswerKey) {
    const { WebAPI } = this.rootStore;

    // Use module for the resource title if missing (module test answer keys)
    const resource = resourceParam || module;

    const params = {
      template_key: (resource.template_key || resource.templateKey),
      module_key: (module.key || resource.module_key || resource.moduleKey),
      unit_key: (resource.unit_key || resource.unitKey),
      url,
      title: resource.title,
    };

    if (isAnswerKey) {
      params.title = `${resource.title} Answer Key`;
    }

    WebAPI.post('teacher/events', {
      event: {
        action: 'download',
        ...params,
      },
    });

    this.downloads += 1;
  }

  @action loadSingleSignOn() {
    const { WebAPI } = this.rootStore;

    WebAPI.get('teacher/profile')
      .then((result) => runInAction(() => {
        this.singleSignOnConnections = result.single_sign_ons;
      }));
  }

  @action connectProvider(provider, code) {
    const { WebAPI, auth: { token } } = this.rootStore;
    WebAPI.post('authenticate/connect', {
      token,
      provider,
      code,
    }).then((result) => runInAction(() => this.singleSignOnConnections.push(result)));
  }

  @action setSuppressUpdate(value = 'tomorrow') {
    const hideUntil = moment(0, 'H');

    switch (value) {
      case 'tomorrow':
        hideUntil.add(1, 'day');
        break;
      case 'week':
        hideUntil.add(7, 'day');
        break;
      default:
        hideUntil.add(100, 'year');
        break;
    }

    this.setOption('hideUpdateUntil', hideUntil.valueOf());
  }
}
