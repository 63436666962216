import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';
import Dropdown from 'widgets/form/Dropdown';

@inject('assessmentOpens')
@observer
class OpenOverride extends Component {
  overrideOptions = {
    3600000: '1 hour',
    28800000: '8 hours',
    86400000: '24 hours',
    172800000: '2 days',
    259200000: '3 days',
    432000000: '5 days',
    604800000: '7 days',
  }

  @observable overrideTime

  @observable showDialog = false

  @action.bound openDialog = () => {
    this.overrideTime = null;
    this.showDialog = true;
  }

  @action.bound closeDialog = () => { this.showDialog = false; }

  @action.bound setOverrideTime = (value) => { this.overrideTime = +value; }

  @action.bound createOverride() {
    const { assessmentOpens, entry, student } = this.props;
    assessmentOpens.openAssessment({
      entry,
      student,
      expiration: new Date(Date.now() + this.overrideTime),
    })
      .finally(this.closeDialog);
  }

  render() {
    const { entry, student } = this.props;

    if (!entry) {
      return null;
    }

    const title = entry.type === 2 ? entry.assignment.title : entry.title;
    const testName = `${entry.prettyType}: ${title}`;

    const pacingStatus = () => {
      const assignmentStatus = student.pacingAssignments.get(entry.assignment.id);
      const entryStatus = student.pacingEntries.get(entry.id);

      if (assignmentStatus && assignmentStatus.status === 'Closed') {
        return { open: false, message: `Module Closed: ${assignmentStatus.reason}` };
      } if (entry.isTest && entryStatus && entryStatus.status === 'Closed') {
        return { open: false, message: `Test Closed: ${entryStatus.reason}` };
      } if (entry.isTest && entryStatus && entryStatus.quiz_status === 'Closed') {
        return { open: false, message: `Test Closed: ${entryStatus.quiz_reason}` };
      } if (entryStatus && entryStatus && entryStatus.status === 'Closed') {
        return { open: false, message: `Unit Closed: ${entryStatus.reason}` };
      } if (entryStatus && entryStatus && entryStatus.quiz_status === 'Closed') {
        return { open: false, message: `Quiz Closed: ${entryStatus.quiz_reason}` };
      }
      return { open: true, message: 'Open' };
    };

    const { open, message } = pacingStatus();
    const translated = message.replace(': No pacing', ' by teacher');

    return (
      <span>
        <span>{translated}</span>

        {!entry.unitKey.match(/^PREMT_/) && !open
          && (
          <span>
            &nbsp;
            <small>
              (
              <button type="button" className="anchor" onClick={this.openDialog}>Open</button>
              )
            </small>
          </span>
          )}
        <Modal
          visible={this.showDialog}
          title="Open Assessment"
          onDismiss={this.closeDialog}
        >
          <div className="openOverride">
            <div>
              <b>Student:</b>
              {' '}
              {student.name}
            </div>
            <div>
              <b>Assessment:</b>
              {' '}
              {testName}
            </div>
            <div>
              <b>Open for:</b>
              <Dropdown
                options={this.overrideOptions}
                value={this.overrideTime}
                onChange={this.setOverrideTime}
                inline
              />
            </div>
          </div>

          <div style={{ textAlign: 'right' }}>
            <button
              type="button"
              onClick={this.createOverride}
              disabled={this.overrideTime == null}
            >
              Open
            </button>
            <button type="button" className="secondary" onClick={this.closeDialog}>Cancel</button>
          </div>
        </Modal>
      </span>
    );
  }
}

export default OpenOverride;
