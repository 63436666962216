/*
 * README
 * In the end each form validation should return a function that accepts
 * a value as an input and returns a falsy value if validation succeeds, or
 * an object with the following form if it fails:
 *
 * {
 *  error: '<code>',
 *  [additional_field: <value>,]
 * }
 *
 * Validations can accept parameters and then return a function that
 * uses them later.
 */

export function NotBlank(value) {
  if (!value) {
    return { error: 'blank' };
  }

  return false;
}

export function NotEmpty(value) {
  if (!value) {
    return { error: 'empty' };
  }

  return false;
}

export function Email(value) {
  const email = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;

  if (!email.test(value)) {
    return { error: 'email' };
  }

  return false;
}

export function MinLength(len) {
  return (value) => {
    if (!value || value.length < len) {
      return { error: 'too_short', count: len };
    }

    return false;
  };
}

export function Password(value) {
  if (!value) {
    return { error: 'pass' };
  }

  return MinLength(6)(value);
}

export function PasswordVerify(field) {
  const validator = (value, linked) => {
    if (value !== linked) {
      return { error: 'pass_match' };
    }

    return false;
  };

  validator.linkedField = field;

  return validator;
}

export function PhoneNumber(value) {
  const phoneFormat = /^(\(\d\d\d\)|\d\d\d-?)\s*\d\d\d\s*-?\s*\d\d\d\d$/;

  if (!value.match(phoneFormat)) {
    return { error: 'phone' };
  }

  return false;
}

export function Optional(value) {
  if (!value) return true;
  return false;
}

export function translateError(validation) {
  if (!validation) {
    return 'This field isn\'t valid';
  }
  const { count } = validation;
  switch (validation.error) {
    case 'inclusion': return 'Please enter a valid choice';
    case 'exclusion': return 'Please choose a different value';
    case 'invalid': return 'Please enter a valid choice';
    case 'empty': return 'This field can\'t be blank';
    case 'blank': return 'This field can\'t be blank';
    case 'present': return 'This field must be blank';
    case 'too_long': return `Please enter at most ${count} characters`;
    case 'too_short': return `Please enter at least ${count} characters`;
    case 'wrong_length': return `Please enter ${count} characters`;
    case 'not_a_number': return 'Please enter a number';
    case 'not_an_integer': return 'Please enter only whoile numbers';
    case 'greater_than': return `Please enter a value over ${count}`;
    case 'greater_than_or_equal_to': return `Please enter a value of ${count} or more`;
    case 'equal_to': return `This field must be ${count}`;
    case 'less_than': return `Please enter a value under ${count}`;
    case 'less_than_or_equal_to': return `Please enter a value of ${count} or less`;
    case 'other_than': return `This field can't be ${count}`;
    case 'odd': return 'Please enter an odd number';
    case 'even': return 'Please enter an even number';
    case 'pass': return 'Please enter a password';
    case 'pass_match': return 'Passwords must match';
    case 'email': return 'Please enter a valid email address';
    case 'auth': return 'That password is incorrect';
    case 'phone': return 'Please enter a 10 digit phone number';
    case 'taken': return 'Is taken by another user';
    default: return validation.message || 'This field isn\'t valid';
  }
}

export function translateField(f) {
  switch (f) {
    case 'first_name': return 'First name';
    case 'last_name': return 'Last name';
    default: return f;
  }
}

export function convertValidationErrors(errors) {
  return Object.keys(errors).map((e) => {
    const error = errors[e][0];
    return `${translateField(e)}: ${translateError(error)}`;
  });
}

export default {
  NotBlank,
  Email,
  Password,
  PasswordVerify,
  MinLength,
  PhoneNumber,
  Optional,
  translateError,
  convertValidationErrors,
};
