import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import FormErrors from 'widgets/form/FormErrors';
import Modal from 'widgets/Modal';
import { convertValidationErrors } from 'util/FormValidation';

// NOTE - Don't use field names of password
// or email. Otherwise password managers (lastpass)
// will fill in with the teacher's data

@inject('courses', 'students', 'router') @observer
class ManualEnrollment extends Component {
  @observable email = ''

  @observable firstName = ''

  @observable lastName = ''

  @observable password = ''

  @observable formErrors = null

  @observable validation = null

  @observable student = null

  @observable step = null

  @observable stepSubmitted = null

  @action.bound handleEmailChange({ target: { value } }) {
    this.email = value.trim();
  }

  @action.bound handleFirstNameChange(e) {
    this.firstName = e.target.value;
  }

  @action.bound handleLastNameChange(e) {
    this.lastName = e.target.value;
  }

  @action.bound handlePasswordChange(e) {
    this.password = e.target.value;
  }

  @action setFormError(errors) {
    this.formErrors = convertValidationErrors(errors);
  }

  @action.bound cancelEnrollment = () => {
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.password = '';
    this.step = null;
  }

  @action.bound nextStep = () => { this.step += 1; }

  @action.bound prevStep = () => { this.step -= 1; }

  @action.bound setEmail = (val) => { this.email = val; }

  @action.bound startEnrollment = () => {
    this.step = 1;
    this.stepSubmitted = null;
    this.formErrors = null;
    this.validation = null;
  }

  @action.bound async verifyEmail() {
    const { email } = this;
    this.stepSubmitted = 1;
    if (email.length < 1) {
      return;
    }
    const { courses: { fromRoute: course }, students } = this.props;

    if (course.students.find((s) => s.email === email)) {
      this.validation = 'In Course';
    } else {
      const status = await students.validate(email);
      this.setEmail(email);
      this.parseValidation(status);
    }

    this.nextStep();
  }

  @action parseValidation(status) {
    this.validation = status.validation;
    this.student = status.student;
  }

  @action.bound async addExistingStudent() {
    const { courses: { fromRoute: course }, router } = this.props;

    const student = await course.addExistingStudent(this.student.id);
    this.cancelEnrollment();

    router.push(`/courses/${course.id}/students/list/${student.id}`);
  }

  @action.bound async addNewStudent() {
    this.stepSubmitted = this.step;
    const { courses: { fromRoute: course }, router } = this.props;
    const fields = {
      email: this.email,
      first_name: this.firstName,
      last_name: this.lastName,
      password: this.password,
      password_confirmation: this.password,
    };

    try {
      const student = await course.addNewStudent(fields);
      this.cancelEnrollment();
      router.push(`/courses/${course.id}/students/list/${student.id}`);
    } catch (ex) {
      this.setFormError(ex.validation);
    }
  }

  render() {
    const {
      email, firstName, lastName, password,
    } = this;
    return (
      <div>
        <button type="button" className="lgButton" onClick={this.startEnrollment} data-testid="enroll_button">
          Enroll a Student
        </button>

        <Modal
          title="Enroll a Student"
          visible={this.step === 1}
          onDismiss={this.cancelEnrollment}
          data-testid="step_1"
        >
          <form>
            <p>
              Enter the student&rsquo;s school email address
              <br />
              or other unique identifier:
            </p>

            <input
              name="field001"
              type="text"
              id={this.uid}
              value={email}
              placeholder="Email"
              onChange={this.handleEmailChange}
              className="enrollInput"
            />
            <br />
            <FormErrors />
            {this.stepSubmitted === 1 && email.length < 1
              && <p style={{ color: '#941C5D', marginTop: 0, marginBottom: 0 }}>Email cannot be blank.</p>}
            <br />
            <button type="button" onClick={this.verifyEmail} className="emailNext">Next</button>
            <button type="button" onClick={this.cancelEnrollment} className="secondary">Cancel</button>
          </form>
        </Modal>

        <Modal
          title="Enroll a Student"
          visible={this.step === 2 && this.validation === 'In Course'}
          onDismiss={this.cancelEnrollment}
          data-testid="step_2_in_course"
        >
          <p>That student is already enrolled in this course.</p>

          <button type="button" onClick={this.prevStep} className="secondary">Back</button>
        </Modal>

        <Modal
          title="Enroll a Student"
          visible={(this.step === 2 && this.validation === 'In Site') || this.validation === 'Added'}
          onDismiss={this.cancelEnrollment}
          data-testid="step_2_in_site"
        >
          <h2>Student Enrollment</h2>
          <p>You are about to add the following student into your class.</p>

          {this.student && (
          <div>
            <div className="formField">
              <strong>Name:</strong>
              {' '}
              {this.student.name}
            </div>
            <div className="formField">
              <strong>Email/ID:</strong>
              {' '}
              {this.student.email}
            </div>
          </div>
          )}

          <button type="button" onClick={this.addExistingStudent}>Enroll</button>
          <button type="button" onClick={this.cancelEnrollment} className="secondary">Cancel</button>
        </Modal>

        <Modal
          title="Enroll a Student"
          visible={this.step === 2 && this.validation === 'Available'}
          onDismiss={this.cancelEnrollment}
          data-testid="step_2_create"
        >
          <form>
            <p>Enter the students details</p>

            <div className="formField">
              Email/ID:
              {' '}
              {this.email}
            </div>

            <div className="enrollForm">
              <div className="enrollField">
                <label htmlFor="first_name">
                  <span>First Name:</span>
                  <input
                    name="first_name"
                    type="text"
                    id={this.uid}
                    value={firstName}
                    placeholder="First Name"
                    onChange={this.handleFirstNameChange}
                    className="enrollInput"
                  />
                </label>
              </div>
              <div className="enrollField">
                <label htmlFor="lastName">
                  <span>Last Name:</span>
                  <input
                    name="last_name"
                    type="text"
                    id={this.uid}
                    value={lastName}
                    placeholder="Last Name"
                    onChange={this.handleLastNameChange}
                    className="enrollInput"
                  />
                </label>
              </div>
              <div className="enrollField">
                <label htmlFor="field002">
                  <span>Password:</span>
                  <input
                    name="field002"
                    type="text"
                    id={this.uid}
                    value={password}
                    placeholder="Password"
                    onChange={this.handlePasswordChange}
                    className="enrollInput"
                  />
                </label>
              </div>
              <FormErrors errors={this.formErrors} />

              {this.stepSubmitted === 2 && password.length < 6
                && <p style={{ marginTop: 0, marginBottom: 0, color: '#941C5D' }}>Password must be at least 6 characters long.</p>}

              <div className="enrollField buttons" style={{ marginTop: '15px' }}>
                <button type="button" onClick={this.addNewStudent}>Finish</button>
                <button type="button" onClick={this.cancelEnrollment} className="secondary">Cancel</button>
              </div>
            </div>

          </form>
        </Modal>
      </div>
    );
  }
}

export default ManualEnrollment;
