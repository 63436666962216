import React from 'react';
import './Alert.scss';
import MaterialIcon from './MaterialIcon';

const getTypeIcon = (type) => {
  switch (type) {
    case 'success':
      return 'done';
    case 'banner':
    case 'warning':
      return 'warning';
    default:
      return 'info';
  }
};

const Alert = ({
  actions,
  children,
  message,
  type = 'error',
}) => (
  <div className={`alert ${type}`} role="alert">
    <MaterialIcon className="md-24">
      { getTypeIcon(type)}
    </MaterialIcon>
    <p>{children || message}</p>
    { actions
      ? <div className="alert-actions">{ actions }</div>
      : null }
  </div>
);

export default Alert;
