import React, { useEffect, useState } from 'react';
import ProgressSpinner from '../../../widgets/ProgressSpinner';

const ClassCopyProcessing = ({ copyPhrasing = 'copied', onClickClose }) => {
  const [isSlowJob, setSlowJob] = useState(false);

  useEffect(() => {
    setTimeout(() => setSlowJob(true), 20000);
  }, []);

  return (
    <div className="copy-processing">
      <ProgressSpinner />
      { isSlowJob
        ? (
          <React.Fragment>
            <p>
              This class has a large number of custom questions that need to be loaded
              so it might take a bit longer.
            </p>
            <p>
              You can wait for the class to load
              or close this dialog to access your other classes.
            </p>
            <button type="button" onClick={onClickClose}>Close</button>
          </React.Fragment>
        )
        : (
          <p>
            {`Your class will be ${copyPhrasing} momentarily.`}
          </p>
        ) }
    </div>
  );
};

export default ClassCopyProcessing;
