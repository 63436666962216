import React from 'react';
import { inject, observer } from 'mobx-react';
import ToggleSlider from 'widgets/ToggleSlider';
import ResourceList from './ResourceList';
import Lesson from './Lesson';

const Unit = ({
  curriculum,
  unit,
  entry,
  approved,
  phaseTitle,
  unitNumber,
  moduleKey,
  showLessonViewer,
  checkTest,
}) => {
  const key = entry ? entry.key : unit.key;
  const enabled = entry ? entry.enabled : true;
  const title = entry ? entry.title : unit.title;
  const hours = entry ? entry.hours : unit.hours;
  const curriculumKey = entry ? entry.id : undefined;
  const lessonKeys = curriculum.unitsByKey[unit.key]?.lessons;
  const longTitle = `Unit ${unitNumber}: ${title}`;
  const showQuestionExcludedText = () => {
    if (checkTest) {
      return phaseTitle.includes('Learn') ? entry.hasQuiz : false;
    }
    return false;
  };

  return (
    <li key={curriculumKey} className="curriculumUnit">
      <div className="desc">
        {phaseTitle.includes('Learn')
          && (
          <h2 style={enabled
            ? { marginBottom: 0, marginTop: 0 }
            : { opacity: 0.5, marginBottom: 0, marginTop: 0 }}
          >
            {longTitle}
          </h2>
          )}
        {!phaseTitle.includes('Learn') && (
          <h2 style={enabled
            ? { marginBottom: 0, marginTop: 0 }
            : { opacity: 0.5, marginBottom: 0, marginTop: 0 }}
          >
            {title}
          </h2>
        )}
        <p style={enabled
          ? { marginBottom: 0, marginTop: 0 }
          : { opacity: 0.5, marginBottom: 0, marginTop: 0 }}
        >
          <span>
            {hours}
            {' '}
            hour
            {hours !== 1 ? 's' : null}
            {' '}
            est.
          </span>
          <br />
          {unit.description}
        </p>
        {enabled && lessonKeys && lessonKeys.length > 0
          && (
          <div>
            <h3 style={{
              paddingTop: 10, paddingBottom: 5, margin: 0, fontWeight: 'normal', color: '#000',
            }}
            >
              Lessons
            </h3>
            <ol style={{ type: '1' }}>
              {lessonKeys.map((lessonKey) => (
                <Lesson
                  key={lessonKey}
                  lessonKey={lessonKey}
                  showLessonViewer={showLessonViewer}
                />
              ))}
            </ol>
          </div>
          )}
        {!enabled && showQuestionExcludedText() && (
          <p>
            This unit has been excluded from the module.
            Questions from this unit will not appear on the module test.
          </p>
        )}
        {!enabled && !showQuestionExcludedText()
          && <p>This unit has been excluded from the module.</p>}
      </div>
      {approved && (
      <div className="resources" style={enabled ? null : { opacity: 0.5 }}>
        {(unit.hasQuiz || unit.teacherResources.length > 0)
          && (
          <ResourceList
            title="Teacher Resources"
            moduleKey={moduleKey}
            unit={unit}
            entry={entry}
            resources={unit.teacherResources}
            showQuiz
          />
          )}
        {unit.sampleAnswers.length > 0
          && (
          <ResourceList
            title="Sample Answers"
            moduleKey={moduleKey}
            unit={unit}
            entry={entry}
            resources={unit.sampleAnswers}
          />
          )}
      </div>
      )}
      {approved && (
      <div className="resources" style={enabled ? null : { opacity: 0.5 }}>
        {unit.studentResources.length > 0
          && (
          <ResourceList
            title="Student Resources"
            moduleKey={moduleKey}
            unit={unit}
            entry={entry}
            resources={unit.studentResources}
          />
          )}
        {unit.checklistResources.length > 0
          && (
          <ResourceList
            title="Skills Checklists"
            moduleKey={moduleKey}
            unit={unit}
            entry={entry}
            resources={unit.checklistResources}
          />
          )}
      </div>
      )}
      <div className="options noPrint">
        <div>
          <div className="unitControls">
            {entry && key !== 'MODULE'
              && (
              <ToggleSlider
                id={`unitIncluded${entry.id}`}
                titleOn="Unit Included"
                titleOff="Unit Excluded"
                onClick={() => entry.setEnabled(!enabled)}
                active={!!enabled}

              />
              )}
          </div>
          <div className={enabled && entry && (entry.hasLessons || entry.hasQuiz) ? 'unitEnabled' : 'unitDisabled'}>
            {entry && entry.hasLessons
            && (
            <div className="unitControls">
              <ToggleSlider
                id={`lessonOpen${entry.id}`}
                titleOn="Unit Open"
                titleOff="Unit Closed"
                onClick={() => entry.setLessonsOpen(!entry.lessonsOpen)}
                active={entry.lessonsOpen}
              />
            </div>
            )}
            {entry && entry.hasQuiz
            && (
              <div className="unitControls">
                <ToggleSlider
                  id={`quizOpen${entry.id}`}
                  titleOn="Quiz Open"
                  titleOff="Quiz Closed"
                  onClick={() => entry.setQuizOpen(!entry.quizOpen)}
                  active={entry.quizOpen}
                />
              </div>
            )}
          </div>
        </div>

      </div>
    </li>
  );
};

export default inject('auth', 'curriculum')(observer(Unit));
