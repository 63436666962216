import React, { Component } from 'react';
import { action, observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import TeacherInvite from 'widgets/teacher/TeacherInvite';
import TeacherList from 'widgets/teacher/TeacherList';
import { convertValidationErrors } from 'util/FormValidation';

@inject('auth', 'teachers') @observer
class Teachers extends Component {
  @observable inviteDialog = false;

  @observable firstName= '';

  @observable lastName= '';

  @observable email= '';

  @observable school= '';

  @observable title= '';

  @observable reason= '';

  @observable otherReason= '';

  @observable teacherExists= false;

  @observable validationErrors= null;

  @action.bound handleInputChange(type, value) {
    if (value) {
      this[type] = value;
    } else {
      this[type] = '';
    }
  }

  @action.bound showInvite = () => { this.inviteDialog = true; }

  @action.bound hideInvite() {
    this.inviteDialog = false;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.school = '';
    this.title = '';
    this.reason = '';
    this.otherReason = '';
    this.teacherExists = false;
    this.validationErrors = null;
  }

  @action.bound addTeacher() {
    const { teachers } = this.props;
    const {
      firstName,
      lastName,
      email,
      school,
      title,
      reason,
      otherReason,
    } = this;

    // Check if email is already in the list
    if (teachers.list.find((t) => t.email === email.trim())) {
      this.teacherExists = true;
      return;
    }

    // Use the Text Entry when Other is Selected
    const selectedReason = (reason !== 'other' ? reason : otherReason);

    const faculty = {
      first_name: firstName,
      last_name: lastName,
      email,
      school,
      title,
      reason: selectedReason,
    };

    // Manual Validation for Non Database Fields
    if ((title || '').length === 0) {
      this.validationErrors = ['Title: This field cannot be blank'];
    } else if ((school || '').length === 0) {
      this.validationErrors = ['School: This field cannot be blank'];
    } else if ((selectedReason || '').length === 0) {
      this.validationErrors = ['Reason: This field cannot be blank'];
    } else {
      // create faculty member
      teachers.createOrAdd(faculty)
        .then(this.hideInvite)
        .catch((errors) => {
          if (errors.validation) {
            runInAction(() => {
              this.validationErrors = convertValidationErrors(errors.validation);
            });
          }
        });
    }
  }

  render() {
    const { auth: { site, user }, teachers } = this.props;
    const {
      firstName,
      lastName,
      email,
      school,
      title,
      reason,
      otherReason,
      teacherExists,
      validationErrors,
    } = this;
    return (
      <div>
        <h1>Teachers</h1>
        <div style={{ maxWidth: 1000 }}>
          <div style={{ textAlign: 'right' }}>
            <button type="button" onClick={this.showInvite}>+ Add Another Teacher</button>
          </div>
          <TeacherList
            teachers={teachers.list}
            siteName={site.name}
            currentUserId={user.id}
          />
        </div>
        { this.inviteDialog
          ? (
            <TeacherInvite
              values={{
                firstName,
                lastName,
                email,
                school,
                title,
                reason,
                otherReason,
              }}
              visible={this.inviteDialog}
              onDismiss={this.hideInvite}
              handleInputChange={this.handleInputChange}
              addTeacher={this.addTeacher}
              teacherExists={teacherExists}
              validationErrors={validationErrors}
              reason={reason}
            />
          )
          : null }
      </div>
    );
  }
}

export default Teachers;
