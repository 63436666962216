import React from 'react';
import { inject, observer } from 'mobx-react';
import Checkbox from 'widgets/form/Checkbox';
import RadioButtons from 'widgets/form/RadioButtons';

const PacingSequencing = ({ courses: { fromRoute: course }, auth }) => (
  <div>
    <h1>Pacing &amp; Sequencing</h1>

    {!auth.site.hasAdvancedPacing && (
    <div>
      Your package doesn&rsquo;t provide access to due dates and advanced pacing.
      <br />
      Please contact our support team if you would like to evaluate this feature.
    </div>
    )}
    {auth.site.hasAdvancedPacing && (
    <div>
      <span>Automatically hand-in assessments after 60 minutes of student inactivity:</span>
      <div className="formGroup">
        <Checkbox
          label="Quizzes"
          noMargin
          value={course.autoCloseQuiz}
          onChange={(value) => course.setCanResume('Quiz', !value)}
        />
        <Checkbox
          label="Tests"
          noMargin
          value={course.autoCloseTest}
          onChange={(value) => course.setCanResume('Test', !value)}
        />
        <Checkbox
          label="Exams"
          noMargin
          value={course.autoCloseExam}
          onChange={(value) => course.setCanResume('Exam', !value)}
        />
      </div>

      <span>When an assignment&rsquo;s due date has passed:</span>
      <div className="formGroup">
        <RadioButtons
          options={{
            CLOSE: 'Close the assignment.',
            NOTHING: 'Do nothing.',
          }}
          value={course.dueDateAction}
          onChange={(value) => course.setDueDateAction(value)}
        />
      </div>

      <h2>Advanced Pacing</h2>
      <div>
        <Checkbox
          label="Require students to complete the previous module in the class before starting the next."
          value={course.pacingModuleOrder}
          onChange={(value) => course.setPacingModuleOrder(value)}
          noMargin
        />
        <Checkbox
          label="Require students to complete all units in a module before taking the module test."
          value={course.pacingModuleTest}
          onChange={(value) => course.setPacingModuleTest(value)}
          noMargin
          style={{ marginLeft: 22 }}
        />
        <Checkbox
          label="Require students to complete all lessons in a unit before taking the unit quiz."
          value={course.lessonBlock}
          onChange={(value) => course.setLessonBlock(value)}
          noMargin
          style={{ marginLeft: 44 }}
        />
      </div>
      <p>
        <a
          href="https://help.aeseducation.com/questions-and-answers/how-pacing-works"
          target="_blank"
          rel="noreferrer noopener"
        >
          <i className="material-icons">help</i>
&nbsp;Advanced Pacing Help
        </a>
      </p>
    </div>
    )}
  </div>
);

export default inject('courses', 'auth')(observer(PacingSequencing));
