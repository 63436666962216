import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import ModuleRow from './ModuleRow';
import Accordion from '../Accordion';
import ModuleIcon from './ModuleIcon';

const ModuleGroupRow = ({
  moduleGroup,
  selectedModules,
  addModule,
  addMultipleModules,
  removeModule,
  removeMultipleModules,
  showPopup,
}) => {
  const {
    key,
    title,
    imageUrl,
    minHours,
    maxHours,
    modules,
    moduleKeys,
    resource,
  } = moduleGroup;
  const selectedModulesInGroup = selectedModules
    .filter((moduleKey) => moduleKeys.includes(moduleKey));
  const hasSelectedModules = selectedModulesInGroup.length > 0;
  const [closed, setClosed] = useState(true);
  const toggleClosed = () => setClosed(!closed);
  const addAll = () => addMultipleModules(moduleKeys);
  const removeAll = () => removeMultipleModules(selectedModulesInGroup);

  return (
    <React.Fragment>
      <tr id={`module_group_${key}`} className="module-group">
        <td className="action">
          <button type="button" className="lightButton" onClick={toggleClosed}>
            {closed ? 'Show All' : 'Hide All'}
          </button>
        </td>
        <td className="image">
          <ModuleIcon title={title} image={imageUrl} />
        </td>
        <td colSpan={2}>
          <div
            className={`summary ${closed ? 'closed' : 'open'}`}
            role="button"
            tabIndex={0}
            aria-pressed={!closed}
            onClick={toggleClosed}
            onKeyPress={toggleClosed}
          >
            <h4>{title}</h4>
            <p className="module-count">
              { hasSelectedModules
                ? `${selectedModulesInGroup.length} of ${modules.length} Modules Added`
                : `${modules.length} Modules` }
            </p>
            <p className="hours">
              {minHours}
              {' to '}
              {maxHours}
              {' Curriculum Hours'}
            </p>
          </div>
        </td>
      </tr>
      <tr id={`module_group_details_${moduleGroup.key}`} className="module-group-details">
        <td colSpan={4}>
          <Accordion closed={closed}>
            <div className="description">
              {moduleGroup.description}
            </div>
            <div className="buttons">
              <button type="button" className="lightButton" onClick={hasSelectedModules ? removeAll : addAll}>
                { hasSelectedModules ? 'Remove All' : 'Add All'}
              </button>
              <a className="button" target="aes_course_guide" href={resource.url}>
                {`Download ${resource.title}`}
              </a>
            </div>
            <div className="modules">
              <table>
                {modules.map((module) => (
                  <ModuleRow
                    key={module.key}
                    module={module}
                    selectedModules={selectedModules}
                    addModule={addModule}
                    removeModule={removeModule}
                    showPopup={showPopup}
                  />
                ))}
              </table>
            </div>
          </Accordion>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default inject('auth')(observer(ModuleGroupRow));
