import React from 'react';
import { createPortal } from 'react-dom';
import {
  action,
  computed,
  observable,
  runInAction,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import Learnosity from 'services/learnosity';
import './LearnosityReport.scss';

const MarkCorrectButton = ({ elementId, markCorrect }) => createPortal(
  <button type="button" className="mark-correct" onClick={markCorrect}>Mark as Correct</button>,
  document.getElementById(elementId),
);

const CorrectBadge = ({ elementId }) => createPortal(
  <span className="chip correct">Correct</span>,
  document.getElementById(elementId),
);

@inject('grades')
@observer
class LearnosityReport extends React.Component {
  @observable questions = [];

  componentDidMount() {
    this.initializeLearnosity();
  }

  componentDidUpdate({ learnosityRequest: prevLearnosityRequest }) {
    const { learnosityRequest } = this.props;
    if (learnosityRequest !== prevLearnosityRequest) {
      this.initializeLearnosity();
    }
  }

  @action.bound onQuestionsLoaded(questions) {
    this.questions = questions;
  }

  @computed get incorrectQuestions() {
    return this.questions.filter((question) => !question.score);
  }

  @computed get correctQuestions() {
    return this.questions.filter((question) => !!question.score);
  }

  @action markCorrect(index) {
    const { grades, gradeId } = this.props;
    const question = this.incorrectQuestions[index];
    const { response_id: responseId } = question;

    grades.markLearnosityAnswerCorrect(gradeId, responseId)
      .then(() => runInAction(() => {
        question.score = 1;
      }));
  }

  @action initializeLearnosity() {
    const { learnosityRequest } = this.props;

    this.questions = [];
    Learnosity.initializeReport(learnosityRequest, {
      onQuestionsLoaded: this.onQuestionsLoaded,
    });
  }

  render() {
    const {
      correctQuestions,
      incorrectQuestions,
      props: { attemptId, assessmentTitle },
    } = this;
    const reportId = `assessment_detail_${attemptId}`;
    return (
      <section key={attemptId} className="learnosity">
        <h2>
          {`Details for the Most Recent ${assessmentTitle} Attempt`}
        </h2>
        <div id={reportId} />
        { incorrectQuestions.map(({ response_id: responseId }, index) => (
          <MarkCorrectButton
            key={responseId}
            elementId={responseId}
            markCorrect={() => this.markCorrect(index)}
          />
        )) }
        { correctQuestions.map(({ response_id: responseId }) => (
          <CorrectBadge key={responseId} elementId={responseId} />
        )) }
      </section>
    );
  }
}

export default LearnosityReport;
