import { action, observable } from 'mobx';

class GamesStore {
  @observable cache = new Map()

  constructor(rootStore, api) {
    this.api = api;
  }

  @action async getQuizChallengeResults(type = 'leaderboard', course) {
    const courseId = course && course.id;
    const cacheId = `quiz/${courseId}/${type}`;

    if (!this.cache.has(cacheId) || this.cache.get(cacheId).time < Date.now() - 30000) {
      this.cache.set(cacheId, {
        time: Date.now(),
        data: this.api.get(
          `gaming/challenge_reports/${type}`,
          { course_id: courseId },
        ),
      });
    }

    return this.cache.get(cacheId).data;
  }

  @action async getKeyboardingLeaders(course) {
    const courseId = Object(course) === course ? course.id : course;
    const type = courseId || 'global';
    const cacheId = `keyboard/${type}`;

    if (!this.cache.has(cacheId) || this.cache.get(cacheId).time < Date.now() - 30000) {
      this.cache.set(cacheId, {
        time: Date.now(),
        data: this.api.get(`gaming/leaderboard/${type}?game_type=KEYBOARDING`),
      });
    }

    return this.cache.get(cacheId).data;
  }
}

export default GamesStore;
