import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import Modal from '../Modal';

@inject('auth', 'courses')
@observer
class CourseIntegrationDialog extends Component {
  @observable coursesRefreshed = false;

  componentDidUpdate() {
    const { auth, courses: { fromRoute: course } } = this.props;
    if (course.pendingIntegration) {
      if (!auth.hasCanvasOAuth) {
        auth.loadIntegrationProviders();
      } else if (!this.coursesRefreshed) {
        auth.loadIntegrationCourses();
        runInAction(() => { this.coursesRefreshed = true; });
      }
    }
  }

  render() {
    const { auth, courses } = this.props;
    const { fromRoute: course } = courses;
    const selectedIndex = course.pendingIntegrationCourse
      ? auth.integrationCourses.findIndex((c) => (
        c.canvas_course_id === course.pendingIntegrationCourse.canvas_course_id
      ))
      : -1;
    return (
      <Modal
        title="Link to Canvas"
        visible={!!course.pendingIntegration}
        onDismiss={() => course.cancelIntegration()}
      >
        {auth.hasCanvasOAuth
          ? (
            <form className="editClassForm">
              { course.processingIntegration
                ? (
                  <div className="formContent">
                    <h2>Processing Canvas Link...</h2>
                    <p>This may take a few miniutes...</p>
                  </div>
                )
                : (
                  <div className="formContent">
                    <h2>Choose a Canvas Course to Link</h2>
                    <p>
                      We will connect your AES Class to Canvas
                      and create assignments for you.
                    </p>
                    <div className="formField">
                      <select
                        value={selectedIndex}
                        onChange={({ target: { value } }) => runInAction(() => {
                          course.pendingIntegrationCourse = auth.integrationCourses[value];
                        })}
                      >
                        <option value={-1} disabled>Select Course</option>
                        {/* eslint-disable camelcase */
                          auth.integrationCourses.map(({
                            canvas_course_uuid, canvas_course_name,
                          }, index) => (
                            <option key={canvas_course_uuid} value={index}>
                              {canvas_course_name}
                            </option>
                          ))
                          /* eslint-enable camelcase */}
                      </select>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        courses.linkCanvasCourse(course.id, course.pendingIntegrationCourse);
                      }}
                      disabled={!course.pendingIntegrationCourse}
                    >
                      Link
                    </button>
                  </div>
                )}

            </form>
          )
          : (
            <React.Fragment>
              <h3>Canvas Authentication needed</h3>
              <p>
                Before we can retrieve your course list, you will need to authenticate with Canvas.
                <br />
                Click the button below to begin.
              </p>
              <button type="button" onClick={() => auth.initiateOAuth('canvas')}>
                Authenticate
              </button>
            </React.Fragment>
          )}
      </Modal>
    );
  }
}

export default CourseIntegrationDialog;
