/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  action, autorun, computed, observable,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import TeacherPersona from 'widgets/teacher/TeacherPersona';
import CreateFromTemplate from 'routes/course/new/CreateFromTemplate';
import AssignmentEditModules from 'widgets/assignment/AssignmentEditModules';
import { getBounds } from 'util/domUtil';
import { collision2D, distance2D } from 'util/math';
import { Prompt } from 'react-router-dom';
import SaveModal from './SaveModal';

import './style.scss';

const dragStartDistance = 5;
const autoScrollSize = 40;
const autoScrollSpeed = 2.5;

@inject('auth', 'courses', 'catalog', 'curriculum', 'router', 'rootStore')
@observer
class CourseCreator extends React.Component {
  @observable dragProps = {
    startX: 0,
    startY: 0,
    currentX: 0,
    currentY: 0,
    offsetX: 0,
    offsetY: 0,
    isClick: true,
    index: null,
    module: null,
    remove: false,
  }

  @observable error = ''

  @observable isBlocking = true

  constructor(props) {
    super(props);
    this.state = {
      showCreateClass: false,
      createFromTemplateDeclined: false,
      backButtonClass: 'hide',
    };
  }

  componentDidMount() {
    this.resetCatalog();
    autorun(() => {
      const { catalog } = this.props;
      if (catalog && catalog.executeSave) {
        this.saveClass();
      }
    });
  }

  componentWillUnmount() {
    this.endDrag();
  }

  @computed get embedOpts() {
    const { auth } = this.props;
    return [
      'popover=true',
      'popoverAnimateThumbnail=false',
      'popoverSize=750x417',
      `email=${auth.user.email}`,
    ].join(' ');
  }

  @computed get dragDropTitle() {
    if (this.dragProps.assignment) return this.dragProps.assignment.title;
    if (this.dragProps.module) return this.dragProps.module.title;
    return '';
  }

  @computed get dragModuleStyle() {
    if (!this.dragProps) return undefined;

    const {
      currentX, currentY, offsetX, offsetY,
    } = this.dragProps;

    return {
      top: currentY - offsetY,
      left: currentX - offsetX,
    };
  }

  @computed get firstCourse() {
    const { courses } = this.props;
    return courses.active.length === 0;
  }

  hideCreateClass = () => {
    this.setState({
      showCreateClass: false,
    });
  }

  @action clearDragProps = () => { this.dragProps = null; }

  @action saveNewClass = (courseTitle) => {
    const {
      asTemplate, catalog, courses, router,
    } = this.props;

    this.error = '';
    this.isBlocking = false;

    if (!courseTitle || courseTitle.length < 1) {
      this.error = 'Your class must have a name';
      return;
    }

    courses.create(courseTitle, catalog.selectedModules, null, asTemplate)
      .then((result) => {
        router.push(`/courses/${result.id}`);
      });
  }

  @action.bound startDrag(ev, module) {
    ev.preventDefault();
    ev.stopPropagation();

    const isTouch = !!ev.touches;
    const touch = isTouch ? ev.touches[0] : {};
    const targetBox = getBounds(ev.target);
    const clientX = isTouch ? touch.clientX : ev.clientX;
    const clientY = isTouch ? touch.clientY : ev.clientY;
    const offsetX = Math.min(400, clientX - targetBox.x);
    const offsetY = Math.min(40, clientY - targetBox.y);

    this.dragProps = {
      startX: clientX,
      startY: clientY,
      currentX: clientX,
      currentY: clientY,
      offsetX,
      offsetY,
      isClick: true,
      index: null,
      module,
      // assignment,
      remove: false,
    };

    if (isTouch) {
      document.addEventListener('touchmove', this.moveDrag, false);
      document.addEventListener('touchend', this.endDrag, false);
    } else {
      document.addEventListener('mousemove', this.moveDrag, false);
      document.addEventListener('mouseup', this.endDrag, false);
    }
  }

  @action.bound moveDrag(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    try {
      const { isClick, startX, startY } = this.dragProps;

      const isTouch = !!ev.touches;
      const touch = isTouch ? ev.touches[0] : {};
      const x = isTouch ? touch.clientX : ev.clientX;
      const y = isTouch ? touch.clientY : ev.clientY;
      this.dragProps.currentX = x;
      this.dragProps.currentY = y;

      if (isClick) {
        if (distance2D(startX, startY, x, y) >= dragStartDistance) {
          this.dragProps.isClick = false;
        }
        return;
      }

      if (!this.assignmentList || !this.dragElement) {
        throw new Error('DOM error while dragging');
      }

      const dragging = getBounds(this.dragElement);
      const assignmentsArea = getBounds(this.assignmentList);

      if (collision2D(dragging, assignmentsArea)) {
        this.dragProps.remove = false;

        if (dragging.top < assignmentsArea.top + autoScrollSize) {
          if (!this.scrollTimer) {
            this.scrollTimer = setInterval(() => {
              this.assignmentList.scrollTop -= autoScrollSpeed;
            });
          }
        } else if (dragging.bottom > assignmentsArea.bottom - autoScrollSize) {
          if (!this.scrollTimer) {
            this.scrollTimer = setInterval(() => {
              this.assignmentList.scrollTop += autoScrollSpeed;
            });
          }
        } else if (this.scrollTimer) {
          clearTimeout(this.scrollTimer);
          this.scrollTimer = null;
        }

        const elements = [...this.assignmentList.querySelectorAll('.assignmentRow')];
        const boxes = elements.map(getBounds);

        let index = 0;

        for (let i = 0; i < boxes.length; i += 1) {
          const box = boxes[i];

          if (box.height && dragging.middle > box.middle) {
            index = i + 1;
          }
        }

        this.dragProps.index = index;
      } else {
        this.dragProps.index = null;
        this.dragProps.remove = false;
      }
    } catch (e) {
      this.endDrag(ev);
      throw e;
    }
  }

  @action.bound async endDrag(ev) {
    document.removeEventListener('touchmove', this.moveDrag);
    document.removeEventListener('touchend', this.endDrag);
    document.removeEventListener('mousemove', this.moveDrag);
    document.removeEventListener('mouseup', this.endDrag);
    if (this.scrollTimer) clearTimeout(this.scrollTimer);

    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    if (!this.dragProps) return;

    const { module, index } = this.dragProps;
    const { catalog } = this.props;

    if (index != null) {
      const to = index;
      const from = catalog.selectedModules.findIndex((m) => m === module.key);
      await catalog.moveModule(to, from);
    }

    this.clearDragProps();
  }

  rowStyle(assignment) {
    if (this.dragProps && !this.dragProps.isClick) {
      return {
        display: assignment === this.dragProps.assignment ? 'none' : undefined,
      };
    }

    return undefined;
  }

  @action resetCatalog() {
    // Reset Catalog Editor Data
    const { catalog } = this.props;
    const selectGroup = catalog.groupsForCurrentUserAssignments[0];
    catalog.setCurrentTemplate(selectGroup, null);
    catalog.setSelectedModules([]);
    catalog.editingClass = false;
    catalog.executeSave = false;
  }

  @action saveClass() {
    const { catalog } = this.props;
    catalog.executeSave = false;
    this.setState({ showCreateClass: true });
  }

  @action createClass(moduleKeys) {
    const { catalog } = this.props;
    catalog.addMultipleModules(moduleKeys);
    this.setState({ showCreateClass: true });
  }

  @action createClassFromTemplate(moduleKeys) {
    const { catalog } = this.props;
    catalog.currentModules = [];
    catalog.addMultipleModules(moduleKeys);
    this.setState({ showCreateClass: true });
  }

  render() {
    const {
      asTemplate,
      auth,
      catalog,
      curriculum,
      courses,
    } = this.props;

    const {
      createFromTemplateDeclined,
      showCreateClass,
      backButtonClass,
    } = this.state;

    const type = asTemplate ? 'Template' : 'Class';

    const showIntroVideo = !catalog.currentTemplate
      && !catalog.myTemplate && !catalog.searchResults;

    const showCreateFromTemplate = showIntroVideo
      && courses.publishedTemplates.length > 0 && !createFromTemplateDeclined
      && !asTemplate;

    const showValidationMessage = catalog.selectedModules.length < 1;

    if (!auth || !auth.user) {
      return (<div />);
    }
    if (!auth.user.persona) {
      if (auth.site.isHealth) {
        action(() => {
          auth.user.persona = 'Health';
          auth.user.updatePersona('Health');
        })();
      } else {
        return (<TeacherPersona />);
      }
    }

    const space = <div key="space" style={{ height: 40, background: 'rgba(0, 0, 0, 0.1)' }} />;
    const assignmentList = [...catalog.selectedModules].map((m) => {
      const module = curriculum.modulesByKey[m];
      return (
        <tr
          className="assignmentListRow assignmentRow"
          key={m}
          style={this.rowStyle(module)}
        >
          <td className="assignmentTitle">
            <div>
              <div
                className="moduleGripper"
                onMouseDown={(ev) => this.startDrag(ev, module)}
                onTouchStart={(ev) => this.startDrag(ev, module)}
              />
              <div>{module.title}</div>
            </div>
          </td>
          <td className="deleteModule" onClick={() => catalog.removeModule(module.key)}>
            <i className="fas fa-trash-alt" />
          </td>
        </tr>
      );
    });

    if (this.dragProps && this.dragProps.index != null) {
      assignmentList.splice(this.dragProps.index, 0, space);
    }

    return (
      <div className="newClassNew">

        {showCreateFromTemplate
          && (
            <CreateFromTemplate
              onDeclineCreateFromTemplate={() => {
                this.setState({ createFromTemplateDeclined: true, backButtonClass: 'show' });
              }}
              saveNewClassFromTemplate={(title, id) => this.saveNewClassFromTemplate(title, id)}
              error={this.error}
            />
          )}
        {!showCreateFromTemplate && showIntroVideo
          && (
            <div>
              <div className="course-creator-top-div">
                <h1 style={{ marginBottom: 0 }}>{`Create a ${type}`}</h1>
                <button type="button" className={`button ${backButtonClass}`} onClick={() => this.setState({ createFromTemplateDeclined: false })}>&larr; Back to Previous Screen</button>
              </div>
              <p style={{ marginTop: 0, width: 550 }}>
                Choose one of the items to see modules that can be assigned to your students.
              </p>
              <h2 style={{ marginBottom: 8, marginTop: 100 }}>How to create a class</h2>
              <div style={{ width: 400, height: 220 }}>
                <div
                  className={`wistia_embed wistia_async_je97pb81mk videoFoam=true ${this.embedOpts}`}
                  style={{ width: 400, height: 220 }}
                />
              </div>
              <p style={{ marginTop: 8 }}>Video Length: 2 minutes, 24 seconds</p>
              <div className="arrow_box firstCoursePrompt" />
            </div>
          )}
        <div className="assignmentEditEditor">
          {(catalog.currentTemplate || catalog.searchModules)
            && (
              <AssignmentEditModules
                groupKey={catalog.currentGroup}
                templateKey={catalog.currentTemplate}
                addModule={(m) => catalog.addModule(m)}
                addMultipleModules={(array) => catalog.addMultipleModules(array)}
                createClassFromTemplate={(m) => this.createClassFromTemplate(m)}
                createClass={(m) => this.createClass(m)}
                removeModule={(m) => catalog.removeModule(m)}
                removeMultipleModules={(array) => catalog.removeMultipleModules(array)}
                selectedModules={catalog.selectedModules}
              />
            )}
        </div>
        {!showCreateFromTemplate
          && (
            <div className="assignmentEditSidebar">
              <h2>
                {catalog.selectedModules.length}
                {' '}
                Modules Assigned
              </h2>
              <p className={showValidationMessage ? 'showMessage' : 'hideMessage'}>
                You need to add at least one module to create a
                {' '}
                {type.toLowerCase()}
              </p>
              <p className="center">
                <button
                  type="button"
                  className="createClass"
                  onClick={() => this.saveClass()}
                  disabled={showValidationMessage}
                >
                  {catalog.editingClass ? 'Save Changes' : `Create ${type}`}
                </button>
              </p>
              <div className="selectedAssignments">
                <table ref={(el) => { this.assignmentList = el; }}>
                  {assignmentList}
                </table>
                <div className="drag" />

                {this.dragProps && !this.dragProps.isClick
                  && (
                    <div
                      ref={(el) => { this.dragElement = el; }}
                      className="card assignmentRow arrange dragging"
                      style={this.dragModuleStyle}
                    >
                      <div className="gripper" />
                      <div className="info">
                        {this.dragDropTitle}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
        <SaveModal
          type={type}
          error={this.error}
          visible={showCreateClass}
          onSave={(title) => this.saveNewClass(title)}
          onDismiss={() => this.hideCreateClass()}
        />
        <Prompt
          when={this.isBlocking && !showCreateFromTemplate}
          message={() => `Are you sure you want to navigate away without creating a ${type}?`}
        />
      </div>
    );
  }
}

export default CourseCreator;
