import React, { useState } from 'react';
import { sanitizeString } from 'util/domUtil';
import MaterialIcon from '../MaterialIcon';

const getLongType = (type) => {
  switch (type) {
    case 'Test':
      return 'Module Test';
    case 'AdvancedTest':
      return 'Advanced Test';
    case 'Quiz':
      return 'Unit Quiz';
    default:
      return 'Custom';
  }
};

const ExamItem = ({
  reference,
  stimulus,
  unit,
  type,
  inExam,
  previewLoaded,
  previewItem,
  onClickAdd,
  onClickRemove,
  onClickEdit,
}) => {
  const [preview, setPreview] = useState(false);
  const previewOpen = preview && previewLoaded;

  return (
    <div key={reference} className="item">
      <div className="header">
        <button
          type="button"
          className="preview icon lightButton"
          onClick={() => {
            setPreview(!preview);
            if (!previewLoaded) {
              previewItem(reference);
            }
          }}
        >
          <MaterialIcon toolTip={`${previewOpen ? 'Hide' : 'Show'} Preview`}>
            { previewOpen ? 'visibility_off' : 'visibility' }
          </MaterialIcon>
        </button>
        <span className={`chip ${type}`}>{getLongType(type)}</span>
        { unit
          ? <span className="chip unit">{unit.title}</span>
          : null }
        <div className="actions" />
        { onClickEdit
          ? (
            <button type="button" className="edit icon lightButton" onClick={() => onClickEdit(reference)}>
              <MaterialIcon>edit</MaterialIcon>
            </button>
          )
          : null }
        { onClickAdd
          ? (
            <button
              type="button"
              className="add"
              disabled={inExam}
              onClick={() => onClickAdd(reference)}
            >
              { inExam
                ? 'Added'
                : (
                  <React.Fragment>
                    <span>Add to Exam</span>
                    <MaterialIcon>add</MaterialIcon>
                  </React.Fragment>
                ) }
            </button>
          )
          : null }
        { onClickRemove
          ? (
            <button type="button" className="remove lightButton" onClick={() => onClickRemove(reference)}>
              <span>Remove</span>
              <MaterialIcon>clear</MaterialIcon>
            </button>
          )
          : null }
      </div>

      <p className={`stimulus ${previewOpen ? 'hide' : 'show'}`}>{sanitizeString(stimulus)}</p>
      <div className={`preview ${previewOpen ? 'show' : 'hide'}`}>
        <span data-reference={reference} className="learnosity-item" />
      </div>
    </div>
  );
};

export default ExamItem;
