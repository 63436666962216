import React from 'react';
import img from 'data/img/404.png';

import './style.scss';

export default function NotFound() {
  return (
    <div className="err404">
      <img src={img} alt="Oops! We apologize, we can't find that page." />
    </div>
  );
}
