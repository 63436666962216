import React from 'react';
import Modal from 'widgets/Modal';

const AssignmentDeleteModal = ({ showDeleteModal, closeDeleteModal, deleteModule }) => (
  <Modal
    title="Delete Module"
    id="delete-modal"
    visible={showDeleteModal}
    onDismiss={closeDeleteModal}
  >
    <p>Are you sure you want to delete this assignment?</p>
    <button type="button" onClick={deleteModule}>Yes</button>
    <button type="button" onClick={closeDeleteModal} className="secondary">No</button>
  </Modal>
);

export default AssignmentDeleteModal;
