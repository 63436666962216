import React from 'react';

const EmptyAssignmentSummaryRow = ({ title, id }) => (
  <tr
    className="assignmentRow"
    key={id}
  >
    <td colSpan={4} title="Assessment is empty" className="emptyAssignment">
      <b>{title}</b>
      <span style={{ float: 'right' }}>
        N/A
      </span>
    </td>
  </tr>
);

export default EmptyAssignmentSummaryRow;
