import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';
import Dropdown from 'widgets/form/Dropdown';
import DateTime from 'widgets/format/DateTime';
import Alert from 'widgets/Alert';
import './OverrideGrade.scss';

@inject('grades', 'router', 'entry') @observer
class OverrideGrade extends Component {
  @observable loading = false

  @observable points = null

  componentDidMount() {
    const { grades, work } = this.props;
    if (work) {
      grades.loadGradeHistory(work.id);
    }
    this.loadExam();
  }

  @action setLoading = (val) => { this.loading = val; }

  @action setPoints = (value) => { this.points = value; }

  @computed get inProgress() {
    const { work } = this.props;
    return work && work.quizStarted && !work.quizCompleted;
  }

  @action.bound async updateGrade() {
    const {
      course, grades, student, moduleKey, unitKey, entry,
    } = this.props;

    this.setLoading(true);

    let mKey = moduleKey;
    let uKey = unitKey;

    if (entry) {
      mKey = (!moduleKey) ? entry.moduleKey : moduleKey;
      uKey = (!unitKey) ? entry.unitKey : unitKey;
    }

    await grades.createOrUpdate({
      course,
      student,
      module: mKey,
      unit: uKey,
      points: this.points === 'No Override' ? null : +this.points,
    });

    this.setLoading(false);

    this.cancelDialog();
  }

  @action.bound async restoreGrade() {
    const { grades, work } = this.props;
    this.setLoading(true);
    grades.restoreGrade(work.id)
      .then(() => {
        this.setLoading(false);
      });
  }

  @action.bound cancelDialog() {
    const { actionCanceled } = this.props;
    if (typeof actionCanceled === 'function') {
      actionCanceled();
    }
  }

  @action.bound async resetGrade() {
    const { grades, work: { id }, actionCanceled } = this.props;
    grades.resetGrade(id)
      .then(actionCanceled());
  }

  @action.bound async loadExam() {
    const { entry: { examId, assignment: { exam } } } = this.props;
    if (examId && !exam.questionsLoaded) {
      exam.getQuestions();
    }
  }

  render() {
    const {
      editMode, student, entry, work, visible,
    } = this.props;
    if (!student) {
      return null;
    }
    const numberList = (max) => Array(max + 1).fill(0).map((_, i) => i);

    let maxPoints = 0;

    if (entry) {
      // Why Returning undefined for isExam?
      if (entry.totalPossible && !entry.isReset) {
        maxPoints = entry.totalPossible;
      } else if (entry.isExam || entry.examId) {
        if (entry.assignment.exam.questions) {
          maxPoints = entry.assignment.exam.questions.length;
        }
      } else if (entry.isTest) {
        const { totalTestQuestionCount, testType } = entry.assignment;
        if (entry.moduleKey === 'HSANTMYCO') {
          maxPoints = testType === 0 ? 25 : 100;
        } else {
          maxPoints = totalTestQuestionCount;
        }
      } else if (entry.unit && entry.unit.possiblePoints) {
        maxPoints = entry.unit.possiblePoints;
      }
    }

    return (
      <Modal
        id="quiz-override"
        title={`${student.name} - ${(entry) ? entry.title : 'No entry / title'}`}
        visible={visible}
        onDismiss={() => this.cancelDialog()}
      >
        {this.loading && <p>Manually assigning a grade, please wait...</p>}
        {!this.loading && (
        <div>
          {this.inProgress
            && (
            <div className="formField">
              <p>The student has started this quiz but has not finished it.</p>
              <button type="button" onClick={this.resetGrade}>Reset Grade</button>
            </div>
            )}
          {work && work.quizCompleted
            && (
            <div>
              <p>
                The student earned
                {(work.studentEarned || 0) / 10}
                {' '}
                out of
                {maxPoints}
                {' '}
                points
              </p>
            </div>
            )}
          {work && !!work.overrideEarned
            && (
            <p>
              The student was assigned
              {work.overrideEarned}
              {' '}
              out of
              {maxPoints}
              {' '}
              points
            </p>
            )}
          <div className="formField">
            Manually edit this grade:&nbsp;
            <Dropdown
              inline
              options={['No Override', ...numberList(maxPoints)]}
              value={this.points}
              onChange={this.setPoints}
            />
            &nbsp;out of
            {' '}
            {maxPoints}
          </div>
          <Alert type="info">
            {'Editing a student\'s grade will lock it and the'}
            {' '}
            {'student won\'t be able to retake the assessment'}
          </Alert>
          <p />
          <button type="button" onClick={() => this.updateGrade()} disabled={this.points == null}>
            Set Grade
          </button>
          {work && !editMode
            && (
            <button type="button" onClick={() => this.restoreGrade()}>
              Restore Grade
            </button>
            )}
          <button type="button" className="secondary" onClick={() => this.cancelDialog()}>
            Cancel
          </button>

          {work && !editMode && (
          <div>
            <h3 style={{ position: 'relative', top: 22 }}>Grade history</h3>
            <table>
              <thead>
                <tr>
                  <th>Points</th>
                  <th>Grade</th>
                  <th>Earned</th>
                  <th>Reset</th>
                </tr>
              </thead>
              <tbody>
                {work.history.map((grade) => (
                  <tr>
                    <td>{grade.points}</td>
                    <td>{grade.percent}</td>
                    <td><DateTime value={grade.completed} /></td>
                    <td><DateTime value={grade.deletedAt} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
        </div>
        )}
      </Modal>
    );
  }
}

export default OverrideGrade;
