import React from 'react';
import { inject, observer } from 'mobx-react';
import Checkbox from 'widgets/form/Checkbox';

const Worksheets = ({ courses: { fromRoute: course } }) => (
  <div>
    <h1>Student Worksheets</h1>
    <p>
      Worksheets are supplements answered by students as they work through the lessons, improving
      retention and understanding.
    </p>

    <h2>Class Settings</h2>
    <Checkbox
      label="Use electronic worksheets"
      subtitle="Electronic worksheets are completed by students on the computer and are available instantly in your gradebook."
      value={course.eWorksheets}
      onChange={(value) => course.setWorksheetType(value ? 'electronic' : 'paper')}
    />
    <Checkbox
      label="Enable Student Worksheet Export"
      subtitle="Allow students to export electronic worksheets as a PDF."
      value={course.allowExportWorksheets}
      onChange={(value) => course.setWorksheetStudentExport(value)}
    />

  </div>
);

export default inject('courses')(observer(Worksheets));
