import React from 'react';
import {
  action, computed, observable, runInAction,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import TextBox from 'widgets/form/TextBox';
import Modal from 'widgets/Modal';
import ClassCopyProcessing from './ClassCopyProcessing';

@inject('courses', 'router', 'teachers') @observer
class DuplicateClass extends React.Component {
  @observable waiting = false;

  @observable newCourseId = 0;

  @observable title = '';

  componentDidMount() {
    const { selectedCourse } = this.props;
    if (selectedCourse?.title) {
      this.handleTitleChange(this.defaultTitle);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedCourse } = this.props;
    if (selectedCourse?.title && selectedCourse !== prevProps.selectedCourse) {
      this.handleTitleChange(this.defaultTitle);
    }
  }

  @computed get defaultTitle() {
    const { selectedCourse } = this.props;
    return `${selectedCourse.title} Copy`;
  }

  @computed get newTitle() {
    return this.title || this.defaultTitle;
  }

  @action.bound handleTitleChange = (value) => {
    this.title = value;
  }

  @action.bound resetState() {
    this.title = this.defaultTitle;
    this.waiting = false;
    this.newCourseId = 0;
  }

  @action.bound async duplicateClass() {
    const { selectedCourse, courses, template } = this.props;
    const { newTitle } = this;
    this.waiting = true;

    let newCourse;

    if (template) {
      newCourse = await courses.create(newTitle, [], selectedCourse.id, true);
    } else {
      newCourse = await courses.copy(newTitle, selectedCourse.id);
    }

    if (this.waiting) {
      runInAction(() => {
        this.newCourseId = newCourse.id;
        this.waiting = false;
      });
    }
  }

  @action.bound closeDialog() {
    const { onDismiss, noRedirect, router } = this.props;
    const { newCourseId } = this;
    this.resetState();
    onDismiss();
    if (!noRedirect && newCourseId) {
      router.push(`/courses/${newCourseId}`);
    }
  }

  render() {
    const {
      create, style, template, title, visible, noRedirect,
    } = this.props;
    const { newCourseId, waiting } = this;
    const buttonText = create ? 'Create' : 'Copy';

    return (
      <Modal
        onDismiss={this.closeDialog}
        style={style}
        title={title}
        visible={visible}
      >
        <form className="duplicateClass">
          { !waiting && !newCourseId
            ? (
              <React.Fragment>
                {!create
                  && (
                  <React.Fragment>
                    <h1>
                      Copy
                      {template ? ' Template' : ' Class'}
                    </h1>
                    <p>
                      Create an exact copy of this
                      {' '}
                      {template ? 'template.' : 'class with no students or grades.'}
                    </p>
                  </React.Fragment>
                  )}
                {create
                  && (
                  <React.Fragment>
                    <h1>Create Class</h1>
                    <p>Create a new class based on this template.</p>
                  </React.Fragment>
                  )}
                <TextBox
                  placeholder={this.newTitle}
                  value={this.title}
                  label="New Title:"
                  leftLabel
                  name="title"
                  onChange={this.handleTitleChange}
                  style={{ width: 300 }}
                />
                <button type="button" data-testid="duplicateBtn" onClick={this.duplicateClass}>{buttonText}</button>
                <button type="button" className="secondary" onClick={this.closeDialog}>Cancel</button>
              </React.Fragment>
            )
            : null }
          { waiting
            ? <ClassCopyProcessing onClickClose={this.closeDialog} />
            : null }
          { newCourseId
            ? (
              <React.Fragment>
                <p>Class copied successfully.</p>
                { !noRedirect
                  ? <p>Closing this dialog will redirect you to your new class.</p>
                  : null }
                <button type="button" onClick={this.closeDialog}>Close</button>
              </React.Fragment>
            )
            : null }
        </form>
      </Modal>
    );
  }
}

export default DuplicateClass;
