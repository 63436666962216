import moment from 'moment';

/**
 * Accepts ISO date string | epoch | Date, returns formatted String
 * @param value
 * @param format
 * @param noValue
 * @returns {string}
 * @constructor
 */
export default function DateTime({ value, format = 'MM/DD/YYYY hh:mm a', noValue = 'Never' }) {
  return (value)
    ? moment(value).format(format)
    : noValue;
}
