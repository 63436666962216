import { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('auth') @observer
class Logout extends Component {
  componentDidMount() {
    const { auth } = this.props;
    auth.logout();
  }

  render = () => {
    const { auth } = this.props;
    document.location = auth.loginURL;
    return null;
  }
}

export default Logout;
