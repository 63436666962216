import { extendObservable, computed, action } from 'mobx';
import Course from 'models/Course';
import Student from 'models/Student';
import Unit from 'models/Unit';
import Module from 'models/Module';

export default class Grade {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    extendObservable(this, {
      id: +input.id || 0,
      courseId: +input.courseId || +input.course_id || 0,
      quizGrade: +input.quizGrade || +input.quiz_grade || 0,
      attempts: +input.attempts || +input.total_attempts || 0,
      studentEarned: +input.studentEarned || +input.total_earned || 0,
      overrideEarned: input.overrideEarned || input.override_earned,
      unitPossible: +input.unitPossible || +input.total_possible || 0,
      moduleKey: input.moduleKey || input.module_key || '',
      unitKey: input.unitKey || input.unit_key || '',
      processed: !!input.processed,
      archived: input.archived || false,
      status: input.status || 0,
      completed: input.completed || input.completed_at || null,
      studentId: input.studentId || input.student_id || 0,
      examId: +input.examId || +input.exam_id || 0,
      worksheet: input.worksheet || {},
      createdAt: input.createdAt || input.created_at || null,
      updatedAt: input.updatedAt || input.updated_at || null,
      deletedAt: input.deletedAt || input.deleted_at || null,
      quizStartedAt: input.quizStartedAt || input.quiz_start_time || null,
      quizCompletedAt: input.quizCompletedAt || input.quiz_finish_time || null,
      learnosityRequest: '',
      details: [],
      detailsLoaded: false,
      history: [],
    });
  }

  @action update(input = {}) {
    this.quizGrade = input.quiz_grade;
    this.studentEarned = input.total_earned;
    this.overrideEarned = input.override_earned;
    this.status = input.status;
    this.updatedAt = input.updated_at;
    this.quizStartedAt = input.quiz_start_time;
    this.quizCompletedAt = input.quiz_finish_time;
    this.unitPossible = input.total_possible;
  }

  @computed get course() {
    const { courses } = this.rootStore;

    return courses.byId.get(this.courseId) || new Course(this.rootStore);
  }

  @computed get student() {
    const { students } = this.rootStore;

    return students.byId.get(this.studentId) || new Student(this.rootStore);
  }

  @computed get assignment() {
    return this.course.assignments.find((a) => a.moduleKey === this.moduleKey);
  }

  @computed get entry() {
    return this.assignment && this.assignment.entries.find((e) => e.unitKey === this.unitKey);
  }

  @computed get module() {
    const { curriculum } = this.rootStore;

    return curriculum.modulesByKey[this.moduleKey] || new Module(this.rootStore);
  }

  @computed get unit() {
    if (this.examId) return new Unit(this.rootStore, { title: 'None' });
    const units = this.module.gradeUnits || [];
    return units.find((u) => u.key === this.unitKey) || { title: 'Unknown' };
  }

  @computed get title() {
    if (this.examId) return 'Exam';
    if (this.unit.isTest) return this.module.title;
    return this.unit.title;
  }

  @computed get isTest() {
    return this.unit && this.unit.isTest;
  }

  @computed get typeName() {
    if (this.unitKey) {
      if (this.unitKey.match(/^PREMT_/)) return 'Module Pre-Test';
      if (this.unitKey.match(/^MT_/)) return 'Module Test';
    }
    if (this.examId) return 'Exam';
    if (this.isComplete && this.totalPossible === 0) return 'Activity';

    return 'Quiz';
  }

  @computed get statusText() {
    switch (this.status) {
      case 0: return 'Not Started';
      case 1: return 'In Progress';
      case 2: return 'Completed';
      default: return 'Unknown';
    }
  }

  @computed get quizStatus() {
    if (this.quizCompleted) return 'Completed';
    if (this.quizStarted) return 'In Progress';
    return 'Not Started';
  }

  @computed get isStarted() {
    return this.status !== 0;
  }

  @computed get isComplete() {
    return this.status === 2;
  }

  @computed get isGraded() {
    if (!this.entry) return false;
    return this.entry.isGraded;
  }

  @computed get quizStarted() {
    return !!this.quizStartedAt;
  }

  @computed get quizCompleted() {
    return !!this.quizCompletedAt;
  }

  @computed get percentEarned() {
    if (!this.isComplete) return 0;
    if (this.quizGrade) return this.quizGrade * 100;
    return (this.totalEarned / this.totalPossible) * 100;
  }

  @computed get totalEarned() {
    if (!this.isComplete) {
      return 0;
    }
    if (Number.isInteger(this.overrideEarned)) {
      return this.overrideEarned;
    }
    return this.studentEarned / 10;
  }

  @computed get totalPossible() {
    return this.unitPossible / 10;
  }

  @computed get percent() {
    if (this.isComplete && this.totalPossible === 0) return 'N/A';
    if (!this.isComplete) return '-';

    return `${this.percentEarned.toFixed(1)}%`;
  }

  @computed get points() {
    if (this.isComplete && this.totalPossible === 0) return 'N/A';
    if (!this.isComplete) return '-';

    return `${this.totalEarned}/${this.totalPossible}`;
  }

  @computed get hasDetails() {
    return this.isComplete && this.totalPossible !== 0;
  }

  @computed get canReset() {
    return !this.deleted && this.quizStarted;
  }

  @computed get mostRecentAttempt() {
    const { length } = this.history;
    return length ? this.history[length - 1] : null;
  }

  @computed get isReset() {
    const { mostRecentAttempt } = this;
    return !!mostRecentAttempt && !!mostRecentAttempt.deletedAt;
  }

  @computed get isAssessment() {
    if (this.unit && this.unit.showQuiz) return true;
    if (this.assignment && this.assignment.isExam) return true;
    return !!(this.unitKey && this.unitKey.match(/^(PRE)?MT_/));
  }

  @computed get includeInAverage() {
    if (!this.isComplete) return false;
    if (this.unitKey.match(/^PREMT_/)) return false;
    return this.isAssessment && this.stillInCourse;
  }

  @computed get includeInTotal() {
    if (this.unitKey.match(/^PREMT_/)) return false;
    return this.isAssessment && this.stillInCourse;
  }

  @computed get stillInCourse() {
    return this.course.hasAssignmentEntry(this.moduleKey, this.unitKey);
  }
}
