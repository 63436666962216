import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import AssignmentSummary from 'widgets/student/AssignmentSummary';
import StudentActions from 'widgets/student/StudentActions';
import StudentRecentGrades from 'widgets/student/RecentGrades';

@inject('students', 'courses', 'router') @observer
class StudentDashboard extends Component {
  componentDidMount() {
    const {
      router: { courseId },
      students: { fromRoute: student },
    } = this.props;
    student.loadPacingStatus(courseId);
  }

  componentDidUpdate({ router: { studentId } }) {
    const {
      router: { courseId },
      students: { fromRoute: student },
    } = this.props;
    if (student && student.id !== studentId) {
      student.loadPacingStatus(courseId);
    }
  }

  render() {
    const {
      router,
      courses: { fromRoute: course },
      students: { fromRoute: student },
    } = this.props;
    const grade = student.gradesByCourse.get(course.id);

    return student ? (
      <div className="studentDashboard">
        <h1>
          Student:
          {' '}
          {student.formatName}
        </h1>

        <StudentActions student={student} type="buttons" />
        <div className="studentData">
          <div>
            <p>Full Name:</p>
            <h3>{student.name}</h3>
          </div>
          <div>
            <p>Username/Email:</p>
            <h3>{student.email}</h3>
          </div>
          <div>
            <p>Average Score:</p>
            <h3>{(grade && grade.average) || '-'}</h3>
          </div>
          <div>
            <Link to={`${router.path.replace('list', 'tracking')}`}>
              <button type="button">
                Student Activity Report
              </button>
            </Link>
          </div>
        </div>

        <div className="contentBlock">
          <div className="flexContainer">
            <div className="card courseSummary">
              <h2>Assessment Status</h2>
              <AssignmentSummary />
            </div>
            <div className="col2">
              <div className="card">
                <Link
                  to={router.path.replace(/\/students\/list\/(\d+)/, '/grades/by-student/$1')}
                  style={{ float: 'right' }}
                >
                  View Status Report
                </Link>
                <h2>Recent Work</h2>
                <StudentRecentGrades />
              </div>
            </div>
          </div>
        </div>

      </div>
    ) : null;
  }
}

export default StudentDashboard;
