import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { scrollToRef } from 'util/domUtil';

import GradePercent from 'widgets/grade/GradePercent';
import Modal from 'widgets/Modal';
import EmptyStatusReportRow from './EmptyStatusReportRow';
import WorksheetStatus from './WorksheetStatus';

@inject('courses', 'curriculum', 'router') @observer
class StudentDetail extends React.Component {
  titles = []

  constructor(props) {
    super(props);
    this.state = {
      exportModal: false,
      downloadLink: '',
    };
  }

  componentDidMount() {
    this.loadLessonStatus();
  }

  componentDidUpdate() {
    this.loadLessonStatus();
  }

  @computed get course() {
    const { courses: { fromRoute: course } } = this.props;
    return course;
  }

  @computed get student() {
    const { router: { studentId } } = this.props;
    return studentId
      ? this.course.findStudent(studentId)
      : null;
  }

  @computed get grade() {
    if (this.student) {
      const { router: { courseId } } = this.props;
      return this.student.gradesByCourse.get(courseId);
    }
    return null;
  }

  @computed get lessonStatus() {
    if (this.student) {
      return this.student.lessonStatus;
    }
    return null;
  }

  @computed get enrollmentId() {
    if (this.student) {
      const { course: { id } } = this;
      return this.student.enrollments.reduce((acc, enrollment) => {
        if (enrollment.course_id === id) {
          return enrollment.id;
        }
        return acc;
      }, '');
    }
    return '';
  }

  statusType = (status) => {
    if (status === 0) {
      return 'Not Started';
    } if (status === 1) {
      return 'In Progress';
    } if (status === 2) {
      return 'Completed';
    }
    return 'Unknown';
  }

  // redirect back to status report dashboard
  handleGoToDashboard = () => {
    const { router } = this.props;
    router.push(`/courses/${router.courseId}/grades/by-student`);
  }

  handleClose = () => {
    this.setState({
      exportModal: false,
    });
  }

  // export status report
  handleExportStatusReport = () => {
    const { course } = this;
    const { enrollmentId } = this;
    course.getLessonStatusReport(course.id, enrollmentId).then((res) => {
      this.getLessonStatusReport(res.job_id);
      this.setState({ exportModal: true });
    });
  }

  getLessonStatusReport = (jobId) => {
    const { course } = this;
    course.getLessonStatusReportData(jobId)
      .then((result) => {
        this.setState({
          downloadLink: result.url,
        });
      })
      // eslint-disable-next-line no-console
      .catch((res) => console.log('Error getting report: ', res));
  }

  loadLessonStatus() {
    if (this.student && this.student.statusLoaded === false) {
      const { course: { id } } = this;
      this.student.loadLessonStatus(id);
    }
  }

  scrollToSection() {
    const { router } = this.props;
    const match = router.path.match(/by-student\/\d+\/(.*)$/);
    if (!match) return;

    const moduleKey = match[1];
    if (!moduleKey) return;

    const ref = this.titles[moduleKey];
    if (!ref) return;

    scrollToRef(ref);
  }

  render() {
    const {
      course, grade, lessonStatus, student,
    } = this;
    const { curriculum } = this.props;
    const { downloadLink, exportModal } = this.state;

    const lessonTitle = (lesson) => {
      if (lesson.page_set_key) {
        const pageset = curriculum.lessons[lesson.page_set_key];
        if (pageset) return pageset.title;
        return lesson.page_set_key;
      }
      return '';
    };

    if (!student) {
      return (
        <div className="statusReportNoDetail">
          <p>Loading...</p>
        </div>
      );
    }

    return (
      <div className="statusReportDetail">
        <div className="pageHeader">
          <button type="button" onClick={this.handleGoToDashboard}>&larr; View All Students</button>
          <button type="button" onClick={this.handleExportStatusReport}>Export Status Report</button>
        </div>
        <h1>
          Status Report:
          {' '}
          {student.name}
        </h1>

        {!grade && <div className="noStatusReport">No work done by this student</div>}
        {grade && course.assignments.map((assignment) => (
          <div key={`assignment_${assignment.id}`} className="statusReport">
            <h3 style={{ fontSize: 20 }}>{assignment.title}</h3>
            {!assignment.hasEntries
              ? <EmptyStatusReportRow id={assignment.id} />
              : (
                assignment.entries.map((entry) => {
                  const { unit } = entry;
                  const explore = (unit.phase === 'EXPLORE');
                  const reflect = (unit.phase === 'REFLECT');
                  const reinforce = (unit.phase === 'REINFORCE');
                  const module = (unit.phase === 'MODULE');
                  const worksheet = grade && grade.worksheet[entry.unit.key];
                  const exception = explore || reflect || reinforce || module;
                  if (!exception) {
                    return (
                      <table key={`assignment_entry_${entry.id}`}>
                        <tbody>
                          <tr>
                            {assignment.isExam ? (
                              <th id="unitHeader">
                                {assignment.title}
                              </th>
                            )
                              : (
                                <th id="unitHeader">
                                  Unit:
                                  {' '}
                                  {unit.title}
                                </th>
                              )}

                            <th id="statusHeader">Progress</th>
                            <th>Grade</th>
                          </tr>

                          {lessonStatus[unit.key]
                            ? (lessonStatus[unit.key].map((lesson, lessonNumber) => (
                              <tr key={`lesson_status_${lesson.page_set_key}`}>
                                <td>
                                  Lesson
                                  {' '}
                                  {lessonNumber + 1}
                                  {' '}
                                  -
                                  {' '}
                                  {lessonTitle(lesson)}
                                </td>
                                <td>{this.statusType(lesson.status)}</td>
                                <td>-</td>
                              </tr>
                            )))
                            : (
                              unit.lessons && unit.lessons.map((lesson, lessonNumber) => (
                                <tr key={`lesson_${lesson}`}>
                                  <td>
                                    Lesson
                                    {' '}
                                    {lessonNumber + 1}
                                    {' '}
                                    -
                                    {' '}
                                    {curriculum.lessons[lesson].title}
                                  </td>
                                  <td>Not Started</td>
                                  <td>-</td>
                                </tr>
                              ))
                            )}

                          {grade.workByUnit[unit.key] && unit.showQuiz ? (
                            <tr>
                              <td>{unit.isTest ? (unit.title) : 'Quiz'}</td>
                              <td>{grade.workByUnit[unit.key].quizStatus}</td>
                              <td>
                                <GradePercent
                                  entry={entry}
                                  grade={grade.workByUnit[unit.key]}
                                  unit={unit}
                                />
                              </td>
                            </tr>
                          ) : null}

                          {!lessonStatus[unit.key] && unit.showQuiz && (
                            <tr>
                              <td>Quiz</td>
                              <td>Not Started</td>
                              <td>-</td>
                            </tr>
                          )}

                          {unit && unit.worksheetQuestions > 0
                              && (
                              <tr>
                                <td>Worksheet</td>
                                {worksheet && <WorksheetStatus unit={unit} worksheet={worksheet} />}
                                {!worksheet
                                        && (
                                        <td>
                                          0 /
                                          {' '}
                                          {unit.worksheetQuestions}
                                              &nbsp;Answered
                                        </td>
                                        )}
                                <td>-</td>
                              </tr>
                              )}

                          {assignment.isExam && grade.workByModule[assignment.moduleKey] && (
                            <tr>
                              <td>Exam</td>
                              <td>{grade.workByModule[assignment.moduleKey][0].statusText}</td>
                              <td>
                                <GradePercent
                                  entry={entry}
                                  grade={grade.workByModule[assignment.moduleKey][0]}
                                />
                              </td>
                            </tr>
                          )}
                          {assignment.isExam && !grade.workByModule[assignment.moduleKey] && (
                            <tr>
                              <td>No work done in this Exam</td>
                              <td>Not Started</td>
                              <td>-</td>
                            </tr>

                          )}
                        </tbody>
                      </table>

                    );
                  }
                  return null;
                }))}
          </div>
        ))}
        <Modal
          title={`Status Report: ${student.firstName} ${student.lastName}`}
          visible={exportModal}
          onDismiss={this.handleClose}
        >
          <p style={{ margin: 0 }}>
            You can download the status report for
            {' '}
            {student.firstName}
            {' '}
            {student.lastName}
            {' '}
            using the link below.
          </p>
          {downloadLink
            ? (
              <a
                href={downloadLink}
                rel="noopener noreferrer"
                onClick={this.handleClose}
                download="Lesson Status Report"
              >
                <p>Download Status Report</p>
              </a>
            )
            : <p>Loading...</p>}
          <button type="button" onClick={this.handleClose}>Close</button>
        </Modal>
      </div>
    );
  }
}

export default StudentDetail;
