import {
  extendObservable, action, runInAction, computed,
} from 'mobx';

export default class Exam {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    extendObservable(this, {
      id: +input.id || 0,
      assignmentId: +input.assignmentId || +input.assignment_id || 0,
      courseId: +input.courseId || +input.course_id || 0,
      title: input.title || '',
      customized: !!input.customized,
      modules: input.modules || [],
      numQuestions: +input.questionCount || +input.number_of_questions || 0,
      createdAt: input.createdAt || input.created_at || null,
      deletedAt: input.deletedAt || input.deleted_at || null,
      updatedAt: input.updatedAt || input.updated_at || null,
      questionsLoaded: false,
      questionsLoading: false,
      learnosityStatus: input.learnosity_status || 'updated',
      itemReferences: [],
      learnosityPreviewRequest: '',
      learnosityEditRequest: '',
    });
  }

  @computed get assignment() {
    const { assignments } = this.rootStore;
    return assignments.byId.get(this.assignmentId);
  }

  @computed get course() {
    const { courses: { byId } } = this.rootStore;
    return byId.get(this.courseId) || {};
  }

  @computed get learnosityItems() {
    const { itemReferences, rootStore } = this;

    // TODO: This is to catch sloppy instantiation in legacy exam creator; remove after beta.
    if (!rootStore) return [];

    const { learnosityItems } = rootStore;
    return itemReferences
      .map((reference) => learnosityItems.byReference.get(reference))
      .filter((item) => !!item && item.isPublished);
  }

  @computed get activeItems() {
    return this.learnosityItems.filter((item) => item.isActive);
  }

  @computed get activeModules() {
    const keysWithDuplicates = this.activeItems.map(({ moduleKey }) => moduleKey);
    return Array.from(new Set(keysWithDuplicates));
  }

  @computed get deadModules() {
    const { curriculum: { modulesByKey } } = this.rootStore;
    return this.activeModules
      .filter((key) => !modulesByKey[key]);
  }

  @computed get unmatchedModules() {
    const { moduleKeys } = this.course;
    return this.activeModules
      .filter((key) => (
        !moduleKeys.includes(key) && !this.deadModules.includes(key)
      ));
  }

  @computed get goodModules() {
    return this.activeModules.filter((key) => (
      !this.deadModules.includes(key) && !this.unmatchedModules.includes(key)
    ));
  }

  @computed get goodItems() {
    return this.activeItems
      .filter(({ moduleKey }) => this.goodModules.includes(moduleKey));
  }

  @computed get deadItems() {
    return this.activeItems
      .filter(({ moduleKey }) => this.deadModules.includes(moduleKey));
  }

  @computed get unmatchedItems() {
    return this.activeItems
      .filter(({ moduleKey }) => this.unmatchedModules.includes(moduleKey));
  }

  @computed get questions() {
    return this.activeItems;
  }

  @computed get questionCount() {
    if (this.questions.length > 0) {
      return this.questions.length;
    }
    return this.numQuestions;
  }

  @computed get maxQuestions() {
    const { assignments } = this.rootStore;
    return this.modules.reduce((count, moduleKey) => (
      count + (assignments.questionCounts.get(moduleKey) || 0)
    ), 0);
  }

  @computed get moduleCount() {
    return this.modules.length;
  }

  @computed get availableItems() {
    const { moduleKeys } = this.course;
    const { learnosityItems: { byModule } } = this.rootStore;
    return moduleKeys.reduce((acc, key) => {
      const items = byModule.get(key) || [];
      return acc.concat(items.map((item) => ({
        ...item,
        unit: item.unit,
        module: item.module,
        type: item.type,
        inExam: item.isActive && item.examId === this.id,
      })));
    }, []);
  }

  @action save() {
    const {
      title,
      customized,
      questionCount,
    } = this;
    const { WebAPI } = this.rootStore;

    return WebAPI.patch(
      `teacher/exams/${this.id}`,
      {
        exam: {
          title,
          customized,
          number_of_questions: questionCount,
        },
      },
    );
  }

  @action loadPreview(reference) {
    const { learnosityItems } = this.rootStore;
    const itemReference = reference || this.activeItems[0]?.reference;
    this.learnosityPreviewRequest = '';
    learnosityItems.loadExamItemPreview(this.id, itemReference)
      .then((learnosityRequest) => runInAction(() => {
        this.learnosityPreviewRequest = learnosityRequest;
      }));
  }

  @action loadItems() {
    const { learnosityItems } = this.rootStore;

    if (this.questionsLoading) {
      return Promise.resolve();
    }

    this.questionsLoading = true;

    return learnosityItems.loadExamItems(this.id)
      .then((list) => {
        runInAction(() => {
          this.itemReferences = list;
          this.questionsLoaded = true;
          this.questionsLoading = false;
          this.refreshModules();
        });
      });
  }

  @action refreshModules() {
    const { course: { moduleKeys }, learnosityItems } = this;
    const foundModules = learnosityItems.reduce(
      (acc, item) => acc.add(item.moduleKey),
      new Set(),
    );
    this.modules = moduleKeys.filter((key) => foundModules.has(key));
  }

  @action addItemReference(reference) {
    const { itemReferences } = this;
    if (!itemReferences.includes(reference)) {
      this.itemReferences.push(reference);
    }
  }

  @action addItem(reference) {
    const { learnosityItems } = this.rootStore;
    learnosityItems.addItemToExam(this.id, reference)
      .then((item) => {
        this.addItemReference(item.reference);
        this.refreshModules();
      })
      .then(() => {
        runInAction(() => {
          this.numQuestions = this.questions.length;
        });
      });
  }

  @action removeItem(reference) {
    const { itemReferences, rootStore: { learnosityItems } } = this;
    learnosityItems.removeItemFromExam(this.id, reference)
      .then(() => runInAction(() => {
        this.itemReferences = itemReferences.filter((match) => match !== reference);
        this.numQuestions = this.questions.length;

        if (this.numQuestions < 1) { // if there are no questions, turn off student access.
          this.assignment.setStatus(false);
        } else { // no need to refresh if there's nothing to show
          this.refreshModules();
        }
      }));
  }

  @action createItem(moduleKey) {
    const { learnosityItems } = this.rootStore;

    this.learnosityEditRequest = '';

    return learnosityItems.createExamItem(this.id, moduleKey)
      .then((learnosityRequest) => {
        runInAction(() => {
          this.learnosityEditRequest = learnosityRequest;
        });
        return learnosityRequest;
      });
  }

  @action editItem(reference) {
    const { learnosityItems } = this.rootStore;
    const sourceItem = learnosityItems.byReference.get(reference);
    let nextAction;

    this.learnosityEditRequest = '';

    if (sourceItem.ownerType === 'AES') {
      nextAction = learnosityItems.cloneExamItem(this.id, reference);
    } else {
      nextAction = learnosityItems.loadExamItemDetails(this.id, reference);
    }

    return nextAction.then((item) => runInAction(() => {
      this.learnosityEditRequest = item.editRequest;
      this.addItemReference(item.reference);
      return item;
    }));
  }

  @action saveItem(moduleKey, unitKey, data) {
    const { learnosityItems } = this.rootStore;
    return learnosityItems.saveExamItem(this.id, moduleKey, unitKey, data)
      .then((item) => runInAction(() => {
        this.learnosityEditRequest = '';
        this.addItemReference(item.reference);
        this.refreshModules();
        this.loadPreview(item.reference);
        this.numQuestions = this.questionCount;
      }));
  }

  @action loadAvailableItems(moduleKey) {
    const { learnosityItems } = this.rootStore;
    learnosityItems.loadAvailableItems(this.courseId, this.id, moduleKey);
  }

  @action getQuestions() {
    return this.loadItems();
  }

  @action loadAnswerKeyPDF() {
    const { WebAPI } = this.rootStore;

    return WebAPI.get(
      [
        `teacher/courses/${this.courseId}`,
        `assignments/${this.assignment.id}`,
        'answer_key.pdf',
      ].join('/'),
    )
      .then((result) => WebAPI.pollJob(result.job_id));
  }

  @action rename(newTitle) {
    const { WebAPI } = this.rootStore;
    const oldTitle = this.title;
    this.title = newTitle;

    return WebAPI.patch(
      `teacher/exams/${this.id}`,
      {
        exam: {
          title: newTitle,
        },
      },
    )
      .catch((e) => {
        runInAction(() => {
          this.title = oldTitle;
        })();
        throw e;
      });
  }
}
