import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';
import ModuleIcon from '../../widgets/assignment/ModuleIcon';

const TemplateModules = ({
  assignments,
  closeModal,
  visible,
  style,
  selectedCourse,
  createClass,
}) => {
  useEffect(() => {
    const { id } = selectedCourse;
    if (id) {
      assignments.loadByCourse(id);
    }
  }, [selectedCourse]);

  const curriculumHours = (a) => {
    if (!a.isExam) {
      return a.hours === '-' ? null : `${a.hours} Curriculum Hours`;
    }

    return null;
  };

  const moduleName = (a) => (a.isCustom ? a.module.title : a.title);

  return (
    <Modal
      onDismiss={closeModal}
      style={style}
      title="Course Modules"
      visible={visible}
    >
      <h1>{selectedCourse.title}</h1>
      { selectedCourse?.assignments
        ? (
          <div>
            <button
              type="button"
              className="createClass lightButton"
              onClick={() => {
                createClass(selectedCourse);
                closeModal();
              }}
            >
              Create class from this template
            </button>
            <table className="assignment-list-modal">
              <tbody>
                {selectedCourse.assignments.map((assignment) => (
                  <tr key={assignment.title}>
                    <td className="assignment">
                      <ModuleIcon title={assignment.title} image={assignment.imageUrl} />
                      <div className="assignment-details">
                        <span className="assignment-title">{moduleName(assignment)}</span>
                        <br />
                        {curriculumHours(assignment)}
                      </div>

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )
        : null}
    </Modal>
  );
};

export default inject('assignments', 'catalog')(observer(TemplateModules));
