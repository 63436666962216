import React, { Component } from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';

@inject('courses', 'router') @observer
class ArchiveCourse extends Component {
  @action.bound closeDialog() {
    const { onDismiss } = this.props;
    onDismiss();
  }

  @action.bound async archiveCourse() {
    const { selectedCourse, courses, router } = this.props;
    const { available } = courses;
    await courses.archive(selectedCourse);

    if (available.length === 0) {
      router.push('/get-started');
    } else {
      router.push('/');
    }
  }

  render() {
    const {
      style, template, title, visible,
    } = this.props;

    return (
      <Modal
        onDismiss={this.closeDialog}
        style={style}
        title={title}
        visible={visible}
      >
        <h1>
          Delete
          {template ? ' Template' : ' Class'}
        </h1>
        <p>
          Your data will be saved, but the
          {' '}
          {template ? 'template' : 'class'}
          {' '}
          will become inaccessible to students
          and disappear from your dashboard. You can find it later in your archive.
        </p>
        <button type="button" onClick={this.archiveCourse}>
          <span>
            Delete
            {template ? ' Template' : ' Class'}
          </span>
        </button>
        <button
          className="secondary"
          onClick={this.closeDialog}
          type="button"
        >
          <span>Cancel</span>
        </button>
      </Modal>
    );
  }
}

export default ArchiveCourse;
