/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import { action, observable, autorun } from 'mobx';
import { inject, observer } from 'mobx-react';

import TextBox from 'widgets/form/TextBox';

@inject('courses', 'assignments', 'students', 'router') @observer
class GeneralSearch extends Component {
  @observable query = '';

  @observable focused = false;

  @observable results = [];

  componentDidMount() {
    this.dispose = autorun(() => {
      const { courses: { fromRoute: course } } = this.props;
      if (this.query && course) {
        this.runSearch();
      }
    });
  }

  componentWillUnmount() {
    this.dispose();
  }

  @action setQuery = (val) => { this.query = val; }

  @action setFocus = (val) => {
    this.focused = val;
    if (val) this.runSearch();
  }

  @action runSearch() {
    const { router: { courseId }, students } = this.props;
    this.showResults(
      students.quickSearch(this.query, courseId, true),
    );
  }

  @action showResults(students) {
    const scoreSort = (a, b) => a.score - b.score;
    const allResults = [
      ...students.map((r) => ({ ...r, type: 'student' })),
    ];

    const normalizeResult = (result) => ({
      icon: 'person',
      title: result.item.name,
      action: this.showStudent.bind(this, result.item.id),
    });

    this.results = allResults
      .sort(scoreSort)
      .slice(0, 6)
      .map(normalizeResult);
  }

  @action.bound fullSearch() {
    const { router } = this.props;
    router.push(`/courses/${router.courseId}/search?q=${this.query}`);
  }

  showStudent(id) {
    const { router } = this.props;

    router.push(`/courses/${router.courseId}/students/list/${id}`);
  }

  keyDown(ev) {
    if (ev.which === 13) this.fullSearch();
  }

  render() {
    return (
      <div className="generalSearch">
        <TextBox
          placeholder="Student Search"
          value={this.query}
          onChange={this.setQuery}
          onFocus={() => this.setFocus(true)}
          onBlur={() => this.setFocus(false)}
          onKeyDown={(ev) => this.keyDown(ev)}
        />

        <i className="material-icons">search</i>

        {this.query && this.focused
          && (
          <ul className="searchResults">
            {this.results.map((result) => (
              <li onMouseDown={result.action}>
                <i className="material-icons">{result.icon}</i>
                <span className="resultTitle">{result.title}</span>
              </li>
            ))}
            {this.results.length === 0
              && <li><i>No results.</i></li>}
            <li onMouseDown={this.fullSearch}><b>See all results...</b></li>
          </ul>
          )}
      </div>
    );
  }
}
export default GeneralSearch;
