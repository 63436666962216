import React from 'react';

const ToggleSlider = ({
  active, onClick, titleOff, titleOn, id, disable,
}) => {
  const disabled = disable;
  return (
    <div className="toggle">
      <div className="status">{active ? titleOn : titleOff}</div>
      <label className="switch" htmlFor={id}>
        <input
          id={id}
          type="checkbox"
          checked={active}
          onChange={() => onClick(!active)}
          disabled={disabled}
        />
        <span className="slider round" />
      </label>
    </div>
  );
};

export default ToggleSlider;
