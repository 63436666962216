import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Courses from './Courses';
import Exams from './Exams';
import ArchivedTemplates from './ArchivedTemplates';
import './style.scss';

const Archive = () => (
  <div>
    <Switch>
      <Route path="*/courses" component={Courses} />
      <Route path="*/exams" component={Exams} />
      <Route path="*/templates" component={ArchivedTemplates} />
    </Switch>
  </div>
);

export default Archive;
