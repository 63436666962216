import { action, observable, runInAction } from 'mobx';

const byStudentReducer = (acc, unitStatus) => {
  const {
    student_id: studentId,
    unit_key: unitKey,
    lessons_complete: complete,
    lessons_total: total,
  } = unitStatus;
  const percent = (complete / total) * 100;
  const student = acc[studentId] || {};

  student[unitKey] = { complete, total, percent };
  acc[studentId] = student;

  return acc;
};

class LessonStatusStore {
  @observable byCourse = new Map();

  constructor(rootStore, api) {
    this.api = api;
    this.rootStore = rootStore;
  }

  @action
  async load(courseId, moduleKey) {
    if (!courseId || !moduleKey) {
      return;
    }

    if (!this.byCourse.has(courseId)) {
      this.byCourse.set(courseId, observable.map());
    }
    const courseStatus = this.byCourse.get(courseId);

    const result = await this.api.get(
      `teacher/courses/${courseId}/lesson_status?module_key=${moduleKey}`,
    );

    runInAction(() => {
      courseStatus.set(moduleKey, result.reduce(byStudentReducer, {}));
    });
  }

  @action reload(courseId, moduleKey) {
    const courseStatus = this.byCourse.get(courseId);

    if (courseStatus) {
      courseStatus.delete(moduleKey);
    }
    return this.load(courseId, moduleKey);
  }
}

export default LessonStatusStore;
