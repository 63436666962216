import { action, computed, extendObservable } from 'mobx';
import { scrubResourceType } from 'util/Resources';
import Unit from 'models/Unit';
import Question from './Question';

export default class Module {
  type = 'Module';

  constructor(rootStore, input = {}) {
    const units = input.module_units || [];

    this.rootStore = rootStore;
    this.isCustom = false;

    extendObservable(this, {
      id: +input.id || 0,
      key: input.key || input.module_key || 'XXX',
      title: input.title || '',
      visible: !!input.visible || false,
      image: input.image || '',
      description: input.description || '',
      unitKeys: units.map((unit) => unit.unit_key),
      useQuestionPool: input.old_test_format === 1,
      version: input.version,
      questionCount: +input.test_question_count,
      hasTest: input.showtest,
      noSearch: !!input.nosearch,
      _resources: input.resources,
      answerKey: [],
      advancedAnswerKey: [],
      alert: input.alert || '',
      lessonDetailsLoaded: false,
    });
  }

  @computed get units() {
    const { curriculum } = this.rootStore;
    return this.unitKeys.map((k) => curriculum.unitsByKey[k]).filter((v) => v);
  }

  @computed get gradeUnits() {
    return this.units.concat([
      new Unit(this.rootStore, { unit_key: `PREMT_${this.key}`, title: 'Module Pre-Test', showquiz: true }),
      new Unit(this.rootStore, { unit_key: `MT_${this.key}`, title: 'Module Test', showquiz: true }),
    ]);
  }

  @computed get learnAndPracticeUnits() {
    return this.units.filter((unit) => unit.phase === 'LP');
  }

  @computed get minHours() {
    return this.units.filter((a) => !a.teacherOnly).reduce((a, b) => a + b.hours, 0);
  }

  @computed get hours() {
    const unitHours = this.units.reduce((a, b) => a + b.hours, 0);
    const testHours = this.hasTest ? 1 : 0;
    return unitHours + testHours;
  }

  @computed get imageUrl() {
    if (this.image) {
      const { auth } = this.rootStore;
      return auth.mediaBaseUrl + this.image;
    }
    return null;
  }

  @computed get resources() {
    /* eslint-disable no-underscore-dangle */
    if (!this._resources) return [];
    return this._resources.map(scrubResourceType);
    /* eslint-enable no-underscore-dangle */
  }

  // TODO: MOVE API calls to store.
  @action loadAnswerKey() {
    const { WebAPI } = this.rootStore;
    return WebAPI.fetch(
      'GET',
      `teacher/catalog_answer_keys.json?module_key=${this.key}&test_type=B`,
    )
      .then(action('loadedAnswerKey', (result) => {
        this.answerKey = result?.map((data) => new Question(this.rootStore, data));
        return Promise.resolve(this.answerKey);
      }));
  }

  @action loadAdvancedTestAnswerKey() {
    const { WebAPI } = this.rootStore;
    return WebAPI.fetch(
      'GET',
      `teacher/catalog_answer_keys.json?module_key=${this.key}&test_type=A`,
    )
      .then(action('loadedAdvancedAnswerKey', (result) => {
        this.advancedAnswerKey = result?.map((data) => new Question(this.rootStore, data));
        return Promise.resolve(this.advancedAnswerKey);
      }));
  }

  @action loadAnswerKeyPDF({ isAdvancedAnswerKey }) {
    const { WebAPI } = this.rootStore;

    // Advanced or Basic?
    const testType = isAdvancedAnswerKey ? 'A' : 'B';

    return WebAPI.get(
      `teacher/catalog_answer_keys.pdf?module_key=${this.key}&test_type=${testType}`,
    )
      .then((result) => WebAPI.pollJob(result.job_id));
  }

  @action loadLessonDetails() {
    const { curriculum } = this.rootStore;
    if (!this.lessonDetailsLoaded && this.key !== 'XXX') {
      curriculum.loadLessonDetails(this.key);
    }
  }
}
