import React from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import {
  FlexibleWidthXYPlot, XAxis, YAxis, MarkSeries, VerticalBarSeries,
  Crosshair, HorizontalGridLines, VerticalGridLines,
} from 'react-vis';
import 'react-vis/dist/style.css';
import ToggleSlider from 'widgets/ToggleSlider';
import previewImg from 'data/img/preview.png';
import studentImg from 'data/img/student.png';
import assignmentsImg from 'data/img/assignments.png';
import RenameCourse from './RenameCourse';
import DuplicateClass from './DuplicateClass';
import ArchiveCourse from './ArchiveCourse';
import ResetClass from './ResetClass';
import CourseIntegrationButton from '../../../widgets/dashboard/CourseIntegrationButton';
import CourseIntegrationDialog from '../../../widgets/dashboard/CourseIntegrationDialog';

@inject('auth', 'courses', 'curriculum', 'router') @observer
class Overview extends React.Component {
  @observable popup = null

  @observable selectedChart = null

  @observable tooltipValue = null

  @computed get tooltipStyle() {
    return {
      position: 'absolute',
      top: 0,
      left: this.tooltipValue.left,
    };
  }

  @action.bound setSelectedChart(n) {
    this.selectedChart = n;
  }

  @action.bound setTooltip(value, details) {
    this.tooltipValue = {
      ...value,
      left: details.innerX,
    };
  }

  goToPreview = () => {
    const { courses: { fromRoute: course }, router } = this.props;
    if (course.enableBeta) {
      window.open(course.previewUrl, 'aes_course_preview');
    } else { // TODO: Remove after all courses are updated to V2
      router.push(`/courses/${course.id}/dashboard/preview`);
    }
  }

  goToEnrollment = () => {
    const { router } = this.props;
    router.push(`/courses/${router.courseId}/dashboard/enroll`);
  }

  goToAssignments = () => {
    const { router } = this.props;
    router.push(`/courses/${router.courseId}/assignments`);
  }

  @action.bound clearTooltip() {
    this.tooltipValue = null;
  }

  @action.bound showPopup(name) {
    this.popup = name;
  }

  @action.bound hidePopup() {
    this.popup = null;
  }

  selectStudent() {
    const { router } = this.props;

    if (this.tooltipValue) {
      const studentId = this.tooltipValue.id;
      router.push(`/courses/${router.courseId}/students/list/${studentId}`);
    }
  }

  renderModals(course) {
    return (
      <div>
        <RenameCourse
          onDismiss={this.hidePopup}
          selectedCourse={course}
          template={course.template}
          title="Rename Class"
          visible={this.popup === 'rename'}
        />

        <DuplicateClass
          onDismiss={this.hidePopup}
          selectedCourse={course}
          template={course.template}
          title={`Copy Class: ${course.title}`}
          visible={this.popup === 'copy'}
        />

        <ResetClass
          onDismiss={this.hidePopup}
          selectedCourse={course}
          title="Reset Class"
          visible={this.popup === 'reset'}
        />

        <ArchiveCourse
          onDismiss={this.hidePopup}
          selectedCourse={course}
          template={course.template}
          title="Delete Class"
          visible={this.popup === 'delete'}
        />

        <CourseIntegrationDialog />
      </div>
    );
  }

  renderActions(isOwner, template) {
    return (
      <div className="overview-actions">
        {isOwner && (
        <button type="button" onClick={() => this.showPopup('rename')}>Rename</button>
        )}
        <button type="button" onClick={() => this.showPopup('copy')}>Copy</button>
        {!template && isOwner && (
        <button type="button" onClick={() => this.showPopup('reset')}>Reset</button>
        )}
        {isOwner && (
        <button type="button" onClick={() => this.showPopup('delete')}>Delete</button>
        )}
        { !template && isOwner && (
        <CourseIntegrationButton />
        )}
      </div>
    );
  }

  render() {
    const {
      auth: { user: { id } },
      courses: { fromRoute: course },
      curriculum,
    } = this.props;
    const { facultyId, template, sharedTemplate } = course;
    const isOwner = facultyId === id;

    // view when no students in course
    if (!course.students.length) {
      return (
        <div className="overview-container">
          { this.renderActions(isOwner, template) }
          <div className="next-overview-actions-container">
            {template && isOwner
              ? (
                <React.Fragment>
                  <h1>Publish Template?</h1>
                  <ToggleSlider
                    active={sharedTemplate}
                    onClick={(active) => course.setDistrictTemplate(active)}
                    titleOn="Published"
                    titleOff="Unpublished"
                  />
                  <p>Publishing this template will share it site-wide for all teachers to use.</p>
                </React.Fragment>

              )
              : null}

            <h1>What&apos;s next?</h1>
            <div className="next-overview-actions">
              <div className="next-overview">
                <div className="next-overview-img">
                  <img src={previewImg} width="100%" alt="" />
                </div>
                <button type="button" className="CTA" onClick={this.goToPreview}>
                  View as Student
                </button>
                <p>
                  See what your class would
                  <br />
                  look like to students.
                </p>
              </div>
              <div className="next-overview">
                <div className="next-overview-img">
                  <img src={studentImg} width="100%" alt="" />
                </div>
                <button type="button" className="CTA" onClick={this.goToAssignments}>
                  View Assignments
                </button>
                <p>
                  View assignments and
                  <br />
                  teacher resources.
                </p>
              </div>
              {!template
                && (
                  <div className="next-overview">
                    <div className="next-overview-img">
                      <img src={assignmentsImg} width="100%" alt="" />
                    </div>
                    <button type="button" className="CTA" onClick={this.goToEnrollment}>
                      Enroll Students
                    </button>
                    <p>
                      Give your students access so
                      <br />
                      they can start learning.
                    </p>
                  </div>
                )}
            </div>
            {this.renderModals(course)}
          </div>
        </div>
      );
    }

    // data used in student average graph
    const studentAverages = course.students.map((student, i) => {
      const grade = student.gradesByCourse.get(course.id);
      if (!grade) return null;

      return {
        name: student.name,
        id: student.id,
        x: i,
        y: parseFloat(grade.average || 0),
      };
    }).filter((v) => v);

    // data used in student module graph
    const moduleCompletion = (assignment) => (
      course.students.reduce((numberCompleted, student) => {
        const grade = student.gradesByCourse.get(course.id);
        if (grade) {
          const completedStatus = grade.moduleProgress[assignment.moduleKey] || 0;
          if (completedStatus === 1) {
            return numberCompleted + 1;
          }
        }
        return numberCompleted;
      }, 0)
    );

    const moduleStatus = course.assignments
      .map((assignment, i) => ({
        label: assignment.id,
        title: assignment.title,
        x: i,
        y: moduleCompletion(assignment),
      }));

    if (!curriculum.loaded) return null;

    return (
      <div className="overview-container">
        <div className="overview-header">
          {this.renderActions(isOwner, template)}
          <h1>{course.title}</h1>
        </div>

        <div className="overview-details">
          <div className="metric-overview">
            <div className="metric">
              <h2>Students Enrolled</h2>
              <p>{course.studentCount}</p>
            </div>
            <div className="metric">
              <h2>Signed In Today</h2>
              <p>{course.signedInToday}</p>
            </div>
            <div className="metric">
              <h2>Signed In Past 7 Days</h2>
              <p>{course.signedInPast7}</p>
            </div>
          </div>

          <h2>What are the average grades for each student?</h2>
          {moduleStatus.length > 0
            ? (
              <FlexibleWidthXYPlot
                height={200}
                yDomain={[0, 100]}
                onMouseEnter={() => this.setSelectedChart(1)}
                onMouseLeave={() => this.clearTooltip()}
                onClick={() => this.selectStudent()}
              >
                <HorizontalGridLines />

                <YAxis title="Average Grade" position="middle" />
                <XAxis hideTicks title="Student" position="middle" />

                <MarkSeries data={studentAverages} onNearestX={this.setTooltip} />

                {this.selectedChart === 1
                  && (
                    <Crosshair values={[this.tooltipValue]}>
                      {this.tooltipValue && (
                        <div className="chartTipContainer">
                          <h3>{this.tooltipValue.name}</h3>
                          <div>
                            {this.tooltipValue.y}
                            %
                          </div>
                        </div>
                      )}
                    </Crosshair>
                  )}
              </FlexibleWidthXYPlot>
            )
            : <p>There is no grade data to display.</p>}

          <h2>How many students completed each module?</h2>
          {moduleStatus.length > 0
            ? (
              <FlexibleWidthXYPlot
                height={200}
                yDomain={[0, course.students.length]}
                onMouseEnter={() => this.setSelectedChart(2)}
                onMouseLeave={this.clearTooltip}
              >
                <HorizontalGridLines />
                <VerticalGridLines />
                <VerticalBarSeries data={moduleStatus} onNearestX={this.setTooltip} color="#ae90ae" />
                <YAxis title="Students" position="middle" />
                <XAxis hideTicks title="Module" position="middle" />

                {this.selectedChart === 2
                  && (
                    <Crosshair values={[this.tooltipValue]}>
                      {this.tooltipValue && (
                        <div className="chartTipContainer">
                          <h3>{this.tooltipValue.title}</h3>
                          <div>
                            {this.tooltipValue.y}
                            {' '}
                            Students
                          </div>
                        </div>
                      )}
                    </Crosshair>
                  )}
              </FlexibleWidthXYPlot>
            )
            : <p>Your students have not currently completed any modules.</p>}
        </div>
        {this.renderModals(course)}
      </div>
    );
  }
}

export default Overview;
