import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

@inject('catalog') @observer
class CatalogGroup extends Component {
  render() {
    const { catalog, match } = this.props;

    if (!catalog.loaded) {
      return <div />;
    }

    const group = catalog.groups[match.params.group];
    const list = group.templates;
    const { heading } = group;

    if (!list) {
      return (
        <div>
          Missing Template:
          {match.params.group.toUpperCase()}
        </div>
      );
    }

    return (
      <div>
        <h1>{heading}</h1>
        <ul>
          {list.map((t) => (t
            && (
            <li key={t.templateKey}>
              <Link to={`/catalog/${match.params.group}/${t.templateKey}`}>
                {t.title}
              </Link>
            </li>
            )
          ))}
        </ul>
      </div>
    );
  }
}

export default CatalogGroup;
