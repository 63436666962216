/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {
  action, autorun, computed, observable, runInAction, when,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import QuestionList from 'widgets/question/QuestionList';
import OverrideGrade from 'widgets/grade/OverrideGrade';
import GradeAttempts from './GradeAttempts';
import LearnosityReport from './LearnosityReport';

@inject('courses', 'grades', 'router', 'questions', 'work') @observer
class GradeDetails extends React.Component {
  @observable loaded = false;

  @observable loading = false;

  @observable override = false;

  @observable selectedStudentId = '';

  @observable selectedAssessmentId = '';

  @observable unitKey = '';

  componentDidMount() {
    runInAction(() => {
      const { unitKey } = this.props;
      this.unitKey = unitKey;
    });
    when(
      () => {
        const { grade, student, assignment } = this;
        return (
          assignment
          && grade
          && student
          && student.id
        );
      },
      () => {
        this.setLoaded(true);
        this.initializeSelects();
      },
    );

    this.disposeAutorun = autorun(() => {
      const {
        assignment, course, grade, student,
      } = this;
      if (
        assignment
        && student?.id
        && grade
        && !grade.detailsLoaded
      ) {
        this.loadGradeEntries(grade);
        student.loadPacingStatus(course.id);
      }
    });
  }

  componentWillUnmount() {
    this.disposeAutorun();
  }

  @computed get backUrl() {
    const { router, detailType, moduleKey } = this.props;
    const { selectedStudentId } = this;
    const path = router.location.pathname.split('/');
    path.splice(6);

    if (detailType === 'Student') { // back navigate to current student
      path[5] = selectedStudentId || this.student.id;
    } else if (moduleKey.startsWith('EXAM_')) { // if this is an exam, go back to the root of the gradebook
      path.splice(-1);
    }

    return path.join('/');
  }

  @computed get course() {
    const { courses, router: { courseId } } = this.props;

    return courses.byId.get(courseId);
  }

  @computed get student() {
    const { students } = this.props;
    const { selectedStudentId } = this;

    if (selectedStudentId) {
      return students.find((s) => s.id === +selectedStudentId);
    } if (this.grade) {
      return this.grade.student;
    }
    return undefined;
  }

  @computed get assignment() {
    const { moduleKey } = this.props;
    const { course } = this;
    return course.assignmentsByModuleKey.get(moduleKey);
  }

  @computed get assessments() {
    if (this.assignment) {
      return this.assignment.entries.filter((a) => !a.removed && a.isGraded);
    }
    return undefined;
  }

  @computed get assessment() {
    const { selectedAssessmentId } = this;
    return this.assessments.find((a) => a.id === +selectedAssessmentId);
  }

  @computed get assessmentEntry() {
    const { selectedAssessmentId } = this;
    return this.assignment.entries.find((e) => (e.id === +selectedAssessmentId));
  }

  @computed get assessmentTitle() {
    const { assessmentEntry } = this;
    return assessmentEntry?.typeName === 'test' ? assessmentEntry.title : 'Quiz';
  }

  @computed get grade() {
    const { grades, moduleKey } = this.props;
    const { unitKey, selectedStudentId, selectedAssessmentId } = this;
    let key = unitKey;

    if (this.assessments && selectedAssessmentId) {
      // NOTE: not updating url on assessment change
      const assignment = this.assessments.find((a) => a.id === +selectedAssessmentId);
      key = assignment.unitKey;
    }

    // student selected from dropdown
    if (selectedStudentId) {
      const studentGrades = this.course.work.filter((g) => g.studentId === +selectedStudentId);
      const moduleGrades = studentGrades.filter((g) => g.moduleKey === moduleKey);
      const unitGrade = moduleGrades.find((g) => g.unitKey === key);

      return unitGrade;
    }

    // initial load, student not selected from dropdown
    const urlPieces = window.location.href.split('/');
    const gradeId = urlPieces[urlPieces.length - 1];
    return grades.list.find((g) => +g.id === +gradeId);
  }

  @action.bound setLoaded = (val) => { this.loaded = !!val; }

  @action.bound setLoading = (val) => { this.loading = !!val; }

  @action.bound showOverride = () => { this.override = true; }

  @action.bound hideOverride = () => { this.override = false; }

  @action.bound onChangeStudent = ({ target: { value } }) => {
    this.selectedStudentId = value;
    this.setLoaded(false);
  }

  @action.bound onChangeAssessment = ({ target: { value } }) => {
    this.selectedAssessmentId = value;
    this.setLoaded(false);
  }

  @action backNavigation() {
    const { router } = this.props;
    router.history.push(this.backUrl);
  }

  @action async loadGradeEntries(grade) {
    const { grades } = this.props;
    this.setLoading(true);

    if (grade) {
      this.unitKey = grade.unitKey;
      await grades.loadGradeDetails(grade.id);
    }

    this.setLoading(false);
  }

  @action initializeSelects() {
    const { moduleKey, unitKey } = this.props;

    // set initial selected states for dropdown filters
    if (this.assessments) {
      const selectedAssessment = this.assessments.find((a) => (
        a.moduleKey === moduleKey && a.unitKey === unitKey
      ));
      if (selectedAssessment) {
        this.selectedAssessmentId = selectedAssessment.id;
      }
    }

    if (this.student?.id) {
      this.selectedStudentId = this.student.id;
    }
  }

  render() {
    const { grades, work, moduleKey } = this.props;
    const { unitKey, selectedAssessmentId, selectedStudentId } = this;
    const { course } = this;
    const { assignment } = this;
    const { students } = course;

    if (!assignment) {
      return <span>Loading...</span>;
    }

    const questions = (this.grade && this.grade.details)
      ? this.grade.details.sort((a, b) => a.quiz_question_number - b.quiz_question_number)
      : [];

    return (
      <span>
        {this.loading && <p>Loading quiz details...</p>}
        {!this.loading && (
          <div>
            <div className="button" onClick={() => this.backNavigation()}>
              &larr; Return to Grades
            </div>
            <div className="quizTitle">
              {this.student
                && <div>{this.student.name}</div>}
              <div>{assignment.title}</div>
              {this.assessment
                && <div>{this.assessment.title}</div>}
            </div>
            <div className="dropdownSelectors">
              <div className="studentDropdown">
                <label htmlFor="student">
                  <span>Selected Student:</span>
                  {' '}
                  <select
                    value={selectedStudentId}
                    id={this.uid}
                    name="student"
                    onChange={this.onChangeStudent}
                  >
                    {students.map((s) => (
                      <option value={s.id} key={s.id}>{`${s.lastName}, ${s.firstName}`}</option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="studentDropdown">
                <label htmlFor="module">
                  <span>Selected Assessment:</span>
                  {' '}
                  <select
                    value={selectedAssessmentId}
                    id={this.uid}
                    name="module"
                    onChange={this.onChangeAssessment}
                  >
                    {this.assessments.map((a) => (
                      <option value={a.id} key={a.id}>{`${a.title}`}</option>
                    ))}
                  </select>
                </label>
              </div>
            </div>

            <GradeAttempts
              grades={grades}
              course={course}
              work={work}
              assessment={this.assessment}
              student={this.student}
              grade={this.grade}
              unitKey={unitKey}
              moduleKey={moduleKey}
            />

            {(!this.grade && !!this.assessmentEntry)
              && (
                <div>
                  <p>There are no grades for this student.</p>
                  <button type="button" onClick={() => this.showOverride()}>Add Grade</button>
                  <OverrideGrade
                    editMode
                    visible={this.override}
                    grades={grades}
                    course={course}
                    student={this.student}
                    entry={this.assessmentEntry}
                    work={this.grade}
                    actionCanceled={this.hideOverride}
                  />
                </div>
              )}

            { this.grade
              && !this.grade.isReset
              && this.assessmentEntry
              && questions.length > 0
              && (
                <div>
                  <div style={{ marginTop: '30px' }}>
                    {/* TODO: adjust type of assessment based on origin */}
                    <h2>
                      {`Details for the Most Recent ${this.assessmentTitle} Attempt`}
                    </h2>
                    <hr />
                  </div>
                  <div className="questionList">
                    <QuestionList
                      grade={this.grade}
                      questions={questions}
                      showAnswerSource={this.assessmentEntry.isTest}
                    />
                  </div>
                </div>
              ) }
            {
              this.grade
              && !this.grade.isReset
              && this.grade.mostRecentAttempt?.id
              && this.grade.learnosityRequest
              && (
                /* NB: it looks odd that grade props are passed individually here;
                  This is done because MobX models are mutable.
                  I can't detect a change in value in componentDidUpdate if I don't pass the
                  individual props explicitly, because they are accessed by pointer and
                  prevProps.grade and this.props.grade will be literally the same value. */
                <LearnosityReport
                  gradeId={this.grade.id}
                  assessmentTitle={this.assessmentTitle}
                  attemptId={this.grade.mostRecentAttempt.id}
                  learnosityRequest={this.grade.learnosityRequest}
                />
              )
            }
          </div>
        )}
      </span>
    );
  }
}

export default GradeDetails;
