import React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject('auth') @observer
class TeacherPersona extends React.Component {
  @action setPersona(persona) {
    const { auth } = this.props;
    auth.user.updatePersona(persona);
    const product = persona === 'Health' ? 'health' : 'bcit';
    auth.siteProduct = product;
    auth.site.skin = product;
  }

  render() {
    return (
      <div className="personaContainer">
        <h1>What do you teach?</h1>
        <p>Choose the curriculum that best aligns with the classes you teach!</p>
        <div className="personas">
          <div className="personaCard">
            <div className="cardHeading">
              <h2>
                Health Science
              </h2>
            </div>
            <div className="cardBody">
              <p>Health Care Foundations</p>
              <p>Health Career Readiness</p>
              <p>Nurse Assisting</p>
              <p>Medical Assistant</p>
              <p>And more!</p>
            </div>
            <div className="cardAction">
              <button type="button" className="lgButton" onClick={() => this.setPersona('Health')}>Select</button>
            </div>
          </div>
          <div className="personaCard">
            <div className="cardHeading">
              <h2>
                Business Education
              </h2>
            </div>
            <div className="cardBody">
              <p>Introduction to Business</p>
              <p>Business &amp; Computer Applications</p>
              <p>Digital Information Technology</p>
              <p>And more!</p>
            </div>
            <div className="cardAction">
              <button type="button" className="lgButton" onClick={() => this.setPersona('Business')}>Select</button>
            </div>
          </div>
          <div className="personaCard">
            <div className="cardHeading">
              <h2>
                Career Readiness
              </h2>
            </div>
            <div className="cardBody">
              <p>Digital Citizenship</p>
              <p>Professionalism</p>
              <p>Job Seeking Skills</p>
              <p>And more!</p>
            </div>
            <div className="cardAction">
              <button type="button" className="lgButton" onClick={() => this.setPersona('Career')}>Select</button>
            </div>
          </div>
          <div className="personaCard">
            <div className="cardHeading">
              <h2>
                Computer Apps
              </h2>
            </div>
            <div className="cardBody">
              <p>Microsoft Office</p>
              <p>Keyboarding</p>
              <p>Google Apps</p>
              <p>And more!</p>
            </div>
            <div className="cardAction">
              <button type="button" className="lgButton" onClick={() => this.setPersona('Computer Applications')}>Select</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeacherPersona;
