import React from 'react';
import ExamImage from '../../data/img/exam.png';
import CustomImage from '../../data/img/custom-module.png';

const urlFor = (image) => {
  if (image === 'EXAM') {
    return ExamImage;
  }
  if (image === 'CUSTOM') {
    return CustomImage;
  }
  return image || 'https://media.aeseducation.com/modules/bcwcomm-2016/media/bcwcomm-2016.png';
};

const ModuleIcon = ({ title, image }) => <img src={urlFor(image)} alt={`${title} icon`} className="moduleImage" />;

export default ModuleIcon;
