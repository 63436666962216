class APIError extends Error {
  constructor(method, resource, response, body = null) {
    super();

    this.method = method;
    this.resource = resource;
    this.errors = [];

    if (response) {
      this.code = response.status;
      this.message = response.statusText;
    }

    if (body) {
      if (body.error) {
        this.message = body.error;
        this.errors.push(body.error);
      }

      if (body.validation_errors || body.errors) {
        if (this.code === 400 || this.code === 422) {
          this.validation = body.validation_errors || body.errors;
        }
      } else if (this.code === 403 && this.resource === 'authenticate') {
        const { error } = body;

        if (error.match(/e-mail/i)) this.validation = { email: [{ error: 'email' }] };
        if (error.match(/password/i)) this.validation = { password: [{ error: 'auth' }] };
      } else if (this.code === 403 && this.message === 'Rostering unavailable for site') {
        // eslint-disable-next-line no-console
        console.log('Rostering Error: Rostering unavailable for site');
      } else if ([400, 403, 422].includes(this.code)) {
        if (body.error && body.message) {
          this.validation = { [body.error]: [body] };
        } else if (body.error) {
          this.validation = { general: { error: 'Something went wrong', message: body.error } };
        }
      }
    }

    this.message = [
      this.code,
      String(method).toUpperCase(),
      resource,
      String(this.message),
    ].join(' - ');
  }
}

export default APIError;
