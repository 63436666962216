import React from 'react';

const MaterialIcon = ({ children, className = 'md-18', toolTip }) => (
  <i
    className={`material-icons ${className} ${toolTip ? 'helpIcon' : ''}`}
    data-tooltip={toolTip}
  >
    {children}
  </i>
);

export default MaterialIcon;
