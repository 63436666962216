import MemoryStorage from './MemoryStorage';

export const storage = (typeof localStorage !== 'undefined') ? localStorage : new MemoryStorage();

/** Provides a method to persist application data */
export default class Storage {
  static get(key, defVal = null) {
    try {
      return JSON.parse(storage[key]);
    } catch (e) {
      return defVal;
    }
  }

  static set(key, value) {
    // Single equals checks for both null and undefined
    if (value != null) storage[key] = JSON.stringify(value);
    else storage.removeItem(key);

    return value;
  }

  static dehydrate(name, obj) {
    this.set(name, obj);
  }

  static rehydrate(name, obj) {
    const target = obj || {};

    try {
      const data = JSON.parse(storage[name]);

      Object.entries(data).forEach(([key, value]) => {
        target[key] = value;
      });

      return target;
    } catch (e) {
      throw new Error(`Could not rehydrate '${name}'`);
    }
  }
}
