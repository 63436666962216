import { action, computed, extendObservable } from 'mobx';
import { pluralize, scrubResourceType } from 'util/Resources';
import Question from './Question';

export default class Unit {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    extendObservable(this, {
      id: +input.id || 0,
      key: input.key || input.unit_key || 'XXX',
      title: input.title || '<Unknown Unit>',
      description: input.description || '',
      hours: +input.hours || 0,
      phase: input.phase || input.curriculum_phase || 'XX',
      showQuiz: input.showQuiz || !!input.showquiz,
      teacherOnly: !(input.teacherOnly === false || input.teacher_only === 0),
      resources: input.resources || [],
      questionCount: input.quiz_question_count,
      quizQuestionCount: input.quiz_question_count,
      testQuestionCount: input.test_question_count || 0,
      alwaysTestQuestionCount: input.always_test_question_count || 0,
      randomTestQuestionCount: input.random_test_question_count || 0,
      worksheetQuestions: input.electronic_worksheet_questions || 0,
      versionProduction: input.version_production,
      versionStaging: input.version_staging,
      lessons: input.lessons,
      answerKey: [],
      modules: [],
    });
  }

  @computed get totalTestQuestionCount() {
    const sum = this.alwaysTestQuestionCount + this.randomTestQuestionCount;
    return sum || this.testQuestionCount;
  }

  @computed get hasLessons() {
    return this.showQuiz;
  }

  @computed get hasQuiz() {
    return this.showQuiz;
  }

  @computed get isTest() {
    return !!this.key.match(/^(PRE)?MT_/);
  }

  @computed get possiblePoints() {
    return this.questionCount || 0;
  }

  @computed get lessonPlan() {
    return this.resources.map(scrubResourceType).find((r) => r.resource_type === 'Lesson Plan');
  }

  @computed get teacherResources() {
    const types = pluralize([
      'Teacher Resource', 'Teacher Guide', 'Assessment Guide',
      'Extra', 'Presentation', 'Lesson Plan', 'Quiz Key',
    ]);
    return this.resources.map(scrubResourceType).filter((r) => types.includes(r.resource_type));
  }

  @computed get studentResources() {
    const types = pluralize([
      'Student Resource', 'Student Handout', 'Transcript', 'Student Material',
      'Sample Answer Same Line', 'Worksheet', 'Student Worksheet', 'Lesson Attachment',
    ]);
    return this.resources.map(scrubResourceType).filter((r) => types.includes(r.resource_type));
  }

  @computed get sampleAnswers() {
    const types = pluralize([
      'Sample Answer',
    ]);
    return this.resources.map(scrubResourceType).filter((r) => types.includes(r.resource_type));
  }

  @computed get checklistResources() {
    const types = pluralize(['Checklist']);
    return this.resources.map(scrubResourceType).filter((r) => types.includes(r.resource_type));
  }

  // TODO: Move API calls to store
  @action loadAnswerKey({ moduleKey }) {
    const { WebAPI } = this.rootStore;

    return WebAPI.fetch(
      'GET',
      `teacher/catalog_answer_keys.json?unit_key=${this.key}&module_key=${moduleKey}`,
    )
      .then(action('loadedAnswerKey', (result) => {
        this.answerKey = result?.map((data) => new Question(this.rootStore, data));
        return Promise.resolve(this.answerKey);
      }));
  }

  @action loadAnswerKeyPDF({ moduleKey }) {
    const { WebAPI } = this.rootStore;

    return WebAPI.get(
      `teacher/catalog_answer_keys.pdf?unit_key=${this.key}&module_key=${moduleKey}`,
    )
      .then((result) => WebAPI.pollJob(result.job_id));
  }

  @computed get unitKey() {
    return this.key;
  }
}
