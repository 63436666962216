import React from 'react';
import { Switch, Route } from 'react-router';
import Enrollment from 'routes/course/students/Enrollment';
import Overview from './Overview';
import Preview from './Preview';
import RenameCourse from './RenameCourse';
import ArchiveCourse from './ArchiveCourse';
import ResetClass from './ResetClass';
import ShareCourse from './ShareCourse';
import TransferClass from './TransferClass';
import NewTemplateCourse from './NewTemplateCourse';
import DeleteTemplate from './DeleteTemplate';
import CourseBeta from './CourseBeta';

import './style.scss';

const Messages = () => <div><h1>Messages</h1></div>;

const Dashboard = () => (
  <Switch>
    <Route path="*/overview" component={Overview} />
    <Route path="*/messages" component={Messages} />
    <Route path="*/preview" component={Preview} />
    <Route path="*/enroll" component={Enrollment} />
    <Route path="*/rename" component={RenameCourse} />
    <Route path="*/share" component={ShareCourse} />
    <Route path="*/transfer" component={TransferClass} />
    <Route path="*/new" component={NewTemplateCourse} />
    <Route path="*/delete" component={DeleteTemplate} />
    <Route path="*/reset" component={ResetClass} />
    <Route path="*/archive" component={ArchiveCourse} />
    <Route path="*/beta" component={CourseBeta} />
  </Switch>
);

export default Dashboard;
