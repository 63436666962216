export default class CurriculumPhase {
  constructor(unit = {}) {
    this.key = unit.phase;

    switch (this.key) {
      case 'EXPLORE':
        this.num = 1;
        this.color = 'blue';
        this.title = 'Explore';
        this.subtitle = 'Instructor led activities to introduce topics & engage students';
        break;
      case 'LP':
        this.num = 2;
        this.color = 'green';
        this.title = 'Learn & Practice';
        this.subtitle = 'Interactive lessons & quizzes for students';
        break;
      case 'REFLECT':
        this.num = 3;
        this.color = 'orange';
        this.title = 'Reflect';
        this.subtitle = 'Instructor led activities to help students see the big picture';
        break;
      case 'REINFORCE':
        this.num = 4;
        this.color = 'red';
        this.title = 'Reinforce';
        this.subtitle = 'Projects and Instructor led classroom activities to reinforce learning';
        break;
      case 'MODULETEST':
        this.num = 'Final';
        this.color = 'red';
        this.title = 'Module Test';
        this.subtitle = 'Final assessment for the module';
        break;
      case 'MODULE':
        this.num = 0;
        this.color = 'blue';
        this.title = 'Module Resources';
        this.subtitle = 'Information to review before starting the module';
        break;
      default:
        this.num = '';
        this.color = 'gray';
        this.title = 'No Phase';
        this.subtitle = '';
    }
  }
}
