import { addMinutes, isFuture, parseJSON } from 'date-fns';

class BannerEvent {
  constructor(input) {
    const {
      bannerBody: body = '',
      startDateTime,
      endDateTime,
      learnMoreUrl,
      logoutBody = '',
      logoutDateTime,
      isUnexpected = false,
    } = input;

    const start = startDateTime ? parseJSON(startDateTime) : new Date();
    const end = endDateTime ? parseJSON(endDateTime) : addMinutes(start, 60);
    const logout = logoutDateTime && parseJSON(logoutDateTime);

    Object.assign(this, {
      body,
      logoutBody,
      learnMoreUrl,
      start,
      end,
      logout,
      isUnexpected,
    });
  }

  isActive() {
    return isFuture(this.end);
  }
}

export default BannerEvent;
