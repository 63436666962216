import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import ModuleIcon from 'widgets/assignment/ModuleIcon';

import './style.scss';

const CatalogTemplate = ({
  auth, catalog, curriculum, match: { params },
}) => {
  if (!catalog.loaded || !curriculum.loaded) {
    return <div />;
  }
  const { group } = params;
  const { templateKey } = params;
  const template = catalog.getTemplate(templateKey);
  const modulesAndGroups = template?.modulesAndGroups;

  if (!template) {
    return (
      <div>
        <h1>
          Missing Template
          {' '}
          {group}
          -
          {templateKey}
        </h1>
      </div>
    );
  }

  const { moduleKeys } = template;
  if (!moduleKeys) {
    return (
      <div>
        <h1>
          Missing Module Keys:
          {templateKey}
        </h1>
      </div>
    );
  }

  const ModuleHeading = ({ module }) => {
    if (!module) {
      return (
        <div className="moduleIcon">
          <h3>
            Missing Module:
            {module.key}
          </h3>
        </div>
      );
    }
    const link = `/catalog/${group}/${templateKey}/${module.key}`;
    return (
      <div className="moduleIcon">
        <h3><Link to={link}>{module.title}</Link></h3>
        <Link to={link}><ModuleIcon title={module.title} image={module.imageUrl} /></Link>
        <Link to={link}>View Details</Link>
      </div>
    );
  };

  const GroupHeading = ({ moduleGroup }) => {
    if (!moduleGroup) {
      return (
        <div className="moduleIcon">
          <h3>
            Missing Module Group:
            {moduleGroup.key}
          </h3>
        </div>
      );
    }
    const link = `/catalog/${group}/${templateKey}/group/${moduleGroup.key}`;
    return (
      <div className="moduleIcon">
        <h3><Link to={link}>{moduleGroup.title}</Link></h3>
        <Link to={link}><ModuleIcon title={moduleGroup.title} image={moduleGroup.imageUrl} /></Link>
        <Link to={link}>View Modules</Link>
      </div>
    );
  };

  return (
    <div className="catalogTemplates">
      <h1>{template.title}</h1>
      <p className="subheading">{template.description}</p>
      <div className="downloads">
        {
          template.resources && template.resources.map((r) => (
            <p key={r.id}>
              <a target="new" href={`${auth.mediaURL(r.url)}`}>
                <button type="button">
                  Download
                  {' '}
                  {r.title}
                </button>
              </a>
            </p>
          ))
        }
      </div>
      <div className="row">
        {modulesAndGroups.map((item) => (
          // { type, key }
          item.type === 'ModuleGroup'
            ? (
              <GroupHeading
                key={item.key}
                moduleGroup={item}
              />
            )
            : (
              <ModuleHeading
                key={item.key}
                module={item}
              />
            )
        ))}
      </div>
    </div>
  );
};

export default inject('auth', 'catalog', 'curriculum')(observer(CatalogTemplate));
