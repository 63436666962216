import React from 'react';
import { NavLink } from 'react-router-dom';

const DashboardNav = () => (
  <ul className="tabs">
    <li><NavLink to="/courses">My Classes</NavLink></li>
  </ul>
);

export default DashboardNav;
