import React, { Component, Fragment } from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import TextBox from 'widgets/form/TextBox';
import Modal from 'widgets/Modal';

@inject('courses', 'router') @observer
class NewTemplateCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      className: '',
      submitting: false,
    };
  }

  handleNameChange = (value) => {
    this.setState({ className: value });
  }

  handleShowModal = (e) => {
    e.preventDefault();
    this.setState({ modalVisible: true });
  }

  handleHideModal = () => {
    this.setState({ modalVisible: false });
  }

  @action.bound handleSaveClass = async () => {
    this.setState({ submitting: true });
    const { className } = this.state;
    const { courses, router } = this.props;
    const { fromRoute: course } = courses;
    const newClassName = className || `Class based on ${course.title}`;
    // courses.create params: template name, modules, course id, isTemplate true || false
    const newTemplate = await courses.create(newClassName, [], course.id, false);

    // send teacher to new
    router.push(`/courses/${newTemplate.id}`);
  }

  render() {
    const { courses: { fromRoute: course } } = this.props;
    const { modalVisible, className, submitting } = this.state;
    // set new template name
    const newClassName = className || `Class based on ${course.title}`;
    return (
      <div>
        <h1>Create Class from Template</h1>
        <p>Create a new class from this template for quick setup.</p>
        <form onSubmit={(e) => this.handleShowModal(e)}>
          <TextBox
            name="className"
            label="Class Name:"
            value={className}
            onChange={this.handleNameChange}
            leftLabel
          />
          <button type="submit" data-testid="saveNameChange">Save</button>
        </form>
        <Modal
          title="Confirm Class Name"
          visible={modalVisible}
        >
          {!submitting
            && (
            <Fragment>
              <p>{`Your class will be named: ${newClassName}`}</p>
              <p>If this is correct, click Save to continue.</p>
            </Fragment>
            )}
          {submitting && <h1>Please wait...</h1>}
          {!submitting
            && (
            <Fragment>
              <button type="button" data-testid="submitBtn" disabled={submitting} onClick={this.handleSaveClass}>Save</button>
              <button name="cancel" type="button" className="secondary" onClick={this.handleHideModal}>Cancel</button>
            </Fragment>
            )}
        </Modal>
      </div>
    );
  }
}
export default NewTemplateCourse;
