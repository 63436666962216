import React from 'react';
import { shuffle } from 'util/Array';
import { sanitizeString } from 'util/domUtil';

const QuizQuestionLearnosity = ({ question, number }) => {
  const {
    data: {
      stimulus, options, validation, shuffle_options: randomize,
    },
  } = question;
  const correct = validation.valid_response.value[0];
  const text = sanitizeString(stimulus);
  const regexpImg = /src="([^"]+)"/;
  const match = stimulus.match(regexpImg);
  const image = match && match[1];
  const list = randomize ? shuffle(options) : options;
  return (
    <div className="question questionLearnosity">
      <div className="body">
        <p>
          {number && `${number}. `}
          {text}
        </p>
      </div>
      {image && (
        <div className="image">
          <img src={image} alt="" />
        </div>
      )}
      <div className="answers">
        <ol>
          {list.map(({ value, label }) => (
            <li key={value}>
              <div>
                {value === correct && (
                  <span>
                    <b>
                      Correct
                    </b>
                    {' '}
                    &mdash;
                    {' '}
                  </span>
                )}
                {label}
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default QuizQuestionLearnosity;
