import React, { useState, useEffect } from 'react';
import './style.scss';
import UsageStudent from './UsageStudent';
import Accordion from '../../widgets/Accordion';

const UsageTeacher = ({ teacherData, singleTeacher, search }) => {
  const {
    teacher,
    students,
    totalStudents,
    unlicensedStudents,
  } = teacherData;

  const [showStudents, setShowStudents] = useState(false);

  useEffect(() => {
    if (search && !showStudents) { setShowStudents(true); }
  }, [search]);

  const handleClick = () => setShowStudents(!showStudents);

  const showButton = () => (!search
    ? (
      <button
        type="button"
        className="anchor"
        onClick={handleClick}
        key={teacher.id}
      >
        {showStudents
          ? 'Hide Enrolled Students'
          : 'Show Enrolled Students'}
      </button>
    )
    : null
  );

  return (
    <div className="usage-table">
      { singleTeacher
        ? <h1>Your License Usage Data</h1>
        : (
          <div className="teacher-list-heading">
            <h1>{teacher.name}</h1>
            {showButton()}
          </div>
        )}

      <table className="license-summary">
        <tbody>
          <tr>
            <td>
              <h6>Students Enrolled</h6>
              {totalStudents}
            </td>
            <td>
              <h6>Licenses Used</h6>
              {totalStudents - unlicensedStudents}
            </td>
          </tr>
        </tbody>
      </table>
      <Accordion closed={!showStudents}>
        <div className="license-details">
          { students.length > 0 ? (
            <table className="detailed-data">
              <thead>
                <tr>
                  <th>Student</th>
                  <th className="email">Email</th>
                  <th className="date">Enrollment Date</th>
                  <th className="inactive">Days Inactive</th>
                  <th className="enrolled">Enrolled Classes</th>
                  <th className="licensed">License Used</th>
                </tr>
              </thead>
              <tbody>
                { students.map((student) => (
                  <UsageStudent student={student} key={student.fullName} />
                )) }
              </tbody>
            </table>
          ) : (
            <>
              <p>No students enrolled yet.</p>
              <p>
                <strong>New to AES?</strong>
                {' '}
                Here are some handy links to help you on your way with enrollments
              </p>
              <a
                href="https://help.aeseducation.com/en/articles/4900775-enroll-students-for-the-first-time-with-an-enrollment-code"
                target="_blank"
                rel="noreferrer noopener"
              >
                Enroll Students for the First Time with an Enrollment Code
              </a>
              <br />
              <a
                href="https://help.aeseducation.com/en/articles/2164291-best-practices-for-enrolling-students-in-aes"
                target="_blank"
                rel="noreferrer noopener"
              >
                Best Practices for Enrolling Students in AES
              </a>
            </>
          ) }
        </div>
      </Accordion>
    </div>
  );
};
export default UsageTeacher;
