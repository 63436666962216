import React, { Component } from 'react';
import {
  action,
  autorun,
  observable,
  runInAction,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import ExamItemEditor from 'widgets/exam/ExamItemEditor';
import ExamItemList from 'widgets/exam/ExamItemList';
import ExamCopy from 'widgets/exam/ExamCopy';
import DateTime from 'widgets/format/DateTime';
import TextBox from 'widgets/form/TextBox';
import Modal from 'widgets/Modal';
import Alert from '../../../widgets/Alert';

@inject('courses', 'learnosityItems')
@observer
class ExamDetailsLearnosity extends Component {
  @observable editingItem = null;

  @observable newTitle = '';

  @observable showCopy = false;

  @observable showRename = false;

  componentDidMount() {
    const { assignment: { exam } } = this.props;
    this.dispose = autorun(() => {
      if (exam.id) {
        exam.getQuestions()
          .then(() => exam.loadPreview());
        runInAction(() => {
          this.newTitle = exam.title;
        });
      }
    });
  }

  componentWillUnmount() {
    const { learnosityItems } = this.props;
    learnosityItems.resetPreview();
    this.dispose();
  }

  @action.bound onChangeNewTitle(value) {
    this.newTitle = value;
  }

  @action.bound onClickRename() {
    const { assignment: { exam } } = this.props;
    exam.rename(this.newTitle)
      .then(() => this.toggleRename());
  }

  onClickEditItem(reference) {
    const { assignment: { exam } } = this.props;
    exam.editItem(reference)
      .then((item) => runInAction(() => {
        this.editingItem = item;
      }));
  }

  onClickRemoveItem(reference) {
    const { assignment: { exam } } = this.props;
    exam.removeItem(reference);
  }

  @action.bound closeEditItem() {
    this.editingItem = null;
  }

  @action.bound toggleCopy() {
    this.showCopy = !this.showCopy;
  }

  @action.bound toggleRename() {
    this.showRename = !this.showRename;
  }

  render() {
    const { assignment: { id, exam } } = this.props;
    const {
      title,
      questionCount,
      createdAt,
      activeItems,
      goodModules,
      goodItems,
      deadModules,
      deadItems,
      unmatchedModules,
      unmatchedItems,
    } = exam;
    const { editingItem } = this;
    const isEmpty = activeItems.length < 1;

    return (
      <div className="create-exam">
        <div className="formField">
          <Link to="../view" className="button">
            &larr; Return to Assignment List
          </Link>
        </div>
        <div className="heading">
          <h1>{`Exam: ${title}`}</h1>
          <div className="actions">
            <button type="button" className="lightButton" onClick={this.toggleRename}>
              Rename
            </button>
            {exam.questionCount > 0
              ? (
                <React.Fragment>
                  <button type="button" className="lightButton" onClick={this.toggleCopy}>
                    Copy
                  </button>
                  <Link className="button lightButton" to={`${id}/answer-key/`}>Answer Key</Link>
                </React.Fragment>
              )
              : null}
            <Link className="button" to={`${id}/add-items`}>Add Questions</Link>
          </div>
        </div>

        <div className="card details flex">
          <div className="info-block">
            <h3>Questions</h3>
            <span>{questionCount}</span>
          </div>
          <div className="info-block">
            <h3>Date Created</h3>
            <span><DateTime value={createdAt} /></span>
          </div>
        </div>
        {isEmpty
          ? (
            <div className="empty-message">
              <p>No questions to show yet.</p>
            </div>
          )
          : null }

        {deadItems.length > 0
          ? (
            <div className="dead">
              <Alert type="error">
                The following questions are from modules that have been retired.
                We recommend that you remove these questions from the exam.
              </Alert>
              <ExamItemList
                items={deadItems}
                moduleKeys={deadModules}
                onClickRemove={(reference) => this.onClickRemoveItem(reference)}
              />
            </div>
          )
          : null }

        {unmatchedItems.length > 0
          ? (
            <div className="unmatched">
              <Alert type="info">
                The following exam questions are from modules that you do not currently have in
                your Assignments list.
                <br />
                You should either add the respective modules, or remove the questions.
              </Alert>
              <ExamItemList
                items={unmatchedItems}
                moduleKeys={unmatchedModules}
                onClickRemove={(reference) => this.onClickRemoveItem(reference)}
              />
            </div>
          )
          : null}

        <ExamItemList
          items={goodItems}
          moduleKeys={goodModules}
          onClickEdit={(reference) => this.onClickEditItem(reference)}
          onClickRemove={(reference) => this.onClickRemoveItem(reference)}
          visible={!isEmpty}
        />
        {editingItem
          ? (
            <ExamItemEditor
              item={editingItem}
              onDismiss={this.closeEditItem}
              visible
            />
          )
          : null}
        <ExamCopy exam={exam} visible={this.showCopy} onDismiss={this.toggleCopy} />
        <Modal
          title="Rename Exam"
          visible={this.showRename}
          onDismiss={this.toggleRename}
        >
          <TextBox
            value={this.newTitle}
            onChange={this.onChangeNewTitle}
          />
          <button type="button" onClick={this.onClickRename} disabled={!this.newTitle}>
            Save
          </button>
          <button type="button" onClick={this.toggleRename} className="secondary">
            Cancel
          </button>
        </Modal>
      </div>
    );
  }
}

export default ExamDetailsLearnosity;
