/** Emulates the Storage object for our purposes */
class MemoryStorage {
  key(n) { return Object.keys(this)[n] || null; }

  getItem(key) { return this[key] || null; }

  setItem(key, value) { this[key] = value; }

  removeItem(key) { delete this[key]; }

  clear() {
    Object.keys(this).forEach((k) => this.removeItem(k));
  }
}

export default MemoryStorage;
