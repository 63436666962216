import React from 'react';
import { inject, observer } from 'mobx-react';

import NotFound from 'routes/error/404';
import AssignmentDetails from './AssignmentDetails';
import CustomModuleDetails from './CustomModuleDetails';
import ExamDetailsLearnosity from './ExamDetailsLearnosity';

const AssignmentSingle = (params) => {
  const {
    courses: {
      fromRoute: course,
    },
    match: {
      params: {
        id,
      },
    },
  } = params;
  const assignment = course.assignmentsById[id];
  if (!assignment) return <NotFound />;

  switch (assignment.typeName) {
    case 'module': return <AssignmentDetails assignment={assignment} />;
    case 'teacher_module': return <CustomModuleDetails assignment={assignment} />;
    case 'exam': return <ExamDetailsLearnosity assignment={assignment} />;
    default: return <NotFound />;
  }
};

export default inject('courses')(observer(AssignmentSingle));
