import { action, observable, runInAction } from 'mobx';
import Grade from 'models/Grade';
import Question from 'models/Question';

class GradesStore {
  @observable list = [];

  @observable lessonStatus = new Map();

  constructor(rootStore, api) {
    this.api = api;
    this.rootStore = rootStore;
  }

  get(id) {
    return this.list.find((grade) => grade.id === id);
  }

  processDetails = (details) => details?.map((data) => {
    const question = data.question && new Question(this.rootStore, data.question);
    return ({ ...data, question });
  });

  @action add(grade) {
    const length = this.list.push(new Grade(this.rootStore, grade));
    return this.list[length - 1];
  }

  @action addToCourse(courseId, grade) {
    this.add({ ...grade, courseId });
  }

  @action loadByCourse(courseId) {
    return this.api.get(`teacher/courses/${courseId}/grades?per_page=2000`)
      .then(action('doneLoadingGrades', (json) => {
        json.forEach((result) => this.addToCourse(+courseId, result));
      }));
  }

  @action reloadByCourse(courseId) {
    this.list = this.list.filter((grade) => grade.courseId !== courseId);
    this.loadByCourse(courseId);
  }

  @action loadGradeHistory(gradeId) {
    const grade = this.get(gradeId);
    return this.api.get(`teacher/courses/${grade.courseId}/grades/${grade.id}/history`)
      .then(action((result) => {
        grade.history = result?.map((e) => new Grade(this.rootStore, e))
          .sort((a, b) => new Date(a.completed).valueOf() - new Date(b.completed).valueOf());
      }));
  }

  loadLearnosityRequest(gradeId, compareGradeId) {
    const { location: { hostname } } = window;
    const grade = this.get(gradeId);
    const sessionId = grade.mostRecentAttempt?.id;
    const url = `teacher/courses/${grade.courseId}/grades/${gradeId}/session_details/${sessionId}`;
    let params = `host=${hostname}`;

    if (compareGradeId) {
      params = `compare_to=${compareGradeId}&host=${hostname}`;
    }

    return this.api.get(`${url}?${params}`)
      .then((result) => result && result.learnosity_request)
      .catch(() => '');
  }

  @action async loadGradeDetails(gradeId, compareGradeId) {
    const grade = this.get(gradeId);

    if (grade.attempts > 0) {
      await this.loadGradeHistory(gradeId);
      const { course: { enableBeta } } = grade;
      if (enableBeta) {
        if (compareGradeId) {
          await this.loadGradeHistory(compareGradeId);
        }
        if (grade.status > 1) {
          const learnosityRequest = await this.loadLearnosityRequest(gradeId, compareGradeId);
          const details = !learnosityRequest
            ? await this.api.get(`teacher/courses/${grade.courseId}/grades/${gradeId}/details`)
            : [];
          runInAction(() => {
            grade.learnosityRequest = learnosityRequest;
            grade.details = this.processDetails(details);
            grade.detailsLoaded = true;
          });
        } else {
          runInAction(() => { grade.detailsLoaded = true; });
        }
      } else {
        const details = await this.api.get(`teacher/courses/${grade.courseId}/grades/${gradeId}/details`);
        runInAction(() => {
          grade.details = this.processDetails(details);
          grade.detailsLoaded = true;
        });
      }
    }
  }

  @action refreshGrade(gradeId) {
    const grade = this.get(gradeId);

    return this.api.get(`teacher/courses/${grade.courseId}/grades/${gradeId}`)
      .then((result) => grade.update(result))
      .then(() => this.loadGradeHistory(gradeId));
  }

  @action markAnswerCorrect(gradeId, answerId) {
    const grade = this.get(gradeId);
    const answer = grade.details.find((detail) => detail.id === answerId);

    // NB: This looks odd to be putting nothing, but that's how the API controller is structured
    this.api.put(`teacher/courses/${grade.courseId}/answers/${answerId}`)
      .then(action(() => { answer.marked_correct = true; }))
      .then(() => this.refreshGrade(grade.id));
  }

  @action
  async markLearnosityAnswerCorrect(gradeId, responseId) {
    const { uiState } = this.rootStore;
    const grade = this.get(gradeId);
    const { mostRecentAttempt: { id } } = grade;
    uiState.startRequest();
    const result = await this.api.put(
      `teacher/courses/${grade.courseId}/grades/${gradeId}/session_details/${id}`,
      { override_response_ids: [responseId] },
      { quiet: true },
    ).catch(() => '');

    if (result.job_id) {
      this.api.pollJob(result.job_id)
        .then(() => {
          uiState.finishRequest();
          this.refreshGrade(gradeId);
        });
    } else {
      uiState.finishRequest();
      this.refreshGrade(gradeId);
    }
  }

  @action removeGradeAttempt(gradeId, lessonWorkId) {
    const grade = this.get(gradeId);
    const { courseId } = grade;

    this.api.delete(`teacher/courses/${courseId}/grades/${gradeId}/lesson_works/${lessonWorkId}`)
      .then(() => this.refreshGrade(gradeId));
  }

  @action resetGrade(gradeId) {
    const grade = this.get(gradeId);

    return this.api.delete(`teacher/courses/${grade.courseId}/grades/${gradeId}`)
      .then(action(() => {
        grade.quizGrade = 0;
        grade.status = 1;
        grade.quizStartedAt = null;
        grade.quizCompletedAt = null;
        grade.learnosityRequest = '';
      }))
      .then(() => this.loadGradeHistory(gradeId));
  }

  @action restoreGrade(gradeId) {
    const grade = this.get(gradeId);

    return this.api.patch(
      `teacher/courses/${grade.courseId}/grades/${gradeId}`,
      { unit_work: { restore: true } },
    ).then((result) => grade.update(result))
      .then(() => this.loadGradeDetails(gradeId));
  }

  @action loadExamModuleGrades(courseId, examId) {
    this.api.get(`teacher/courses/${courseId}/exam_grades/${examId}`)
      .then((answers) => answers.reduce((gradeModuleValues, answer) => {
        const {
          earned_points: points,
          possible_points: possible,
          module_key: moduleKey,
          unit_work_id: gradeId,
        } = answer;
        const existingGrade = gradeModuleValues.get(gradeId);
        const existingModule = existingGrade && existingGrade.get(moduleKey);
        const grade = existingGrade || new Map();
        const module = existingModule || { points: 0, possible: 0 };

        if (!existingGrade) {
          gradeModuleValues.set(gradeId, grade);
        }
        if (!existingModule) {
          grade.set(moduleKey, module);
        }

        module.points += points;
        module.possible += possible;

        return gradeModuleValues;
      }, new Map()))
      .then((gradeModuleValues) => gradeModuleValues.forEach((values, gradeId) => {
        runInAction(() => {
          const grade = this.list.find(({ id }) => id === gradeId);
          if (grade) {
            grade.examModuleGrades = values;
          }
        });
      }));
  }

  @action assignNew(input) {
    const {
      course, module, unit, student, points,
    } = input;

    return this.api.post(
      `teacher/courses/${course.id}/grades`,
      {
        unit_work: {
          student_id: student.id,
          module_key: module,
          unit_key: unit,
          total_earned: points,
        },
      },
    ).then((result) => {
      this.addToCourse(course.id, result);
      return result;
    });
  }

  @action updateOld(course, grade, points) {
    return this.api.patch(
      `teacher/courses/${course.id}/grades/${grade.id}`,
      { unit_work: { total_earned: points } },
    ).then((result) => grade.update(result))
      .then(() => this.loadGradeHistory(grade.id));
  }

  @action async createOrUpdate(input) {
    const {
      course, module, unit, student, points,
    } = input;
    const matching = (grade) => {
      if (grade.courseId !== course.id) return false;
      if (grade.studentId !== student.id) return false;
      if (grade.moduleKey !== module) return false;
      if (grade.unitKey !== unit) return false;
      return true;
    };

    const timestamp = (v) => new Date(v).valueOf();
    const byMostRecent = (a, b) => timestamp(b.updatedAt) - timestamp(a.updatedAt);

    const grades = this.list.filter(matching);

    if (grades.length === 0) {
      await this.assignNew(input);
    } else if (grades.length === 1) {
      await this.updateOld(course, grades[0], points);
    } else {
      // Multiple unit works, use most recent
      // See PLT-776
      const grade = grades.sort(byMostRecent)[0];
      await this.updateOld(course, grade, points);
    }
  }

  dispatchUpdate(data) {
    if (data.action === 'unit_work_update') {
      const current = this.list.find((g) => g.id === data.unit_work.id);
      runInAction(() => {
        if (current) { // Existing Grade
          current.update(data.unit_work);
        } else { // New Grade
          const { students } = this.rootStore;
          const { unit_work: unitWork } = data;
          const { student_id: studentId, enrollment_id: enrollmentId } = unitWork;
          const student = students.byId.get(studentId);

          if (student) {
            const { course_id: courseId } = student.enrollments
              .find(({ id }) => id === enrollmentId);

            this.addToCourse(courseId, unitWork);
          }
        }
      });
    }
  }
}

export default GradesStore;
