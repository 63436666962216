import {
  observable, computed, autorun, action, runInAction,
} from 'mobx';
import Fuse from 'fuse.js';
import Student from 'models/Student';

class StudentsStore {
  @observable byId = new Map()

  @observable byCourseId = new Map()

  @observable loaded = new Map()

  constructor(rootStore, api) {
    this.api = api;
    this.rootStore = rootStore;

    autorun(() => {
      const options = {
        keys: ['name', 'email'],
        shouldSort: true,
        threshold: 0.499,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        includeScore: true,
      };

      this.fuse = new Fuse(this.list, options);
    });
  }

  @computed get list() {
    return Array.from(this.byId.values());
  }

  @computed get fromRoute() {
    const { router: { studentId } } = this.rootStore;
    return this.byId.get(studentId);
  }

  @action quickSearch(query, courseId, advanced = false) {
    const raw = this.fuse.search(query.trim());
    const results = advanced ? raw : raw.map((r) => r.item);
    const courseStudents = (this.byCourseId.get(courseId) || []).map((s) => s.id);

    return results.filter((e) => courseStudents.includes((e.item || e).id));
  }

  @action async fullSearch(query) {
    const results = await this.api.get(`teacher/search?student=${query}`);

    const processResult = (result) => {
      const student = new Student(this.rootStore, result);
      student.courses = result.courses;
      return student;
    };

    return results?.map(processResult);
  }

  @action loadEventLogs(courseId, studentId, date, page = 1, totalPages = 0) {
    return this.api.get(
      `teacher/courses/${courseId}/students/${studentId}/event_logs?per_page=100${date}`,
      { page, totalPages },
      { useResponse: true },
    ).then((response) => runInAction(() => {
      const student = this.byId.get(studentId);
      student.events = response.data;
      if (response.page < response.pagesTotal) this.loadMoreEventLogs(response, student);
    }));
  }

  @action loadMoreEventLogs(response, student) {
    return response.nextPage()
      .then((res) => runInAction(() => {
        /* eslint-disable-next-line no-param-reassign */
        student.events = student.events.concat(res.data);
        if (res.page < res.pagesTotal) {
          this.loadMoreEventLogs(res, student);
        }
      }));
  }

  @action loadByCourse(id, page = 1, reload) {
    // if reload, call api - otherwise don't call api
    if (!reload) {
      const tenMinutesAgo = Date.now() - 600000;

      if (this.loaded.get(`${id}-${page}`) > tenMinutesAgo) return Promise.resolve();

      this.loaded.set(`${id}-${page}`, Date.now());
    }

    return this.api.get(
      `teacher/courses/${id}/students`,
      { page, per_page: 2000 },
      { useResponse: true },
    )
      .then((res) => this.doneLoadingStudents(id, page, res));
  }

  @action doneLoadingStudents(id, page, res, loadAll = true) {
    res.data.forEach((studentParam) => {
      const student = new Student(this.rootStore, studentParam);
      this.byId.set(Number(student.id), student);
      this.addToCourse(id, student);
    });

    if (loadAll && res.pagesTotal > 1) {
      const reqs = [];

      for (let p = res.page + 1; p <= res.pagesTotal; p += 1) {
        const req = res.getPage(p)
          .then((resp) => this.doneLoadingStudents(id, p, resp, false));
        reqs.push(req);
      }

      const flatten = (list) => (
        list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), [])
      );
      return Promise.all(reqs).then((resps) => flatten(resps));
    }

    return Promise.resolve(res.data);
  }

  @action addToCourse(id, student) {
    if (!this.byCourseId.has(+id)) {
      this.byCourseId.set(+id, []);
    }

    this.removeFromCourse(+id, student);
    this.byCourseId.get(+id).push(student);
  }

  @action removeFromCourse(id, student) {
    this.byCourseId.set(
      +id,
      this.byCourseId.get(+id).filter((s) => s.id !== student.id),
    );
  }

  @action move(student, oldCourse, newCourse) {
    const oldId = Object(oldCourse) === oldCourse ? oldCourse.id : +oldCourse;
    const newId = Object(newCourse) === newCourse ? newCourse.id : +newCourse;

    this.removeFromCourse(oldId, student);
    this.addToCourse(newId, student);
  }

  @action add(params) {
    const student = new Student(this.rootStore, params);

    this.byId.set(student.id, student);
    if (params.course) this.addToCourse(params.course, student);

    return student;
  }

  @action async validate(email) {
    const result = await this.api.post(
      'teacher/students/validate',
      { student_email: email },
    );

    if (result.student) {
      result.student = this.add(result.student);
    }

    return result;
  }
}

export default StudentsStore;
