import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';

@inject('curriculum', 'router', 'WebAPI') @observer
class WorksheetStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      worksheetUrl: '',
    };
  }

  loadWorksheet = (worksheet) => {
    const { router: { courseId }, WebAPI } = this.props;

    WebAPI.fetch(
      'GET',
      `teacher/courses/${courseId}/worksheets/${worksheet.id}`,
      undefined,
      { quiet: true },
    ).then((results) => this.setState({ worksheetUrl: results.url }));
  }

  render() {
    const { unit, worksheet } = this.props;
    const { worksheetUrl } = this.state;

    return (
      <td>
        {worksheet.worksheet_completed
          && (
          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
          <a onMouseOver={() => this.loadWorksheet(worksheet)} href={worksheetUrl} target="blank">
            {worksheet.worksheet_completed}
            /
            {unit.worksheetQuestions}
            {' '}
            Answered
          </a>
          )}
        {!worksheet.worksheet_completed
          && (
          <Fragment>
            0 /
            {' '}
            {unit.worksheetQuestions}
            {' '}
            Answered
          </Fragment>
          )}
      </td>
    );
  }
}

export default WorksheetStatus;
