/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
class TabControl extends Component {
  @observable selected = 0

  @action setTab = (n) => { this.selected = n; }

  render() {
    const { children } = this.props;
    const { selected } = this;

    return (
      <div className="tabsControl">
        <div className="tabNavigation">
          {children.map((child, i) => (
            <div
              className={`tab${i === selected ? ' active' : ''}`}
              onClick={() => this.setTab(i)}
              key={child.props.name}
            >
              {child.props.name}
            </div>
          ))}
        </div>
        {children.map((child, i) => (
          <div
            className={`tabSingle${i === selected ? ' active' : ''}`}
            key={child.props.name}
          >
            {child}
          </div>
        ))}
      </div>
    );
  }
}

export default TabControl;
