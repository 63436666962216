export function upcaseFirst(input) {
  if (typeof input !== 'string') return input;

  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function titleCase(input) {
  if (typeof input !== 'string') return input;

  return input.replace(/(^|\s)[a-z]/ig, (match) => match.toUpperCase());
}

export function formatDelta(before = 0, after = 0) {
  const delta = after - before;
  const fixed = Math.floor(delta) !== delta
    ? delta.toFixed(1)
    : `${delta}`;

  return delta > 0 ? `+${fixed}` : delta;
}

export function markText(text, query) {
  const matcher = new RegExp(`(${query.trim().replace(' ', '|')})`, 'gi');
  return text.replace(matcher, '<mark>$1</mark>');
}
