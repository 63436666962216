import React from 'react';
import { action } from 'mobx';
import Modal from 'widgets/Modal';
import TextBox from 'widgets/form/TextBox';

class RenameCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTitle: props.selectedCourse?.title || '',
      error: '',
    };
  }

  componentDidUpdate({ selectedCourse: prevCourse }) {
    const { selectedCourse: { title } } = this.props;

    if (title !== prevCourse?.title) {
      const newTitle = title ?? '';
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ newTitle });
    }
  }

  handleCourseRename = (value) => {
    this.setState({ newTitle: value });
  }

  @action.bound renameCourse() {
    const { onDismiss, selectedCourse } = this.props;
    const { newTitle } = this.state;
    // check for blank title
    if (newTitle === '') {
      this.setState({ error: 'Title cannot be blank.' });
    } else {
      selectedCourse.rename(newTitle);
      onDismiss();
      // reset error
      this.setState({ error: '' });
    }
  }

  @action.bound cancelPopup() {
    const { onDismiss, selectedCourse: { title } } = this.props;
    this.setState({
      newTitle: title,
      error: '',
    });
    // hide modal handled in DashboardCourses
    onDismiss();
  }

  render() {
    const {
      onDismiss, template, title, visible,
    } = this.props;
    const { error, newTitle } = this.state;
    return (
      <Modal
        title={title}
        visible={visible}
        onDismiss={onDismiss}
      >
        <form className="editClassForm">
          <div className="formHeading">
            <h1>
              Rename
              {template ? ' Template' : ' Class'}
            </h1>
            <p className="subtitle">
              Change the title of your
              {template ? ' template' : ' class'}
              {' '}
              by entering a new name below.
            </p>
          </div>

          <div className="formContent">
            <TextBox
              name="title"
              style={{ width: '375px' }}
              onChange={this.handleCourseRename}
              value={newTitle}
            />
            <p className="error">{error}</p>
          </div>

          <button type="button" data-testid="submitRename" onClick={this.renameCourse}>
            Rename
            {template ? ' Template' : ' Class'}
          </button>
          <button type="button" className="secondary" onClick={this.cancelPopup}>Cancel</button>
        </form>
      </Modal>
    );
  }
}

export default RenameCourse;
