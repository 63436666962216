import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';
import TextBox from 'widgets/form/TextBox';
import './style.scss';
import ClassCopyProcessing from './ClassCopyProcessing';

@inject('courses', 'router') @observer
class ResetClass extends Component {
  @observable error = null;

  @observable waiting = false;

  @observable newName = '';

  componentDidMount() {
    this.prefillNewName();
  }

  componentDidUpdate(prevProps) {
    const { selectedCourse } = this.props;

    if (selectedCourse !== prevProps.selectedCourse) {
      this.prefillNewName();
    }
  }

  @action setError(text) {
    setTimeout(action(() => {
      this.error = text;
    }));
  }

  @action prefillNewName() {
    const { selectedCourse } = this.props;

    this.newName = selectedCourse.title;
  }

  @action.bound closeDialog() {
    const { onDismiss } = this.props;
    this.waiting = false;
    onDismiss();
  }

  @action.bound async resetClass() {
    const isReset = true;
    const { newName } = this;

    this.waiting = true;

    const {
      selectedCourse, courses, router, noRedirect,
    } = this.props;

    const courseTitle = newName === '' ? selectedCourse.title : newName;

    const newCourse = await courses.copy(courseTitle, selectedCourse.id, isReset);

    if (!noRedirect && this.waiting) {
      router.push(`/courses/${newCourse.id}`);
    }
  }

  render() {
    const {
      selectedCourse,
      title,
      style,
      visible,
    } = this.props;
    const { waiting, newName } = this;
    return (
      <Modal
        title={title}
        visible={visible}
        onDismiss={this.closeDialog}
        style={style}
      >
        <form className="editClassForm">
          { waiting
            ? <ClassCopyProcessing copyPhrasing="reset" onClickClose={this.closeDialog} />
            : null }
          { !waiting
            ? (
              <div>
                <h1>Reset Class</h1>
                <p>
                  Send all of the students and grades in this class
                  to your archive so you can start fresh.
                </p>
                <TextBox
                  className="resetName"
                  label={
                    (
                      <>
                        <strong>Optional</strong>
                        : Give your class a new name
                      </>
                    )
                  }
                  placeholder={selectedCourse.title}
                  value={newName}
                  leftLabel
                  onChange={action((value) => {
                    this.newName = value;
                  })}
                />
                {this.error && <span className="error">{this.error}</span>}
                <button type="button" onClick={this.resetClass}>
                  <span>Reset Class</span>
                </button>
                <button type="button" className="secondary" onClick={this.closeDialog}>
                  Cancel
                </button>
              </div>
            )
            : null }
        </form>
      </Modal>
    );
  }
}

export default ResetClass;
