import { extendObservable, computed } from 'mobx';

class LearnosityItem {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    extendObservable(this, {
      id: input.id || 0,
      examId: input.exam_id || 0,
      reference: input.item_reference || '',
      ownerType: input.owner_type || 'AES',
      assessmentType: input.assessment_type || 'Test',
      questionType: input.question_type || '',
      stimulus: input.stimulus || input.item_stimulus || '',
      moduleKey: input.module_key || '',
      unitKey: input.unit_key || '',
      editRequest: input.learnosity_request || '',
      status: input.status || 'published',
      createdAt: input.created_at || null,
      deletedAt: input.deleted_at || null,
      updatedAt: input.updated_at || null,
    });
  }

  @computed get type() {
    return this.ownerType === 'AES'
      ? this.assessmentType
      : 'Custom';
  }

  @computed get module() {
    const { curriculum } = this.rootStore;
    return this.moduleKey && curriculum.modulesByKey[this.moduleKey];
  }

  @computed get unit() {
    const { curriculum } = this.rootStore;
    return this.unitKey && curriculum.unitsByKey[this.unitKey];
  }

  @computed get isPublished() {
    return this.status === 'published';
  }

  @computed get isActive() {
    return this.isPublished && !this.deletedAt;
  }
}

export default LearnosityItem;
