import React from 'react';
import { NavLink } from 'react-router-dom';

export default function CatalogMenu() {
  return (
    <ul className="tabs rightTabs">
      <li>
        <NavLink to="/catalog">Catalog</NavLink>
      </li>
    </ul>
  );
}
