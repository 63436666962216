export class DueDate {
  constructor(value) {
    if (value != null && value !== '') {
      this.value = new Date(value);
    } else {
      this.value = null;
    }

    if (this.valid) {
      this.value.setUTCHours(23);
      this.value.setUTCMinutes(59);
      this.value.setUTCSeconds(59);
    }
  }

  get valid() {
    return this.value != null;
  }

  get isFuture() {
    return this.value > new Date();
  }

  get isPassed() {
    return this.value < new Date();
  }

  get stringValue() {
    if (this.valid) return this.value.toISOString();
    return null;
  }
}

export default {
  DueDate,
};
