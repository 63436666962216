import React, { Component } from 'react';
import {
  action, runInAction, computed, observable,
} from 'mobx';
import { inject, observer } from 'mobx-react';

import Checkbox from 'widgets/form/Checkbox';
import RadioButtons from 'widgets/form/RadioButtons';
import TextBox from 'widgets/form/TextBox';

@inject('courses', 'router') @observer
class QuizzesTests extends Component {
  @observable retryTime

  courseId = null;

  componentDidMount() {
    const { courses: { fromRoute: course } } = this.props;
    runInAction(() => {
      this.courseId = course.id;
      this.setRetryTime(course.quizRetryTime);
    });
  }

  componentDidUpdate() {
    const { courses: { fromRoute: course } } = this.props;
    if (this.courseId !== course.id) {
      runInAction(() => {
        this.courseId = course.id;
        this.setRetryTime(course.quizRetryTime);
      });
    }
  }

  @action.bound setRetryTime = (val) => { this.retryTime = parseInt(val, 10); }

  @computed get assessmentSetting() {
    const { courses: { fromRoute: course } } = this.props;

    if (course.hideQuestions) return 'GRADEONLY';

    return course.showAnswers;
  }

  @action.bound setAssessmentSetting(value) {
    const { courses: { fromRoute: course } } = this.props;

    if (value === 'GRADEONLY') {
      course.setHideQuestions(true);
    } else {
      course.setHideQuestions(false);
      course.setShowAnswers(value);
    }
  }

  render() {
    const { courses: { fromRoute: course } } = this.props;
    const visibilityOptions = course.hasLearnosity
      ? {
        GRADEONLY: 'Their grade.',
        NEVER: 'Their grade, the questions, their answers, and whether correct or incorrect.',
        IMMEDIATLY: 'Their grade, the questions, their answers, and all the correct answers.',
        'ONE-HOUR': 'Their grade, the questions, their answers, and whether correct or incorrect. Show all the correct answers after one hour.',
        'ONE-DAY': 'Their grade, the questions, their answers, and whether correct or incorrect. Show all the correct answers after one day.',
      }
      : {
        GRADEONLY: 'Their grade.',
        NEVER: 'Their grade, the questions, and whether correct or incorrect.',
        IMMEDIATLY: 'Their grade, their answers and the correct answers for each question.',
        'ONE-HOUR': 'Their grade and their answers. Show the correct answers for each question after one hour.',
        'ONE-DAY': 'Their grade and their answers. Show the correct answers for each question after one day.',
      };

    return (
      <div>
        <h1>Quizzes &amp; Tests</h1>

        <h3>Module Settings</h3>
        <div className="formField">
          <Checkbox
            label="Module Pre-test"
            subtitle="Requires students to answer questions from the module test before beginning to measure learning."
            value={course.preTest}
            onChange={(value) => course.setPreTestEnabled(value)}
          />
        </div>

        <h3>Assessment Settings</h3>
        <div>
          After taking an assessment, show the student:
          <div className="formGroup" style={{ marginBottom: 0 }}>
            <RadioButtons
              options={visibilityOptions}
              value={this.assessmentSetting}
              onChange={this.setAssessmentSetting}
            />
          </div>
        </div>

        <h3>Unit Quiz Settings</h3>

        <div>
          Students can attempt unit quizzes a total of:
          <div className="formGroup" style={{ marginBottom: 0 }}>
            <RadioButtons
              options={{
                1: 'One time',
                2: 'Two times',
                3: 'Three times',
                4: 'Four times',
                5: 'Five times',
              }}
              value={course.quizAttempts}
              onChange={(value) => course.setQuizAttempts(value)}
            />
          </div>
        </div>

        <div className="formField">
          <span>Students must wait</span>
          <TextBox
            inline
            size={2}
            type="number"
            value={this.retryTime}
            onChange={this.setRetryTime}
            onBlur={(ev) => course.setQuizRetryTime(ev.target.value)}
            style={{ width: 44, margin: '0 11px' }}
          />
          <span>minutes before retaking a quiz.</span>
        </div>

        <span>When students attempt a quiz more than once, grade them by the:</span>
        <div className="formGroup">
          <RadioButtons
            options={{
              LAST: 'Most Recent Result.',
              HIGHEST: 'Best Result.',
              AVERAGE: 'Average of All Results.',
            }}
            value={course.gradeCalculation}
            onChange={(value) => course.setGradeCalculation(value)}
          />
        </div>
      </div>
    );
  }
}

export default QuizzesTests;
