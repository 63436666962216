/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const RadioButton = ({
  checked,
  group,
  inline,
  label,
  name,
  onChange,
  value,
  ...props
}) => {
  const id = String(group) + String(name);
  const isOn = checked || value || false;
  const selected = () => {
    if (typeof onChange === 'function') {
      onChange(name);
    }
  };

  return (
    <div className={`formField ${inline ? 'inline' : ''}`}>
      <input
        className="formInput"
        type="radio"
        name={group}
        id={id}
        checked={isOn}
        onChange={selected}
        {...props}
      />
      <label className="formLabel" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
