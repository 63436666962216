import { extendObservable, computed } from 'mobx';

class ModuleGroup {
  type = 'ModuleGroup';

  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    extendObservable(this, {
      key: input.module_group_key,
      title: input.title,
      description: input.description,
      image: input.image,
      moduleData: input.group_modules,
      resources: input.resources,
    });
  }

  @computed get resource() {
    const { auth } = this.rootStore;
    const { title, url } = this.resources[0];
    return {
      title,
      url: auth.mediaBaseUrl + url,
    };
  }

  @computed get imageUrl() {
    const { auth } = this.rootStore;
    return auth.mediaBaseUrl + this.image;
  }

  @computed get moduleKeys() {
    return this.moduleData
      .map((data) => data.module_key)
      .filter((key) => !!key);
  }

  @computed get modules() {
    const { curriculum } = this.rootStore;

    return this.moduleKeys
      .map((key) => curriculum.modulesByKey[key])
      .filter((module) => !!module);
  }

  @computed get minHours() {
    return this.modules.reduce((sum, module) => sum + module.minHours, 0);
  }

  @computed get maxHours() {
    return this.modules.reduce((sum, module) => sum + module.hours, 0);
  }
}

export default ModuleGroup;
