import React, { Component, Fragment } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { formatDelta } from 'util/String';
import Modal from 'widgets/Modal';
import QuestionList from 'widgets/question/QuestionList';

@inject('grades')
@observer
class PrePostDetails extends Component {
  componentDidUpdate() {
    const { grades } = this.props;
    if (!this.pretest || !this.postest) return;

    if (this.pretest && !this.pretest.detailsLoaded) {
      grades.loadGradeDetails(this.pretest.id);
    }

    if (this.postest && !this.postest.detailsLoaded) {
      grades.loadGradeDetails(this.postest.id);
    }
  }

  @computed get pretest() {
    const { assignment, grade } = this.props;
    return assignment && grade && grade.workByUnit[`PREMT_${assignment.moduleKey}`];
  }

  @computed get postest() {
    const { assignment, grade } = this.props;
    return assignment && grade && grade.workByUnit[`MT_${assignment.moduleKey}`];
  }

  @computed get loaded() {
    if (!this.pretest || !this.postest) return true;
    return this.pretest.detailsLoaded && this.postest.detailsLoaded;
  }

  @computed get pretestQuestions() {
    if (!this.pretest || !this.pretest.detailsLoaded) return [];
    return this.pretest.details;
  }

  @computed get postestQuestions() {
    if (!this.postest || !this.postest.detailsLoaded) return [];
    return this.postest.details;
  }

  render() {
    const {
      assignment,
      grade,
      onDismiss,
      student,
      visible,
    } = this.props;

    if (!grade || !assignment) return null;

    const deltaPercent = this.pretest && this.postest
      && formatDelta(this.pretest.percentEarned, this.postest.percentEarned);
    const deltaPoints = this.pretest && this.postest
      && formatDelta(this.pretest.totalEarned, this.postest.totalEarned);

    return (
      <Modal
        id="pre-post-details"
        title={`${student.name} - ${assignment.title}`}
        visible={visible}
        onDismiss={onDismiss}
        className="quizModal"
      >
        {!this.loaded && <div>Loading Test Details...</div>}
        {this.loaded
          && (
            <Fragment>
              <div className="row">
                <div className="col3" style={{ textAlign: 'center' }}>
                  <div>Pre-Test</div>
                  {this.pretest
                    && (
                      <div>
                        <h1 style={{ margin: 0 }}>{this.pretest.percent}</h1>
                        <div>{this.pretest.points}</div>
                      </div>
                    )}
                  {!this.pretest && <div>Not Taken</div>}
                </div>
                <div className="col3" style={{ textAlign: 'center' }}>
                  <div>Post-Test</div>
                  {this.postest
                    && (
                      <div>
                        <h1 style={{ margin: 0 }}>{this.postest.percent}</h1>
                        <div>{this.postest.points}</div>
                      </div>
                    )}
                  {!this.postest && <div>Not Taken</div>}
                </div>
                <div className="col3" style={{ textAlign: 'center' }}>
                  {this.pretest && this.postest
                    && (
                      <div>
                        <div>Improvement</div>
                        <h1 style={{ margin: 0, color: deltaPercent[0] === '+' ? '#1C7C32' : '#941C5D' }}>
                          {deltaPercent}
                          %
                        </h1>
                        <div>
                          {deltaPoints}
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <hr />

              <QuestionList
                numbered
                grade={grade}
                preQuestions={this.pretestQuestions}
                postQuestions={this.postestQuestions}
              />
            </Fragment>
          )}
      </Modal>
    );
  }
}

export default PrePostDetails;
