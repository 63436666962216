import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import GoogleLogin from 'react-google-login';

@inject('auth') @observer
class Signon extends Component {
  // Provider data isn't loaded b default
  componentDidMount() {
    const { auth } = this.props;
    auth.user.loadSingleSignOn();
  }

  addProvider() {
    const { auth } = this.props;
    document.location = auth.singleSignOnUrl;
  }

  finishGoogleConnect(googleResponse) {
    const { auth: { user } } = this.props;
    const { tokenId } = googleResponse;
    user.connectProvider('google', tokenId);
  }

  renderProvider({ key, title }) {
    const { auth: { user, user: { singleSignOnConnections } } } = this.props;
    const connection = singleSignOnConnections.find((c) => c.provider === key);
    return (
      <div style={{ marginTop: 20, marginBotton: 20 }}>
        <h1>
          {title}
          {' '}
          Single Sign On
        </h1>
        {connection && (
          <table>
            <tbody>
              <tr>
                <td>AES Account Email:</td>
                <td>{user.email}</td>
              </tr>
              <tr>
                <td>Provider Email:</td>
                <td>{connection.provider_email}</td>
              </tr>
              <tr>
                <td>Provider ID:</td>
                <td>{connection.provider_id}</td>
              </tr>
            </tbody>
          </table>
        )}
        {!connection && key === 'google' && (
          this.renderConnectGoogle()
        )}
        {!connection && (key === 'clever' || key === 'classlink') && (
          this.renderPortalInstructions(title, user.email)
        )}
        {!connection && key === 'canvas-lti' && (
          this.renderCanvasInstructions(user.email)
        )}
      </div>
    );
  }

  renderConnectGoogle() {
    const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;
    return (
      <div>
        <GoogleLogin
          clientId={REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Connect Google Account"
          onSuccess={(r) => this.finishGoogleConnect(r)}
        />
      </div>
    );
  }

  renderPortalInstructions = (title, email) => (
    <div>
      {title}
      {' '}
      is not connected. Follow the instructions below to connect
      your account for single sign on.
      <ul>
        <li>
          Verify that your AES and
          {' '}
          {title}
          {' '}
          emails addresses are the same.
        </li>
        <li>
          Sign in to your district&apos;s
          {' '}
          {title}
          {' '}
          Portal.
        </li>
        <li>Verify the official AES Icon is available.</li>
        <li>Click the AES Icon.</li>
        <li>Your account will be connected.</li>
      </ul>
      <p>
        Your AES Email Address:
        {' '}
        {email}
      </p>
    </div>
  );

  renderCanvasInstructions = (email) => (
    <div>
      Canvas is not connected. Follow the instructions below to connect
      your account for single sign on.
      <ul>
        <li>Sign in to your district&apos;s Canvas portal.</li>
        <li>Navigate to your course in Canvas.</li>
        <li>
          Click the
          {' '}
          <strong>AES Staging</strong>
          {' '}
          link in Canvas LMS.
        </li>
        <li>Enter your AES Email &amp; Password to connect accounts.</li>
      </ul>
      <p>
        Your AES Email Address:
        {' '}
        {email}
      </p>
    </div>
  )

  render() {
    const {
      auth: {
        site: {
          hasSingleSignOnGoogle,
          hasSingleSignOnClasslink,
          hasSingleSignOnClever,
          hasCanvas,
        },
      },
    } = this.props;

    return (
      <div>

        {hasSingleSignOnGoogle && (
          this.renderProvider({ title: 'Google', key: 'google' })
        )}

        {hasSingleSignOnClasslink && (
          this.renderProvider({ title: 'Classlink', key: 'classlink' })
        )}

        {hasSingleSignOnClever && (
          this.renderProvider({ title: 'Clever', key: 'clever' })
        )}

        {hasCanvas && (
          this.renderProvider({ title: 'Canvas', key: 'canvas-lti' })
        )}

      </div>
    );
  }
}

export default Signon;
