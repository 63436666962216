import React from 'react';

const ProgressBar = ({ max = 100, value }) => {
  // calculate width of progress bar
  const percent = value ? `${((value / max) * 100).toFixed(1)}%` : '0';

  return (
    <div className="progressBar">
      <div className="bar" style={{ width: percent }} />
      <span className="label">{`${value}/${max}`}</span>
    </div>
  );
};

export default ProgressBar;
