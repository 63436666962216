import React, { Component } from 'react';
import { action, observable, autorun } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';
import Dropdown from 'widgets/form/Dropdown';

@inject('teachers', 'auth') @observer
class TeacherEdit extends Component {
  @observable show = false

  @observable role

  componentDidMount() {
    autorun(() => {
      const { props: { teacher } } = this;
      this.setRole(teacher.role);
    });
  }

  @action.bound showModal = () => { this.show = true; }

  @action.bound hideModal = () => { this.show = false; }

  @action.bound setRole = (value) => { this.role = value; }

  @action.bound confirmChange() {
    const { teachers, teacher } = this.props;
    teachers.setRole(teacher.id, this.role);
  }

  render() {
    const { auth, teacher } = this.props;

    return (
      <React.Fragment>
        <button type="button" onClick={this.showModal}>Edit</button>
        <Modal
          id="teacher-edit"
          visible={this.show}
          onDismiss={this.hideModal}
          title="Edit Teacher Permissions"
        >
          <p>
            {teacher.name}
            {' '}
            will be able to access
            {' '}
            {auth.site.name}
            {' '}
            as a:
          </p>

          <Dropdown
            options={{ INSTRUCTOR: 'Teacher', ADMIN: 'Administrator' }}
            value={this.role}
            onChange={this.setRole}
            noEmpty
          />

          <div>
            <button type="button" onClick={this.confirmChange}>Change Permissions</button>
            <button type="button" className="secondary" onClick={this.hideModal}>Cancel</button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default TeacherEdit;
