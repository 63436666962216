import React from 'react';

const UsageTeacherFilter = ({
  filteredTeacherID,
  handleChange,
  teachers,
  finalTeacherList,
  hasSearch,
}) => {
  const optionDisabled = (optionID) => (
    hasSearch && finalTeacherList.filter(({ teacher: { id } }) => id === optionID).length < 1
  );

  return (
    <label htmlFor="teacher">
      <select
        value={filteredTeacherID}
        id={filteredTeacherID}
        name="teacher"
        onChange={handleChange}
        className="teacherDropdown"
      >
        <option value="">All Teachers</option>
        {teachers.map(({ teacher: { id, name } }) => (
          <option
            disabled={optionDisabled(id)}
            value={id}
            key={id}
          >
            {name}
          </option>
        ))}
      </select>
    </label>
  );
};

export default UsageTeacherFilter;
