/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import {
  action, computed, observable, runInAction,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import WorksheetEmpty from './WorksheetEmpty';

@inject('router', 'WebAPI') @observer
class WorksheetGrade extends React.Component {
  @observable worksheetUrl = '';

  @computed get worksheet() {
    const { grade, assignment } = this.props;

    // select module or unit worksheet information for grid
    let sheet = {};
    if (grade && !assignment.unitKey) {
      // module level worksheet info
      sheet = grade.worksheetByModule[assignment.moduleKey];
    } else if (grade && assignment.unitKey) {
      // unit level worksheet info
      sheet = grade.worksheet[assignment.unitKey];
    }

    return sheet;
  }

  @action openUnit() {
    const { assignment, router, work } = this.props;

    router.push(`${router.path}/unit/${assignment.unitKey}/grade/${work.id}`);
  }

  @action loadWorksheet(event) {
    const { router: { courseId }, WebAPI } = this.props;
    const { worksheet: { id }, worksheetUrl } = this;
    const worksheetWindow = window.open('about:blank', 'student_worksheet');

    if (!worksheetUrl) {
      event.preventDefault();
      WebAPI.fetch(
        'GET',
        `teacher/courses/${courseId}/worksheets/${id}`,
        undefined,
        { quiet: true },
      ).then(({ url }) => {
        worksheetWindow.location = url;
        runInAction(() => { this.worksheetUrl = url; });
      });
    }
  }

  render() {
    const {
      assignment, onClick, worksheetCount,
    } = this.props;
    const { worksheetUrl } = this;

    return (
      <div>
        {/* Module grade, can't show worksheet information */}
        {/* Student has selected a unit / has started a worksheet */}
        {!assignment.unitKey
          && this.worksheet
          && this.worksheet.started !== 0
          && (
            <button
              onClick={onClick}
              key={`${assignment.id}+grade`}
              className="fixedWidth anchor"
              type="button"
            >
              {this.worksheet.completed}
              /
              {worksheetCount}
            </button>
          )}
        {/* Student has selected a unit / has not started worksheet */}
        {!assignment.unitKey && this.worksheet && this.worksheet.started === 0
          && (
            <button
              onClick={onClick}
              className="fixedWidth anchor"
              type="button"
            >
              0/
              {worksheetCount}
            </button>
          )}
        {/* Student has not selected a unit / has not started a worksheet */}
        {!assignment.unitKey && !this.worksheet
          && (
            <button
              onClick={onClick}
              className="fixedWidth anchor"
              type="button"
            >
              0/
              {worksheetCount}
            </button>
          )}

        {/* Unit grade, can load and show worksheet information */}
        {assignment.unitKey && this.worksheet && this.worksheet.worksheet_questions
          && (
            <a
              onClick={(e) => this.loadWorksheet(e)}
              href={worksheetUrl}
              target="student_worksheet"
            >
              {this.worksheet.worksheet_completed}
              /
              {this.worksheet.worksheet_questions}
            </a>
          )}
        {assignment.unitKey && this.worksheet && this.worksheet.worksheet_questions === undefined
          && <WorksheetEmpty />}
      </div>
    );
  }
}

export default WorksheetGrade;
