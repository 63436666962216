import React, { Component } from 'react';

export default class Keywords extends Component {
  render() {
    const { text, keywords } = this.props;

    const kw = keywords.map((k) => k.toLowerCase());
    const modified = text.replace(new RegExp(`(${kw.join('|')})`, 'gi'), '&%~$1&%~');
    const results = modified.split('&%~');

    return results.map((chunk) => {
      if (kw.includes(chunk.toLowerCase())) {
        return <span className="highlight">{chunk}</span>;
      }

      return chunk;
    });
  }
}
