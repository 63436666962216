export function distance2D(x1, y1, x2, y2) {
  return Math.sqrt(((x1 - x2) ** 2) + ((y1 - y2) ** 2));
}

export function collision2D(box1, box2) {
  if (box1.right < box2.left) return false;
  if (box1.left > box2.right) return false;
  if (box1.bottom < box2.top) return false;
  if (box1.top > box2.bottom) return false;
  return true;
}

export default {
  distance2D,
  collision2D,
};
