import { observable, action, runInAction } from 'mobx';

import AssessmentOpen from 'models/AssessmentOpen';

class AssessmentOpensStore {
  @observable byCourse = new Map()

  // @observable byId = new Map()
  @observable loaded = new Map()

  constructor(rootStore, api) {
    this.api = api;
  }

  @action loadByCourse(id) {
    const fiveMinutesAgo = Date.now() - 300000;

    if (this.loaded.get(+id) > fiveMinutesAgo) return Promise.resolve();

    this.loaded.set(+id, Date.now());

    return this.api.get(`teacher/courses/${+id}/assessment_opens`)
      .then((json) => this.doneLoadingOverrides(+id, json));
  }

  @action doneLoadingOverrides(courseId, json) {
    json.forEach((data) => {
      if (!this.byCourse.has(courseId)) {
        this.byCourse.set(courseId, []);
      }

      const override = new AssessmentOpen(data);
      this.byCourse.get(courseId).push(override);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  @action openAssessment(params) {
    const { entry, student, expiration } = params;
    const { courseId } = entry;

    return this.api.post(
      `teacher/courses/${courseId}/assessment_opens`,
      {
        assessment_open: {
          student_id: student.id,
          assignment_id: entry.assignment.id,
          assignment_entry_id: entry.isExam ? undefined : entry.id,
          expires: expiration,
        },
      },
    )
      .then((result) => this.doneLoadingOverrides(courseId, [result]));
  }

  @action delete(assessmentOpen) {
    const { id, courseId } = assessmentOpen;
    return this.api.delete(`teacher/courses/${courseId}/assessment_opens/${id}`)
      .then(() => runInAction(() => {
        const opens = this.byCourse.get(courseId);
        const updated = opens.filter((e) => e.id !== id);
        this.byCourse.set(courseId, updated);
      }));
  }

  find(course, student, entry) {
    if (!course.id || !student.id || !entry) return null;
    if (!this.byCourse.has(course.id)) return null;

    const opens = this.byCourse.get(course.id)
      .filter((open) => open.studentId === student.id);

    if (!opens.length) return null;

    return opens.find((open) => { // eslint-disable-line consistent-return
      if (open.isExpired) return false;

      // Opened assessment
      if (open.assignmentEntryId === entry.id) {
        return true;
      }

      // Opened exam
      if (entry.isExam && open.assignmentId === entry.assignment.id) {
        return true;
      }

      return false;
    });
  }
}

export default AssessmentOpensStore;
