import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

@inject('auth', 'courses') @observer
class CourseBeta extends Component {
  render() {
    const { auth: { isAesAdmin }, courses: { fromRoute: course } } = this.props;
    return (
      <div>
        <h1>
          Enable V2 Beta:
          {course.enableBeta}
        </h1>
        <p>
          Would you like students in the class to use the new student user interface?
        </p>
        <label htmlFor="enableBeta">
          <input
            id="enableBeta"
            name="enableBeta"
            type="checkbox"
            checked={course.enableBeta}
            disabled={!isAesAdmin}
            onClick={() => course.setEnableBeta(!course.enableBeta)}

          />
          <span>Enable V2 Beta</span>
        </label>
      </div>
    );
  }
}
export default CourseBeta;
