import React from 'react';
import { observer } from 'mobx-react';
import QuizQuestionClassic from './QuizQuestionClassic';
import QuizQuestionLearnosity from './QuizQuestionLearnosity';

const QuizQuestion = ({
  grade,
  number,
  compact,
  actions,
  question,
  answer,
  isTest,
  isPrePost,
  lessonAnswer,
  showAnswerSource,
}) => {
  if (question) {
    return (
      question.reference ? (
        <QuizQuestionLearnosity
          number={number}
          question={question}
        />
      ) : (
        <QuizQuestionClassic
          grade={grade}
          number={number}
          compact={compact}
          actions={actions}
          question={question}
          answer={answer}
          isTest={isTest}
          isPrePost={isPrePost}
          lessonAnswer={lessonAnswer}
          showAnswerSource={showAnswerSource}
        />
      )
    );
  }
  return null;
};

export default observer(QuizQuestion);
