import React from 'react';
import { inject, observer } from 'mobx-react';

const Lesson = ({
  curriculum,
  lessonKey,
  showLessonViewer,
}) => {
  const { title, url } = curriculum.lessons[lessonKey];
  const onClick = () => showLessonViewer(url);

  return (
    <li>
      { url
        ? <button type="button" className="anchor" onClick={onClick}>{title}</button>
        : title }
    </li>
  );
};

export default inject('curriculum')(observer(Lesson));
