/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Checkbox from 'widgets/form/Checkbox';
import DatePicker from 'widgets/form/DatePicker';
import DateTime from 'widgets/format/DateTime';
import ModuleIcon from 'widgets/assignment/ModuleIcon';
import MaterialIcon from '../../../widgets/MaterialIcon';

const AssignmentListRow = observer(({
  index, assignment, rowStyle, startDrag, router, auth, updateDueDate, showModal, disabled,
}) => {
  const onMouseDown = (ev) => !disabled && startDrag(ev, assignment.module, assignment, index);
  const onTouchStart = (ev) => !disabled && startDrag(ev, assignment.module, assignment, index);
  const disableExam = () => !!disabled || assignment.exam.numQuestions === 0;
  const disableDate = () => (assignment.isExam ? disableExam() : disabled);

  return (
    <tr
      className="assignmentListRow assignmentRow"
      key={assignment.id}
      style={rowStyle}
    >
      <td className="assignmentTitle">
        <div
          className="moduleGripper"
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
        />
        { disabled
          ? (
            <div className="disabled">
              <ModuleIcon title={module.title} image={assignment.imageUrl} />
              <div className="assignmentDetails">
                <span className="title">
                  {`Loading ${assignment.title}...`}
                </span>
              </div>
            </div>
          )
          : (
            <Link to={`${router.path}/${assignment.id}`}>
              <ModuleIcon title={module.title} image={assignment.imageUrl} />
              <div className="assignmentDetails">
                <span className="title">{assignment.title}</span>
                {assignment.module.version && (
                  <small>
                    {' '}
                    (
                    {assignment.module.version}
                    )
                  </small>
                )}
              </div>
            </Link>
          )}

      </td>
      <td>{assignment.hours || ''}</td>
      {auth.site.hasAdvancedPacing && (
      <td>
        <div className="dueDateEdit">
          <DatePicker
            placeholder="No Due Date"
            value={assignment.dueDate}
            onChange={(value) => updateDueDate(assignment, value)}
            disabled={disableDate()}
          />
          <button
            type="button"
            className="native"
            onClick={() => assignment.setDueDate(null, assignment.open)}
            disabled={disabled}
          >
            X
          </button>
        </div>
        <div className="dueDateDisplay">
          <DateTime format="MM/DD/YYYY" value={assignment.dueDate} noValue="None" />
        </div>
      </td>
      )}
      <td className="accessCol">
        { assignment.module && !assignment.isExam
          && (
          <Checkbox
            label="Module"
            checked={assignment.open}
            onChange={(value) => assignment.setStatus(value)}
            disabled={disabled}
            inline
            noMargin
          />
          )}
        { assignment.test && assignment.test.enabled
          && (
          <Checkbox
            label="Test"
            checked={assignment.test.open}
            onChange={(value) => assignment.setTestStatus(value)}
            disabled={disabled}
            inline
            noMargin
          />
          )}
        { assignment.isExam && (
          <div className="examRow">
            <Checkbox
              label="Exam"
              checked={assignment.test.open}
              onChange={(value) => assignment.setTestStatus(value)}
              disabled={disableExam()}
              inline
              noMargin
            />
            {assignment.exam.numQuestions < 1
              ? <div className="exam-empty">Exam is Empty</div>
              : null}
          </div>
        )}
      </td>
      <td className="deleteModule">
        <button
          type="button"
          className="icon transparent"
          onClick={(value) => showModal(assignment, value)}
          disabled={disabled}
        >
          <MaterialIcon className="md-24">delete</MaterialIcon>
        </button>
      </td>
    </tr>
  );
});

export default AssignmentListRow;
