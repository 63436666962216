import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  NavLink, Route, Switch, withRouter,
} from 'react-router-dom';
import GeneralSearch from 'widgets/GeneralSearch';
import CatalogMenu from 'widgets/curriculum/CatalogMenu';
import AssignmentEditMenu from 'widgets/assignment/AssignmentEditMenu';
import PreviewLink from './PreviewLink';

@inject('auth', 'router', 'courses') @withRouter @observer
class SubNavigation extends React.Component {
  render() {
    const { auth, courses } = this.props;
    const { fromRoute: course } = courses;
    const courseUpgrading = !!course?.upgradeJobId;

    const NavItem = ({ path, children }) => (
      <li>
        <NavLink to={`${path}`}>
          {children}
        </NavLink>
      </li>
    );

    return (
      <Switch>

        <Route path="*/assignments/edit">
          <AssignmentEditMenu />
        </Route>

        <Route
          path="/courses/:courseId(\d+)"
          render={() => {
            const isTemplate = course && course.template;
            const isOwner = course && course.facultyId === auth.user.id;

            const CourseNav = ({ path, children }) => (
              <NavItem path={`/courses/${course.id}/${path}`}>{children}</NavItem>
            );

            return (
              <div className="subNav">
                { courseUpgrading
                  ? (
                    <div className="chip upgrading">
                      Beta conversion in progress
                    </div>
                  )
                  : null }
                <GeneralSearch />
                <Switch>
                  {!isTemplate
                    && (
                    <Route path="*/dashboard">
                      <div>
                        <h3>My Class</h3>
                        <ul>
                          <CourseNav path="dashboard/overview">Overview</CourseNav>
                          <PreviewLink path="dashboard/preview" />
                          <CourseNav path="dashboard/enroll">Enroll Students</CourseNav>
                        </ul>
                        {isOwner && (
                          <React.Fragment>
                            <h3>Advanced Tools</h3>
                            <ul>
                              <CourseNav path="dashboard/share">Share Class</CourseNav>
                              <CourseNav path="dashboard/transfer">Transfer Class</CourseNav>
                              {auth.enableBeta && auth.isAesAdmin
                              && <CourseNav path="dashboard/beta">Enable V2 Beta</CourseNav>}
                            </ul>
                          </React.Fragment>
                        )}
                      </div>
                    </Route>
                    )}
                  {isTemplate && auth.enableCreateTemplates
                  && (
                  <Route path="*/dashboard">
                    <div>
                      <h3>Template Tools</h3>
                      <ul>
                        <CourseNav path="dashboard/overview">Overview</CourseNav>
                        <PreviewLink path="dashboard/preview" />
                        {isOwner && <CourseNav path="dashboard/new">Create Class</CourseNav>}
                        {isOwner && <CourseNav path="dashboard/delete">Delete Template</CourseNav>}
                      </ul>
                    </div>
                  </Route>
                  )}
                  <Route path="*/assignments">
                    <div>
                      <h3>Assignments</h3>
                      <ul>
                        <CourseNav path="assignments/view">Assignment Details</CourseNav>
                        <CourseNav path="assignments/edit">Add Assignments</CourseNav>
                        <PreviewLink path="dashboard/preview" />
                      </ul>

                      <h3>Advanced Tools</h3>
                      <ul>
                        <CourseNav path="assignments/create-exam">Create an Exam</CourseNav>
                        <CourseNav path="assignments/custom-modules">Custom Modules</CourseNav>
                        <CourseNav path="assignments/settings/quizzes-tests">Quizzes &amp; Tests</CourseNav>
                        <CourseNav path="assignments/settings/pacing-sequencing">Student Pacing</CourseNav>
                        <CourseNav path="assignments/settings/worksheets">Student Worksheets</CourseNav>
                        {auth.enableQuizChallenge
                          && <CourseNav path="assignments/settings/daily-quiz-challenge">Daily Quiz Challenge</CourseNav>}
                      </ul>
                    </div>
                  </Route>
                  <Route path="*/students">
                    <div>
                      <h3>Students</h3>
                      <ul>
                        <CourseNav path="students/list">Student Roster</CourseNav>
                        <CourseNav path="students/enroll">Enroll Students</CourseNav>
                      </ul>
                    </div>
                  </Route>
                  <Route path="*/grades">
                    <div>
                      <h3>Grades</h3>
                      <ul>
                        <CourseNav path="grades/book">Gradebook</CourseNav>
                        <CourseNav path="grades/by-student">Status Report</CourseNav>
                        <CourseNav path="grades/pre-post">Pre/Post Report</CourseNav>
                        <CourseNav path="grades/activities">Activities/Games</CourseNav>
                      </ul>

                      <h3>Advanced Tools</h3>
                      <ul>
                        <CourseNav path="grades/certificates">Create Certificates</CourseNav>
                        <CourseNav path="grades/question-analysis">Question Analysis</CourseNav>
                        <CourseNav path="grades/timeonline">Time Online Report</CourseNav>
                      </ul>
                    </div>
                  </Route>
                </Switch>
              </div>
            );
          }}
        />

        <Route path="*/settings">
          <div>
            <h3 title={auth.user.name}>{auth.user.name}</h3>
            <ul>
              <NavItem path="/settings/teacher/profile">Profile</NavItem>
              <NavItem path="/settings/teacher/security">Security</NavItem>
              {auth.site.hasSingleSignOn
                && <NavItem path="/settings/site/single-signon">Single Sign On</NavItem>}
            </ul>
            <h3 title={auth.site.name}>{auth.site.name}</h3>
            <ul>
              <NavItem path="/settings/site/subscription">Subscription</NavItem>
            </ul>
            {auth.user.isAdmin && <h3>Administration</h3>}
            {auth.user.isAdmin && (
            <ul>
              <NavItem path="/settings/site/teachers">Teachers</NavItem>
            </ul>
            )}
          </div>
        </Route>

        <Route path="*/archive">
          <div>
            <h3>Deleted Items</h3>
            <ul>
              <NavItem path="/archive/courses">Deleted Classes</NavItem>
              <NavItem path="/archive/exams">Deleted Exams</NavItem>
              <NavItem path="/archive/templates">Deleted Templates</NavItem>
            </ul>
          </div>
        </Route>

        <Route path="/courses/new">
          <AssignmentEditMenu />
        </Route>

        <Route path="/catalog">
          <CatalogMenu />
        </Route>

      </Switch>
    );
  }
}

export default SubNavigation;
