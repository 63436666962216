import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import {
  action, autorun, computed, observable,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import { triggerDownload } from 'services/WebAPI';
import Modal from 'widgets/Modal';
import StudentName from 'widgets/grade/StudentName';
import StudentAverage from 'widgets/grade/StudentAverage';
import StudentModuleGrade from 'widgets/grade/StudentModuleGrade';
import StudentUnitGrade from 'widgets/grade/StudentUnitGrade';
import GradeDisplayOptions from './GradeDisplayOptions';
import ExportElectronicWorksheets from './ExportElectronicWorksheets';
import './gradetablestyle.scss';

@inject('auth', 'courses', 'curriculum', 'grades', 'lessonStatus', 'router') @observer
class GradeTable extends Component {
  @observable showInProgress = true;

  @observable showGrades = true;

  @observable showWorksheets = true;

  @observable measure;

  @observable toggleHeight = 100;

  @observable hoveredRow = null;

  @observable hoveredCol = null;

  @observable filter = '';

  @observable usePoints = false;

  constructor(props) {
    super(props);
    const { auth } = this.props;
    const { user } = auth;

    autorun(() => {
      this.usePoints = !!user.getOption('usePoints');
      this.showInProgress = user.getOption('showInProgress');
      this.showGrades = !!user.getOption('showGrades');
      this.showWorksheets = !!user.getOption('showWorksheets');
    });

    autorun(() => {
      const { grades, courses: { fromRoute: course } } = this.props;
      if (course.id) {
        grades.reloadByCourse(course.id, this.filter);
        // TODO: enable line when lesson status view is implemented.
        // lessonStatus.load(course.id, this.filter);
        this.resetScroll();
      }
    });

    this.state = {
      showOptions: false,
      showExportWorksheets: false,
    };
  }

  async componentDidMount() {
    this.attachEvent();

    const { router } = this.props;
    if (!this.showGrades && !this.showWorksheets) {
      this.toggleGrades();
    }

    this.dispose = autorun(() => {
      const match = router.path.match(/\/book\/([-\w]+)/);
      if (!match) {
        this.setFilter('');
        return;
      }

      const key = match[1];
      if (this.filter !== key) this.setFilter(key);
    });
  }

  componentWillUnmount() {
    this.dispose();
    this.detachEvent();
  }

  @computed get assignments() {
    const { course } = this;

    return course.gradeAssignments;
  }

  @computed get selectedAssignment() {
    const { course, filter } = this;

    return course.assignments.find((assignment) => assignment.moduleKey === filter);
  }

  @computed get assignmentEntries() {
    return this.selectedAssignment?.gradeEntries || [];
  }

  @computed get course() {
    const { courses: { fromRoute: course } } = this.props;
    return course;
  }

  @computed get students() {
    return this.course.students;
  }

  @computed get studentsColumnWidth() {
    if (!this.measure) return 0;

    const min = 255;
    const padding = 48;
    const column = 80;
    let max = 0;

    this.measure.style.display = 'initial';
    this.students.forEach((student) => {
      this.measure.innerText = student.formatName;
      max = Math.max(max, this.measure.offsetWidth);
    });
    this.measure.style.display = 'none';

    return Math.max(min, max + padding + column);
  }

  @computed get headerStyle() {
    return {
      left: this.studentsColumnWidth,
    };
  }

  @computed get fixedStyle() {
    return {
      width: this.studentsColumnWidth,
    };
  }

  @computed get bodyStyle() {
    return {
      left: this.studentsColumnWidth,
    };
  }

  getModifierClasses = (assignment = {}) => {
    const { isExam, worksheetQuestionCount, totalPossibleModulePoints } = assignment;
    const large = (worksheetQuestionCount > 99 || totalPossibleModulePoints > 99) ? 'large' : '';
    const doubleCol = this.showWorksheets && this.showGrades && !isExam ? 'doubleCol' : '';
    return `${doubleCol} ${large}`;
  }

  @action.bound setMeasureRef(el) {
    this.measure = el;
  }

  @action.bound togglePoints = () => {
    const { auth: { user } } = this.props;
    this.usePoints = !this.usePoints;
    user.setOption('usePoints', this.usePoints);
  }

  @action.bound toggleInProgress = () => {
    const { auth: { user } } = this.props;
    this.showInProgress = !this.showInProgress;
    user.setOption('showInProgress', this.showInProgress);
  }

  @action.bound toggleGrades = () => {
    const { auth: { user } } = this.props;
    this.showGrades = !this.showGrades;
    user.setOption('showGrades', this.showGrades);
  }

  @action.bound toggleWorksheets = () => {
    const { auth: { user } } = this.props;
    this.showWorksheets = !this.showWorksheets;
    user.setOption('showWorksheets', this.showWorksheets);
  }

  @action.bound hoverRow = (n) => { this.hoveredRow = n; }

  @action.bound leaveRow = () => { this.hoveredRow = null; }

  @action.bound hoverCol = (n) => { this.hoveredCol = n; }

  @action.bound leaveCol = () => { this.hoveredCol = null; }

  @action setFilter = (key) => { this.filter = key; }

  handleShowOptions = () => {
    this.setState({ showOptions: true });
  }

  handleHideOptions = () => {
    this.setState({ showOptions: false });
  }

  syncScroll = () => {
    this.fixedCol.scrollTop = this.tableBody.scrollTop;
    this.tableHeader.scrollLeft = this.tableBody.scrollLeft;
  }

  handleModuleSelect = (event) => {
    this.openModule(event.target.value);
  }

  handleExport = async () => {
    const { course } = this;
    const { assignments } = course;
    const { auth } = this.props;

    const assignmentData = assignments.filter((asgn) => asgn.moduleKey === this.filter);
    const selectedAssignment = assignmentData.length > 0 ? assignmentData[0].id : null;
    const display = auth.user.options.usePoints ? 'points' : 'percentage';
    const calculation = auth.user.options.showInProgress ? 'inprogress' : 'final';
    const selectedTitle = assignmentData.length > 0 ? assignmentData[0].title : 'All Modules';

    const result = await course.generateExcel(
      'grades',
      {
        assignment: selectedAssignment,
        display,
        calculation,
      },
    );

    triggerDownload(
      result,
      `Grades - ${course.title} - ${selectedTitle}.xls`,
    );
  }

  handleExportWorksheets = () => {
    this.setState({ showExportWorksheets: true });
  }

  handleRefresh = () => {
    const { courses } = this.props;
    courses.reloadCourseData(this.course.id, this.filter);
  }

  worksheetDisplay = () => {
    const {
      auth: {
        user: {
          options: {
            showGrades,
            showWorksheets,
          },
        },
      },
    } = this.props;
    if (showGrades && showWorksheets) {
      return 'Both';
    } if (showWorksheets) {
      return 'Worksheets';
    }
    return 'Grades';
  }

  @action openModule(key) {
    const { router } = this.props;
    const rootPath = router.path.replace(/\/book\/.*$/, '/book');
    this.filter = key;
    router.push(`${rootPath}/${key}`);
  }

  hoverStyle(row, col) {
    // Cell highlight
    if (row === this.hoveredRow && col === this.hoveredCol) {
      return { background: 'rgba(237, 201, 81, 0.4)' };
    }

    // Row/column highlight
    if (row === this.hoveredRow || col === this.hoveredCol) {
      return { background: 'rgba(237, 201, 81, 0.2)' };
    }

    return null;
  }

  resetScroll() {
    try {
      this.tableBody.scrollLeft = 0;
      this.tableHeader.scrollLeft = 0;
      // eslint-disable-next-line no-empty
    } catch (e) { }
  }

  attachEvent() {
    if (!this.listener && this.tableBody) {
      this.listener = this.syncScroll;
      this.tableBody.addEventListener('scroll', this.listener);
    }
  }

  detachEvent() {
    if (this.tableBody && this.listener) {
      this.tableBody.removeEventListener('scroll', this.listener);
      this.listener = undefined;
    }
  }

  @action hoverStudentRow(r) {
    this.hoverRow(r);
    this.hoverCol(0);
  }

  @action.bound leaveStudentRow() {
    this.leaveRow();
    this.leaveCol();
  }

  renderUnitCell = (assignmentEntry, course, courseGrade, student, row, col) => (
    <div
      className={`gradesCol ${this.getModifierClasses(assignmentEntry)}`}
      key={assignmentEntry.id + student.id}
      onMouseEnter={() => this.hoverCol(col + 1)}
      onMouseLeave={this.leaveCol}
      style={this.hoverStyle(row, col + 1)}
    >
      <StudentUnitGrade
        assignment={assignmentEntry}
        course={course}
        grade={courseGrade}
        points={this.usePoints}
        showGrades={this.showGrades}
        showWorksheets={this.showWorksheets}
        student={student}
      />
    </div>
  )

  renderExamCell = (assignment, course, courseGrade, student, row, col) => {
    const assignmentEntry = assignment.gradeEntries[0];
    return this.renderUnitCell(assignmentEntry, course, courseGrade, student, row, col);
  };

  renderModuleCell = (assignment, course, courseGrade, student, row, col) => (
    assignment.isExam
      ? this.renderExamCell(assignment, course, courseGrade, student, row, col)
      : (
        <div
          className={`gradesCol ${this.getModifierClasses(assignment)}`}
          key={assignment.id + student.id}
          onMouseEnter={() => this.hoverCol(col + 1)}
          onMouseLeave={this.leaveCol}
          style={this.hoverStyle(row, col + 1)}
        >
          <StudentModuleGrade
            assignment={assignment}
            course={course}
            grade={courseGrade}
            onClick={() => this.openModule(assignment.moduleKey)}
            usePoints={this.usePoints}
            showGrades={this.showGrades}
            showWorksheets={this.showWorksheets}
            student={student}
            useFinal={!this.showInProgress}
          />
        </div>
      )
  )

  renderHeaderCells(assignmentOrEntry) {
    const className = `gradesAssignment ${this.getModifierClasses(assignmentOrEntry)}`;

    if (!this.filter) {
      return (
        <div className={className} key={`header_${assignmentOrEntry.id}`}>
          <p>
            <button
              className="anchor assignmentTitle"
              onClick={() => this.openModule(assignmentOrEntry.moduleKey)}
              type="button"
            >
              {assignmentOrEntry.title}
            </button>
          </p>
        </div>
      );
    }

    return (
      <div className={className} key={`header_${assignmentOrEntry.id}`}>
        <p>
          { assignmentOrEntry.type === 2
            ? assignmentOrEntry.title
            : `${assignmentOrEntry.title} Quiz` }
        </p>
      </div>
    );
  }

  render() {
    const { course } = this;
    const { auth } = this.props;
    const { showOptions, showExportWorksheets } = this.state;
    const headerValues = this.filter
      ? this.assignmentEntries
      : this.assignments;

    if (this.students.length === 0) {
      return (
        <div>No graded work has been done in your class.</div>
      );
    }

    return (
      <React.Fragment>
        <div className="gradesHeaderContainer">
          <div className="gradesSelectModule">
            <h2>Module:</h2>
            <select onChange={this.handleModuleSelect} value={this.filter}>
              <option value="">All Modules</option>
              {course.gradeAssignments
                .map((assignment) => (
                  <option key={assignment.id} value={assignment.moduleKey}>
                    {assignment.title}
                  </option>
                ))}
            </select>
          </div>
          <div className="gradesDisplayOptions noPrint" style={this.fixedStyle}>
            <div className="displayOptions">
              <p>
                <strong>Display:</strong>
                {' '}
                {auth.user.options.usePoints ? 'Points' : 'Percentage'}
              </p>
              <p>
                <strong>Calculation:</strong>
                {' '}
                {auth.user.options.showInProgress ? 'In-Progress' : 'Final'}
              </p>
              <p>
                <strong>Worksheets:</strong>
                {' '}
                {auth.user.options.showWorksheets ? 'Shown' : 'Hidden'}
              </p>
            </div>
            <button type="button" onClick={this.handleShowOptions}>
              View Options
            </button>
            <div className="displayHelp">
              <a href="https://help.aeseducation.com/en/articles/3041092-review-the-gradebook" target="new">
                Gradebook Help
                <i className="material-icons">help</i>
              </a>
            </div>
          </div>
          <div className="gradesSelectOptions">
            <button type="button" onClick={this.handleExport}>Export Grades</button>
            <button type="button" onClick={this.handleExportWorksheets}>Export Worksheets</button>
            <button type="button" onClick={this.handleRefresh}>Refresh</button>
          </div>
        </div>
        <div className="gradesTable">
          <div
            className="gradesHeader noPrint"
            ref={(el) => { this.tableHeader = el; }}
            style={this.headerStyle}
          >
            {headerValues.map((value) => this.renderHeaderCells(value))}
          </div>
          <div className="separator printOnly" />
          <div
            className="gradesFixed noPrint"
            ref={(el) => { this.fixedCol = el; }}
            style={this.fixedStyle}
          >
            {this.students.map((student, row) => {
              const grade = student.gradesByCourse.get(course.id);
              return (
                <div
                  className="gradesRow"
                  onMouseEnter={() => this.hoverStudentRow(row)}
                  onMouseLeave={this.leaveStudentRow}
                  key={student.id}
                >
                  <div
                    className="gradesCol gradesStudent"
                    key={student.id + student.firstName}
                    style={{
                      width: this.studentsColumnWidth - 80,
                      ...this.hoverStyle(row, 0),
                    }}
                  >
                    <StudentName student={student} />
                  </div>
                  <div
                    className="gradesCol"
                    style={this.hoverStyle(row, 0)}
                    key={student.id + student.firstLastName}
                  >
                    <StudentAverage
                      student={student}
                      moduleKey={this.filter}
                      points={this.usePoints}
                      grade={grade}
                      useFinal={!this.showInProgress}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="gradesBody"
            ref={(el) => { this.tableBody = el; }}
            style={this.bodyStyle}
          >
            <div className="gradesRow gradesPrintHeader printOnly">
              <div className="gradesCol">Student</div>
              <div className="gradesCol">Avg</div>
              {headerValues.map(({ id, title }) => (
                <div className="gradesCol gradesAssignment" key={id}>
                  <span>{title}</span>
                </div>
              ))}
            </div>

            {this.students.map((student, row) => {
              const courseGrade = student.gradesByCourse.get(this.course.id);

              return (
                <div
                  className="gradesRow"
                  onMouseEnter={() => this.hoverRow(row)}
                  onMouseLeave={this.leaveRow}
                  key={student.id}
                >
                  <div className="gradesCol gradesStudent printOnly">
                    <StudentName student={student} />
                  </div>
                  <div className="gradesCol printOnly">
                    <StudentAverage
                      student={student}
                      moduleKey={this.filter}
                      points={this.usePoints}
                      grade={courseGrade}
                    />
                  </div>
                  { this.filter
                    ? this.assignmentEntries.map((assignmentEntry, col) => (
                      this.renderUnitCell(assignmentEntry, course, courseGrade, student, row, col)
                    ))
                    : this.assignments.map((assignment, col) => (
                      this.renderModuleCell(assignment, course, courseGrade, student, row, col)
                    )) }
                </div>
              );
            })}
          </div>
          <span ref={this.setMeasureRef} />
        </div>
        <ExportElectronicWorksheets
          showExportWorksheets={showExportWorksheets}
          course={course}
          onDismiss={() => this.setState({ showExportWorksheets: false })}
        />
        <Modal
          onDismiss={this.handleHideOptions}
          title="View Options"
          visible={showOptions}
        >
          <GradeDisplayOptions
            onDismiss={this.handleHideOptions}
            showGrades={this.showGrades}
            showWorksheets={this.showWorksheets}
            showInProgress={this.showInProgress}
            toggleGrades={this.toggleGrades}
            toggleInProgress={this.toggleInProgress}
            togglePoints={this.togglePoints}
            toggleWorksheets={this.toggleWorksheets}
            usePoints={this.usePoints}
          />
        </Modal>
        {createPortal(
          <style>
            {`
              #app .layout main {
                padding: 0;
              }
            `}
          </style>,
          document.head,
        )}

      </React.Fragment>
    );
  }
}

export default GradeTable;
