import React, { Component } from 'react';

export default class FileButton extends Component {
  onChange() {
    const { onSelect, multiple } = this.props;
    if (typeof onSelect === 'function') {
      if (multiple) {
        onSelect(this.fileInput.files);
      } else {
        onSelect(this.fileInput.files[0]);
      }
    }
  }

  render() {
    const { children } = this.props;
    return (
      <div className="button fileButton">
        {children}
        <input type="file" ref={(el) => { this.fileInput = el; }} onChange={() => this.onChange()} />
      </div>
    );
  }
}
