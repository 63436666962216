import React from 'react';
import Modal from 'widgets/Modal';

// This modal shows when teachers change the due date for a closed
// module to a date after today. This is done because they may not
// realize that changing the due date isn'f sufficient to give
// students access.
const AssignmentDueDateModal = ({
  visible,
  updateDueDateAndOpen,
  updateDueDate,
}) => (
  <Modal
    title="Change Due Date"
    id="due-prompt"
    visible={visible}
    onDismiss={updateDueDate}
  >
    <p>
      The new due date has not passed but this assignment is currently closed to students.
      Would you like to open it?
    </p>
    <button type="button" onClick={updateDueDateAndOpen}>
      Open the Assignment
    </button>
    <button type="button" className="secondary" onClick={updateDueDate}>
      Leave it Closed
    </button>
  </Modal>
);

export default AssignmentDueDateModal;
