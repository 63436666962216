import React from 'react';
import { NavLink } from 'react-router-dom';

const GettingStartedNav = () => (
  <ul className="tabs">
    <li><NavLink to="/get-started">Getting Started</NavLink></li>
  </ul>
);

export default GettingStartedNav;
