import React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import './style.scss';

const adjustTitle = (title) => title
  .replace('Computer Applications - ', '')
  .replace('Career Readiness & Digital Literacy', 'Career Readiness');

@inject('auth', 'assignments', 'catalog') @observer
class AssignmentEditMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchQuery: '' };
  }

  componentDidMount() {
    this.resetCatalog();
  }

  // get assignments for course
  @action getAssignments(course) {
    const { assignments } = this.props;
    assignments.loadByCourse(course.id).then(() => {
      const courseAssignments = course.assignments;
      const modules = courseAssignments.filter((a) => a.typeName === 'module').map((a) => a.moduleKey);
      this.setModules(modules);
    });
  }

  @action setModules(modules) {
    const { catalog } = this.props;
    catalog.myModules = modules;
  }

  @action resetCatalog() {
    // Reset Catalog Editor Data
    const { catalog } = this.props;
    catalog.myTemplate = null;
    catalog.myTemplateTitle = '';
  }

  @action runSearch(e) {
    const { catalog } = this.props;
    e.stopPropagation();
    e.preventDefault();
    catalog.currentTemplate = null;
    catalog.currentGroup = null;
    catalog.search(catalog.searchQuery);
  }

  @action updateQuery(value) {
    const { catalog } = this.props;
    catalog.searchQuery = value;
    this.setState({ searchQuery: value });
  }

  @action selectGroup(e, groupKey) {
    e.stopPropagation();
    e.preventDefault();
    const { catalog } = this.props;
    catalog.currentGroup = groupKey;
    catalog.currentTemplate = null;
    catalog.searchResults = null;
  }

  @action selectLocalGroup() {
    const { catalog } = this.props;
    catalog.currentGroup = null;
    catalog.currentTemplate = null;
    catalog.myTemplate = null;
    catalog.myTemplateTitle = null;
    catalog.searchResults = null;
  }

  @action selectTemplate(e, templateKey) {
    e.stopPropagation();
    e.preventDefault();
    const { catalog } = this.props;
    catalog.currentTemplate = templateKey;
    catalog.searchResults = null;
  }

  @action selectLocalTemplate(template) {
    const { catalog } = this.props;
    catalog.myTemplateTitle = template.title;
    catalog.myTemplate = template;
    this.getAssignments(template);
  }

  group(key, group) {
    const { catalog } = this.props;
    if (!group || !group.templates || group.templates.length === 0) {
      return null;
    }
    const active = catalog.currentGroup === key;
    const selected = active && !catalog.currentTemplate;
    const classes = [];
    if (selected) classes.push('active');
    if (key === 'my' || key === 'other') classes.push('custom');
    return (
      <li key={key}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className={classes.join(' ')}
          onClick={(e) => this.selectGroup(e, key)}
        >
          {group.title}
        </a>
        {active && (
        <ul>
          {group.templates.map((t) => (t
            && (
            <li key={t.templateKey}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                className={catalog.currentTemplate === t.templateKey ? 'active' : null}
                onClick={(e) => this.selectTemplate(e, t.templateKey)}
              >
                {adjustTitle(t.title)}
              </a>
            </li>
            )
          ))}
        </ul>
        )}
      </li>
    );
  }

  render() {
    const { catalog } = this.props;
    const { searchQuery } = this.state;

    return (
      <div className="assignmentEditNav">
        <h3>Catalog</h3>
        <ul className="catalogTop">
          {catalog.groupsForCurrentUserAssignments.map((g) => this.group(g, catalog.groups[g]))}
        </ul>
        <div className="searchContainer">
          <form onSubmit={(e) => this.runSearch(e)}>
            <input
              className="searchInput"
              placeholder="Search"
              type="text"
              value={searchQuery}
              onChange={(e) => this.updateQuery(e.target.value)}
            />
            <button type="submit" className="lightCatalogButton">Search</button>
          </form>
        </div>
      </div>
    );
  }
}

export default AssignmentEditMenu;
