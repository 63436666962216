import React from 'react';
import { createRoot } from 'react-dom/client';
import { action, configure, toJS } from 'mobx';
import { Provider } from 'mobx-react';
import { Router } from 'react-router-dom';
import RootStore from 'stores/RootStore';
import Application from 'application';
import 'style/global.scss';

const container = document.getElementById('app');
const root = createRoot(container);
const rootStore = new RootStore();

configure({ enforceActions: 'observed' });

// Debugging tools
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
  window.stores = rootStore;
  window.action = action;
  window.toJS = toJS;
  window.WebAPI = rootStore.webAPI;
  global.debug = true;
}

root.render(
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Provider rootStore={rootStore} {...rootStore}>
    <Router history={rootStore.router.history}>
      <Application />
    </Router>
  </Provider>,
);

if (module.hot) {
  module.hot.decline();
}
