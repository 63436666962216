import {
  action,
  computed,
  observable,
  runInAction,
  toJS,
} from 'mobx';

import Storage from 'services/Storage';
import User from 'models/User';
import Site from 'models/Site';
import BannerEvent from '../models/BannerEvent';

class AuthStore {
  @observable server = 'production'

  @observable errors = []

  @observable verified = false

  @observable site = new Site()

  @observable admin = null

  @observable originalToken

  @observable originalUser = null

  @observable impersonationToken = null

  @observable impersonationUser = null

  @observable expired = null

  @observable lastActive = Date.now()

  @observable lastRefresh = Date.now()

  @observable showSessionWarning = false

  @observable features = []

  @observable emailVerified = false

  @observable siteValidated = false

  @observable sitePackage = 'STUDENT_LICENSE'

  @observable siteProduct = 'unknown'

  @observable siteType = 'EVAL'

  @observable singleSignOnConnections = []

  @observable providers = []

  @observable integrationProviders = []

  @observable integrationCourses = []

  @observable integrationError = false

  @observable oauthError = false

  @observable currentOAuthProvider

  @observable jobAttempts = 0;

  @observable suppressUpdate = false;

  @observable bannerData = [];

  @observable bannerDismissed = false;

  constructor(rootStore, api) {
    // Don't load local storage if there is a token in the query string
    const tokenInQuery = window.location.search.includes('token=');
    const value = tokenInQuery ? null : Storage.get('auth');

    this.api = api;
    this.rootStore = rootStore;
    this.originalUser = new User(rootStore);

    if (value) {
      this.server = value.server || 'production';
      this.originalToken = value.token || undefined;
      this.impersonationToken = value.impersonationToken || undefined;
      if (!this.originalToken) this.impersonationToken = undefined;
    }

    window.onbeforeunload = this.persist.bind(this);
    window.addEventListener('message', ({ origin, data, source }) => {
      if (origin !== window.location.origin) {
        return;
      }
      if (data.oauthStatus === 'complete') {
        this.completeOAuth(data.code, data.state);
        source.close();
      }

      if (data.oauthStatus === 'error') {
        this.oauthError = true;
        source.close();
      }
    });
    this.refreshInterval = setInterval(this.handleSession, 2500);
  }

  persist() {
    Storage.set('auth', {
      token: this.originalToken,
      server: this.server,
      impersonationToken: this.impersonationToken,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  clearStorage() {
    Storage.set('auth', null);
  }

  @computed get simulated() {
    const { uiState } = this.rootStore;
    return uiState.devMode || this.originalToken === 'test';
  }

  @computed get loggedIn() {
    return this.simulated || !!(this.originalToken && this.verified);
  }

  @computed get isProduction() {
    return this.server === 'production';
  }

  @computed get isDemo() {
    return this.server === 'demo';
  }

  @computed get isStaging() {
    return !this.isProduction;
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get isLocal() {
    return window.location.hostname?.match(/localhost|127.0.0.1|10.17.\d+.\d+/);
  }

  @computed get apiAccess() {
    return this.loggedIn && !this.simulated;
  }

  @computed get isAdmin() {
    if (!this.originalToken || !this.verified) {
      return false;
    }

    return this.originalUser.isAdmin;
  }

  @computed get isImpersonation() {
    return !!(this.impersonationToken && this.impersonationUser);
  }

  @computed get isAesAdmin() {
    return this.originalUser.role === 'AES'
    || (!this.isImpersonation && !!this.admin?.id);
  }

  @computed get token() {
    if (this.isImpersonation) return this.impersonationToken;
    return this.originalToken;
  }

  @computed get user() {
    const userObject = this.isImpersonation ? this.impersonationUser : this.originalUser;

    if (!userObject) {
      if ('Rollbar' in window) {
        // eslint-disable-next-line no-undef
        Rollbar.error('User object is not defined', toJS(this));
      }

      return new User();
    }

    return userObject;
  }

  @computed get adminUrl() {
    const {
      REACT_APP_ADMIN_URL,
      REACT_APP_PRODUCTION_ADMIN_URL,
      REACT_APP_STAGING_ADMIN_URL,
    } = process.env;

    const params = `?server=${this.server}&token=${this.originalToken}`;
    if (REACT_APP_ADMIN_URL) {
      return REACT_APP_ADMIN_URL + params;
    }

    switch (this.server) {
      case 'production':
        return REACT_APP_PRODUCTION_ADMIN_URL + params;
      case 'local':
      case 'localhost':
        return `http://localhost:8081/#/login${params}`;
      case 'demo':
      case 'staging':
      default:
        return REACT_APP_STAGING_ADMIN_URL;
    }
  }

  @computed get normalVersionURL() {
    const params = [
      `?token=${this.originalToken}`,
      `&server=${this.server}`,
    ].join('');

    switch (this.server) {
      case 'production':
      case 'demo':
        return `https://teacher.aeseducation.com/login${params}`;
      case 'staging':
      default:
        return `https://teacher.staging.aeseducation.com/login${params}`;
    }
  }

  @computed get intercomSettings() {
    return {
      app_id: this.server === 'local' ? 'nyj014kn' : 'qvzgqw7c',
      user_id: this.originalUser.id,
      name: this.originalUser.name,
      email: this.originalUser.email,
      user_hash: this.originalUser.hmac,
      teacher_ui_version: 'NEW',
      created_at: this.originalUser.createdAt,
      product: this.site.skin,
      site: this.site.name,
      status: this.site.intercomStatus,
      package: this.site.prettyPackage,
      trial_score: this.originalUser.trialScore,
      unvalidated: !this.site.validated,
      course_count: this.originalUser.courseCount,
      expires_at: this.site.expiration,
      student_count: this.site.students,
      teacher_logons_30: this.originalUser.logons30,
      company: {
        id: this.site.id,
        name: this.site.name,
        plan: this.site.prettyPackage,
        owner: this.site.salesOwner,
        oppty: this.site.salesOpportunity,
        platform: `https://admin.aeseducation.com/#/sites/${this.site.id}`,
        trial_teacher_logons_30: this.originalUser.logons30,
        contract_start_date_at: this.site.salesContractDate,
        school_start_at: this.site.schoolStartDate,
        state: this.site.state,
      },
    };
  }

  @computed get currentBanner() {
    return this.bannerData.find((event) => event.isActive()) || new BannerEvent({});
  }

  // eslint-disable-next-line class-methods-use-this
  getLoginUrl(server) {
    const {
      REACT_APP_LOGIN_URL,
      REACT_APP_PRODUCTION_LOGIN_URL,
      REACT_APP_DEMO_LOGIN_URL,
      REACT_APP_BETA_LOGIN_URL,
      REACT_APP_STAGING_LOGIN_URL,
    } = process.env;

    if (REACT_APP_LOGIN_URL && server !== 'beta') {
      return REACT_APP_LOGIN_URL;
    }

    switch (server) {
      default:
      case 'production':
        return REACT_APP_PRODUCTION_LOGIN_URL;
      case 'staging':
        return REACT_APP_STAGING_LOGIN_URL;
      case 'demo':
        return REACT_APP_DEMO_LOGIN_URL;
      case 'beta':
        return REACT_APP_BETA_LOGIN_URL;
      case 'local':
      case 'localhost':
        // eslint-disable-next-line no-restricted-globals
        return `http://${location.hostname}/#/login`;
    }
  }

  @computed get loginURL() {
    const url = this.getLoginUrl(this.server);
    const message = this.errors.length ? this.errors[0] : '';
    return message ? `${url}?message=${message}` : url;
  }

  @computed get lessonOrigin() {
    return new URL(this.getLoginUrl(this.server)).origin;
  }

  @computed get migrationURL() {
    return `${this.lessonOrigin}/migrate/?token=${this.token}`;
  }

  // eslint-disable-next-line class-methods-use-this
  getMediaBaseUrl(server) {
    const {
      REACT_APP_MEDIA_BASE_URL,
      REACT_APP_PRODUCTION_MEDIA_BASE_URL,
      REACT_APP_STAGING_MEDIA_BASE_URL,
    } = process.env;

    if (REACT_APP_MEDIA_BASE_URL) {
      return REACT_APP_MEDIA_BASE_URL;
    }

    return server === 'production'
      ? REACT_APP_PRODUCTION_MEDIA_BASE_URL
      : REACT_APP_STAGING_MEDIA_BASE_URL;
  }

  @computed get mediaBaseUrl() {
    return this.getMediaBaseUrl(this.server);
  }

  // eslint-disable-next-line class-methods-use-this
  getIcevRedirectUrl(server) {
    const {
      REACT_APP_ICEV_STAGING_REDIRECT,
      REACT_APP_ICEV_PRODUCTION_REDIRECT,
    } = process.env;

    return server === 'production'
      ? REACT_APP_ICEV_PRODUCTION_REDIRECT
      : REACT_APP_ICEV_STAGING_REDIRECT;
  }

  @computed get iCEVRedirectUrl() {
    return this.getIcevRedirectUrl(this.server);
  }

  @computed get hasCanvasAPI() {
    return this.features.includes('lti_canvas');
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get hasCanvasOAuth() {
    return !!this.integrationProviders.find(({ provider }) => provider === 'canvas-api');
  }

  @action login(email, password, site, role = 'teacher') {
    this.clearErrors();

    return this.api.post('authenticate', {
      email, password, site, role,
    })
      .then(action('loginSuccess', this.handleLoginSuccess))
      .catch(action('loginFailure', this.handleError));
  }

  @action impersonate(teacher) {
    const teacherId = teacher === Object(teacher) ? +teacher.id : +teacher;

    return this.api.post(
      'authenticate',
      {
        token: this.originalToken,
        impersonate_faculty: teacherId,
        site: this.site.id,
      },
    )
      .then(this.handleImpersonateSuccess)
      .catch(this.endImpersonation);
  }

  @action.bound handleImpersonateSuccess(result) {
    const userData = {
      ...result.user,
      type: result.user_type,
      role: result.user_role,
      downloads: result.all_time_downloads || 0,
    };

    this.impersonationToken = result.auth_token;
    this.impersonationUser = new User(this.rootStore, userData);

    return this.impersonationUser;
  }

  @action.bound endImpersonation() {
    this.impersonationToken = null;
    this.impersonationUser = null;

    return Promise.resolve();
  }

  @action initiateOAuth(provider) {
    const oauthURL = `${window.location.origin}/oauth`;
    const features = `toolbar=no, menubar=no, width=700, height=480, left=${window.screenLeft}, top=${window.screenTop}`;
    const oauthWindow = window.open(oauthURL, 'AES_TEACHER_OAUTH', features);

    this.currentOAuthProvider = provider;
    this.api.post('teacher/integrations', { action_type: `${provider}-oauth-request` })
      .then(({ request_link: link }) => {
        if (link) {
          oauthWindow.location = link;
        }
      });
  }

  @action.bound completeOAuth(code, state) {
    const provider = this.currentOAuthProvider;

    this.jobAttempts = 0;
    this.api.put('teacher/integrations/update', {
      action_type: `${provider}-oauth-complete`,
      code,
      state,
    }).then((result) => {
      if (result.job_id) {
        this.pollIntegrationJob(result.job_id);
      } else {
        runInAction(() => { this.integrationError = true; });
      }
    });
  }

  @action pollIntegrationJob(jobId) {
    this.jobAttempts += 1;
    this.api.get(`/jobs/${jobId}`, {}, { quiet: true }).then(({ status }) => {
      if (status === 'completed') {
        this.loadIntegrationProviders();
      } else if (this.jobAttempts < 20) {
        setTimeout(() => { this.pollIntegrationJob(jobId); }, 500);
      } else {
        runInAction(() => { this.integrationError = true; });
      }
    });
  }

  @action loadIntegrationCourses() {
    this.jobAttempts = 0;
    this.api.post('/teacher/integrations', {
      action_type: 'canvas-available-courses',
    }, { quiet: true }).then((result) => {
      if (result.job_id) {
        this.pollIntegrationCoursesJob(result.job_id);
      } else {
        runInAction(() => { this.integrationError = true; });
      }
    });
  }

  @action pollIntegrationCoursesJob(jobId) {
    this.jobAttempts += 1;
    this.api.get(`/jobs/${jobId}`, {}, { quiet: true }).then((result) => {
      if (result.status === 'completed') {
        if (result.canvas_courses) {
          runInAction(() => { this.integrationCourses = result.canvas_courses; });
        }
      } else if (this.jobAttempts < 20) {
        setTimeout(() => { this.pollIntegrationCoursesJob(jobId); }, 500);
      } else {
        runInAction(() => { this.integrationError = true; });
      }
    });
  }

  @action loadIntegrationProviders() {
    this.api.get('teacher/integrations/faculty').then(({ providers }) => {
      runInAction(() => { this.integrationProviders = providers; });
    });
  }

  @action verifyLogin() {
    this.clearErrors();

    return this.api.get(
      'authenticate',
      undefined,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-Token': this.originalToken,
        },
      },
    )
      .then(action('verifyLoginResult', (userResult) => {
        this.handleLoginSuccess(userResult);

        if (this.isImpersonation) {
          return this.api.get('authenticate')
            .then(this.handleImpersonateSuccess)
            .catch(() => {
              // Invalid impersonation token
              this.endImpersonation();
              // TODO: Redirect to dashboard
            });
        }
        // Notify the root store
        return Promise.resolve();
      }))
      .catch(action('verifyLoginFailure', this.handleError));
  }

  @action loadBannerData() {
    const url = `${this.lessonOrigin}/status/eventData.json`;
    const params = { t: Date.now() };
    const options = { ignoreActivity: true, headers: {} };
    this.api.get(url, params, options)
      .then((bannerData = []) => runInAction(() => {
        this.bannerData = bannerData.map((data) => new BannerEvent(data));
      }))
      .catch(() => {
        // File doesn't exist, just catch the error and do nothing.
      });
  }

  @action.bound handleLoginSuccess(result) {
    const userData = {
      ...result.user,
      type: result.user_type,
      role: result.user_role,
      options: result.faculty_options,
      downloads: result.all_time_downloads || 0,
      hmac: (result.intercom || {}).user_hash,
      sso: result.sso,
    };
    const adminData = {
      ...result.admin,
      hmac: (result.intercom || {}).admin_hash,
    };

    const siteData = {
      ...result.site,
      features: result.site_features,
      templates: result.site_templates,
    };

    this.verified = true;
    this.expired = null;
    this.originalToken = result.auth_token;
    this.admin = new User(this.rootStore, adminData);
    this.originalUser = new User(this.rootStore, userData);
    this.site = new Site(siteData);
    this.features = result.site_features || [];
    this.siteTemplates = result.site_templates;
    this.sitePackage = this.site.package;
    this.siteProduct = this.site.skin;
    this.siteType = this.site.type;
    this.emailVerified = this.site.verified;
    this.siteValidated = this.site.validated;
    this.providers = result.providers;

    this.rootStore.onAuthenticate();
    this.enableIntercom();
    this.enableRollbar();
    this.loadBannerData();

    /* eslint-disable no-undef */
    ga('set', 'userId', String(this.originalUser.id));
    ga('set', 'dimension1', this.site.prettyType);
    ga('set', 'dimension2', this.site.prettyPackage);
    ga('set', 'dimension3', this.site.id);
    ga('set', 'dimension4', this.site.verified);
    ga('set', 'dimension5', this.site.validated);
    ga('set', 'dimension7', this.site.maxDownloads);
    ga('set', 'metric1', this.originalUser.downloads);
    ga('send', 'event', 'Authentication', 'Login');
    /* eslint-enable no-undef */

    if (this.server === 'staging') {
      document.title = 'AES - Staging';
    } else if (this.server === 'demo') {
      document.title = 'AES - Demo';
    }

    this.persist();
  }

  @action updateSignon(params) {
    const { WebAPI } = this.rootStore;

    return WebAPI.patch('teacher/profile', { faculty: params });
  }

  @action async enableIntercom() {
    // Intercom is disabled?
    if (!window.Intercom) {
      try {
        await new Promise((resolve, reject) => {
          const timer = setInterval(() => {
            if (!window.Intercom) return;

            clearInterval(timer);
            resolve();
          }, 500);
          setTimeout(() => {
            clearInterval(timer);
            reject();
          }, 15000);
        });
      } catch (e) {
        return;
      }
    }

    // Best way I have to detect AES admin
    if (this.isAesAdmin) {
      // Enable as the AES admin
      const params = {
        app_id: 'qvzgqw7c',
        user_id: this.admin.id,
        name: this.admin.name,
        email: this.admin.email,
        user_hash: this.admin.hmac,
      };

      window.Intercom('boot', params);

      return;
    }

    window.Intercom('boot', this.intercomSettings);
  }

  // eslint-disable-next-line class-methods-use-this
  @action disableIntercom() {
    if ('Intercom' in window) window.Intercom('shutdown');
  }

  @action enableRollbar() {
    if ('Rollbar' in window) {
      window.Rollbar.configure({
        payload: {
          person: {
            id: this.originalUser.id,
            username: this.originalUser.name,
            email: this.originalUser.email,
          },
        },
      });
    }
  }

  @action.bound handleError(error) {
    const { uiState } = this.rootStore;
    if (!error.message) throw new Error(error);
    const tokenError = /Bad API Key|Authorization token not found|Unable to decode token|No authentication token provided/;

    const isToken = error.message.match(tokenError);

    if (isToken) {
      if (!this.originalUser.id) {
        const message = 'Authentication failed, please log in again';
        this.logout();
        this.addError(message);
        return Promise.resolve();
      }
      const message = 'Your session has expired; please log in again.';
      this.addError(message);

      this.expire();
      uiState.clearErrors();
      return Promise.resolve();
    }

    return Promise.reject(error);
  }

  @action addError(message) {
    if (!this.errors.includes(message)) {
      this.errors.push(message);
    }
  }

  @action.bound clearErrors() {
    this.errors = [];
  }

  @action logout() {
    const { uiState } = this.rootStore;
    if (this.loggedIn) {
      this.api.delete('authenticate').catch(() => { }); // Don't care about the result
    }

    this.originalToken = undefined;
    this.originalUser = new User(this.rootStore);
    this.impersonationToken = null;
    this.impersonationUser = null;
    this.site = new Site();
    this.verified = false;
    uiState.devMode = false;

    this.disableIntercom();
    this.clearStorage();
  }

  @action expire() {
    const { router } = this.rootStore;
    this.expired = {
      email: this.originalUser.email,
      site: this.site.id,
      role: this.originalUser.role,
    };
    router.push('/logout');
  }

  @action refresh() {
    this.lastRefresh = Date.now();
    return this.api.get('authenticate', { refresh: true }, { ignoreActivity: true })
      .then(() => runInAction(() => {
        this.showSessionWarning = false;
        this.loadBannerData();
      }))
      .catch((error) => {
        if ('Rollbar' in window) {
          window.Rollbar.error('Error Refreshing Token', error);
        }
        clearInterval(this.refreshInterval);
        this.addError('Your session has expired; please log in again.');
        this.expire();
      });
  }

  @action.bound handleSession() {
    if (this.loggedIn) {
      const { lastActive, lastRefresh } = this;
      const now = Date.now();
      const oneHourAgo = now - 3600000;
      const fourtyfiveMinutesAgo = now - 2700000;
      const fifteenMinutesAgo = now - 900000;
      const isExpiredSession = lastActive < oneHourAgo;
      const isIdleSession = !isExpiredSession && lastActive < fourtyfiveMinutesAgo;
      const shouldRefresh = !isExpiredSession
        && lastRefresh < fifteenMinutesAgo
        && lastRefresh < lastActive;

      if (isExpiredSession) {
        this.addError('Your session has expired; please log in again.');
        this.expire();
      }

      if (isIdleSession) {
        this.showSessionWarning = true;
      }

      if (shouldRefresh) {
        this.refresh();
      }
    }
  }

  @action.bound setActive() {
    this.lastActive = Date.now();
  }

  @action setServer(value) {
    this.server = value;
  }

  @action setToken(token) {
    this.verified = false;
    this.originalToken = token;
  }

  @action setImpersonationToken(token) {
    this.impersonationToken = token;
  }

  @action productionLogin(token) {
    this.setServer('production');
    this.setToken(token);
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }

  @action dismissBanner() {
    this.bannerDismissed = true;
  }

  @computed get isTrial() {
    return this.siteType === 'EVAL';
  }

  @computed get isCustomer() {
    return this.siteType === 'SAAS';
  }

  @computed get isHealth() {
    return this.siteProduct === 'health';
  }

  @computed get isMaster() {
    return this.sitePackage === 'TEACHER_MASTER'
      || this.sitePackage === 'SCHOOL_MASTER'
      || this.sitePackage === 'BIT_CLASSROOM_2019'
      || this.sitePackage === 'BIT_CAMPUS_2019';
  }

  @computed get isStudentLicenses() {
    return this.sitePackage === 'STUDENT_LICENSE';
  }

  // Student Licenses - Get All Master Features
  @computed get enableMasterFeatures() {
    return this.isMaster || this.isStudentLicenses;
  }

  // Trial or Master Package
  @computed get enableExams() {
    return this.isTrial || this.isHealth || this.isMaster;
  }

  // Trial or Master Package
  @computed get enableAdvancedPacing() {
    return this.isTrial || this.isHealth || this.isMaster;
  }

  // Validated Trials or Customers
  @computed get enableAnswerKeys() {
    return this.isCustomer || !this.isTrial || this.siteValidated;
  }

  // Validated Trials or Customers
  @computed get enableEnrollment() {
    return !this.isTrial || this.siteValidated;
  }

  // Quiz Challenge - Health Sites
  @computed get enableQuizChallenge() {
    return this.site.skin === 'health';
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get enableBeta() {
    const { site: { features } } = this;
    return features.includes('beta') && !this.enableBetaReturn;
  }

  @computed get betaActive() {
    return !this.isDemo;
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get enableBetaReturn() {
    // eslint-disable-next-line no-restricted-globals
    return location.hostname && location.hostname.includes('beta');
  }

  // Teacher Templates
  @computed get enableCreateTemplates() {
    const { isAdmin, site: { features } } = this;
    return isAdmin && features.includes('allow_templates');
  }

  // Enable rostering when providers are connected
  @computed get enableRostering() {
    const { providers, user: { singleSignOnConnections } } = this;
    const siteEnabled = providers && providers.length > 0;
    const userConnected = singleSignOnConnections && singleSignOnConnections.length > 0;
    return siteEnabled && userConnected;
  }

  @computed get singleSignOnUrl() {
    const {
      REACT_APP_SSO_URL,
      REACT_APP_PRODUCTION_SSO_URL,
      REACT_APP_DEMO_SSO_URL,
      REACT_APP_STAGING_SSO_URL,
    } = process.env;

    const params = `?connect=true&token=${this.token}`;

    if (REACT_APP_SSO_URL) {
      return REACT_APP_SSO_URL + params;
    }

    switch (this.server) {
      case 'production':
        return REACT_APP_PRODUCTION_SSO_URL + params;
      case 'demo':
        return REACT_APP_DEMO_SSO_URL + params;
      case 'local':
      case 'localhost':
        return `http://localhost:3001/magic${params}`;
      case 'staging':
      default:
        return REACT_APP_STAGING_SSO_URL + params;
    }
  }

  mediaURL(filename) {
    return this.mediaBaseUrl + filename;
  }

  previewUrl(courseId) {
    const { loginURL, site, token } = this;
    const rootUrl = loginURL.replace('/signout', '');
    return `${rootUrl}/site/${site.id}/course/${courseId}/?token=${token}&view_as_student=1`;
  }

  // TODO: Remove after all classes are upgraded to V2
  previewUrlLegacy(courseId) {
    const protocol = 'https';
    const params = [
      this.site.id,
      `?faculty_id=${this.user.id}`,
      `&token=${this.token}`,
      `&course_id=${courseId}`,
    ].join('');

    switch (this.server) {
      case 'production':
      default:
        return `${protocol}://classic.aeseducation.com/manage_site/${params}`;
      case 'demo':
        return `${protocol}://student.demo.aeseducation.com/manage_site/${params}`;
      case 'staging':
        return `${protocol}://student.staging.aeseducation.com/manage_site/${params}`;
      case 'local':
      case 'localhost':
        return `http://localhost:3001/manage_site/${params}`;
    }
  }
}

export default AuthStore;
