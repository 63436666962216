import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { v4 as uuid } from 'uuid';

@observer
class TextBox extends Component {
  uid = uuid()

  // TODO: remove onChange hijacking - refactor components using TextBox
  onChange = (ev) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(ev.target.value);
    }
  }

  render() {
    const {
      value = '', label, leftLabel, inline, error, ...passThrough
    } = this.props;

    return (
      <div className={`formField textbox${inline ? ' inline' : ''}`}>
        {label && leftLabel
          && <label htmlFor={this.uid}>{label}</label>}
        <div style={{ display: 'inline-block', position: 'relative' }}>
          {/* TODO: Figure out what props are needed in `passThrough` and set explicitly */}
          {/* eslint-disable react/jsx-props-no-spreading */}
          <input
            type="text"
            className={error ? 'error' : ''}
            id={this.uid}
            {...passThrough}
            value={value}
            onChange={this.onChange}
          />
          {/* eslint-enable react/jsx-props-no-spreading */}
          {error
            && <div className="fieldError"><span className="error">{error}</span></div>}
        </div>
        {label && !leftLabel
          && (
            <label htmlFor={this.uid}>
              {label}
            </label>
          )}
      </div>
    );
  }
}

export default TextBox;
