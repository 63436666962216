import React, { Component } from 'react';
import { computed, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';

@inject('uiState', 'router') @observer
class ErrorModal extends Component {
  @computed get errors() {
    const { uiState: { errors } } = this.props;
    return errors;
  }

  @computed get criticalErrors() {
    return this.errors.filter((e) => e.severity === 'critical');
  }

  @computed get isCritical() {
    return this.criticalErrors.length !== 0;
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get isDev() {
    return global.debug;
  }

  @computed get canDismiss() {
    return !this.isCritical || this.isDev;
  }

  @computed get visible() {
    return this.errors.length > 0;
  }

  // eslint-disable-next-line class-methods-use-this
  @action reload() {
    window.location.reload();
  }

  @action.bound resetAndLogout() {
    const { router, uiState } = this.props;
    uiState.clearErrors();
    router.push('/logout');
  }

  @action.bound dismissErrors() {
    const { uiState } = this.props;
    uiState.clearErrors();
  }

  render() {
    return (
      <Modal
        data-testid="error-modal"
        id="error-modal"
        title="Something went wrong"
        visible={this.visible}
        onDismiss={this.canDismiss ? this.dismissErrors : undefined}
      >
        <p>An error occurred on this page. Our technical team has been notified of this error.</p>

        {this.isCritical
        && <p>For now, please try refreshing the window to try again.</p>}
        {!this.isCritical
        && <p>You may continue, but if the error persists please try refreshing the page.</p>}

        {this.isDev
        && (
        <pre style={{ maxHeight: '400px', overflow: 'auto' }}>
          {JSON.stringify(this.errors, null, 2)}
        </pre>
        )}

        <button type="button" onClick={this.reload}>Refresh Now</button>
        {this.isCritical
        && <button type="button" onClick={this.resetAndLogout}>Log Out</button>}
        {!this.isCritical
        && <button type="button" onClick={this.dismissErrors}>Continue</button>}
      </Modal>
    );
  }
}

export default ErrorModal;
