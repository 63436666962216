/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { join } from 'util/Array';

@inject('grades', 'questions') @observer
class GradeActions extends Component {
  @action.bound resetGrade() {
    const { grades, grade: { id } } = this.props;
    grades.resetGrade(id);
  }

  render() {
    const { grade, courseId } = this.props;

    if (!grade) {
      return <span />;
    }

    const actions = [];
    if (grade.hasDetails) {
      actions.push(
        <Link
          to={`/courses/${courseId}/grades/book/${grade.moduleKey}/unit/${grade.unitKey}/grade/${grade.id}`}
          key={`${grade.id}VIEW`}
        >
          View
        </Link>,
      );
    }
    if (grade.canReset) {
      actions.push(
        <span
          className="gradeReset"
          onClick={this.resetGrade}
          key={`${grade.id}RESET`}
        >
          Reset
        </span>,
      );
    }

    return (
      <span>
        {join(actions, <span key={grade.id}>&nbsp;|&nbsp;</span>)}
      </span>
    );
  }
}

export default GradeActions;
