import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import DropZone from 'react-dropzone';
import FormErrors from 'widgets/form/FormErrors';
import TextBox from 'widgets/form/TextBox';

@inject('auth') @observer
class Profile extends Component {
  // set accepted image types
  accept = [
    'image/jpg', 'image/jpeg', 'image/png', 'image/bmp', 'image/x-png',
    'image/pjpeg', 'image/x-bmp', 'image/x-png', 'image/gif',
  ]

  // set max image size
  maxSize = 1024 * 1024 // 1MB

  @observable fileError = ''

  @observable file

  @observable firstName = ''

  @observable lastName = ''

  @observable customName = ''

  @observable profileError = ''

  componentDidMount() {
    const { auth: { user: { firstName, lastName, customName } } } = this.props;
    this.setProfileValues(firstName, lastName, customName);
  }

  @action.bound onDrop(accepted, rejected) {
    if (rejected.length) {
      const file = rejected[0];

      if (file.size > this.maxSize) {
        this.fileError = 'Image file size is too large';
      }
      if (!this.accept.includes(file.type)) {
        this.fileError = 'File must be a valid image type';
      }

      this.file = undefined;
      return;
    }

    if (!accepted.length) {
      this.fileError = 'No file was uploaded';
      this.file = undefined;
      return;
    }

    if (this.file) {
      window.URL.revokeObjectURL(this.file.preview);
      this.file = undefined;
    }

    this.fileError = undefined;
    const [file] = accepted;
    this.file = file;
  }

  @action.bound setProfileValues(firstName, lastName, customName) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.customName = customName;
  }

  @action.bound handleInputChange = (type, value) => {
    switch (type) {
      case 'firstName':
        this.firstName = value ?? '';
        break;
      case 'lastName':
        this.lastName = value ?? '';
        break;
      case 'customName':
        this.customName = value ?? '';
        break;
      default:
        break;
    }
  }

  @action.bound updateProfile = (event) => {
    event.preventDefault();
    const { firstName, lastName, customName } = this;
    const { auth } = this.props;

    if (!firstName) {
      this.profileError = 'First Name cannot be blank.';
    }

    if (!lastName) {
      this.profileError = 'Last Name cannot be blank.';
    }

    if (firstName && lastName) {
      const faculty = {
        first_name: firstName,
        last_name: lastName,
        custom_name: customName,
      };

      auth.user.updateProfile(faculty).then(() => { this.clearError(); });
    }
  }

  @action.bound clearError = () => {
    this.profileError = '';
  }

  @action.bound updateAvatar(event) {
    event.preventDefault();
    const { auth } = this.props;
    return auth.user.updateAvatar(this.file);
  }

  render() {
    const { auth: { user: { avatarUrl } } } = this.props;
    const { file } = this;
    const preview = file
      ? URL.createObjectURL(file)
      : avatarUrl;

    return (
      <div>
        <h1>My Profile</h1>
        <section>
          <h2>Name</h2>
          <form onSubmit={this.updateProfile}>
            <div className="formField loginForm">
              <div className="formRow">
                <TextBox
                  name="firstName"
                  label="First Name:"
                  value={this.firstName}
                  onChange={(value) => this.handleInputChange('firstName', value)}
                  inline
                  leftLabel
                />
              </div>
              <div className="formRow">
                <TextBox
                  name="lastName"
                  label="Last Name:"
                  value={this.lastName}
                  onChange={(value) => this.handleInputChange('lastName', value)}
                  inline
                  leftLabel
                />
              </div>
              <div className="formRow">
                <TextBox
                  name="customName"
                  label="Teaching Name:"
                  value={this.customName}
                  onChange={(value) => this.handleInputChange('customName', value)}
                  inline
                  leftLabel
                />
              </div>
            </div>

            <p style={{ marginTop: -42 }}>
              <small>
                If set, students will see your teaching name instead of your full name.
              </small>
            </p>

            <FormErrors errors={[this.profileError]} />

            <button type="submit" style={{ marginBottom: '50px' }}>Save Changes</button>
          </form>
        </section>

        <section>
          <h2>Avatar</h2>

          <p>Assign an image to be displayed to students with your name.</p>

          <form onSubmit={this.updateAvatar}>
            <div className="formField">
              <DropZone
                accept={this.accept}
                multiple={false}
                maxSize={this.maxSize}
                onDrop={this.onDrop}
                className="fileDrop"
                activeClassName="fileHover"
                acceptClassName="fileAccept"
                rejectClassName="fileAccept"
              >
                { /* eslint-disable react/jsx-props-no-spreading */
                  ({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="avatar" style={{ backgroundImage: `url(${preview})` }} />
                    </div>
                  )
                  /* eslint-enable react/jsx-props-no-spreading */}
              </DropZone>
            </div>

            <FormErrors errors={[this.fileError]} />

            <button type="submit" disabled={!this.file}>Save Changes</button>
          </form>
        </section>
      </div>
    );
  }
}
export default Profile;
