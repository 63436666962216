import {
  extendObservable, computed, observable, action, runInAction,
} from 'mobx';
import Course from 'models/Course';
import StudentCourseGrade from 'models/StudentCourseGrade';

export default class Student {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    extendObservable(this, {
      id: +input.id || 0,
      firstName: input.firstName || input.first_name || 'Invalid',
      lastName: input.lastName || input.last_name || 'Student',
      email: input.email || '',
      enrollments: input.enrollments || [],
      lastLogon: input.lastLogon || input.last_logon || 0,
      createdAt: input.createdAt || input.created_at || 0,
      events: [],
      lessonStatus: {},
      pacingAssignments: new Map(),
      pacingEntries: new Map(),
      statusLoaded: false,
      _statusLoading: false,
    });
  }

  @computed get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed get formatName() {
    return `${this.lastName}, ${this.firstName}`;
  }

  @computed get activity() {
    const { courses, teachers } = this.rootStore;

    const byDateTime = (a, b) => {
      const timeStamp = (string) => new Date(string).valueOf();
      return timeStamp(b.performed_at) - timeStamp(a.performed_at);
    };

    const getAssessmentType = (details) => {
      const {
        type,
        assessment_type: assessmentType,
        quiz_type: quizType,
        unit_key: unitKey = '',
      } = details;
      if (unitKey.startsWith('PREMT_')) {
        return 'Pre-Test';
      }
      if (unitKey.startsWith('MT_')) {
        return 'Test';
      }
      if (unitKey.startsWith('EXAM_')) {
        return 'Exam';
      }
      return type || quizType || assessmentType || 'Quiz';
    };

    // The lesson exit events use the session_details param instead
    // of the regular details param for some events. The lesson and
    // page_set_title are sometimes both filled in.
    const curriculumTitles = (details, sessionDetails = {}) => {
      const titles = [
        details.module_title,
        sessionDetails.mod_title,
        details.unit_title,
        sessionDetails.unit_title,
        details.lesson || details.page_set_title || sessionDetails.page_set_title,
      ].filter((e) => e).join(' / ');
      return titles;
    };

    const adaptQuizEntry = (entry) => {
      const { details } = entry;
      const type = getAssessmentType(details);

      if (entry.action === 'QUIZ_START') {
        return {
          action: `Start ${type}`,
          desc: curriculumTitles(details),
        };
      }

      if (entry.action === 'QUIZ_FINISH') {
        return {
          action: `Finish ${type}`,
          desc: curriculumTitles(details),
        };
      }

      if (entry.action === 'QUIZ_ERROR') {
        const { error_description: description } = details;
        const extra = description
          ? `(${description.replace(
            'Session timeout',
            'The student was signed out due to inactivity',
          )})`
          : '';

        const actionName = `${type} Error ${extra}`;
        return {
          action: actionName,
          desc: curriculumTitles(details),
        };
      }

      if (entry.action === 'QUIZ_RESET') {
        const { reset_reason: resetReason } = details;
        const extra = resetReason ? `(${resetReason})` : '';
        const actionName = `${type} Reset ${extra}`;

        return {
          action: actionName,
          desc: curriculumTitles(details),
        };
      }

      return undefined;
    };

    const activityTitle = (entry) => {
      switch (entry.action) {
        case 'ENROLLMENT':
          return {
            action: 'Enrolled',
            desc: `Enrollment Method: ${entry.details.method}`,
          };
        case 'LOGON':
          return {
            action: 'Student Sign In',
            desc: 'Student Sign In',
          };
        case 'COURSE_ENTER':
          return {
            action: 'Enter Class',
            desc: 'Enter Class',
          };
        case 'COURSE_EXIT':
          return {
            action: 'Exit Class',
            desc: 'Exit Class',
          };
        case 'LOGOFF':
          if (entry.details.timeout) {
            return {
              action: 'Student Sign Out',
              desc: '(The student was signed out due to inactivity)',
            };
          }
          return {
            action: 'Student Sign Out',
            desc: 'Student Sign Out',
          };
        case 'FAILED_LOGON':
          return {
            action: 'Student Sign Out',
          };
        case 'QUIZ_RESET':
        case 'QUIZ_ERROR':
        case 'QUIZ_START':
        case 'QUIZ_FINISH':
          return adaptQuizEntry(entry);
        case 'LESSON_ENTER':
          return {
            action: 'Enter Lesson',
            desc: curriculumTitles(entry.details),
          };
        case 'LESSON_EXIT':
          return {
            action: 'Exit Lesson',
            desc: curriculumTitles(entry.details, entry.details.session_details || {}),
          };
        default:
          return {
            action: entry.action,
            desc: entry.details.description,
          };
      }
    };

    const eventsToDisplay = [
      'ENROLLMENT',
      'LOGON',
      'LOGOFF',
      'COURSE_ENTER',
      'COURSE_EXIT',
      'QUIZ_RESET',
      'QUIZ_ERROR',
      'QUIZ_START',
      'QUIZ_FINISH',
      'LESSON_ENTER',
      'LESSON_EXIT',
    ];

    return this.events
      .sort(byDateTime)
      .map((entry) => {
        if (!eventsToDisplay.includes(entry.action)) {
          return undefined;
        }

        const actions = activityTitle(entry);
        let teacher = teachers.byId.get(entry.faculty_id);
        let course = courses.byId.get(entry.course_id);

        if (!course && teacher) {
          course = teacher.courses.find((item) => item.id === entry.course_id) || {};
        } else if (!teacher && course) {
          teacher = { id: course.facultyId, name: course.ownerName };
        } else if (!course && !teacher) {
          course = {};
          teacher = {};
        }

        return {
          time: new Date(entry.performed_at),
          action: actions.action || 'Unknown',
          desc: actions.desc || entry.details.description || ' ',
          id: entry.id,
          course,
          teacher,
        };
      }).filter((e) => e);
  }

  @action editStudent(params, course, student) {
    const { WebAPI } = this.rootStore;

    if (params.password) {
      // eslint-disable-next-line no-param-reassign
      params.password_confirmation = params.password;
    }

    return WebAPI.patch(
      `teacher/courses/${course}/students/${student}`,
      { student: params },
    ).then(action((res) => {
      this.firstName = res.first_name;
      this.lastName = res.last_name;
      this.email = res.email;
    })).catch((errors) => errors.validation);
  }

  @action async loadActivity(course, from, to) {
    const { students } = this.rootStore;
    const courseId = course instanceof Course ? course.id : +course;
    const date = from ? `&start_date=${from}&end_date=${to || from}` : '';

    students.loadEventLogs(courseId, this.id, date);
  }

  @action loadEventLogsPdf(course, params) {
    const { WebAPI } = this.rootStore;
    const courseId = course instanceof Course ? course.id : +course;

    return WebAPI.post(`teacher/courses/${courseId}/students/${this.id}/event_logs/pdf`, params).then((result) => WebAPI.pollJob(result.job_id));
  }

  @computed get gradesByCourse() {
    const { grades } = this.rootStore;
    const studentGrades = grades.list.filter((g) => g.studentId === this.id);
    const byCourse = studentGrades.reduce(
      (map, grade) => {
        // eslint-disable-next-line no-param-reassign
        if (!map[grade.courseId]) map[grade.courseId] = [];
        map[grade.courseId].push(grade);
        return map;
      },
      {},
    );

    const result = observable.map();
    Object.entries(byCourse).forEach(([courseIdString, list]) => {
      // NB Object.entries coerces numerical keys to strings
      const courseId = parseInt(courseIdString, 10);
      result.set(courseId, new StudentCourseGrade(this.rootStore, list, courseId, this.id));
    });

    return result;
  }

  @action loadLessonStatus(courseId) {
    const { WebAPI } = this.rootStore;
    /* eslint-disable no-underscore-dangle */
    if (this._statusLoading) {
      return;
    }
    this._statusLoading = true;
    /* eslint-ensable no-underscore-dangle */

    WebAPI.get(
      `teacher/courses/${courseId}/lesson_status?student_id=${this.id}`,
    ).then((result) => {
      const onlyLessons = (work) => {
        const keys = /U_[A-Z0-9-]+_[LFDG]\d+/;
        return work.page_set_key.match(keys);
      };
      const lessonSort = (a, b) => {
        const l = a.page_set_key;
        const r = b.page_set_key;

        if (l < r) return -1;
        if (l > r) return 1;
        return 0;
      };
      const byUnit = (units, work) => {
        // eslint-disable-next-line no-param-reassign
        units[work.unit_key] = work.lessons.filter(onlyLessons)
          .sort(lessonSort);
        return units;
      };
      const lessonStatus = result.reduce(byUnit, {});

      runInAction(() => {
        this.lessonStatus = lessonStatus;
        this.statusLoaded = true;
      });
    }).finally(() => runInAction(() => { this._statusLoading = false; }));
  }

  @action loadPacingStatus(courseId) {
    const { WebAPI } = this.rootStore;
    const student = this;
    const url = `teacher/courses/${courseId}/students/${this.id}/pacing`;
    return WebAPI.get(url).then((response) => {
      student.updatePacing(response);
    });
  }

  @action updatePacing(response) {
    response.assignments.forEach((a) => {
      this.pacingAssignments.set(a.assignment_id, a);
      a.assignment_entries.forEach((e) => {
        this.pacingEntries.set(e.assignment_entry_id, e);
      });
    });
  }

  lastLogonForCourse(course) {
    const enrollment = this.enrollments.find((e) => e.course_id === course.id);
    if (enrollment) {
      return enrollment.last_logon;
    }
    return null;
  }

  toJSON() {
    const {
      id, firstName, lastName, email, enrollments,
    } = this;
    return ({
      id, firstName, lastName, email, enrollments,
    });
  }
}
