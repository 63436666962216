import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Table from 'widgets/Table';
import TeacherEdit from 'widgets/teacher/TeacherEdit';
import TeacherRemove from 'widgets/teacher/TeacherRemove';

const TeacherList = ({ teachers, siteName, currentUserId }) => {
  const TeacherStatus = ({ teacher }) => {
    if (!teacher.lastLogon && teacher.isPending) {
      return <span>Invited</span>;
    } if (!teacher.lastLogon) {
      return <span>Never</span>;
    }
    return <span>{moment(teacher.lastLogon).format('MM/DD/YYYY')}</span>;
  };

  const TeacherActions = ({ teacher }) => {
    if (teacher.id === currentUserId) {
      return (
        <div className="actions">
          <Link to="/settings/teacher/profile">
            <button type="button">View My Profile</button>
          </Link>
        </div>
      );
    }
    return (
      <div className="actions">
        <TeacherEdit teacher={teacher} />
        <TeacherRemove teacher={teacher} />
      </div>
    );
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      sortable: true,
    },
    {
      title: 'Email',
      key: 'email',
      sortable: true,
    },
    {
      title: 'School',
      key: 'school',
      value: (t) => t.school || siteName,
      sortable: true,
    },
    {
      title: 'Role',
      key: 'role',
      value: (t) => t.prettyRole,
      sortable: true,
    },
    {
      title: 'Last Logon',
      key: 'status',
      value: (t) => <TeacherStatus teacher={t} />,
      sortable: false,
      width: 110,
    },
    {
      title: '',
      key: 'actions',
      value: (t) => <TeacherActions teacher={t} />,
      sortable: false,
    },
  ];

  return (
    <Table
      sortable
      defaultSort="name"
      data={teachers}
      columns={columns}
    />
  );
};

export default TeacherList;
