import React from 'react';

const HelpMenu = () => (
  <div className="topNavItem">
    <a href="https://help.aeseducation.com/" target="_blank" rel="noopener noreferrer">
      <i className="material-icons" style={{ lineHeight: 1 }}>help</i>
      <span className="helpText">Help</span>
    </a>
  </div>
);

export default HelpMenu;
