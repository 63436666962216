import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import './style.scss';

@inject('courses') @observer
class QuestionAnalysis extends Component {
  @observable url

  @observable loading = false

  @observable error = false

  @action setLoading(val) {
    this.url = null;
    this.error = null;
    this.loading = val;
  }

  @action setFailure(msg) {
    this.loading = false;
    this.error = msg || true;
  }

  @action setFinished(url) {
    this.loading = false;
    this.url = url;
  }

  @action.bound async generateReport() {
    try {
      const { courses: { fromRoute: course } } = this.props;

      this.setLoading(true);
      const result = await course.generateQuestionAnalysis();
      this.setLoading(false);

      this.setFinished(result.url);
    } catch (e) {
      this.setFailure(e.message);
    }
  }

  render() {
    return (
      <div>
        <h1>Question Analysis Report</h1>
        <p>Use this report to see how your class is doing on specific quiz/test questions.</p>

        {this.error
          && (
          <div>
            <span className="error">
              There was a problem generating your report, please try again later.
              <br />
              {this.error}
            </span>
          </div>
          )}

        <div className="formField">
          <button
            className="lgButton"
            disabled={this.loading}
            onClick={this.generateReport}
            type="button"
          >
            <span>Generate Question Analysis</span>
            <small>This may take some time.</small>
          </button>
          {this.url
            && (
            <button
              className="lgButton"
              onClick={() => window.open(this.url, '_blank')}
              type="button"
            >
              <span>Download Your Report</span>
            </button>
            )}
        </div>

        {this.loading
          && (
          <div>
            <div className="spinner spin" />
            We&rsquo;re generating your report, please be patient. It may take up to a minute.
          </div>
          )}
      </div>
    );
  }
}

export default QuestionAnalysis;
