import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import OnboardCustomer from './OnboardCustomer';
import OnboardTrial from './OnboardTrial';

@inject('auth') @observer
class Onboard extends Component {
  componentDidMount() {
    // Wistia Events
    // eslint-disable-next-line no-underscore-dangle
    window._wq = window._wq || [];
    // eslint-disable-next-line no-underscore-dangle
    window._wq.push({
      id: 'ag5arld9sd',
      playerColor: '#4282e7',
    });
  }

  @computed get embedOpts() {
    const { auth: { user: { email } } } = this.props;
    return [
      `email=${email}`,
    ].join(' ');
  }

  render() {
    const { auth: { enableCreateTemplates, isTrial } } = this.props;
    return (isTrial)
      ? (
        <OnboardTrial
          embedOpts={this.embedOpts}
          enableCreateTemplates={enableCreateTemplates}
        />
      )
      : (
        <OnboardCustomer
          embedOpts={this.embedOpts}
          enableCreateTemplates={enableCreateTemplates}
        />
      );
  }
}

export default Onboard;
