import React from 'react';
import { Link } from 'react-router-dom';

const OnboardCustomer = ({ enableCreateTemplates, embedOpts }) => (
  <div className="onboardContainer">
    <div className="onboardIntro">
      <h1>How Does AES Make Your Life Easier?</h1>
      <h2>
        Find out how you can use the AES curriculum
        <br />
        structure to save time in your classroom.
      </h2>
    </div>
    <div className="onboardVideo">
      <span
        className={`wistia_embed wistia_async_ag5arld9sd ${embedOpts}`}
        key={Date.now()}
      />
    </div>
    <div className="onboardCTA">
      <h1>
        Ready to discover how the AES digital curriculum can save you time?
        Create your first class to get started:
      </h1>
      {enableCreateTemplates
        && <Link to="/courses/new/template" className="CTA">Create a District Template </Link>}
      <Link to="/courses/new" className="CTA">Create My Class</Link>
      <h2>Applied Educational Systems</h2>
      <h3>Digital Curriculum Systems for Health Science and Business Educators</h3>
    </div>
  </div>
);

export default OnboardCustomer;
