import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';

// TODO remove this component when all classes are upgraded to V2

@inject('auth', 'courses') @observer
class Preview extends Component {
  @observable frameRef

  @computed get canFullscreen() {
    return this.frameRef && typeof this.frameRef.requestFullscreen === 'function';
  }

  @computed get previewUrl() {
    const { auth, courses: { fromRoute: course } } = this.props;
    return auth.previewUrlLegacy(course.id);
  }

  @action.bound saveFrameRef = (ref) => { this.frameRef = ref; }

  @action.bound fullscreenFrame() {
    if (!this.canFullscreen) {
      // eslint-disable-next-line no-alert
      alert('Your browser doesn\'t support fullscreen viewing');
      return;
    }

    this.frameRef.requestFullscreen();
  }

  @action.bound openInNewWindow() {
    window.open(this.previewUrl, '_new', 'toolbars=0');
  }

  render() {
    const { auth, courses: { fromRoute: course } } = this.props;

    if (!course || !auth.token || !course.siteId || !course.facultyId) {
      return <p>Cannot preview this course.</p>;
    }

    if (course.enableBeta) {
      return <Redirect to=".." />;
    }

    const frameStyle = {
      position: 'absolute',
      top: 44,
      left: 0,
      width: '100%',
      height: '100%',
    };

    return (
      <div style={{
        position: 'absolute', top: 68, left: 22, right: 22, bottom: 74,
      }}
      >
        <h1>View as Student</h1>

        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <button type="button" onClick={this.openInNewWindow}>
            Open in a New Window
          </button>
          <button
            type="button"
            onClick={this.fullscreenFrame}
            className={!this.canFullscreen ? 'disabled' : undefined}
            title={this.canFullscreen ? 'Your browser doesn\'t support fullscreen viewing' : undefined}
          >
            View Fullscreen
          </button>
        </div>

        <iframe
          title="preview"
          src={this.previewUrl}
          frameBorder="0"
          style={frameStyle}
          ref={this.saveFrameRef}
        />
      </div>
    );
  }
}

export default Preview;
