import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import UsageTeacher from './UsageTeacher';
import UsageTeacherList from './UsageTeacherList';
import UsageTeacherFilter from './UsageTeacherFilter';
import TextBox from '../../widgets/form/TextBox';
import './LicenseUsage.scss';

const LicenseUsage = ({ auth, licenseUsage }) => {
  const { isAdmin, site } = auth;
  const {
    totalLicenses,
    totalStudents,
    unlicensedStudents,
    byTeacher,
    isLoaded,
    students,
  } = licenseUsage;

  const totalUsed = totalStudents - unlicensedStudents;
  const totalAvailable = totalLicenses - totalUsed;
  const teachers = Array.from(byTeacher.values());

  const [filteredTeacherID, setFilteredTeacherID] = useState('');
  const selectedTeacher = () => (
    teachers.filter(({ teacher: { id } }) => id === parseInt(filteredTeacherID, 10))
  );
  const onSelectTeacher = ({ target: { value } }) => setFilteredTeacherID(value);

  const teacherMap = filteredTeacherID === ''
    ? teachers
    : selectedTeacher();

  const [search, setSearch] = useState('');
  const updateSearch = (e) => setSearch(e);

  const clearSearch = () => {
    if (search) { setSearch(''); }
  };

  const studentMatchesSearch = (student) => (
    student.fullName.toLowerCase().includes(search.toLowerCase())
  );

  const finalTeacherList = search
    ? teacherMap.map((t) => ({
      teacher: t.teacher,
      totalStudents: t.totalStudents,
      unlicensedStudents: t.unlicensedStudents,
      students: t.students.filter(studentMatchesSearch),
    })).filter((u) => u.students.length > 0)
    : teacherMap;

  useEffect(() => {
    licenseUsage.loadReport();
  }, []);

  if (!isLoaded) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="license-usage">
      <h1>License Usage</h1>
      <p>
        License usage data for site:
        {' '}
        <strong>{site.name}</strong>
      </p>
      <table className="license-summary">
        <tbody>
          <tr>
            <td>
              <h6>Site Total Purchased</h6>
              {totalLicenses}
            </td>
            <td>
              <h6>Site Total Used</h6>
              {totalUsed}
            </td>
            <td>
              <h6>Site Total Available</h6>
              {totalAvailable}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="filters">
        { isAdmin
          ? (
            <UsageTeacherFilter
              filteredTeacherID={filteredTeacherID}
              handleChange={onSelectTeacher}
              teachers={teachers}
              finalTeacherList={finalTeacherList}
              hasSearch={!!search}
            />
          )
          : null}

        {students.length > 0
          ? (
            <div className="studentSearch">
              <TextBox
                placeholder="Search Student"
                value={search}
                onChange={(e) => updateSearch(e)}
              />
              <i
                className="material-icons"
                onClick={clearSearch}
                onKeyDown={clearSearch}
                role="button"
                tabIndex={0}
              >
                {search ? 'close' : ''}
              </i>
            </div>
          )
          : null}

      </div>
      { search
        ? (
          <p className="search-display-text">
            {finalTeacherList.length > 0 ? 'Showing results' : 'No results to display'}
            {' '}
            for
            {' '}
            <strong>
              {search}
            </strong>
          </p>
        )
        : null}
      { isAdmin
        ? (
          <UsageTeacherList
            finalTeacherList={finalTeacherList}
            search={search}
            teachersCreated={!!teacherMap}
          />
        )
        : <UsageTeacher teacherData={teachers[0]} singleTeacher search /> }
    </div>

  );
};

export default inject('auth', 'licenseUsage')(observer(LicenseUsage));
