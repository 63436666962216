import React from 'react';
import StudentSearchBox from './StudentSearchBox';

const DashboardSearch = () => (
  <div>
    <StudentSearchBox />
  </div>
);

export default DashboardSearch;
