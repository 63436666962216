import React, { Component } from 'react';
import {
  action, autorun, computed, observable,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import TabControl from 'widgets/navigation/TabControl';
import Table from 'widgets/Table';
import moment from 'moment';

@inject('games', 'courses', 'students', 'curriculum') @observer
class Activities extends Component {
  @observable leaderboard = { national: [], site: [] }

  @observable participation = []

  @observable competencies = { course: {}, national: {} }

  @observable fullLeaderboard = false

  @observable keyboardLocal = []

  @observable keyboardGlobal = []

  componentDidMount() {
    autorun(() => {
      const { courses: { fromRoute: course } } = this.props;
      if (this.showQuizChallenge) {
        this.loadQuizChallengeData(course);
      }
      if (this.showKeyboarding) {
        this.loadKeyboardingData(course);
      }
    });
  }

  @computed get showKeyboarding() {
    const { courses: { fromRoute: course } } = this.props;
    return course && course.hasAssignment('BCKEYBOARD');
  }

  @computed get showQuizChallenge() {
    const { courses: { fromRoute: course } } = this.props;
    return course && course.quizChallenge;
  }

  @computed get quizLocalLeaderboard() {
    if (this.fullLeaderboard) {
      return this.leaderboard.site;
    }

    return this.leaderboard.site.slice(0, 10);
  }

  @computed get quizGlobalLeaderboard() {
    if (this.fullLeaderboard) {
      return this.leaderboard.national;
    }

    return this.leaderboard.national.slice(0, 10);
  }

  @computed get participationData() {
    const byWeekAndStudent = this.participation.reduce(
      (acc, entry) => {
        const { week, student_id: student } = entry;

        acc[week] = acc[week] || {};
        acc[week][student] = acc[week][student] || [];
        acc[week][student].push(entry);

        return acc;
      },
      {},
    );

    return byWeekAndStudent;
  }

  @computed get participationWeeks() {
    if (!this.participation.length) return [];

    const result = [];
    let week = moment().endOf('isoWeek');

    for (let w = 0; w < 6; w += 1) {
      result.unshift(week.format('YYYY-MM-DD'));
      week = week.subtract(1, 'week');
    }

    return result;
  }

  @computed get participationTable() {
    const { courses: { fromRoute: { students } } } = this.props;

    return students.map((student) => {
      const byWeek = this.participationWeeks.reduce(
        (acc, week) => {
          const data = (this.participationData[week] || {})[student.id] || [];
          const allPercents = (tot, ent) => tot + (ent.percent * 100);

          acc[week] = {
            plays: data.reduce((sum, r) => sum + r.max_score, 0) / 100,
            score: data.length ? data.reduce(allPercents, 0) / data.length : 0,
          };

          return acc;
        },
        {},
      );

      return {
        name: student.formatName,
        ...byWeek,
      };
    });
  }

  @computed get competencyTable() {
    return Object.entries(this.competencies.course)
      .map((row) => row.concat([this.competencies.national[row[0]]]));
  }

  @action.bound showFullLeaderboard = () => { this.fullLeaderboard = true; }

  @action populateQuizChallenge(leaderboard, participation, competencies) {
    this.leaderboard = leaderboard;
    this.participation = participation;
    this.competencies = competencies;
  }

  @action populatKeyboarding(global, course) {
    this.keyboardLocal = course.slice(0, 10);
    this.keyboardGlobal = global.slice(0, 10);
  }

  @action async loadQuizChallengeData(course) {
    const { games } = this.props;
    const [leaderboard, participation, competencies] = await Promise.all([
      games.getQuizChallengeResults('leaderboard'),
      games.getQuizChallengeResults('participation', course),
      games.getQuizChallengeResults('competencies', course),
    ]);

    this.populateQuizChallenge(leaderboard, participation.participation, competencies);
  }

  @action async loadKeyboardingData(course) {
    const { games } = this.props;
    const [global, local] = await Promise.all([
      games.getKeyboardingLeaders(),
      games.getKeyboardingLeaders(course),
    ]);

    this.populatKeyboarding(global.leaders, local.leaders);
  }

  render() {
    const { curriculum } = this.props;
    const {
      fullLeaderboard,
      participationTable,
      participationWeeks,
      competencyTable,
      keyboardLocal,
      keyboardGlobal,
      showKeyboarding,
      showQuizChallenge,
    } = this;

    const keyboardLeaderboardCols = [
      {
        title: '#',
        value: (o, i) => i,
      },
      {
        title: 'Name',
        key: 'name',
      },
      {
        title: 'Score',
        key: 'score',
      },
    ];

    const quizLeaderboardCols = [
      {
        title: '#',
        value: (o, i) => i,
      },
      {
        title: 'Name',
        key: 0,
      },
      {
        title: 'Score',
        key: 2,
      },
    ];

    const quizCompetenciesCols = [
      {
        title: 'Unit',
        value: (r) => (curriculum.unitsByKey[r[0]] || {}).title,
      },
      {
        title: 'My Class',
        value: (r) => `${Number(r[1]).toFixed(1)}%`,
      },
      {
        title: 'National',
        value: (r) => `${Number(r[2]).toFixed(1)}%`,
      },
      {
        title: 'Difference',
        value(row) {
          const [, course, national] = row;
          const diff = (course - national).toFixed(1);
          const prefix = diff > 0 ? '+' : '';
          const classes = ['result', diff > 0 ? 'positive' : 'negative'];

          return (
            <span className={classes.join(' ')}>
              {prefix}
              {diff}
              %
            </span>
          );
        },
      },
    ];

    const quizParticipationCols = [
      {
        title: 'Student',
        key: 'name',
      },
      // {
      //   title: 'Average',
      //   key: 'average',
      // },
      ...participationWeeks
        .map((key) => ({
          title: (
            <span style={{ display: 'block', lineHeight: '1em' }}>
              Week Ending
              <br />
              {key}
            </span>
          ),
          key,
          value: (row) => (
            <div>
              {/* <pre>{JSON.stringify(row[key], null, 2)}</pre> */}
              <div>
                {Math.floor(row[key].plays)}
                {' '}
                plays
              </div>
              <div>
                {row[key].score.toFixed(1)}
                % average
              </div>
            </div>
          ),
        })),
    ];

    return (
      <div style={{ maxWidth: 1100 }}>
        <h1>Activities</h1>

        {showKeyboarding && (
        <div className="keyboardChallenge">
          <h2>Keyboarding Challenge</h2>
          <div className="row">
            <div className="col2">
              <b>Class Leaderboard</b>
              {keyboardLocal.length < 1
                && <p>There are no scores for this activity/game from your class.</p>}
              <Table data={keyboardLocal} columns={keyboardLeaderboardCols} />
            </div>
            <div className="col2">
              <b>Global Leaderboard</b>
              {keyboardGlobal.length < 1
                && <p>There are no scores available for this activity/game.</p>}
              <Table data={keyboardGlobal} columns={keyboardLeaderboardCols} />
            </div>
          </div>
        </div>
        )}

        {showQuizChallenge && (
        <div className="quizChallenge">
          <h2>Quiz Challenge</h2>
          <TabControl>
            <div name="Leaderboard">
              <div className="row">
                <div className="col2">
                  <b>Class Leaderboard</b>
                  { this.quizLocalLeaderboard.length < 1 && (
                    <p>There are no scores available for this quiz challenge from your class.</p>
                  )}
                  <Table data={this.quizLocalLeaderboard} columns={quizLeaderboardCols} />

                  {!fullLeaderboard && this.quizLocalLeaderboard.length > 1 && (
                    <div style={{ paddingTop: 8 }}>
                      <button type="button" onClick={this.showFullLeaderboard}>
                        Show All Scores
                      </button>
                    </div>
                  )}
                </div>
                <div className="col2">
                  <b>Global Leaderboard</b>
                  {this.quizGlobalLeaderboard.length < 1
                    && <p>There are no scores available for this quiz challenge.</p>}
                  <Table data={this.quizGlobalLeaderboard} columns={quizLeaderboardCols} />

                  {!fullLeaderboard && this.quizGlobalLeaderboard.length > 1 && (
                  <div style={{ paddingTop: 8 }}>
                    <button type="button" onClick={this.showFullLeaderboard}>
                      Show All Scores
                    </button>
                  </div>
                  )}
                </div>
              </div>
            </div>
            <div name="Participation">
              <i>Number of 10 question quizzes completed and average score for the past 6 weeks.</i>
              <Table data={participationTable} columns={quizParticipationCols} />
            </div>
            <div name="Performance">
              <b>Quiz Challenge Performance</b>
              {competencyTable.length < 1
                && <p>There is no performance to report.</p>}
              <Table data={competencyTable} columns={quizCompetenciesCols} />
            </div>
          </TabControl>
        </div>
        )}
      </div>
    );
  }
}

export default Activities;
