/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import DateTime from 'widgets/format/DateTime';
import OpenOverride from 'widgets/assignment/OpenOverride';
import OverrideGrade from './OverrideGrade';

@inject('assessmentOpens', 'grades', 'WebAPI') @observer
class GradeAttempts extends React.Component {
  @observable worksheets = new Map()

  @observable loaded = false

  @observable loading = false

  @observable override = false

  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
    };
  }

  @action.bound setLoaded = (val) => { this.loaded = !!val; }

  @action.bound setLoading = (val) => { this.loading = !!val; }

  getAssessmentWorksheet() {
    const { course, student, unitKey } = this.props;
    const studentGrade = student.gradesByCourse.get(course.id);

    return (studentGrade && studentGrade.worksheet[unitKey]);
  }

  @action.bound hideOverride = () => { this.override = false; }

  @action.bound showOverride(isEdit) {
    this.override = true;
    this.setState({ isEdit });
  }

  @action.bound resetGrade() {
    const { grades, grade: { id } } = this.props;

    this.setLoading(true);
    grades.resetGrade(id)
      .then(() => {
        this.setLoading(false);
      });
  }

  @action.bound restoreGrade() {
    const { grades, grade: { id } } = this.props;

    this.setLoading(true);
    grades.restoreGrade(id)
      .then(() => {
        this.setLoading(false);
      });
  }

  // load & prep worksheet on hover
  @action async loadWorksheet() {
    const { course, WebAPI } = this.props;
    const worksheet = this.getAssessmentWorksheet();

    WebAPI.fetch(
      'GET',
      `teacher/courses/${course.id}/worksheets/${worksheet.id}`,
      undefined,
      { quiet: true },
    )
      .then(action((result) => {
        this.worksheets.set(worksheet.id, result.url);
      }));
  }

  // open worksheet in new window
  openWorksheet() {
    const worksheet = this.getAssessmentWorksheet();

    if (!this.worksheets.has(worksheet.id)) return;
    window.open(this.worksheets.get(worksheet.id));
  }

  render() {
    const {
      assessment,
      assessmentOpens,
      course,
      grade,
      grades,
      moduleKey,
      student,
      unitKey,
      work,
    } = this.props;
    const { isEdit } = this.state;

    let status;
    if (course && student) {
      const override = assessmentOpens.find(course, student, assessment);

      if (override) {
        // Has override
        status = (
          <span>
            Open until
            {' '}
            {override.relativeExpiration}
            <br />
            <button
              className="secondary"
              type="button"
              onClick={() => assessmentOpens.delete(override)}
            >
              Reset
            </button>
          </span>
        );
      } else {
        status = (
          <OpenOverride
            student={student}
            entry={assessment}
            course={course}
          />
        );
      }
    }

    if (!grade) {
      return <span />;
    }

    const {
      history: attempts,
      mostRecentAttempt: mostRecent,
    } = grade;
    let gradeCalculation = '';
    if (course.gradeCalculation === 'LAST') {
      gradeCalculation = 'Most Recent Result';
    } else if (course.gradeCalculation === 'HIGHEST') {
      gradeCalculation = 'Best Result';
    } else {
      gradeCalculation = 'Average of All Results';
    }

    return (
      <div className="gradeOverview">

        <div className="aStatus card">
          <h2>Assessment Overview</h2>
          <table>
            <tbody>
              <tr>
                <td>Calculated Grade</td>
                <td className="display">{grade.percent}</td>
              </tr>
              <tr>
                <td>Calculation Method</td>
                <td className="display">{gradeCalculation}</td>
              </tr>
              <tr>
                <td>Assessment Status</td>
                <td className="display">{status}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {!grade.examId && (
          <div className="worksheets card">
            <h2 style={{ marginBottom: '0px' }}>Electronic Worksheets</h2>
            {grade && grade.worksheet && grade.worksheet.worksheet_questions !== undefined
              && (
                <div className="worksheets" style={{ marginTop: '0px' }}>
                  <h3>
                    {grade.worksheet.worksheet_completed}
                    /
                    {grade.worksheet.worksheet_questions}
                  </h3>
                  <p>Worksheet Answers Completed</p>
                  <a
                    onMouseOver={() => this.loadWorksheet()}
                    onClick={() => this.openWorksheet()}
                    style={{ marginLeft: '10px' }}
                  >
                    View Worksheet
                  </a>
                </div>
              )}
            {(grade.worksheet === undefined || grade.worksheet.worksheet_questions === undefined)
              && <p className="nodata">No worksheet data</p>}
          </div>
        )}

        {assessment && attempts.length < 1
          && (
            <div className="quizAttempts card">
              <h2>
                The
                {' '}
                {assessment.prettyType.toLowerCase()}
                {' '}
                was not taken
              </h2>
              <button type="button" onClick={() => this.showOverride(true)}>Assign Grade</button>
            </div>
          )}

        {mostRecent
          && (
            <React.Fragment>
              <div className="attempts card">
                <h2>Most Recent Attempt</h2>
                <div className="recentAttempts">
                  <table>
                    <tbody>
                      <tr>
                        <td>Points Earned</td>
                        <td>{mostRecent.totalEarned}</td>
                      </tr>
                      <tr>
                        <td>Points Possible</td>
                        <td>{mostRecent.totalPossible}</td>
                      </tr>
                      <tr>
                        <td>Percent Grade</td>
                        <td>
                          {(!mostRecent.percentEarned) ? '0' : mostRecent.percentEarned.toFixed(1)}
                          %
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="attemptsActions">
                    <button
                      type="button"
                      onClick={() => this.showOverride(true)}
                    >
                      Edit Grade
                    </button>
                    <button
                      type="button"
                      onClick={this.resetGrade}
                      disabled={grade.isReset}
                    >
                      Reset Grade
                    </button>
                    <button
                      type="button"
                      onClick={this.restoreGrade}
                      disabled={!grade.isReset}
                    >
                      Restore Grade
                    </button>
                  </div>
                </div>
                {grade.isReset && <p id="reset">This grade has been reset.</p>}
              </div>
              <div className="attempts card">
                <h2>History of Attempts</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>Attempt</th>
                      <th>Completed</th>
                      <th>Score</th>
                      <th>Percent</th>
                      { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                      {attempts.length > 1 && <th />}
                    </tr>
                    {attempts.map((attempt, i) => (
                      <tr key={attempt.id}>
                        <td>{i + 1}</td>
                        <td><DateTime value={attempt.completed} /></td>
                        <td>
                          {attempt.totalEarned}
                          /
                          {attempt.totalPossible}
                        </td>
                        <td>
                          { (attempt.percentEarned)
                            ? attempt.percentEarned.toFixed(1)
                            : '0' }
                          %
                        </td>
                        {attempts.length > 1
                          && (
                            <td>
                              {(i !== attempts.length - 1)
                                && (
                                  <button
                                    type="button"
                                    onClick={() => grades.removeGradeAttempt(grade.id, attempt.id)}
                                  >
                                    Delete
                                  </button>
                                )}
                            </td>
                          )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          )}

        {student
          && (
            <OverrideGrade
              visible={this.override}
              grades={grades}
              course={course}
              student={student}
              entry={grade}
              work={work}
              gradeSet={this.hideOverride}
              actionCanceled={this.hideOverride}
              editMode={isEdit}
              moduleKey={moduleKey}
              unitKey={unitKey}
            />
          )}
      </div>
    );
  }
}
export default GradeAttempts;
