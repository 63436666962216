import React from 'react';
import Modal from 'widgets/Modal';
import TextBox from 'widgets/form/TextBox';

class SaveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: '' };
  }

  render() {
    const {
      error, visible, type, onDismiss, onSave,
    } = this.props;
    const { name } = this.state;
    return (
      <Modal
        title={`Give Your ${type} a Name`}
        visible={visible}
        onDismiss={onDismiss}
        style={{ height: '200px' }}
      >
        {error && <span className="error">{error}</span>}
        <TextBox
          value={name}
          onChange={(value) => this.setState({ name: value })}
          style={{ width: '300px' }}
        />
        <button type="button" onClick={() => onSave(name)}>
          Create
          {' '}
          {type}
        </button>
      </Modal>
    );
  }
}

export default SaveModal;
