import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import ModuleIcon from 'widgets/assignment/ModuleIcon';

const CatalogModuleGroup = ({
  catalog, curriculum, match: { params },
}) => {
  if (!catalog.loaded || !curriculum.loaded) {
    return <div />;
  }
  const { moduleGroupKey } = params;
  const moduleGroup = curriculum.moduleGroupsByKey[moduleGroupKey];
  const modules = moduleGroup.moduleKeys;
  const { resource } = moduleGroup;

  const ModuleHeading = ({ key, module }) => {
    if (!module) {
      return (
        <div className="moduleIcon">
          <h3>
            Missing Module:
            {key}
          </h3>
        </div>
      );
    }
    const link = `${moduleGroupKey}/${module.key}`;
    return (
      <div className="moduleIcon">
        <h3><Link to={link}>{module.title}</Link></h3>
        <Link to={link}><ModuleIcon title={module.title} image={module.imageUrl} /></Link>
        <Link to={link}>View Details</Link>
      </div>
    );
  };

  return (
    <div className="catalogTemplates">
      <h1>{moduleGroup.title}</h1>
      <p className="subheading">{moduleGroup.description}</p>
      <div className="downloads">
        <p>
          <a target="new" href={resource.url}>
            <button type="button">
              Download
              {' '}
              {resource.title}
            </button>
          </a>
        </p>
      </div>
      <div className="row">
        {modules.map((module) => (
          <ModuleHeading
            key={module}
            module={curriculum.modulesByKey[module]}
          />
        ))}
      </div>
    </div>
  );
};

export default inject('catalog', 'curriculum')(observer(CatalogModuleGroup));
