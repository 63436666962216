import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import TextBox from 'widgets/form/TextBox';
import Dropdown from 'widgets/form/Dropdown';
import Modal from 'widgets/Modal';

@inject('courses') @observer
class Certificates extends Component {
  @observable minimum = 90

  @observable loadingState = 'not started'

  @observable url = null

  @observable module = 'ALL'

  @observable student = 'ALL'

  @observable certificateError = ''

  @observable errors = []

  @observable show = false

  @computed get moduleNames() {
    const { courses: { fromRoute: course } } = this.props;
    const assignmentToOption = (a) => ({ key: a.key, value: a.title });
    const assignments = course.gradeAssignments.map(assignmentToOption);
    const all = { key: 'ALL', value: 'All Modules' };

    return [all].concat(assignments);
  }

  @computed get studentNames() {
    const { courses: { fromRoute: course } } = this.props;
    const studentToOption = (s) => ({ key: s.id, value: s.name });
    const students = course.students.map(studentToOption);
    const all = { key: 'ALL', value: 'All Students' };

    return [all].concat(students);
  }

  @action.bound hideReport = () => { this.show = false; }

  @action.bound setMinimum = (val) => { this.minimum = +val; }

  @action.bound setModule = (val) => { this.module = val; }

  @action.bound setStudent = (val) => { this.student = val; }

  @action.bound showReport() {
    this.generateReport();
    this.show = true;
  }

  @action generateReport() {
    const { courses: { fromRoute: course } } = this.props;
    this.certificateError = '';
    this.loadingState = 'loading';

    if (Number.isNaN(this.minimum)) { this.minimum = 80; }
    if (this.minimum > 100) { this.minimum = 100; }
    if (this.minimum < 0) { this.minimum = 0; }

    course.generateCertificates(this.module, this.minimum, this.student)
      .then(action((result) => {
        if (result.error) {
          this.certificateError = result.error;
          this.loadingState = 'done';
          this.show = false;
        } else {
          this.loadingState = 'done';
          this.url = result.url;
        }
      }))
      .catch(action(() => {
        this.loadingState = 'error';
      }));
  }

  render() {
    const openPDF = () => {
      window.open(this.url);
      this.hideReport();
    };
    return (
      <div>
        <h1>Create Certificates</h1>
        <p>
          Use this tool to generate and print certificates of achievement.
        </p>

        <h3>Generate for:</h3>

        <Dropdown
          options={this.studentNames}
          value={this.student}
          onChange={this.setStudent}
          noEmpty
        />

        <Dropdown
          options={this.moduleNames}
          value={this.module}
          onChange={this.setModule}
          noEmpty
        />

        <div className="formField">
          <TextBox
            label="With a minimum grade of"
            value={this.minimum}
            onChange={this.setMinimum}
            leftLabel
            size={2}
            style={{ marginRight: '10px' }}
            inline
          />
          %
        </div>

        <p>
          {this.errors.map((err) => <span className="error">{err}</span>)}
        </p>

        <button type="button" className="lgButton" onClick={this.showReport}>
          <span>Generate Certificates</span>
        </button>

        <p><span className="error"><strong>{this.certificateError}</strong></span></p>

        <Modal
          title="Certificates of Achievement"
          visible={this.show}
          onDismiss={this.hideReport}
        >
          {this.loadingState === 'loading' && (
          <div>
            Preparing your certificates, please wait...
          </div>
          )}
          {this.loadingState === 'done' && (
          <div>
            <button type="button" onClick={() => openPDF()}>Open PDF</button>
          </div>
          )}
          {this.loadingState === 'error' && (
          <div>
            Something went wrong, please try again later.
          </div>
          )}
        </Modal>

        <p>
          Note: Students must complete all quizzes and the test for the certificate.
          <br />
          Additional information is available in the help article.
          <br />
          <br />
          <a
            href="https://help.aeseducation.com/en/articles/2246841-create-student-certificates"
            target="_blank"
            rel="noreferrer noopener"
          >
            <i className="material-icons">help</i>
&nbsp;Certificates Help
          </a>
        </p>

      </div>
    );
  }
}

export default Certificates;
