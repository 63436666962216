import React, { useRef, useState, useEffect } from 'react';
import MaterialIcon from './MaterialIcon';

const Accordion = ({
  children,
  title,
  className,
  closed: closedProp = false,
}) => {
  const contentRef = useRef();
  const [closedState, setClosedState] = useState(closedProp);
  const [maxHeight, setMaxHeight] = useState(5000);
  const toggleClosed = () => setClosedState(!closedState);

  // change closedState if closedProp changes
  useEffect(() => {
    setClosedState(closedProp);
  }, [closedProp]);

  // creates a MutationObserver and updates maxHeight as content height changes;
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const { offsetHeight } = contentRef.current;
      if (offsetHeight) {
        setMaxHeight(offsetHeight);
      }
    });

    observer.observe(contentRef.current, { attributes: true, childList: true, subtree: true });

    // disconnect MutationObserver on effect destroy
    return () => {
      observer.disconnect();
    };
  }, [contentRef.current]);

  // updates maxHeight when offsetHeight changes due to visibility changes
  useEffect(() => {
    const { offsetHeight } = contentRef.current;
    if (offsetHeight && offsetHeight !== maxHeight) {
      setMaxHeight(offsetHeight);
    }
  }, [contentRef.current?.offsetHeight]);

  return (
    <div className={`accordion ${className} ${closedState ? 'closed' : ''}`}>
      { title
        ? (
          <div
            className="header"
            role="button"
            tabIndex={0}
            aria-expanded={closedState}
            onClick={toggleClosed}
            onKeyPress={toggleClosed}
          >
            <MaterialIcon>{ closedState ? 'expand_more' : 'expand_less' }</MaterialIcon>
            <span>{title}</span>
          </div>
        )
        : null }
      <div className="content-wrapper" style={{ maxHeight }}>
        <div className="content" ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
