import React, { Component } from 'react';
import { autorun } from 'mobx';
import { inject, observer } from 'mobx-react';
import Fuse from 'fuse.js';

@inject('students', 'router') @observer
class StudentFinder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      show: false,
    };
  }

  componentDidMount() {
    const { students } = this.props;

    this.fuse = { search: () => [] };

    autorun(() => {
      const options = {
        keys: ['name', 'email'],
        shouldSort: true,
        threshold: 0.499,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
      };

      this.fuse = new Fuse(students.list, options);
    });
  }

  render = () => {
    const { router: { courseId }, students, onSelect } = this.props;
    const { query, show } = this.state;

    const results = students.quickSearch(query, courseId);

    const focusEvent = (ev) => {
      ev.target.select();
      this.setState({ show: true });
    };

    const blurEvent = () => this.setState({ show: false });

    const activate = (student) => {
      this.setState({ query: student.name });
      if (onSelect) onSelect(student.id);
    };

    return (
      <div className="studentSearch">
        <input
          placeholder="Find a Student"
          onFocus={focusEvent}
          onBlur={blurEvent}
          value={query}
          onInput={(ev) => this.setState({ query: ev.target.value })}
        />

        <i className="material-icons">search</i>

        {query && show
          && (
          <ul className="studentResults">
            {results.map((student) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li key={student.id} onMouseDown={() => activate(student)}>
                {student.name}
                {' '}
                &lt;
                {student.email}
                &gt;
              </li>
            ))}
          </ul>
          )}
      </div>
    );
  }
}

export default StudentFinder;
