import React from 'react';
import { Link } from 'react-router-dom';
import Arrow from '../../data/img/arrow.png';

const OnboardTrial = ({ enableCreateTemplates, embedOpts }) => (
  <div className="onboardContainer trial">
    <div className="onboardIntro">
      <h1>How to Make the Most of Your Trial</h1>
      <h2>
        You just signed up for your free trial and are probably asking yourself:
        {' '}
        <i>Now what?</i>
      </h2>
      <h3>
        Watch the video below to learn everything you need to know about your trial, including:
      </h3>
      <div className="smallBox">
        <img src={Arrow} alt="arrow" />
        <ul className="trialList">
          <li>The three simple steps for success in your trial</li>
          <li>How to determine if AES is right for you and your students</li>
          <li>Answers to common questions teachers ask about the trial</li>
        </ul>
        <h3>
          After watching the video, you’ll know how to make the most of your trial to
          confidently decide whether AES
          is a good fit for your classroom.
        </h3>
      </div>
    </div>
    <div className="videoBg">
      <div className="onboardVideo trial">
        <span
          className={`wistia_embed wistia_async_go1hgliy0b ${embedOpts}`}
          key={Date.now()}
        />
      </div>
    </div>
    <div className="onboardCTABg">
      <div className="onboardCTA trial">
        <h1>
          Ready to discover how the AES digital curriculum can save you time?
        </h1>
        {enableCreateTemplates
          && (
            <Link className="button" to="/courses/new/template">Create a District Template </Link>
          )}
        <Link className="button" to="/courses/new">Create Your First Class</Link>
      </div>
    </div>
  </div>
);

export default OnboardTrial;
