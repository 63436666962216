import React from 'react';
import { inject, observer } from 'mobx-react';

const CourseIntegrationButton = (params) => {
  const {
    courses: { fromRoute: course },
    auth,
  } = params;
  if (auth.hasCanvasAPI) {
    if (course.isRostered) {
      return <button type="button" disabled>Canvas is Linked</button>;
    }
    return (
      <button
        type="button"
        onClick={() => course.initiateIntegration('canvas')}
      >
        Link to Canvas
      </button>
    );
  }
  return null;
};

export default inject('auth', 'courses')(observer(CourseIntegrationButton));
