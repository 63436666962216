import React from 'react';
import '../style/progress.scss';

const ProgressSpinner = () => (
  <svg className="progress indeterminate">
    <circle cx="25" cy="25" r="23" />
  </svg>
);

export default ProgressSpinner;
