import React, { Component } from 'react';
import { action, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import Table from 'widgets/Table';
import Checkbox from 'widgets/form/Checkbox';

@inject('auth', 'courses', 'teachers') @observer
class ShareCourse extends Component {
  @action setShared(teacher, share) {
    const { courses: { fromRoute: course } } = this.props;

    if (share) course.grantSharing(teacher);
    else course.revokeSharing(teacher);
  }

  @computed get teachers() {
    const { auth, teachers } = this.props;

    return teachers.list.filter((t) => t.id !== auth.user.id);
  }

  @computed get sharedWith() {
    const { courses: { fromRoute: course } } = this.props;
    return course.sharedWith;
  }

  render() {
    const { auth } = this.props;
    if (!auth.site.hasCourseSharing) {
      return (
        <div>
          <h1>Share Class</h1>
          <p>
            Your package doesn&rsquo;t provide access to class sharing.
            <br />
            Please
            &nbsp;
            {/* eslint-disable-next-line no-undef */}
            <button type="button" className="anchor" onClick={() => Intercom('show')}>
              contact our support team
            </button>
            {' '}
            if you would like to evaluate this feature.
          </p>
        </div>
      );
    }
    const columns = [
      {
        title: 'Name',
        key: 'name',
      },
      {
        title: 'Email',
        key: 'email',
      },
      {
        title: 'Sharing',
        key: 'shared',
        value: (t) => (
          <Checkbox
            label="Full Access"
            inline
            value={this.sharedWith.includes(t.id)}
            onChange={(v) => this.setShared(t, v)}
          />
        ),
      },
    ];

    return (
      <div>
        <h1>Share Class</h1>

        {this.teachers.length === 0
          && <p>There aren&rsquo;t any other teachers in your school to share your class with!</p>}
        {this.teachers.length !== 0 && (
        <Table
          sortable
          data={this.teachers}
          columns={columns}
        />
        )}
      </div>
    );
  }
}
export default ShareCourse;
