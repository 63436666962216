/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { createPortal } from 'react-dom';

const Modal = ({
  children,
  title,
  onDismiss,
  visible,
  className,
  ...rest
}) => {
  const style = { display: visible ? 'block' : 'none' };
  const onClickOverlay = (event) => {
    if (event.target === event.currentTarget && onDismiss) {
      event.stopPropagation();
      onDismiss(event);
    }
  };

  return createPortal(
    <div className="overlay" style={style} onClick={onClickOverlay}>
      {/* TODO Determine what ...rest props are needed, if any, and supply them explicitly */}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div className={className ? `popup ${className}` : 'popup'} {...rest}>
        <div className="title">
          <span>{title}</span>
          {onDismiss && (
            <button type="button" className="closeButton" onClick={onDismiss}>X</button>
          )}
        </div>
        <div className="content">
          {children}
        </div>
      </div>
    </div>, document.body,
  );
};

export default Modal;
