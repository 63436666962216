/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { getBounds } from 'util/domUtil';
import { distance2D, collision2D } from 'util/math';

import { DueDate } from 'util/DateTime';

import AssignmentListRow from './AssignmentListRow';
import AssignmentDueDateModal from './AssignmentDueDateModal';
import AssignmentDeleteModal from './AssignmentDeleteModal';
import Alert from '../../../widgets/Alert';

const dragStartDistance = 5;
const autoScrollSize = 40;
const autoScrollSpeed = 2.5;

@inject('courses', 'router', 'auth', 'assignments') @observer
class AssignmentList extends Component {
  @observable showDueDateModal;

  @observable showDeleteModal;

  @observable assignment;

  @observable dragProps = {
    startX: 0,
    startY: 0,
    currentX: 0,
    currentY: 0,
    offsetX: 0,
    offsetY: 0,
    isClick: true,
    index: null,
    module: null,
    assignment: null,
    remove: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      module: {},
    };
  }

  componentWillUnmount() {
    this.endDrag();
  }

  @computed get dragDropTitle() {
    if (this.dragProps.assignment) return this.dragProps.assignment.title;
    if (this.dragProps.module) return this.dragProps.module.title;
    return '';
  }

  @computed get dragModuleStyle() {
    if (!this.dragProps) return undefined;

    const {
      currentX, currentY, offsetX, offsetY,
    } = this.dragProps;

    return {
      top: currentY - offsetY,
      left: currentX - offsetX,
    };
  }

  @action clearDragProps = () => { this.dragProps = null; }

  @action.bound startDrag(ev, module, assignment, index) {
    ev.preventDefault();
    ev.stopPropagation();

    const isTouch = !!ev.touches;
    const touch = isTouch ? ev.touches[0] : {};
    const targetBox = getBounds(ev.target);
    const clientX = isTouch ? touch.clientX : ev.clientX;
    const clientY = isTouch ? touch.clientY : ev.clientY;
    const offsetX = Math.min(400, clientX - targetBox.x);
    const offsetY = Math.min(40, clientY - targetBox.y);

    this.dragProps = {
      startX: clientX,
      startY: clientY,
      currentX: clientX,
      currentY: clientY,
      offsetX,
      offsetY,
      isClick: true,
      startIndex: index,
      index: null,
      module,
      assignment,
      remove: false,
    };

    if (isTouch) {
      document.addEventListener('touchmove', this.moveDrag, false);
      document.addEventListener('touchend', this.endDrag, false);
    } else {
      document.addEventListener('mousemove', this.moveDrag, false);
      document.addEventListener('mouseup', this.endDrag, false);
    }
  }

  @action.bound showModal(assignment) {
    this.showDeleteModal = true;
    this.setState({ module: assignment });
  }

  @action.bound updateDueDate(assignment, value) {
    this.assignment = assignment;
    this.newDate = new DueDate(value);
    if (this.newDate.isFuture && !assignment.open) {
      this.showDueDateModal = true;
    } else {
      this.saveDueDate();
    }
  }

  @action.bound saveDueDateAndOpen() {
    this.assignment.setDueDate(this.newDate.value, true);
    this.showDueDateModal = false;
  }

  @action.bound saveDueDate() {
    this.assignment.setDueDate(this.newDate.value, this.assignment.open);
    this.showDueDateModal = false;
  }

  @action.bound closeDeleteModal() {
    this.showDeleteModal = false;
  }

  @action deleteModule() {
    const { courses: { fromRoute: course } } = this.props;
    const { module } = this.state;
    course.removeAssignment(module);
    this.showDeleteModal = false;
  }

  @action.bound moveDrag(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    try {
      const { isClick, startX, startY } = this.dragProps;

      const isTouch = !!ev.touches;
      const touch = isTouch ? ev.touches[0] : {};
      const x = isTouch ? touch.clientX : ev.clientX;
      const y = isTouch ? touch.clientY : ev.clientY;
      this.dragProps.currentX = x;
      this.dragProps.currentY = y;

      if (isClick) {
        if (distance2D(startX, startY, x, y) >= dragStartDistance) {
          this.dragProps.isClick = false;
        }
        return;
      }

      if (!this.assignmentList || !this.dragElement) {
        throw new Error('DOM error while dragging');
      }

      const dragging = getBounds(this.dragElement);
      const assignmentsArea = getBounds(this.assignmentList);

      if (collision2D(dragging, assignmentsArea)) {
        this.dragProps.remove = false;

        if (dragging.top < assignmentsArea.top + autoScrollSize) {
          if (!this.scrollTimer) {
            this.scrollTimer = setInterval(() => {
              this.assignmentList.scrollTop -= autoScrollSpeed;
            });
          }
        } else if (dragging.bottom > assignmentsArea.bottom - autoScrollSize) {
          if (!this.scrollTimer) {
            this.scrollTimer = setInterval(() => {
              this.assignmentList.scrollTop += autoScrollSpeed;
            });
          }
        } else if (this.scrollTimer) {
          clearTimeout(this.scrollTimer);
          this.scrollTimer = null;
        }

        const elements = [...this.assignmentList.querySelectorAll('.assignmentRow')];
        const boxes = elements.map(getBounds);

        let index = 0;

        for (let i = 0; i < boxes.length; i += 1) {
          const box = boxes[i];

          if (box.height && dragging.middle > box.middle) {
            index = i + 1;
          }
        }

        this.dragProps.index = index;
      } else {
        this.dragProps.index = null;
        this.dragProps.remove = false;
      }
    } catch (e) {
      this.endDrag(ev);
      throw e;
    }
  }

  @action.bound async endDrag(ev) {
    const { courses: { fromRoute: course } } = this.props;

    document.removeEventListener('touchmove', this.moveDrag);
    document.removeEventListener('touchend', this.endDrag);
    document.removeEventListener('mousemove', this.moveDrag);
    document.removeEventListener('mouseup', this.endDrag);
    if (this.scrollTimer) clearTimeout(this.scrollTimer);

    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    if (!this.dragProps) return;

    const {
      isClick, assignment, startIndex, index,
    } = this.dragProps;

    if (!isClick && index != null && assignment) {
      await course.moveAssignment(startIndex, index);
    }

    this.clearDragProps();
  }

  rowStyle(assignment) {
    if (this.dragProps && !this.dragProps.isClick) {
      return {
        display: assignment === this.dragProps.assignment ? 'none' : undefined,
      };
    }

    return undefined;
  }

  render() {
    const { courses, router, auth } = this.props;
    const course = courses.fromRoute;
    const space = <div key="space" style={{ height: 40, background: 'rgba(0, 0, 0, 0.1)' }} />;
    const disableExams = !!course.upgradeJobId;
    const assignmentList = course.assignments.map((assignment, index) => (
      <AssignmentListRow
        key={assignment.id}
        index={index}
        assignment={assignment}
        startDrag={this.startDrag}
        rowStyle={this.rowStyle(module)}
        router={router}
        auth={auth}
        updateDueDate={this.updateDueDate}
        showModal={this.showModal}
        disabled={disableExams && assignment.isExam}
      />
    ));

    if (this.dragProps && this.dragProps.index != null) {
      assignmentList.splice(this.dragProps.index, 0, space);
    }

    return (
      <div className="assignmentListContainer">
        <button
          type="button"
          className="assignmentHeaderButton"
          onClick={() => router.push(router.path.replace('view', 'create-exam'))}
        >
          Create Custom Exam
        </button>
        <button
          type="button"
          className="assignmentHeaderButton"
          onClick={() => router.push(router.path.replace('view', 'edit'))}
        >
          Add Module Assignments
        </button>
        <h1>Assignment List</h1>

        { disableExams
          ? (
            <Alert type="info">
              Your exams are loading. When they are finished loading you can access them.
              <br />
              You can still access your other assignments while the exams load.
            </Alert>
          )
          : null }

        <table ref={(el) => { this.assignmentList = el; }} className="assignmentStatus">
          <tbody>
            <tr>
              <th>Module</th>
              <th>Hours</th>
              {auth.site.hasAdvancedPacing && <th>Date Due</th>}
              <th>Student&nbsp;Access</th>
              <th>Remove</th>
            </tr>

            {course.assignments.length === 0
              && <tr><td colSpan={4}>No assignments...</td></tr>}

            {assignmentList}
          </tbody>
        </table>

        <div className="drag" />

        {this.dragProps && !this.dragProps.isClick
          && (
          <div
            ref={(el) => { this.dragElement = el; }}
            className="card assignmentRow arrange dragging"
            style={this.dragModuleStyle}
          >
            <div className="gripper" />
            <div className="info">
              <h2>{this.dragDropTitle}</h2>
              {this.dragProps.module.version && (
              <small>
                (For
                {this.dragProps.module.version}
                )
              </small>
              )}
            </div>
          </div>
          )}

        <AssignmentDueDateModal
          visible={this.showDueDateModal}
          updateDueDateAndOpen={this.saveDueDateAndOpen}
          updateDueDate={this.saveDueDate}
        />
        <AssignmentDeleteModal
          showDeleteModal={this.showDeleteModal}
          closeDeleteModal={this.closeDeleteModal}
          deleteModule={() => this.deleteModule()}
        />
      </div>
    );
  }
}

export default AssignmentList;
