import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import StudentFinder from 'routes/course/students/StudentFinder';

import Dropdown from 'widgets/form/Dropdown';

@inject('grades', 'courses', 'assignments') @observer
class Reset extends Component {
  @observable student = null

  @observable assignment = null

  @action.bound clearStudent = () => {
    this.student = null;
    this.assignment = null;
  }

  @action.bound clearAssignment = () => {
    this.assignment = null;
  }

  @action.bound selectStudent(id) {
    const { courses: { fromRoute: course } } = this.props;
    this.student = course.findStudent(id);
  }

  @action.bound selectAssignments(id) {
    const { assignments } = this.props;
    this.assignment = assignments.byId.get(+id);
  }

  resetGrade(id) {
    const { grades } = this.props;
    grades.resetGrade(id);
  }

  render() {
    const { courses: { fromRoute: course } } = this.props;
    const grade = this.student && this.student.gradesByCourse.get(course.id);
    let currentPage;

    if (!this.student) {
      currentPage = (
        <div>
          <p>First, tell us who&rsquo;s having trouble.</p>
          <StudentFinder onSelect={this.selectStudent} />
        </div>
      );
    } else if (!this.assignment) {
      // Get assignments with grades
      const assignments = course.assignments.filter((a) => (
        grade && grade.workByModule[a.moduleKey]
      ));
      const workDone = assignments.length !== 0;

      currentPage = (
        <div>
          {workDone && (
          <div>
            <p>
              Which assignment is
              {this.student.name}
              {' '}
              having trouble with?
            </p>

            <Dropdown
              options={assignments.map((a) => [a.id, a.title])}
              value={this.assignment}
              onChange={this.selectAssignments}
            />
          </div>
          )}
          {!workDone
            && (
            <div className="formField">
              {this.student.name}
              {' '}
              doesn&rsquo;t have any grades in this class.
            </div>
            )}

          <button type="button" onClick={this.clearStudent}>Go Back</button>
        </div>
      );
    } else {
      const key = this.assignment.moduleKey;
      const grades = grade.work.filter((w) => w.moduleKey === key);

      currentPage = (
        <div>
          <p>
            Here are the grades we found for
            {this.student.name}
            {' '}
            in
            {this.assignment.title}
          </p>

          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Status</th>
                <th>Points</th>
                <th>Grade</th>
                { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                <th />
              </tr>
            </thead>
            <tbody>
              {grades.map((work) => (
                <tr>
                  <td>{work.unit.title}</td>
                  <td>{work.statusText}</td>
                  <td>{work.points}</td>
                  <td>{work.percent}</td>
                  <td>
                    { work.status === 2
                      && <button type="button" onClick={() => this.resetGrade(work.id)}>Reset</button> }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button type="button" onClick={this.clearAssignment}>Go Back</button>
        </div>
      );
    }

    return (
      <div>
        <h1>Reset a Grade</h1>

        {currentPage}
      </div>
    );
  }
}

export default Reset;
