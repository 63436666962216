import React, { Component } from 'react';
import { action, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { sanitizeString } from 'util/domUtil';

@inject('grades')
@observer
class QuizQuestionClassic extends Component {
  @computed get showMarkCorrect() {
    const { lessonAnswer, grade, isPrePost } = this.props;
    return lessonAnswer && grade && !grade.overrideEarned && !isPrePost;
  }

  @action.bound markAsCorrect(ev) {
    const { grades, grade, lessonAnswer } = this.props;

    ev.preventDefault();
    ev.stopPropagation();

    grades.markAnswerCorrect(grade.id, lessonAnswer.id);
  }

  render() {
    const {
      question,
      answer,
      isTest,
      isPrePost,
      number,
      compact,
      actions,
      lessonAnswer,
      grade,
      showAnswerSource,
    } = this.props;

    const getLetter = () => {
      const tfToAb = (str) => str.replace(/t/i, 'a').replace(/f/i, 'b');

      if (typeof answer === 'string') {
        return [answer.toLowerCase()].map(tfToAb);
      }

      if (answer) {
        return answer.map(tfToAb);
      }

      return [];
    };

    const selected = getLetter().filter((v) => v);

    if (!question) {
      return (
        <div className="question questionClassic">
          <div className="body">Missing Question</div>
          <div className="answers">Missing Question</div>
        </div>
      );
    }

    // Don't shuffle if showing the student's answers
    const answerList = grade ? question.answers : question.randomizedAnswers;
    const isCustomQuestion = question.source?.includes('CUSTOM');

    return (
      <div className="question questionClassic">
        {/* Optional action buttons */}
        {actions && <div className="actions">{actions(question)}</div>}

        {/* Question */}
        <div className="body">
          <p>
            {number && `${number}. `}
            {question.body}
          </p>
          {showAnswerSource && !isCustomQuestion && <i>{question.source}</i>}
          {showAnswerSource && isCustomQuestion && <i>Custom Question</i>}
        </div>

        {/* Question image */}
        { question.image
          && (
            <div className={`image${compact ? ' imageHover' : ''}`}>
              {compact && <span>Image</span>}
              <img src={question.imageUrl} alt="" />
            </div>
          ) }

        {/* Answers */}
        <div className={`answers${compact ? ' answerHover' : ''}`}>
          {compact && <span>Answers</span>}
          <ol>
            {answerList.map(({ letter, title }) => {
              const picked = selected.includes(letter);
              const pre = selected.indexOf(letter) === 0;
              const post = selected.indexOf(letter) === 1;
              const correct = letter === question.answer;
              const incorrect = !correct;

              const types = [];
              if (correct) {
                types.push('Answer');

                if (!isPrePost && isTest && !selected.length) {
                  types.push('Skipped');
                }
              }
              if (picked) {
                if (isPrePost) {
                  if (pre) types.push('Pre-Test Choice');
                  if (post) types.push('Test Choice');
                } else {
                  types.push('Selected');
                }
              }

              const classes = [];
              if (picked && correct) classes.push('correct');
              if (picked && incorrect) classes.push('incorrect');
              if (correct && !selected.length) classes.push('missed');

              return (
                <li className={classes.join(' ')} key={`question_${question.id}_${letter}`}>
                  <div>
                    <b>{types.join(', ')}</b>
                    {types.length > 0 && <span> &mdash; </span>}
                    &ldquo;
                    {sanitizeString(title)}
                    &rdquo;
                  </div>
                </li>
              );
            })}
          </ol>

          {this.showMarkCorrect && (
            <div>
              { !lessonAnswer.marked_correct && !selected.includes(question.answer)
                && (
                  <button
                    type="button"
                    className="anchor"
                    onClick={this.markAsCorrect}
                  >
                    Mark as correct
                  </button>
                )}
              { lessonAnswer.marked_correct
                && <span className="error">This answer was marked as correct</span> }
            </div>
          )}
        </div>

      </div>
    );
  }
}

export default QuizQuestionClassic;
