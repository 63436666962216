/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

const AssignmentEditRow = ({
  module, startDrag, removeModule, rowStyle,
}) => (
  <tr
    className="assignmentListRow assignmentRow"
    key={module.key}
    style={rowStyle}
  >
    <td className="assignmentTitle">
      <div>
        <div
          className="moduleGripper"
          onMouseDown={(ev) => startDrag(ev, module)}
          onTouchStart={(ev) => startDrag(ev, module)}
        />
        <div>{module.title}</div>
      </div>
    </td>

    <td className="deleteModule" onClick={() => removeModule(module.key)}>
      <i className="fas fa-trash-alt" />
    </td>
  </tr>
);

export default AssignmentEditRow;
