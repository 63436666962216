/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import Checkbox from './Checkbox';

export default class CheckboxGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
    };
  }

  onChange(newValues) {
    const { onChange } = this.props;
    this.setState({ checked: newValues });

    if (typeof onChange === 'function') {
      onChange(newValues);
    }
  }

  onCheck(key, valueParam) {
    const { value } = this.props;
    const { checked } = this.state;
    let newValues = value || checked;
    newValues = newValues.filter((v) => v !== key);
    if (valueParam) newValues.push(key);
    this.onChange(newValues);
  }

  render = () => {
    const {
      options, noMargin, allNone, value,
    } = this.props;
    const { checked } = this.state;
    const active = value || checked;
    let entries = [];

    if (Array.isArray(options)) {
      entries = options.map(
        (optionValue) => {
          if (Array.isArray(optionValue)) {
            return { key: optionValue[0], value: optionValue[1] };
          }
          if (typeof optionValue === 'object') {
            return {
              key: optionValue.key,
              value: optionValue.value,
              disabled: optionValue.disabled,
            };
          }

          return { key: optionValue, value: optionValue };
        },
      );
    } else if (typeof options === 'object') {
      entries = Object.entries(options);
    }

    return (
      <div className={`${!noMargin && 'formField '}`}>
        {allNone
          && (
          <div>
            <a onClick={() => this.onChange(entries.map((e) => e.key))}>Select All</a>
            &nbsp;|&nbsp;
            <a onClick={() => this.onChange([])}>Select None</a>
          </div>
          )}
        {entries.map((entry) => (
          <Checkbox
            key={`checkbox_${entry.key}`}
            label={entry.value}
            checked={active.includes(entry.key)}
            onChange={(entryValue) => this.onCheck(entry.key, entryValue)}
            disabled={entry.disabled}
            noMargin
          />
        ))}
      </div>
    );
  }
}
