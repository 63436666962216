import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  differenceInMilliseconds,
  differenceInMinutes,
  format,
  isToday,
} from 'date-fns';
import Alert from '../widgets/Alert';

/* eslint-disable no-template-curly-in-string */
const replaceTokens = (body, startDateTime, endDateTime, logoutDateTime) => (
  body
    .replace('${logoutDateTime}', logoutDateTime)
    .replace('${startDateTime}', startDateTime)
    .replace('${endDateTime}', endDateTime)
);
/* eslint-enable no-template-curly-in-string */

const AlertBanner = ({ auth, router }) => {
  const { bannerDismissed, currentBanner } = auth;
  const {
    body,
    logoutBody,
    learnMoreUrl,
    start: eventDate,
    end: endDate,
    logout: logoutDate,
    isUnexpected,
  } = currentBanner;

  const dateFormat = isToday(eventDate)
    ? "'at' h:mm aa"
    : "'on' EEEE, MMMM d, 'at' h:mm aa";
  const eventDateFormatted = format(eventDate, dateFormat);
  const endDateFormatted = format(endDate, dateFormat);
  const logoutDateFormatted = logoutDate ? format(logoutDate, dateFormat) : '';
  const [
    logoutMinutesRemaining,
    setLogoutMinutesRemaining,
  ] = useState(720);
  const logoutMinutesRemainingFormatted = logoutMinutesRemaining > 0
    ? `${logoutMinutesRemaining}`.padStart(2, '0')
    : '00';
  const showLogout = logoutMinutesRemaining < 61 || isUnexpected;
  const hideBanner = bannerDismissed && !showLogout;

  const dismissButton = showLogout
    ? <button key="signout" type="button" className="danger" onClick={() => router.push('/logout')}>Sign Out</button>
    : <button key="dismiss" type="button" onClick={() => auth.dismissBanner()}>Dismiss</button>;

  const actions = learnMoreUrl
    ? [<a key="learnMore" href={learnMoreUrl} target="_blank" rel="noopener noreferrer">Learn more</a>, dismissButton]
    : [dismissButton];

  const bodyHTML = showLogout
    ? replaceTokens(
      logoutBody,
      eventDateFormatted,
      endDateFormatted,
      `<span class="countdown">0:${logoutMinutesRemainingFormatted}</span>`,
    )
    : replaceTokens(body, eventDateFormatted, endDateFormatted, logoutDateFormatted);

  useEffect(() => {
    if (logoutMinutesRemaining < 1) {
      router.push('/logout');
    }
  }, [logoutMinutesRemaining]);

  useEffect(() => {
    if (logoutDate) {
      const updateMinutesRemaining = () => {
        setLogoutMinutesRemaining(differenceInMinutes(logoutDate, new Date()));
      };
      const intervalId = setInterval(updateMinutesRemaining, 30000);
      // need to run once immediately to get start state;
      updateMinutesRemaining();
      return () => clearInterval(intervalId);
    }
    return () => {
      // do nothing if no logout date
    };
  }, [logoutDate]);

  useEffect(() => {
    const delta = differenceInMilliseconds(endDate, Date.now());
    if (delta > 0) {
      const timeoutId = setTimeout(() => auth.loadBannerData(), delta);
      return () => clearTimeout(timeoutId);
    }
    return () => {
      // do nothing if no timeout
    };
  }, [endDate]);

  return bodyHTML && !hideBanner
    ? (
      <Alert type={showLogout ? 'danger' : 'banner'} actions={actions}>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: bodyHTML }} />
      </Alert>
    )
    : null;
};

export default inject('auth', 'router')(observer(AlertBanner));
