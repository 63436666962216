import React, { Component } from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import QuizQuestion from './QuizQuestion';

const byQuestionNumber = (a, b) => a.quiz_question_number - b.quiz_question_number;

@observer
class QuestionList extends Component {
  @computed get isPrePost() {
    const { preQuestions, postQuestions } = this.props;
    return preQuestions || postQuestions;
  }

  @computed get questionList() {
    const { questions, preQuestions, postQuestions } = this.props;
    const isValid = (a) => a && a.length;

    if (isValid(questions)) {
      return questions.sort(byQuestionNumber);
    }

    if (isValid(preQuestions) && isValid(postQuestions)) {
      const pre = preQuestions.sort(byQuestionNumber);
      const post = postQuestions;

      return pre.map((entry) => {
        const preAnswer = entry.answer
          ? entry.answer.toLowerCase()
          : '';
        const postQuestion = post.find((e) => e.question?.key === entry.question?.key);
        const postAnswer = postQuestion
          ? postQuestion.answer.toLowerCase()
          : '';

        return {
          ...entry,
          answer: [preAnswer, postAnswer],
        };
      });
    }

    return [];
  }

  render() {
    const {
      actions,
      compact,
      grade,
      numbered,
      showAnswerSource,
    } = this.props;

    if (!this.questionList.length) {
      return <p>No questions to show.</p>;
    }

    return (
      <div className="questionList">
        {this.questionList.map((q, i) => (
          <QuizQuestion
            grade={grade}
            number={numbered ? i + 1 : undefined}
            key={q.id || q.reference}
            compact={compact}
            actions={actions}
            question={grade ? q.question : q}
            answer={grade ? q.answer : undefined}
            isTest={!!grade}
            isPrePost={this.isPrePost}
            lessonAnswer={grade ? q : undefined}
            showAnswerSource={showAnswerSource}
          />
        ))}
      </div>
    );
  }
}

export default QuestionList;
