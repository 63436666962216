import React from 'react';
import ModuleIcon from './ModuleIcon';

const ModuleRow = ({
  module, selectedModules, addModule, removeModule, showPopup,
}) => {
  const isSelected = selectedModules?.find((m) => m === module.key);
  const prefix = module.key.substring(0, 2);
  return (
    <tr key={module.key} id={module.key}>
      <td className="action">
        {isSelected && (
          <button type="button" className="lightButton" onClick={() => removeModule(module.key)}>Remove</button>
        )}
        {!isSelected && (
          <button type="button" className="lightButton" onClick={() => addModule(module.key)}>Add</button>
        )}
      </td>
      <td className="image">
        <ModuleIcon title={module.title} image={module.imageUrl} />
      </td>
      <td className="title">
        <p>
          <b>
            {module.title}
            {' '}
            {module.version && (
              <span>
                {' '}
                (
                {module.version}
                )
              </span>
            )}
          </b>
          <br />
          {module.minHours === module.hours
          && <span>{module.hours}</span>}
          {module.minHours !== module.hours
          && (
            <span>
              {module.minHours}
              {' '}
              to
              {' '}
              {module.hours}
            </span>
          )}
          <span>
            {' '}
            Curriculum Hour
            {(module.hours !== 1) ? 's' : ''}
          </span>

          <br />
          {prefix !== 'ZT' ? (
            <button type="button" className="anchor" onClick={() => showPopup(module)}>
              More Details
            </button>
          ) : null}
        </p>
      </td>
      <td className="details">
        <p className="fade">{module.description}</p>
      </td>
    </tr>
  );
};

export default ModuleRow;
