import { extendObservable, computed } from 'mobx';

import { shuffle } from 'util/Array';

export default class Question {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    extendObservable(this, {
      id: input.id || 0,
      key: input.key || input.teacher_question_key || input.question_key || '',
      isTeacher: input.isTeacher || !!input.teacher_question_key,
      moduleKey: input.moduleKey || input.module_key || '',
      unitKey: input.unitKey || input.unit_key || '',
      type: input.type || input.question_type || 'MC',
      paragraphs: input.paragraphs || '',
      image: input.image || input.question_image || null,
      choices: input.choices || [],
      answer: input.answer || 'a',
      visible: input.visible || !!input.displayed,
      assessed: !!input.assessed,
      randomize: +input.randomize === 1,
      sequence: +input.sequence || 0,
      testType: input.test_type || 'B',
    });

    this.answer = String(this.answer).toLowerCase();

    // Multiple Choice
    if (this.type === 'MC') {
      ['a', 'b', 'c', 'd', 'e'].forEach((l) => this.choices.push(input[`choice_${l}`] || null));
    }

    // True/False
    if (this.type === 'TF') {
      this.choices = ['True', 'False'];
      this.answer = this.answer === 't' ? 'a' : 'b';
    }

    // "Fill in the Blank"
    if (this.type === 'FIB') {
      this.choices = [input.answer];
    }
  }

  @computed get answers() {
    switch (this.type) {
      case 'TF':
        return [
          {
            letter: 'a',
            title: 'True',
            correct: this.answer === 'a',
          },
          {
            letter: 'b',
            title: 'False',
            correct: this.answer === 'b',
          },
        ];
      case 'FIB':
        return [{
          letter: 'a',
          title: `"${this.answer}"`,
          correct: true,
        }];
      case 'MC':
      default:
        return ['a', 'b', 'c', 'd', 'e'].map((l, i) => this.choices[i] && {
          letter: l,
          title: this.choices[i],
          correct: l === this.answer,
        }).filter((a) => a);
    }
  }

  @computed get module() {
    const { curriculum } = this.rootStore;

    return curriculum.modulesByKey[this.moduleKey];
  }

  @computed get unit() {
    const { curriculum } = this.rootStore;

    return curriculum.unitsByKey[this.unitKey];
  }

  @computed get body() {
    const el = document.createElement('div');
    el.innerHTML = this.paragraphs;
    return el.innerText || '';
  }

  @computed get imageUrl() {
    const { auth } = this.rootStore;
    return auth.mediaBaseUrl + this.image;
  }

  get serialized() {
    const body = {
      question_type: 'MC',
      answer: this.answer.toUpperCase(),
      randomize: this.randomize,
      paragraphs: this.body.replace('\n', '<P>'),
      module_key: this.moduleKey,
    };

    const keys = ['a', 'b', 'c', 'd', 'e'];
    this.choices.slice(0, 5).forEach((choice, i) => {
      body[`choice_${keys[i]}`] = choice;
    });

    return body;
  }

  @computed get randomizedAnswers() {
    if (this.randomize) {
      return shuffle(this.answers);
    }
    return this.answers;
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get source() {
    return '';
    /*
    DATA FROM THE API DOES'T RETURN EVERYTHING THATS NEEDED

    const { curriculum } = this.rootStore;
    const { testType, unitKey, moduleKey } = this;
    const match = unitKey.match(/MT_(.+)/);

    if (match) {
      const module = curriculum.modulesByKey[match[1]];
      const title = module ? module.title : `${match[1]}`;
      return `Module: ${title}`;
    }

    const unit = curriculum.unitsByKey[unitKey];
    const mod = unit ? unit.modules[0] : null;
    const advanced = testType === 'A' ? '(Advanced)' : '';

    if (mod && unit) {
      return `Unit: ${unitKey} ${mod.title} / ${unit.title} ${advanced}`;
    }

    return `Unit: ${unitKey} ${advanced}`;
    */
  }
}
