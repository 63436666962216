import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const findModuleKey = (r) => r.fields['module_key.keyword'][0];

@inject('catalog', 'curriculum', 'auth') @observer
class CatalogSearch extends Component {
  renderResult(r) {
    const { auth, catalog, curriculum } = this.props;
    const moduleKey = findModuleKey(r);
    const mod = curriculum.modulesByKey[moduleKey];
    // TODO fix the following block to work w/ models instead of raw fuze output
    /* eslint-disable no-underscore-dangle */
    const singleResource = r._source.teacher_resource;
    const resources = (r._source.resources || []);
    const lessons = r._source.lessons || [];
    /* eslint-enable no-underscore-dangle */
    const bookmarks = {};
    const template = catalog.findTemplateForModule(moduleKey);
    const include = catalog.searchModules.find((mk) => mk === moduleKey);
    if (!mod || !template || !include) {
      return null;
    }
    return (
      <div className="catalogSearchModule" key={moduleKey}>
        <img src={auth.mediaBaseUrl + mod.image} alt={mod.title} />
        <h1 style={{ marginTop: 40 }}>{mod.title}</h1>
        {template && (
        <div style={{ marginBottom: 24 }}>
          <p>
            <i>
              {`From the ${template.title} Template`}
            </i>
          </p>
          <Link
            className="button"
            to={`/catalog/search/${template.templateKey}/${moduleKey}`}
          >
            View Module
          </Link>
        </div>
        )}
        <div className="catalogSearchModuleDetails">
          { resources.map((resource) => this.renderResource(resource, bookmarks)) }
          { lessons.map((lesson) => this.renderLesson(lesson, bookmarks)) }
          { singleResource && this.renderResource(singleResource, bookmarks) }
        </div>
      </div>
    );
  }

  renderResource(resource, bookmarks) {
    const { auth: { mediaBaseUrl } } = this.props;
    const previous = bookmarks.lastUnit;
    const r = resource.teacher_resource || resource;
    // eslint-disable-next-line no-param-reassign
    bookmarks.lastUnit = r.unit_key;
    return (
      <div key={r.url}>
        { previous !== bookmarks.lastUnit && <h2>{r.unit_title}</h2> }
        <a
          target="new"
          href={mediaBaseUrl + r.url}
        >
          {r.title}
        </a>
      </div>
    );
  }

  renderLesson(lesson, bookmarks) {
    const { curriculum } = this.props;
    const tokens = lesson.page_set.page_set_key.split('_');
    const unitKey = tokens[1];
    const previous = bookmarks.lastUnit;
    const unit = curriculum.unitsByKey[unitKey];
    // eslint-disable-next-line no-param-reassign
    bookmarks.lastUnit = unitKey;
    return (
      <div key={lesson.page_set.page_set_key}>
        { false && previous !== bookmarks.lastUnit && <h2>{unit.title}</h2> }
        <p style={{ marginTop: 0, marginBottom: 0 }}>
          Student Lesson:
          {lesson.page_set.title}
        </p>
      </div>
    );
  }

  render() {
    const { catalog, auth } = this.props;
    const { site } = auth;
    if (!catalog.loaded || !site) {
      return <div />;
    }
    return (
      <div>
        <h1>Search Results</h1>
        <p>
          Showing results for all
          {' '}
          {site.isHealth ? 'health' : 'business/career'}
          {' '}
          modules.
        </p>
        { catalog.searchResults.map((r) => this.renderResult(r)) }
      </div>
    );
  }
}

export default CatalogSearch;
