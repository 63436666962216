import React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import DateTime from 'widgets/format/DateTime';
import Table from 'widgets/Table';
import Modal from 'widgets/Modal';
import Dropdown from 'widgets/form/Dropdown';

@inject('assignments', 'courses') @observer
class Exams extends React.Component {
  @observable courseId = null

  @observable selected = null

  componentDidMount() {
    const { assignments } = this.props;
    assignments.loadArchivedExams();
  }

  @action.bound setCourseId = (val) => { this.courseId = val; }

  @action.bound setSelection = (exam) => { this.selected = exam; }

  @action.bound clearSelection = () => { this.selected = null; }

  restoreExam() {
    const { courseId } = this;
    const { assignments } = this.props;
    assignments.restoreExam(courseId, this.selected)
      .then(() => { this.clearSelection(); });
  }

  render() {
    const { assignments: { archivedExams }, courses } = this.props;

    const examCourseTitle = (exam) => {
      const course = courses.active.find((c) => c.id === exam.courseId);
      return course ? course.title : 'N/A';
    };

    const columns = [
      {
        title: 'Title',
        key: 'title',
        // TODO: Link to preview exam
      },
      {
        title: 'Original Class',
        key: 'course',
        value: (e) => examCourseTitle(e),
      },
      {
        title: 'Created',
        key: 'createdAt',
        value: (e) => <DateTime value={e.createdAt} />,
        sortValue: (e) => new Date(e.createdAt).valueOf(),
      },
      {
        title: 'Questions',
        key: 'questionCount',
      },
      {
        title: 'Modules Covered',
        key: 'moduleCount',
      },
      {
        title: '',
        key: 'actions',
        value: (e) => <button type="button" onClick={() => this.setSelection(e)}>Restore</button>,
        sortable: false,
      },
    ];

    return (
      <div>
        <h1>Deleted Exams</h1>

        <Table sortable data={archivedExams} columns={columns} />

        <Modal
          title="Restore Exam"
          visible={this.selected}
          onDismiss={this.clearSelection}
        >
          <p>
            Restore &ldquo;
            {this.selected && this.selected.title}
            &rdquo; to which course?
          </p>

          <Dropdown
            options={courses.active.map((c) => [c.id, c.title])}
            value={this.courseId}
            onChange={this.setCourseId}
          />

          <button type="button" onClick={() => this.restoreExam()}>Restore</button>
          <button type="button" className="secondary" onClick={this.clearSelection}>Cancel</button>
        </Modal>
      </div>
    );
  }
}

export default Exams;
