/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router';

import './style.scss';

@inject('auth') @observer
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      site: 3,
      token: '',
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-underscore-dangle
    window._appReady = true;
  }

  submitForm = (ev) => {
    const { auth } = this.props;
    const {
      email, password, site, token,
    } = this.state;

    ev.preventDefault();
    if (token) {
      auth.setToken(token);
      auth.verifyLogin();
    } else {
      auth.login(email, password, site);
    }
  }

  render() {
    const { auth } = this.props;
    const {
      email, password, site, token,
    } = this.state;

    if (auth.token) {
      if (!auth.verified) {
        // External login, show a blank screen
        return <div className="loginScreen" />;
      }
      if (auth.loggedIn) {
        return <Redirect to="/" />;
      }
    }

    if (!auth.isLocal) {
      // Disable for production
      window.location = auth.loginURL;
      auth.clearStorage();
      return <div className="loginScreen" />;
    }

    return (
      <div className="loginScreen">
        <div className="loginDialog">
          <h2>Login</h2>
          <form onSubmit={this.submitForm}>
            <div className="formField">
              <label htmlFor="email">Username: </label>
              <input
                name="email"
                data-testid="email"
                value={email}
                onChange={(ev) => this.setState({ email: ev.target.value })}
              />
            </div>
            <div className="formField">
              <label htmlFor="password">Password: </label>
              <input
                name="password"
                data-testid="password"
                type="password"
                value={password}
                onChange={(ev) => this.setState({ password: ev.target.value })}
              />
            </div>
            <div className="formField">
              <label htmlFor="site">Site ID: </label>
              <input
                name="site"
                value={site}
                onChange={(ev) => this.setState({ site: Number(ev.target.value) })}
              />
            </div>
            <div className="formField">
              <label htmlFor="token">Token: </label>
              <input
                name="token"
                value={token}
                onChange={(ev) => this.setState({ token: ev.target.value })}
              />
            </div>
            <div className="formField">
              <label htmlFor="server">Server: </label>
              <select
                name="server"
                data-testid="server"
                value={auth.server}
                onChange={(ev) => auth.setServer(ev.target.value)}
              >
                <option value="production">Production</option>
                <option value="demo">Demo</option>
                <option value="staging">Staging</option>
                <option value="local">Local</option>
              </select>
            </div>
            {
              // eslint-disable-next-line react/no-array-index-key
              auth.errors.map((error, i) => <div className="formError" key={i}>{String(error)}</div>)
            }
            <button type="submit">Login</button>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
