const dummy = document.createElement('div');

export function sanitizeString(str) {
  dummy.innerHTML = str;
  return dummy.innerText;
}

export function getBounds(el) {
  const bbox = el.getBoundingClientRect();

  const bounds = {
    top: bbox.top || bbox.y,
    left: bbox.left || bbox.x,
    x: bbox.x || bbox.left,
    y: bbox.y || bbox.top,
    width: bbox.width,
    height: bbox.height,
    w: bbox.width,
    h: bbox.height,
  };

  bounds.bottom = bounds.top + bounds.height;
  bounds.right = bounds.left + bounds.width;
  bounds.c = bounds.x + (bounds.w / 2);
  bounds.m = bounds.y + (bounds.h / 2);
  bounds.center = bounds.c;
  bounds.middle = bounds.m;

  return bounds;
}

function hasScroll(el) {
  const style = getComputedStyle(el);
  const types = ['auto', 'scroll'];
  const match = (t) => types.includes(t);

  if (match(style.overflow) || match(style.overflowY) || match(style.overflowX)) {
    return true;
  }

  return false;
}

export function scrollToRef(ref) {
  let scrollElement = ref;

  while (!hasScroll(scrollElement) && scrollElement.parentElement) {
    scrollElement = scrollElement.parentElement;
  }

  const outer = getBounds(scrollElement);
  const inner = getBounds(ref);

  const diff = inner.y - outer.y;

  scrollElement.scrollTop += diff;
}

export default {
  sanitizeString,
  scrollToRef,
  getBounds,
};
