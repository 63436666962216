import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Login from 'routes/login';
import Logout from 'routes/logout';
import Layout from 'layout';
import AuthModal from './AuthModal';
import ErrorModal from './ErrorModal';
import OAuth from './OAuth';
import OAuthComplete from './OAuthComplete';

@inject('router')
@observer
class Application extends Component {
  componentDidCatch() {
    const { router } = this.props;
    router.push('/');
    this.forceUpdate();
  }

  render = () => (
    <div data-testid="index">
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <Route path="/oauth" component={OAuth} />
        <Route path="/oauth_complete" component={OAuthComplete} />
        <Route component={Layout} />
      </Switch>

      <AuthModal />
      <ErrorModal />
    </div>
  )
}

export default Application;
