import React from 'react';
import {
  action,
  autorun,
  computed,
  observable,
  runInAction,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import Learnosity from 'services/learnosity';
import Modal from '../Modal';
import ExamItemTagSelector from './ExamItemTagSelector';
import './ExamItemEditor.scss';

@inject('assignments', 'curriculum')
@observer
class ExamItemEditor extends React.Component {
  @observable selectedModule = '';

  @observable selectedUnit = '';

  @observable initialized = false;

  constructor(props) {
    super(props);
    const { item } = props;

    this.learnosityContainer = React.createRef();

    if (item) {
      runInAction(() => {
        this.selectedModule = item.moduleKey || '';
        this.selectedUnit = item.unitKey || '';
      });
    }
  }

  componentDidMount() {
    const { exam } = this;
    this.disposers = [];
    // NB: node created outside virtual DOM because Learnosity will destroy/restore it.
    this.createLearnosityNode();
    this.disposers[0] = autorun(() => {
      if (this.selectedModule && !exam.learnosityEditRequest) {
        exam.createItem(this.selectedModule);
      }
    });
    this.disposers[1] = autorun(() => {
      if (exam.learnosityEditRequest) {
        this.initializeLearnosity();
      }
    });
  }

  componentWillUnmount() {
    this.disposers.forEach((dispose) => dispose());
    Learnosity.resetEdit();
  }

  @action.bound onSelectModule({ target: { value } }) {
    this.selectedModule = value;
    this.selectedUnit = '';
    this.setLearnosityTags();
  }

  @action.bound onSelectUnit({ target: { value } }) {
    this.selectedUnit = value;
    this.setLearnosityTags();
  }

  @action.bound onDismiss({ target }) {
    const { exam } = this;
    const { onDismiss } = this.props;
    // Prevent glitches from overlay click firing on Learnosity editor clicks
    if (target.className !== 'overlay') {
      this.resetTags();
      exam.learnosityEditRequest = '';
      Learnosity.resetEdit();
      onDismiss();
    }
  }

  onSave({ data }) {
    const {
      exam,
      selectedModule,
      selectedUnit,
    } = this;
    const {
      onDismiss,
    } = this.props;

    this.resetTags();
    Learnosity.resetEdit();
    exam.saveItem(selectedModule, selectedUnit, data)
      .then(() => {
        onDismiss();
      });
  }

  @computed get exam() {
    const { assignments: { fromRoute: assignment } } = this.props;
    return assignment?.exam || {};
  }

  @computed get isNew() {
    const { item } = this.props;
    return !item;
  }

  setLearnosityTags() {
    const { selectedModule, selectedUnit } = this;
    Learnosity.setItemTags(selectedModule, selectedUnit);
  }

  initializeLearnosity() {
    const selector = '#learnosity_edit_item';
    const { exam: { learnosityEditRequest } } = this;

    Learnosity.initializeEdit(learnosityEditRequest, selector, {
      onSaveSuccess: (data) => this.onSave(data),
      readyListener: () => setTimeout(() => this.setInitialized(), 1000),
    });
  }

  createLearnosityNode() {
    const { current } = this.learnosityContainer;
    const editorDiv = document.createElement('div');
    editorDiv.id = 'learnosity_edit_item';

    current.append(editorDiv);
  }

  @action resetTags() {
    this.selectedModule = '';
    this.selectedUnit = '';
  }

  @action setInitialized() {
    this.initialized = true;
  }

  render() {
    const {
      exam,
      isNew,
      initialized,
      selectedModule,
      selectedUnit,
      onSelectModule,
      onSelectUnit,
      onDismiss,
    } = this;
    const {
      visible,
    } = this.props;

    const moduleKeys = exam.course?.moduleKeys || [];

    return (
      <Modal
        className="learnosity-item-editor"
        title={isNew ? 'Create New Custom Question' : 'Edit Custom Question'}
        visible={visible}
        onDismiss={initialized ? onDismiss : undefined}
      >
        <form className="tags">
          <strong>Link to:</strong>
          <ExamItemTagSelector
            moduleKeys={moduleKeys}
            selectedModule={selectedModule}
            selectedUnit={selectedUnit}
            moduleEmptyLabel="Select Module"
            unitEmptyLabel="No Unit"
            onSelectModule={onSelectModule}
            onSelectUnit={onSelectUnit}
            requireModule
          />
        </form>
        { !selectedModule
          ? (
            <div className="empty-message">
              <p>Select a module to begin.</p>
            </div>
          )
          : null }
        <div
          id="learnosity-container"
          ref={this.learnosityContainer}
          className={selectedModule ? 'show' : 'hide'}
        />
      </Modal>
    );
  }
}

export default ExamItemEditor;
