import React from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import 'react-day-picker/lib/style.css';

const DATE_FORMAT = 'MM/DD/YYYY';

const readDate = (input) => {
  const date = new Date(input);
  if (!input) return '';
  return date;
};

const formatDate = (date, format = DATE_FORMAT) => (
  date && moment(date).format(format)
);

export default function DatePicker({
  placeholder = 'No Date Selected',
  value,
  disabled,
  onChange,
}) {
  const dayPickerProps = {
    modifiers: {
      past: { before: new Date() },
      highlighted: new Date(),
    },
  };

  if (disabled) {
    return (
      <div className="DayPickerInput">
        <input value={formatDate(value)} placeholder={placeholder} disabled />
      </div>
    );
  }

  return (
    <DayPickerInput
      format={DATE_FORMAT}
      formatDate={formatDate}
      parseDate={readDate}
      placeholder={placeholder}
      dayPickerProps={dayPickerProps}
      onDayChange={onChange}
      value={value || ''}
      inputProps={{ readOnly: true }}
      disabled={disabled}
    />
  );
}
