import React from 'react';
import { Switch, Route } from 'react-router';

import Onboard from './Onboard';
import DashboardCourses from './DashboardCourses';
import DashboardSearch from './DashboardSearch';

const Dashboard = () => (
  <Switch>
    <Route path="/get-started" component={Onboard} />
    <Route path="/courses" component={DashboardCourses} />
    <Route exact path="/dashboard/search" component={DashboardSearch} />
  </Switch>
);

export default Dashboard;
