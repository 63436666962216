import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from 'routes/error/404';
import Enrollment from './Enrollment';
import StudentList from './StudentList';
import StudentSingle from './StudentSingle';

import './style.scss';

const StudentLogons = () => <div>Student Logons</div>;

const Students = () => (
  <div className="studentsPage">
    <Switch>
      <Route path="*/list/:id(\d+)" component={StudentSingle} />
      <Route path="*/tracking/:id(\d+)" component={StudentSingle} />
      <Route path="*/list" component={StudentList} />
      <Route path="*/enroll" component={Enrollment} />
      <Route path="*/logons" component={StudentLogons} />
      <Route component={NotFound} />
    </Switch>
  </div>
);

export default Students;
