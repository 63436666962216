import React from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Accordion from '../Accordion';
import ExamItem from './ExamItem';
import './ExamItemList.scss';
import ExamItemTagSelector from './ExamItemTagSelector';

@inject('curriculum', 'learnosityItems')
@observer
class ExamItemList extends React.Component {
  @observable selectedModule = '';

  @observable selectedUnit = '';

  @action.bound onSelectModule(event) {
    const { onSelectModule = () => {} } = this.props;
    const { target: { value } } = event;
    this.selectedModule = value;
    this.selectedUnit = '';
    onSelectModule(event);
  }

  @action.bound onSelectUnit(event) {
    const { target: { value } } = event;
    this.selectedUnit = value;
  }

  @computed get itemsFiltered() {
    const { items = [] } = this.props;
    return items.filter((item) => this.moduleMatches(item) && this.unitMatches(item));
  }

  @computed get itemsByModule() {
    const { modules, itemsFiltered } = this;
    return modules
      .map((module) => ({
        module,
        items: itemsFiltered.filter((item) => item.moduleKey === module.key),
      }));
  }

  @computed get modules() {
    const { moduleKeys, curriculum: { modulesByKey } } = this.props;
    return moduleKeys
      .map((key) => (
        modulesByKey[key] || { key, title: key }
      ));
  }

  @computed get hideUnitsSelector() {
    const { itemsFiltered, selectedUnit } = this;
    return !selectedUnit && itemsFiltered.length < 1;
  }

  @computed get isEmptyFilter() {
    const { props: { items = [] }, itemsFiltered } = this;
    return itemsFiltered.length < 1 && items.length > 0;
  }

  previewLoaded = (reference) => {
    const { learnosityItems: { previewReferences } } = this.props;
    return previewReferences.includes(reference);
  }

  previewItem = (reference) => {
    const { learnosityItems } = this.props;
    learnosityItems.previewItem(reference);
  }

  moduleMatches(item) {
    return !this.selectedModule || this.selectedModule === item.moduleKey;
  }

  unitMatches(item) {
    return !this.selectedUnit || this.selectedUnit === item.unitKey;
  }

  render() {
    const {
      hideUnitsSelector,
      itemsByModule,
      isEmptyFilter,
      selectedModule,
      selectedUnit,
      previewLoaded,
      previewItem,
      onSelectModule,
      onSelectUnit,
    } = this;
    const {
      moduleKeys,
      requireModule,
      onClickAdd,
      onClickEdit,
      onClickRemove,
      visible = true,
    } = this.props;

    const showEmptyMessage = requireModule && !selectedModule;

    return (
      <section className={`item-list ${visible ? 'show' : 'hide'}`}>
        <form className="filters">
          <ExamItemTagSelector
            moduleKeys={moduleKeys}
            selectedModule={selectedModule}
            selectedUnit={selectedUnit}
            hideUnits={hideUnitsSelector}
            requireModule={requireModule}
            moduleEmptyLabel={requireModule ? 'Select Module' : 'All Modules'}
            onSelectModule={onSelectModule}
            onSelectUnit={onSelectUnit}
          />
        </form>
        { showEmptyMessage
          ? (
            <div className="empty-message">
              <p>Select a module to begin.</p>
            </div>
          )
          : null }
        { isEmptyFilter
          ? (
            <div className="empty-message">
              <p>No questions for current selection.</p>
            </div>
          )
          : itemsByModule.map(({ module, items }) => (
            <Accordion
              key={module.key}
              title={module.title}
              className={items.length < 1 || showEmptyMessage ? 'hide' : ''}
            >
              {items.map(({
                reference,
                stimulus,
                unit,
                type,
                inExam,
              }) => (
                <ExamItem
                  key={reference}
                  reference={reference}
                  stimulus={stimulus}
                  unit={unit}
                  type={type}
                  inExam={inExam}
                  previewLoaded={previewLoaded(reference)}
                  previewItem={previewItem}
                  onClickAdd={onClickAdd}
                  onClickEdit={onClickEdit}
                  onClickRemove={onClickRemove}
                />
              ))}
            </Accordion>
          )) }
      </section>
    );
  }
}

export default ExamItemList;
