import {
  observable, computed, action, autorun,
} from 'mobx';
import Teacher from 'models/Teacher';

class TeachersStore {
  @observable byId = observable.map()

  @observable invitations = []

  @observable loaded = false

  constructor(rootStore, api) {
    this.api = api;
    this.rootStore = rootStore;

    autorun(() => {
      const { router } = this.rootStore;
      const match = router && router.path.match(/dashboard\/(share|transfer)|settings\/site\/teachers|students\/list/);
      const courseDashboard = (router.path === '/courses' || router.path === '/get-started');

      if ((match || courseDashboard) && !this.loaded) {
        this.loadForCurrentSite();
      }
    });
  }

  @action loadForCurrentSite() {
    return this.api.get('teacher/profile/list')
      .then((result) => this.doneLoadingTeachers(result));
  }

  @action doneLoadingTeachers(json) {
    json.forEach((entry) => {
      this.addOrUpdate(entry);
    });

    this.loaded = true;
  }

  @computed get list() {
    return Array.from(this.byId.values());
  }

  @action add(input) {
    const teacher = new Teacher(input);
    this.byId.set(teacher.id, teacher);
    return teacher;
  }

  @action addOrUpdate(input) {
    let teacher;

    if (this.byId.has(input.id)) {
      teacher = this.byId.get(input.id);
      teacher.update(input);
    } else {
      teacher = this.add(input);
    }

    return teacher;
  }

  @action createOrAdd(params) {
    return this.api.post('teacher/admin_instructors', { faculty: params })
      .then((result) => this.add(result));
  }

  @action setRole(teacher, role) {
    const teacherId = typeof teacher === 'object' ? teacher.id : teacher;

    this.api.patch(
      `teacher/admin_instructors/${teacherId}`,
      {
        faculty_sites: {
          role,
        },
      },
    )
      .then(action('finishChangeRole', () => {
        this.byId.get(teacherId).role = role;
      }));
  }

  @action setOption(teacher, options) {
    const teacherId = typeof teacher === 'object' ? teacher.id : teacher;

    this.api.patch(
      `teacher/admin_instructors/${teacherId}`,
      {
        faculty_sites: {
          options,
        },
      },
    )
      .then(action('finishChangeOption', () => {
        this.byId.get(teacherId).options = options;
      }));
  }

  @action removeFromSite(teacher) {
    const teacherId = typeof teacher === 'object' ? teacher.id : teacher;

    this.api.delete(`teacher/admin_instructors/${teacherId}`)
      .then(action('finishRemoveTeacher', () => {
        this.byId.delete(teacherId);
      }));
  }
}

export default TeachersStore;
