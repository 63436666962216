import {
  action,
  computed,
  observable,
  runInAction,
} from 'mobx';
import SearchResult from '../models/SearchResult';
import Template from '../models/Template';
import { dedupe } from '../util/Array';

// set up catalog classes
class CatalogStore {
  // set fields for module editors
  @observable currentTemplate = null

  @observable myTemplate = null

  @observable myTemplateTitle = null

  @observable myModules = null

  @observable currentGroup = null

  @observable selectedModules = []

  @observable editingClass = false

  @observable executeSave = false

  @observable searchModules = []

  @observable searchResults = []

  @observable searchQuery = ''

  // load catalog templates
  constructor(rootStore, api) {
    this.api = api;
    this.rootStore = rootStore;
  }

  @computed get loaded() {
    const { curriculum: { templates } } = this.rootStore;
    return templates.length > 0;
  }

  // get health standard templates by specific template keys
  @computed get healthStandardTemplates() {
    const { curriculum: { templatesByKey } } = this.rootStore;
    return [
      templatesByKey.AP,
      templatesByKey.HS,
      templatesByKey.HC,
      templatesByKey.MA,
      templatesByKey.MO,
      templatesByKey.CN,
      templatesByKey.MD,
      templatesByKey.FR,
      templatesByKey.EM,
      templatesByKey.CC,
    ];
  }

  // get health cert templates
  @computed get healthCertificationTemplates() {
    const { curriculum: { templates } } = this.rootStore;
    return templates.filter((t) => t.state === 'CERT' || t.templateKey === 'EM' || t.templateKey === 'FR');
  }

  // get business standard templates by specific template keys
  @computed get businessStandardTemplates() {
    const { curriculum: { templatesByKey } } = this.rootStore;
    return [
      templatesByKey.DL,
      templatesByKey.BF,
      templatesByKey.GA,
      templatesByKey.CA365,
      templatesByKey.CA2021,
      templatesByKey.CA2019,
      templatesByKey.CA2016,
      templatesByKey.CA2013,
      templatesByKey.CA,
    ];
  }

  // get state templates
  @computed get stateTemplates() {
    const { curriculum: { stateTemplates } } = this.rootStore;
    return stateTemplates;
  }

  // get site, product and custom templates
  @computed get otherTemplates() {
    const list = [];
    const { auth, curriculum: { templates, templatesByKey } } = this.rootStore;

    // get templates assigned to site
    auth.site.templates.forEach((key) => {
      const t = templatesByKey[key];
      if (t) list.push(t);
    });

    const subjectMatch = (template, site) => (
      template.subject === site.skin)
      || (template.subject === 'business' && site.skin === 'bcit'
      );

    // match product template and filter out state templates
    templates.forEach((t) => {
      const prodMatch = subjectMatch(t, auth.site);
      if (!t.state && prodMatch) list.push(t);
    });

    list.push(this.customModuleTemplate);

    return list;
  }

  // get custom modules
  @computed get customModuleTemplate() {
    const { curriculum } = this.rootStore;

    return new Template(this.rootStore, {
      key: 'MY_CUSTOM',
      title: 'My Custom Modules',
      template_modules: curriculum.customModules.map(({ key }) => ({ module_key: key })),
    });
  }

  // load all templates
  @computed get allTemplates() {
    const { courses } = this.rootStore;

    return courses.allTemplates;
  }

  // load published templates
  @computed get publishedTemplates() {
    const { courses } = this.rootStore;

    return courses.publishedTemplates;
  }

  // set catalog menu groups
  @computed get groups() {
    const { auth } = this.rootStore;
    const matchState = (initials) => {
      switch (initials) {
        case 'FL':
          return 'Florida';
        case 'NC':
          return 'North Carolina';
        case 'OH':
          return 'Ohio';
        case 'SC':
          return 'South Carolina';
        case 'TN':
          return 'Tennessee';
        case 'TX':
          return 'Texas';
        default:
          return initials;
      }
    };

    return {
      health: {
        title: 'Health Science',
        heading: 'Health Science Templates',
        templates: this.healthStandardTemplates,
      },
      healthcert: {
        title: 'Health Certifications',
        heading: 'Health Certification Templates',
        templates: this.healthCertificationTemplates,
      },
      bit: {
        title: 'Business/Career Readiness',
        heading: 'Business/Career Readiness Templates',
        templates: this.businessStandardTemplates,
      },
      state: {
        title: `${matchState(auth.site.state)} Templates`,
        heading: 'State Specific Templates',
        templates: this.stateTemplates,
      },
      other: {
        title: 'Other Templates',
        heading: 'Other Templates',
        templates: this.otherTemplates,
      },
      district: {
        title: 'District Templates',
        templates: this.publishedTemplates,
      },
    };
  }

  // set template to selected group and template
  @action setCurrentTemplate(group, template) {
    this.currentGroup = group;
    this.currentTemplate = template;
    this.searchResults = null;
  }

  // reset selected modules in UI
  @action setSelectedModules(modules) {
    this.selectedModules = dedupe(modules);
  }

  // load groups for resource center catalog
  // note that empty template groups won't be rendered
  @computed get groupsForCurrentUserCatalog() {
    const { auth } = this.rootStore;
    if (auth.site.isHealth) {
      return ['health', 'healthcert', 'bit', 'district', 'state', 'other'];
    }
    return ['bit', 'health', 'district', 'state', 'other'];
  }

  // load groups for adding classes / editing assignments
  @computed get groupsForCurrentUserAssignments() {
    const { auth, curriculum } = this.rootStore;
    const baseTemplates = () => {
      if (auth.site.isHealth) {
        return ['health', 'healthcert', 'state'];
      }
      return ['bit', 'state'];
    };
    const specific = auth.site.templates && auth.site.templates.length > 0;
    const custom = curriculum.customModules && curriculum.customModules.length > 0;
    if (specific || custom) {
      return baseTemplates().concat('other');
    }
    return baseTemplates();
  }

  // list modules the teacher is allowed to use
  @computed get permittedModuleKeys() {
    // removes healthcert group because it duplicates the modules of the health group
    const groups = this.groupsForCurrentUserAssignments.filter((t) => t !== 'healthcert');
    const templates = groups.map((t) => this.groups[t].templates).flat();
    return templates.map((t) => (t && t.moduleKeys) || [])
      .reduce((map, obj) => {
        obj.forEach((mk) => {
          // eslint-disable-next-line no-param-reassign
          map[mk] = mk;
        });
        return map;
      }, {});
  }

  // Actions for module editors
  @action addModule(moduleKey) {
    this.selectedModules.push(moduleKey);
  }

  @action addMultipleModules(array) {
    const after = this.selectedModules.slice();
    array.forEach((newKey) => {
      if (!after.find((m) => m === newKey)) {
        after.push(newKey);
      }
    });
    this.selectedModules = after;
  }

  @action async moveModule(to, from) {
    const list = [...this.selectedModules];
    const assignment = list.splice(from, 1)[0]; // Remove from original spot
    list.splice(to <= from ? to : to - 1, 0, assignment); // Add to new spot
    this.selectedModules = dedupe(list);
  }

  @action removeModule(moduleKey) {
    this.selectedModules = this.selectedModules.filter((key) => key !== moduleKey);
  }

  @action removeMultipleModules(array) {
    this.selectedModules = this.selectedModules.filter((key) => !array.includes(key));
  }

  @computed get currentModulesAndGroups() {
    if (this.searchResults && !this.currentTemplate) {
      return this.searchModules.map((key) => this.getModule(key));
    }
    const templateKey = this.currentTemplate;
    const template = this.getTemplate(templateKey);
    return template?.modulesAndGroups;
  }

  @computed get currentTemplateTitle() {
    if (this.searchResults && !this.currentTemplate) {
      return 'Search Results';
    }
    const templateKey = this.currentTemplate;
    const template = this.getTemplate(templateKey);
    return template && template.title;
  }

  @action findTemplateForModule(moduleKey) {
    let template = null;
    Object.values(this.groups).forEach((g) => {
      const temp = g.templates.find((t) => t && t.moduleKeys.find((m) => m === moduleKey));
      if (temp && !template) {
        template = temp;
      }
      return temp;
    });
    return template;
  }

  @action async search(terms) {
    this.searchQuery = terms;
    const result = await this.api.get(`teacher/search?curriculum=${terms}`);

    // Show no results instead of popping an error
    if (result.error) {
      this.updateSearchResults([], []);
      return;
    }
    const mods = result.hits.hits.map((r) => {
      // eslint-disable-next-line no-underscore-dangle
      const singleResource = r._source.teacher_resource;
      // eslint-disable-next-line no-underscore-dangle
      const { resources } = r._source;
      if (singleResource) {
        return singleResource.module_key;
      }
      if (resources && resources[0] && resources[0].module_key) {
        return resources[0].module_key;
      }
      return '';
    });
    this.updateSearchResults(result.hits.hits, mods);
  }

  @action updateSearchResults(results, mods) {
    this.searchResults = results.map((input) => new SearchResult(this.rootStore, input));
    this.searchModules = mods.filter((m) => this.permittedModuleKeys[m]);
  }

  getTemplate(key) {
    const { curriculum: { templatesByKey } } = this.rootStore;
    if (key === 'MY_CUSTOM') {
      return this.customModuleTemplate;
    }
    const template = templatesByKey[key]
      || this.publishedTemplates.find((t) => t.templateKey === key);

    // if this is a district template, load the assignments
    if (template?.sharedTemplate) {
      runInAction(() => {
        const { assignments } = this.rootStore;
        assignments.loadByCourse(template.id);
      });
    }
    return template;
  }

  getUnit(unitKey) {
    const { curriculum: { unitsByKey } } = this.rootStore;
    return unitsByKey[unitKey];
  }

  getModule(moduleKey) {
    const { curriculum: { modulesByKey } } = this.rootStore;
    return modulesByKey[moduleKey];
  }

  @action loadAnswerKeyRequest(moduleKey, unitKey, showAnswers = true, seed = 'STD', extraParams = {}) {
    const { location: { hostname: host } } = window;
    const uri = 'teacher/catalog_answer_keys';
    const sessionId = `ANSWER_KEY_${unitKey || moduleKey}_${seed}`;
    const params = {
      // if unitKey is set, moduleKey must be unset, or else we will get test instead of quiz
      module_key: !unitKey ? moduleKey : undefined,
      unit_key: unitKey,
      session_id: sessionId,
      show_answers: showAnswers,
      host,
      ...extraParams,
    };
    const options = { version: 'v2', quiet: true };
    return this.api.get(uri, params, options)
      .then((response) => response.learnosity_request);
  }
}

export default CatalogStore;
