import React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';

@inject('auth', 'courses', 'router', 'assignments') @observer
class DeleteTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  handleShowModal = (e) => {
    e.preventDefault();
    this.setState({ modalVisible: true });
  }

  handleHideModal = () => {
    this.setState({ modalVisible: false });
  }

  archiveTemplate = async () => {
    const { courses, router } = this.props;
    const course = courses.fromRoute;
    await courses.archive(course);
    router.push('/');
  }

  render() {
    const { auth, courses: { fromRoute: course } } = this.props;
    const { modalVisible } = this.state;
    const isOwner = course.facultyId === auth.user.id;

    return (
      <div>
        <h1>Delete Template</h1>
        <p>Delete currently selected template.</p>
        {isOwner
          && <button type="button" onClick={this.handleShowModal}>Delete</button>}
        <Modal
          title="Delete Template"
          visible={modalVisible}
          onDismiss={this.handleHideModal}
        >
          <div>
            <h1>Delete Template</h1>
            <p>
              <strong>Warning:</strong>
              {' '}
              Deleted templates are inaccessible and will not appear in your archive.
            </p>
            <button type="button" className="lgButton" onClick={this.archiveTemplate}>
              Delete Template
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DeleteTemplate;
