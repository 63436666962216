/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import { action, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import img from 'data/img/superwoman.png';
import messageUs from 'data/img/message-us-button.png';

import './style.scss';

@inject('auth') @observer
class NotValidated extends Component {
  @computed get isGmailTrial() {
    const { auth: { user } } = this.props;
    return user.email.match(/@(gmail|yahoo).com$/);
  }

  @computed get message() {
    if (this.isGmailTrial) {
      return (
        <div>
          <p>
            In order to protect against cheating, we ask that you use
            a school issued teacher email address.
          </p>

          <p>
            Send us a quick message to get your email address updated!
          </p>
        </div>
      );
    }

    return (
      <div>
        <p>
          Sneaky students have been known to create trials to access answer keys.
        </p>

        <p>
          Send us a quick message to validate that you&rsquo;re a teacher
          so we can give you full access.
        </p>
      </div>
    );
  }

  @computed get prefillMessage() {
    if (this.isGmailTrial) {
      return 'How can I update my email? I\'m a real teacher! <Press ENTER>';
    }

    return 'Greetings AES. I am not a sneaky student. Can you please validate my trial site? <Press ENTER>';
  }

  @action.bound startIntercom() {
    window.Intercom('showNewMessage', this.prefillMessage);
  }

  render() {
    return (
      <div className="notValidated">
        <img className="superwoman" src={img} alt="" />
        <div>
          <h1>Hey There!</h1>
          {this.message}
          <img
            className="message-us"
            src={messageUs}
            alt="Message us chat bubble"
            onClick={this.startIntercom}
          />
        </div>
      </div>
    );
  }
}

export default NotValidated;
