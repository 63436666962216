import React from 'react';
import {
  action, computed, observable, runInAction,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';
import FormErrors from 'widgets/form/FormErrors';
import TextBox from 'widgets/form/TextBox';
import Dropdown from 'widgets/form/Dropdown';
import { NotBlank } from 'util/FormValidation';

@inject('auth', 'courses', 'students', 'teachers', 'router', 'WebAPI') @observer
class StudentActions extends React.Component {
  @observable newTeacherId = null;

  @observable newCourseId = null;

  @observable error = null;

  @observable moveError = null;

  @observable studentModal = false

  @observable passwordModal = false

  @observable removeModal = false

  @observable moveModal = false

  @observable errors = null

  @observable passwordErrors = null

  @observable passwordReset = false

  @observable newPassword = ''

  @observable studentData = {
    firstName: '',
    lastName: '',
    email: '',
  }

  @action async handlePasswordReset(e) {
    e.preventDefault();
    const { course, newPassword } = this;
    const { student } = this.props;

    if (newPassword === '') {
      this.passwordErrors = 'Password cannot be blank';
    } else if (newPassword.length < 6) {
      this.passwordErrors = 'Password must be at least 6 characters';
    } else {
      await course.changeStudentPassword(student, newPassword).then(() => {
        this.finishPassword();
      });
    }
  }

  @action handleUpdate({ target: { name, value } }) {
    const { studentData } = this;
    studentData[name] = value;
  }

  // handle edit student
  async handleStudentEdit(e) {
    e.preventDefault();
    const {
      course,
      studentData: {
        email,
        firstName,
        lastName,
      },
    } = this;
    const { student } = this.props;
    const params = {
      email: email.trim(),
      first_name: firstName,
      last_name: lastName,
    };
    const edit = await student.editStudent(params, course.id, student.id);

    if (edit !== undefined) {
      this.setErrors(edit);
    } else {
      this.onSuccess();
    }
  }

  @action setTeacherId = (id) => { this.newTeacherId = +id; }

  @action setCourseId = (id) => { this.newCourseId = +id; }

  @action setError = (val) => { this.error = val; }

  @action setMoveError = (value) => { this.moveError = value; }

  @action setErrors = (errors) => {
    this.errors = [errors];
  }

  @action onSuccess = () => {
    this.hideModal();
  }

  @computed get course() {
    const { courses: { fromRoute: course } } = this.props;
    return course;
  }

  @computed get teacher() {
    const { teachers, auth } = this.props;
    return teachers.byId.get(this.newTeacherId || auth.user.id);
  }

  @computed get teachers() {
    const { teachers } = this.props;
    return teachers.list;
  }

  @computed get otherCourses() {
    const { teacher, course } = this;

    if (!teacher) return [];

    return teacher.courses.filter((c) => c.id !== course.id);
  }

  @computed get nonTemplateCourses() {
    const { courses, router: { courseId } } = this.props;
    const { teacher } = this;

    if (!teacher) return [];

    const courseList = teacher.courses.filter((c) => c !== undefined && c.id !== courseId);
    const allCourses = courses.list;

    // map through data and add template value
    const shortCourse = courseList.map((c) => {
      const combinedData = [c.id, c.title];
      allCourses.forEach((d) => {
        if (d.id === c.id) {
          combinedData.push(d.template);
        }
      });
      return combinedData;
    }).filter((course) => course[2] !== true);

    return shortCourse;
  }

  @action hideStudentModal = () => {
    const { studentData } = this;
    const { student, onClick: Close } = this.props;

    this.studentModal = false;
    studentData.firstName = student.firstName;
    studentData.lastName = student.lastName;
    studentData.email = student.email;
    this.errors = null;
    if (Close) Close();
  }

  // handle password modal
  @action showPasswordModal = () => {
    this.passwordModal = true;
    this.newPassword = '';
    this.passwordReset = false;
  }

  // handle remove student modal
  @action showRemoveModal = () => {
    this.removeModal = true;
  }

  // handle move student modal
  @action showMoveModal = () => {
    const { auth } = this.props;
    this.newTeacherId = auth.user.id;
    this.moveModal = true;
  }

  // handle general modal hide
  @action hideModal = () => {
    const { onClick: Close } = this.props;
    this.studentModal = false;
    this.passwordModal = false;
    this.removeModal = false;
    this.moveModal = false;
    this.errors = null;
    this.passwordErrors = null;
    if (Close) Close();
  }

  // handle edit student modal
  @action showStudentModal = () => {
    const { studentData } = this;
    const { student } = this.props;
    this.studentModal = true;
    studentData.firstName = student.firstName;
    studentData.lastName = student.lastName;
    studentData.email = student.email;
  }

  @action.bound async moveStudent(e) {
    e.preventDefault();
    const {
      router, students, student, onClick,
    } = this.props;
    const { course } = this;
    this.setMoveError(null);

    try {
      await this.course.moveStudent(student, +this.newCourseId);
      students.move(student, course, +this.newCourseId);
      if (onClick) onClick();
      router.push(`/courses/${course.id}/students/list`);
    } catch (error) {
      this.setMoveError(error);
    }
  }

  @action updatePassword(e) {
    this.newPassword = e;
  }

  @action finishPassword() {
    this.passwordReset = true;
  }

  @action removeStudent() {
    const { router, student, onClick: Close } = this.props;
    const { course } = this;
    this.error = null;

    course.removeStudent(student)
      .then(() => runInAction(() => {
        if (Close) Close();
        router.push(`/courses/${course.id}/students/list`);
      }));
  }

  render() {
    const {
      course, studentModal,
      passwordModal,
      removeModal,
      moveModal,
      studentData,
      errors,
      passwordErrors,
      newPassword,
      passwordReset,
    } = this;

    const { type, student } = this.props;

    return (
      <>
        {!course.isRostered
          && type === 'buttons' && (
            <div className="actions">
              <button className="grayButton" type="button" onClick={this.showStudentModal}>Edit Student</button>
              <button className="grayButton" type="button" onClick={this.showPasswordModal}>Reset Password</button>
              <button className="grayButton" type="button" onClick={this.showRemoveModal}>Remove from Class</button>
              <button className="grayButton" type="button" onClick={this.showMoveModal}>Move to Another Class</button>
            </div>
        )}
        {!course.isRostered && type === 'list' && (
          <ul>
            <li><button type="button" onClick={this.showStudentModal}>Edit Student</button></li>
            <li><button type="button" onClick={this.showPasswordModal}>Reset Password</button></li>
            <li><button type="button" onClick={this.showRemoveModal}>Remove from Class</button></li>
            <li><button type="button" onClick={this.showMoveModal}>Move to Another Class</button></li>
          </ul>
        )}

        <Modal
          visible={studentModal}
          title="Edit Student Details"
          onDismiss={this.hideStudentModal}
        >
          <form onSubmit={(e) => this.handleStudentEdit(e)}>
            <div className="formField textbox">
              <label htmlFor="firstName">
                <span className="labelText">First Name:</span>
                <input
                  name="firstName"
                  value={studentData.firstName}
                  onChange={(e) => this.handleUpdate(e)}
                />
              </label>
            </div>

            <div className="formField textbox">
              <label htmlFor="lastName">
                <span className="labelText">Last Name:</span>
                <input
                  name="lastName"
                  value={studentData.lastName}
                  onChange={(e) => this.handleUpdate(e)}
                />
              </label>
            </div>
            <div className="formField textbox">
              <label htmlFor="email">
                <span className="labelText">Email:</span>
                <input
                  name="email"
                  value={studentData.email}
                  onChange={(e) => this.handleUpdate(e)}
                />
              </label>
            </div>
            {
              errors && errors.map((error) => (
                <React.Fragment>
                  {error.first_name && error.first_name[0].error === 'blank' && <p className="errorText">First name cannot be blank.</p>}
                  {error.last_name && error.last_name[0].error === 'blank' && <p className="errorText">Last name cannot be blank.</p>}
                  {error.email && error.email[0].error === 'blank' && <p className="errorText">Email cannot be blank.</p>}
                  {error.email && error.email[0].error === 'taken' && <p className="errorText">Email already in use by another student. Please pick new email.</p>}
                </React.Fragment>
              ))
            }
            <button type="submit">
              Save
            </button>
            <button
              type="button"
              className="secondary"
              onClick={this.hideStudentModal}
            >
              Cancel
            </button>

          </form>
        </Modal>

        <Modal
          visible={passwordModal}
          title="Reset Student Password"
          onDismiss={this.hideModal}
        >
          {!passwordReset
            && (
              <form
                className="editClassForm"
                onSubmit={(e) => this.handlePasswordReset(e)}
              >
                <p>
                  Enter a new password in the box below to manually change
                  the student&rsquo;s password.
                </p>

                <div className="formContent password">
                  <TextBox
                    // Using "password" in the field name causes an implicit password type
                    name="supersecret"
                    inline
                    value={newPassword}
                    onChange={(value) => this.updatePassword(value)}
                  />
                  {passwordErrors && <p className="error">{passwordErrors}</p>}
                </div>
                <div className="buttonSpacing">
                  <button type="submit">Change Password</button>
                  <button
                    type="button"
                    className="secondary"
                    onClick={this.hideModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            )}
          {passwordReset
            && (
              <div>
                <p>
                  Done!
                  {' '}
                  {student.name}
                  {' '}
                  can now log in with the password &ldquo;
                  {newPassword}
                  &rdquo;.
                </p>

                <button type="button" onClick={this.hideModal}>Close</button>
              </div>
            )}
        </Modal>

        <Modal
          visible={removeModal}
          title="Remove Student"
          onDismiss={this.hideModal}
        >
          <p>
            You are about to remove this student from your class.
            The student
            {' '}
            <b>will not</b>
            {' '}
            be deleted and will be able to continue
            any other classes they may have. You may add them back to your class later
            with the student search feature.
          </p>

          <button
            type="button"
            onClick={() => this.removeStudent(student)}
          >
            Remove Student
          </button>
          <button
            type="button"
            className="secondary"
            onClick={this.hideModal}
          >
            Cancel
          </button>
        </Modal>

        <Modal
          visible={moveModal}
          title="Move Student"
          onDismiss={this.hideModal}
        >
          <form onSubmit={(e) => this.moveStudent(e)}>
            <p>
              You are about to move a student to another class. Any work done in this
              class will be transferred to the new class where applicable.
            </p>

            <div className="moveStudent">
              <div className="buttonSpacing">
                <Dropdown
                  name="newteacher"
                  label="Teacher:"
                  options={this.teachers.map((c) => [c.id, c.name])}
                  value={this.newTeacherId}
                  onChange={this.setTeacherId}
                  validate={[NotBlank]}
                  noEmpty
                />
                {this.otherCourses.length === 0
                  && <p>No courses available to move to! Try creating another one first.</p>}
              </div>
              <div className="buttonSpacing">
                {this.otherCourses.length !== 0
                  && (
                    <Dropdown
                      name="newcourse"
                      label="Class:"
                      value={this.newCourseId}
                      onChange={this.setCourseId}
                      options={this.nonTemplateCourses.map((c) => [c[0], c[1]])}
                      validate={[NotBlank]}
                    />
                  )}
              </div>

            </div>

            {this.newCourseId && this.moveError
              && <p className="error">{this.moveError.errors[0]}</p>}

            <FormErrors />

            <div className="buttonSpacing">
              <button type="submit">Move Student</button>
              <button type="button" className="secondary" onClick={this.hideModal}>
                Cancel
              </button>
            </div>
          </form>
        </Modal>

      </>
    );
  }
}

export default StudentActions;
