import React from 'react';
import TextBox from 'widgets/form/TextBox';
import Dropdown from 'widgets/form/Dropdown';
import Modal from 'widgets/Modal';

const TeacherInvite = ({
  values: {
    firstName,
    lastName,
    email,
    title,
    school,
    reason,
    otherReason,
  },
  visible,
  onDismiss,
  handleInputChange,
  addTeacher,
  teacherExists,
  validationErrors,
}) => (
  <Modal
    visible={visible}
    title="Add a Teacher"
    onDismiss={onDismiss}
    className="inviteTeacher"
  >
    <form>
      <p>
        Enter the new teacher&rsquo;s information below to
        invite them to join this site.
      </p>

      <TextBox
        name="first_name"
        label="First Name:"
        value={firstName}
        leftLabel
        onChange={(e) => handleInputChange('firstName', e)}
        style={{ width: 350 }}
      />
      <TextBox
        name="last_name"
        label="Last Name:"
        value={lastName}
        leftLabel
        onChange={(e) => handleInputChange('lastName', e)}
        style={{ width: 350 }}
      />
      <TextBox
        name="email"
        label="Email:"
        value={email}
        leftLabel
        onChange={(e) => handleInputChange('email', e)}
        style={{ width: 350 }}
      />
      <TextBox
        name="title"
        label="Title:"
        value={title}
        leftLabel
        onChange={(e) => handleInputChange('title', e)}
        style={{ width: 350 }}
      />
      <TextBox
        name="school"
        label="School / Building:"
        value={school}
        leftLabel
        onChange={(e) => {
          handleInputChange('school', e);
        }}
        style={{ width: 350 }}
      />
      <Dropdown
        name="reason"
        label="Reason:"
        onChange={(e) => handleInputChange('reason', e)}
        style={{ width: 367 }}
        value={reason}
        options={{
          substitute: 'Substitute Teacher',
          aide: 'Aide / Paraprofessional',
          replacement: "Current Teacher's Replacement",
          new: 'New Teacher',
          other: 'Other',
        }}
      />
      {reason === 'other'
        && (
        <TextBox
          name="otherReason"
          label="Enter Reason:"
          value={otherReason}
          leftLabel
          onChange={(e) => {
            handleInputChange('otherReason', e);
          }}
          style={{ width: 350 }}
        />
        )}
      <div>
        <button type="submit" onClick={addTeacher}>Add Teacher</button>
        <button type="button" onClick={onDismiss} className="secondary">Cancel</button>
      </div>
    </form>
    <p className="error">
      {validationErrors && validationErrors.length > 0
        && validationErrors.map((e) => (
          <span>
            {e}
            .&nbsp;
          </span>
        ))}
      { teacherExists
        && <span>This teacher already exists. </span>}
    </p>
  </Modal>
);

export default TeacherInvite;
