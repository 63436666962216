import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';
import ModuleIcon from 'widgets/assignment/ModuleIcon';
import ModuleRow from './ModuleRow';
import ModuleGroupRow from './ModuleGroupRow';

@inject('auth', 'catalog', 'curriculum') @observer
class AssignmentEditModules extends Component {
  constructor(props) {
    super(props);
    this.state = { modulePopup: null };
  }

  showPopup(modulePopup) {
    this.setState({ modulePopup });
  }

  render() {
    const {
      auth,
      catalog,
      curriculum,
      selectedModules,
      addModule,
      addMultipleModules,
      createClass,
      createClassFromTemplate,
      removeModule,
      removeMultipleModules,
    } = this.props;
    const { modulePopup } = this.state;

    if (!catalog.loaded) {
      return <div />;
    }

    const modulesAndGroups = catalog.currentModulesAndGroups;
    const template = (catalog && catalog.currentTemplate)
      ? catalog.getTemplate(catalog.currentTemplate)
      : {};

    return (
      <div className="catalogTemplates curriculumMain assignEditModules">
        <h1>{catalog.currentTemplateTitle || catalog.myTemplateTitle}</h1>
        {catalog.currentTemplate && (
        <div>
          <p style={{ marginTop: 0 }}>
            Review the list of modules below. Click Add for each module
            <br />
            you would like to assign to your students.
          </p>
          <div className="downloads">
            {template && template.resources && template.resources.map((r) => (
              <p key={r.id}>
                <a target="new" href={`${auth.mediaURL(r.url)}`}>
                  <button type="button" style={{ width: 300 }}>
                    {`Download ${r.title}`}
                  </button>
                </a>
              </p>
            ))}
          </div>
          {catalog.editingClass
            && (
            <button
              type="button"
              className="lightButton"
              onClick={() => addMultipleModules(template.moduleKeys)}
              style={{ marginTop: 8, marginBottom: 16, width: 300 }}
            >
              Add all Modules for this Template
            </button>
            )}
          {!catalog.editingClass
            && (
            <button
              type="button"
              className="lightButton"
              onClick={() => createClass(template.moduleKeys)}
              style={{ marginTop: 8, marginBottom: 16, width: 300 }}
            >
              Create With All Modules
            </button>
            )}
        </div>
        )}
        {!catalog.currentTemplate && catalog.myTemplate
          && (
          <div>
            <p style={{ marginTop: 0 }}>
              Review the list of modules below. Click Add for each module
              <br />
              you would like to assign to your students.
            </p>
            <button
              type="button"
              className="lightButton"
              onClick={() => createClassFromTemplate(catalog.myModules)}
              style={{ marginTop: 8, marginBottom: 16, minWidth: 270 }}
            >
              Create Class from Template
            </button>
            <table>
              <tbody>
                {catalog.myModules !== null && catalog.myModules.map((moduleKey) => (
                  <ModuleRow
                    key={moduleKey}
                    module={curriculum.modulesByKey[moduleKey]}
                    selectedModules={selectedModules}
                    showPopup={(module) => this.showPopup(module)}
                    addModule={addModule}
                    removeModule={removeModule}
                  />
                ))}
              </tbody>
            </table>
          </div>
          )}
        <table>
          <tbody>
            {modulesAndGroups && modulesAndGroups.map((item) => {
              if (item.type === 'ModuleGroup') {
                return (
                  <ModuleGroupRow
                    key={item.key}
                    moduleGroup={item}
                    selectedModules={selectedModules}
                    showPopup={(module) => this.showPopup(module)}
                    addModule={addModule}
                    addMultipleModules={addMultipleModules}
                    removeModule={removeModule}
                    removeMultipleModules={removeMultipleModules}
                  />
                );
              }
              // item.type === 'Module'
              return (
                <ModuleRow
                  key={item.key}
                  module={item}
                  selectedModules={selectedModules}
                  showPopup={(module) => this.showPopup(module)}
                  addModule={addModule}
                  removeModule={removeModule}
                />
              );
            })}
          </tbody>
        </table>
        {modulePopup && (
          <Modal
            id="module-details-popup-new"
            title="Module Details"
            visible={modulePopup}
            onDismiss={() => this.setState({ modulePopup: null })}
          >
            <div>
              <div className="row">
                <div className="left">
                  <ModuleIcon title={modulePopup.title} image={modulePopup.imageUrl} />
                </div>
                <h1>{modulePopup.title}</h1>
                {modulePopup.minHours === modulePopup.hours
                  && <span>{modulePopup.hours}</span>}
                {modulePopup.minHours !== modulePopup.hours
                  && (
                  <span>
                    {modulePopup.minHours}
                    {' '}
                    to
                    {' '}
                    {modulePopup.hours}
                  </span>
                  )}
                <span>
                  {' '}
                  Curriculum Hour
                  {(modulePopup.hours !== 1) ? 's' : ''}
                </span>
              </div>
              <div className="details">
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={{ __html: modulePopup.description }} />
                {modulePopup.units.map((unit) => (
                  <div>
                    <h2>{unit.title}</h2>
                    <p>{unit.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default AssignmentEditModules;
