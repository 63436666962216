import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import CatalogWelcome from './CatalogWelcome';
import CatalogGroup from './CatalogGroup';
import CatalogTemplate from './CatalogTemplate';
import CatalogModule from './CatalogModule';
import CatalogAnswerKey from './CatalogAnswerKey';
import CatalogSearch from './CatalogSearch';
import CatalogSearchAdvanced from './CatalogSearchAdvanced';
import CatalogModuleGroup from './CatalogModuleGroup';

const Catalog = () => (
  <div className="catalogPage">
    <Switch>
      <Redirect exact from="/catalog" to="/catalog/welcome" />
      <Route exact path="/catalog/welcome" component={CatalogWelcome} />
      <Route exact path="/catalog/search" component={CatalogSearch} />
      <Route exact path="/catalog/search/advanced" component={CatalogSearchAdvanced} />
      <Route exact path="/catalog/:group(\w+)" component={CatalogGroup} />
      <Route exact path="/catalog/:group(\w+)/:templateKey([\w-]+)/group/:moduleGroupKey([\w-]+)" component={CatalogModuleGroup} />
      <Route exact path="/catalog/:group(\w+)/:templateKey([\w-]+)/group/:moduleGroupKey([\w-]+)/:moduleKey([\w-]+)" component={CatalogModule} />
      <Route exact path="/catalog/:group(\w+)/:templateKey([\w-]+)/group/:moduleGroupKey([\w-]+)/:moduleKey([\w-]+)/answer-key" component={CatalogAnswerKey} />
      <Route exact path="/catalog/:group(\w+)/:templateKey([\w-]+)/group/:moduleGroupKey([\w-]+)/:moduleKey([\w-]+)/answer-key/:unitKey([\w-]+)" component={CatalogAnswerKey} />
      <Route exact path="/catalog/:group(\w+)/:templateKey([\w-]+)" component={CatalogTemplate} />
      <Route exact path="/catalog/:group(\w+)/:templateKey([\w-]+)/:moduleKey([\w-]+)" component={CatalogModule} />
      <Route exact path="/catalog/:group(\w+)/:templateKey([\w-]+)/:moduleKey([\w-]+)/answer-key" component={CatalogAnswerKey} />
      <Route exact path="/catalog/:group(\w+)/:templateKey([\w-]+)/:moduleKey([\w-]+)/answer-key-advanced" component={CatalogAnswerKey} />
      <Route exact path="/catalog/:group(\w+)/:templateKey([\w-]+)/:moduleKey([\w-]+)/answer-key/:unitKey([\w-]+)" component={CatalogAnswerKey} />

    </Switch>
  </div>
);

export default Catalog;
