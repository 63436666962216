import React from 'react';
import { inject, observer } from 'mobx-react';

const MigrationWarning = ({ auth }) => {
  const { migrationURL } = auth;

  return (
    <div className="migrate-warning-banner">
      <div className="migrate-warning-content">
        <i className="material-icons">warning</i>
        <p>
          <strong>
            All AES users need to be migrated to the iCEV
            {' '}
            platform before the new school year starts.
            {' '}
            We strongly encourage you to complete your course migration to the iCEV
            {' '}
            platform if you have not done so already.
          </strong>
        </p>
        <div className="migrate-warning-links">
          <a
            href="https://www.icevonline.com/aes-integration"
            target="_blank"
            rel="noreferrer noopener"
          >
            Learn more
          </a>
          <a
            href={migrationURL}
            rel="noreferrer noopener"
            className="button"
          >
            Migrate my Courses
          </a>
        </div>
      </div>

    </div>
  );
};

export default inject('auth')(observer(MigrationWarning));
