import React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import SaveModal from './SaveModal';
import './style.scss';
import TemplateModules from '../../home/TemplateModules';

@inject('auth', 'uiState', 'courses', 'router') @observer
class CreateFromTemplate extends React.Component {
  @observable showDialog = false;

  @observable showModal = false;

  @observable activeCourse = {};

  constructor(props) {
    super(props);
    this.state = {
      showCreateClass: false,
      error: null,
      templateId: null,
    };
  }

  // Will hide leftNav on Component Mount
  componentDidMount() {
    const leftNav = document.getElementsByClassName('leftNav')[0];

    if (leftNav) { leftNav.style.display = 'none'; }
  }

  // Will unhide leftNav on Component Unmount
  componentWillUnmount() {
    const leftNav = document.getElementsByClassName('leftNav')[0];

    if (leftNav) { leftNav.style.display = ''; }
  }

  @action saveNewClass = (courseTitle) => {
    const { courses, router } = this.props;
    const { templateId } = this.state;

    if (!courseTitle || courseTitle.length < 1) {
      this.setState({ error: 'Your class must have a name' });
      return;
    }

    this.setState({ error: null });

    courses.create(courseTitle, null, templateId, false)
      .then((result) => {
        router.push(`/courses/${result.id}`);
      });
  }

  // Will allow user to go back to previous page
  goBack = () => {
    const { router: { history } } = this.props;

    history.goBack();
  }

  createClassFromTemplate = (template) => {
    const { id } = template;
    this.setState({ templateId: id, showCreateClass: true });
  }

  @action showCourseModules(course) {
    const { courses } = this.props;
    const localCourse = courses.list.find((c) => c.id === course.id);

    if (localCourse) {
      this.activeCourse = localCourse;
    } else {
      this.activeCourse = course;
    }
    this.showModal = true;
  }

  @action.bound closeCourseModules() {
    this.showModal = false;
    this.goBack();
  }

  hideCreateClass() {
    this.setState({ showCreateClass: false });
  }

  render() {
    const { onDeclineCreateFromTemplate, courses: { publishedTemplates } } = this.props;
    const { error, showCreateClass } = this.state;

    const callback = this.createClassFromTemplate;

    return (
      <div className="createFromTemplate">
        <button type="button" className="button" onClick={this.goBack}>&larr; Back to Previous Screen</button>
        <h1>Create New Class</h1>
        <h2>Standard District Template</h2>
        <p>
          Your district has created predefined templates
          for you to use. You can preview the list of modules
          for each course or create a new class based on that template.
        </p>
        <table>
          <thead>
            <tr>
              <th>Template</th>
              <th>No. of Modules</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {publishedTemplates
              ? publishedTemplates.map((t) => (
                <tr key={t.id.toString()}>
                  <td className="template-title">
                    <Link
                      to={`new/${t.id}`}
                      onClick={() => this.showCourseModules(t)}
                    >
                      {t.title}
                    </Link>
                  </td>
                  <td className="count">{t.assignmentCount}</td>
                  <td>
                    <button
                      type="button"
                      className="createClass lightButton"
                      onClick={() => this.createClassFromTemplate(t)}
                    >
                      Create
                    </button>
                  </td>
                </tr>
              ))
              : (
                <tr>
                  <td>
                    Couldn&apos;t find any templates
                  </td>
                </tr>
              )}
          </tbody>
        </table>

        <h2>Want to create a Custom Class?</h2>
        <p>
          Don&apos;t want to use a template? Click
          the button below to start creating a custom class.
        </p>
        <button
          type="button"
          className="secondary"
          onClick={onDeclineCreateFromTemplate}
        >
          Start a Custom Class
        </button>

        <SaveModal
          type="Class"
          error={error}
          visible={showCreateClass}
          onSave={(title) => this.saveNewClass(title)}
          onDismiss={() => this.hideCreateClass()}
        />

        <TemplateModules
          closeModal={this.closeCourseModules}
          style={{ height: 600, width: 640 }}
          selectedCourse={this.activeCourse}
          visible={this.showModal}
          createClass={callback}
          noRedirect
        />

      </div>
    );
  }
}

export default CreateFromTemplate;
