import React, { Component } from 'react';
import { computed, when } from 'mobx';
import { inject, observer } from 'mobx-react';
import { formatDelta } from 'util/String';
import Learnosity from 'services/learnosity';
import { Link } from 'react-router-dom';
import 'widgets/grade/LearnosityReport.scss';

@inject('courses', 'grades', 'students')
@observer
class PrePostDetails extends Component {
  componentDidMount() {
    when(
      () => this.learnosityRequest,
      () => Learnosity.initializeReport(this.learnosityRequest),
    );
    when(
      () => this.postest && this.pretest,
      () => this.loadDetails(),
    );
  }

  @computed get studentCourseGrade() {
    const { courses, students } = this.props;
    const { fromRoute: course } = courses;
    const { fromRoute: student } = students;
    return student?.gradesByCourse.get(course.id);
  }

  @computed get assignment() {
    const { courses, match } = this.props;
    const { fromRoute: course } = courses;
    const { params: { assignmentId } } = match;
    return course.assignmentsById[assignmentId];
  }

  @computed get pretest() {
    const { assignment, studentCourseGrade } = this;
    return assignment && studentCourseGrade && studentCourseGrade.workByUnit[`PREMT_${assignment.moduleKey}`];
  }

  @computed get postest() {
    const { assignment, studentCourseGrade } = this;
    return assignment && studentCourseGrade && studentCourseGrade.workByUnit[`MT_${assignment.moduleKey}`];
  }

  @computed get learnosityRequest() {
    return this.postest?.learnosityRequest || '';
  }

  @computed get pretestSessionId() {
    return this.pretest?.mostRecentAttempt?.id;
  }

  @computed get postestSessionId() {
    return this.postest?.mostRecentAttempt?.id;
  }

  loadDetails() {
    const { grades } = this.props;
    if (this.postest && this.pretest) {
      grades.loadGradeDetails(this.postest.id, this.pretest.id);
    }
  }

  render() {
    const {
      assignment,
      studentCourseGrade,
    } = this;
    const { students: { fromRoute: student } } = this.props;

    if (!studentCourseGrade || !assignment) return null;

    const deltaPercent = this.pretest && this.postest
      && formatDelta(this.pretest.percentEarned, this.postest.percentEarned);
    const deltaPoints = this.pretest && this.postest
      && formatDelta(this.pretest.totalEarned, this.postest.totalEarned);

    return (
      <div className="prePostSingleResult">
        <h2>{`${student.lastName}, ${student.firstName}: ${assignment.title}`}</h2>
        <Link
          to="."
          className="button"
        >
          &larr;
          Return to Student Overview
        </Link>
        <section className="pre-post learnosity">
          <div className="row scores">
            <div className="col3 pre" style={{ textAlign: 'center' }}>
              <div>Pre-Test</div>
              {this.pretest
                && (
                  <div>
                    <h1 style={{ margin: 0 }}>{this.pretest.percent}</h1>
                    <div>{this.pretest.points}</div>
                  </div>
                )}
              {!this.pretest && <div>Not Taken</div>}
            </div>
            <div className="col3 post" style={{ textAlign: 'center' }}>
              <div>Post-Test</div>
              {this.postest
                && (
                  <div>
                    <h1 style={{ margin: 0 }}>{this.postest.percent}</h1>
                    <div>{this.postest.points}</div>
                  </div>
                )}
              {!this.postest && <div>Not Taken</div>}
            </div>
            <div className="col3 delta" style={{ textAlign: 'center' }}>
              {this.pretest && this.postest
                && (
                  <div>
                    <div>Improvement</div>
                    <h1 style={{ margin: 0, color: deltaPercent[0] === '+' ? '#1C7C32' : '#941C5D' }}>
                      {deltaPercent}
                      %
                    </h1>
                    <div>
                      {deltaPoints}
                    </div>
                  </div>
                )}
            </div>
          </div>
          <hr />
          <div className="row reports">
            <div className="col2">
              <h4>Pre-Test</h4>
              <div id={`assessment_detail_${this.pretestSessionId}`} />
            </div>
            <div className="col2">
              <h4>Post-Test</h4>
              <div id={`assessment_detail_${this.postestSessionId}`} />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PrePostDetails;
