const DEFAULT_CALLBACKS = {
  onQuestionsLoaded: () => {},
  onSaveSuccess: () => {},
  readyListener: () => {},
};

const errorListener = (error) => {
  window.Rollbar.error(`Learnosity Error ${error.code}`, error);
  return false;
};

const populateQuestions = (itemsApp, itemScores = []) => {
  const items = itemsApp.getItems();

  return itemScores.map((itemScore) => {
    const {
      item_reference: itemReference,
      score,
    } = itemScore;
    const item = items[itemReference];
    const question = item?.questions[0] || {};

    return { ...question, score, itemReference };
  });
};

const onReady = (itemsApp, itemScores, callbacks) => {
  const { onQuestionsLoaded } = callbacks;
  const questions = populateQuestions(itemsApp, itemScores);
  onQuestionsLoaded(questions);
};

const dataListener = (data, callbacks) => {
  const { id, data: { items } } = data[0];
  const report = window.reportsApp.getReport(id);

  if (report) {
    report.on('ready:itemsApi', (itemsApp) => onReady(itemsApp, items, callbacks));
  }
};

const initializeReport = (configJSON, callbacks = DEFAULT_CALLBACKS) => {
  const config = JSON.parse(configJSON);
  window.reportsApp = window.LearnosityReports.init(config, {
    dataListener: (data) => dataListener(data, callbacks),
    errorListener,
  });
};

const initializeItems = (configJSON, selector) => {
  try {
    const config = JSON.parse(configJSON);
    const { request: { items } } = config;
    if (items.length > 0) { // Don't try to init if there are no items; it will fail.
      window.itemsApp = window.LearnosityItems.init(config, selector, { errorListener });
    }
  } catch (error) {
    window.Rollbar.error('Error initializing Learnosity', { error, learnosityConfig: configJSON });
  }
};

const getQuestionCount = (configJSON) => {
  try {
    const config = JSON.parse(configJSON);
    const { request: { items } } = config;
    return items.length;
  } catch (error) {
    window.Rollbar.error('Error initializing Learnosity', { error, learnosityConfig: configJSON });
  }
  return 0;
};

const resetItems = () => {
  if (window.LearnosityItems) {
    window.LearnosityItems.reset();
  }
};

const previewItem = (reference) => {
  const { itemsApp } = window;
  const items = itemsApp.getItems();
  if (!items[reference]) {
    itemsApp.addItems({ items: [reference] });
  }
};

const resetEdit = () => {
  const { authorApp } = window;
  if (authorApp) {
    authorApp.destroy();
    window.authorApp = undefined;
  }
};

const initializeEdit = (configJSON, selector, callbacks = DEFAULT_CALLBACKS) => {
  try {
    const config = JSON.parse(configJSON);
    const { request: { reference } } = config;
    const { readyListener } = callbacks;

    if (window.authorApp) {
      window.authorApp.editItem(reference, true);
    } else if (window.LearnosityAuthor) {
      const authorApp = window.LearnosityAuthor
        .init(config, { readyListener, errorListener }, selector);
      authorApp.on('save:success', callbacks.onSaveSuccess);
      authorApp.on('render:item', () => {
        const item = authorApp.getItem();
        const {
          item: { reference: activeReference },
          questions: [question],
        } = item;
        if (activeReference === reference && question) {
          authorApp.navigate(`items/${reference}/widgets/${question.reference}`);
        } else {
          authorApp.navigate(`items/${reference}/widgets/new`);
        }
      });
      window.authorApp = authorApp;
    }
  } catch (error) {
    window.Rollbar.error('Error initializing Learnosity', { error, learnosityConfig: configJSON });
  }
};

const setItemTags = (moduleKey, unitKey) => {
  const { authorApp } = window;
  const tags = [{ type: 'Module', name: moduleKey }];

  if (unitKey) {
    tags.push({ type: 'Unit', name: unitKey });
  }
  if (authorApp) {
    authorApp.setItemTags(tags);
  }
};

export default {
  initializeReport,
  initializeItems,
  initializeEdit,
  setItemTags,
  previewItem,
  resetItems,
  resetEdit,
  getQuestionCount,
};
