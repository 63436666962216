import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import GradeActions from 'widgets/grade/GradeActions';
import OpenOverride from 'widgets/assignment/OpenOverride';
import EmptyAssignmentSummaryRow from './EmptyAssignmentSummaryRow';

@inject('assessmentOpens', 'courses', 'students') @observer
class AssignmentSummary extends Component {
  @observable showAssignment = null

  @action toggleAssignment(assignment) {
    if (this.showAssignment === assignment.id) {
      this.showAssignment = null;
    } else {
      this.showAssignment = assignment.id;
    }
  }

  render() {
    const {
      assessmentOpens,
      courses: { fromRoute: course },
      students: { fromRoute: student },
    } = this.props;
    const grade = student.gradesByCourse.get(course.id);

    return (
      <table className="assignmentSummary">
        <tbody>
          {course.assignments.map((assignment) => (
            (assignment.gradeEntries.length > 0)
              ? (
                <>
                  <tr
                    onClick={() => this.toggleAssignment(assignment)}
                    className="assignmentRow"
                    key={assignment.id}
                  >
                    <td colSpan={4} title="Click to expand">
                      <i className="material-icons" style={{ position: 'relative', left: -8 }}>
                        {this.showAssignment !== assignment.id ? 'arrow_right' : 'arrow_drop_down'}
                      </i>
                      <b>{assignment.title}</b>
                      <span style={{ float: 'right' }}>
                        {(grade && grade.moduleAverage[assignment.moduleKey]) || '-'}
                      </span>
                    </td>
                  </tr>
                  <tr
                    style={{ display: this.showAssignment !== assignment.id ? 'none' : 'table-row' }}
                    key={`${assignment.id}_details`}
                  >
                    <td className="assignmentDetails">
                      <table>
                        <tbody>
                          <tr>
                            <th>Title</th>
                            <th>Status</th>
                            <th>Progress</th>
                            <th>Grade</th>
                            <th>Actions</th>
                          </tr>
                          {assignment.entries.map((entry) => {
                            const work = grade && grade.workByUnit[entry.unitKey];

                            if (entry.removed || !entry.isAssessment) {
                              return null;
                            }

                            const workStatus = work ? work.quizStatus : <span>Not Started</span>;
                            const workPercent = (work && work.percent) || '-';

                            const override = assessmentOpens.find(course, student, entry);
                            let status;

                            if (override) {
                            // Has override
                              status = (
                                <span>
                                  Open until
                                  {' '}
                                  {override.relativeExpiration}
                                  <br />
                                  <button
                                    className="secondary"
                                    type="button"
                                    onClick={() => assessmentOpens.delete(override)}
                                  >
                                    Reset
                                  </button>
                                </span>
                              );
                            } else {
                              status = (
                                <OpenOverride
                                  student={student}
                                  entry={entry}
                                />
                              );
                            }

                            return (
                              <tr key={entry.id}>
                                <td>{entry.title}</td>
                                <td className="status">{status}</td>
                                <td>{workStatus}</td>
                                <td>{workPercent}</td>
                                {/* <td /> */}
                                <td><GradeActions courseId={course.id} grade={work} /></td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </>
              )
              : (
                <EmptyAssignmentSummaryRow
                  title={assignment.title}
                  id={assignment.id}
                />

              )))}
        </tbody>
      </table>
    );
  }
}

export default AssignmentSummary;
