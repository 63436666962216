import React from 'react';
import { Route } from 'react-router';
import { inject, observer } from 'mobx-react';
import { triggerDownload } from 'services/WebAPI';

import PrePostAll from './PrePostAll';
import PrePostSingle from './PrePostSingle';
import PrePostLearnosity from './PrePostLearnosity';

const PrePostTest = ({ courses: { fromRoute: course }, auth, router }) => {
  // handle selecting student
  const handleStudentSelect = (event) => {
    const studentId = event.target.value;
    router.push(`/courses/${course.id}/grades/pre-post/${studentId}`);
  };

  // handle export to excel
  const handleExport = () => {
    const selectedFormat = auth.user.options.usePoints ? 'points' : 'percentage';
    course.generateExcel('prepost', { format: selectedFormat }).then((result) => triggerDownload(result, `PrePost Report - ${course.title}.xls`));
  };

  return (
    <React.Fragment>
      <div className="prePostHeader">
        <div className="headerNav">
          <h1>Pre/Post Test Report</h1>
          <select onChange={handleStudentSelect} value={router.studentId || ''}>
            <option value="">All Students</option>
            {course.students.map((student) => (
              <option key={student.id} value={student.id}>
                {student.lastName}
                ,
                {student.firstName}
              </option>
            ))}
          </select>
          <button type="button" onClick={handleExport}>Export Pre/Post Report</button>
        </div>
      </div>

      <Route
        exact
        path="/courses/:courseId/grades/pre-post/:studentId/:assignmentId"
        component={PrePostLearnosity}
      />

      <Route
        exact
        path="/courses/:courseID/grades/pre-post/:studentID"
        render={() => <PrePostSingle />}
      />

      <Route
        exact
        path="/courses/:courseID/grades/pre-post"
        render={() => <PrePostAll />}
      />
    </React.Fragment>
  );
};

export default inject('courses', 'auth', 'router')(observer(PrePostTest));
