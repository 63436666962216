import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import ToggleSlider from 'widgets/ToggleSlider';
import ManualEnrollment from 'widgets/student/ManualEnrollment';

@inject('auth', 'courses', 'router') @observer
class Enrollment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enrollmentSetup: false,
      sectionsPending: false,
      sectionActive: false,
      providers: null,
      provider: '',
      sections: null,
    };
  }

  componentDidMount() {
    const {
      auth,
      courses: {
        fromRoute: course,
      },
    } = this.props;

    if (course.providers.length > 0) {
      this.setState({
        sectionActive: true,
      });
    }
    if (!course.isRostered) {
      if (auth.enableRostering) {
        course.getCourseProviders()
          .then((res) => {
            if (!res.errors) {
              this.setState({
                providers: res.site_providers,
                enrollmentSetup: true,
              });
            }
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
          });
      } else {
        this.setState({ enrollmentSetup: true });
      }
    }
  }

  @computed get embedOpts() {
    const { auth } = this.props;

    return [
      'popover=true',
      'popoverAnimateThumbnail=false',
      'popoverSize=750x417',
      `email=${auth.user.email}`,
    ].join(' ');
  }

  handleProviderSelect = (provider) => {
    const { courses: { fromRoute: course } } = this.props;
    course.getCourseProviderJob(provider).then((res) => {
      this.getSections(res.job_id);
    });
    this.setState({
      enrollmentSetup: false,
      sectionsPending: true,
      provider,
    });
  }

  getSections = (jobId) => {
    const { courses: { fromRoute: course } } = this.props;
    this.setState({ sectionsPending: true });

    course.getProviderJobData(jobId)
      .then((result) => {
        if (result.status === 'queued') {
          return;
        }
        this.setState({
          sectionsPending: false,
          sections: JSON.parse(result.section_data),
          rosteringError: (result.section_data === 'null'),
        });
      })
      // eslint-disable-next-line no-console
      .catch((res) => console.log('Error getting section data: ', res));
  }

  handleProvisionClass = (id) => {
    const { provider } = this.state;
    const { courses: { fromRoute: course }, router } = this.props;
    this.setState({ sectionsPending: true });

    course.provisionClass(provider, id, course.id).then((result) => {
      if (result.status === 'queued') {
        return;
      }
      const provisionResult = JSON.parse(result.provision_result);
      const providerData = [{
        provider,
        updated_at: Date.now(),
      }];
      course.updateProvider(providerData);
      course.updateStudentCount(provisionResult.number_of_students);
      router.push('students/list');
      // eslint-disable-next-line no-console
    }).catch((res) => console.log('Error provisioning class: ', res));
  }

  handleSync = () => {
    const { courses: { fromRoute: course } } = this.props;
    course.refreshProviderData(course.id).then(() => {
      // eslint-disable-next-line no-console
      console.log('Course Synced');
      // eslint-disable-next-line no-console
    }).catch((res) => console.log('Error syncing class: ', res));
  }

  rosteringSupported(provider) {
    const { auth } = this.props;
    const { providers } = this.state;
    return providers
      && providers.find((p) => p === provider)
      && auth.user.singleSignOnConnections.find((sso) => sso.provider === provider);
  }

  render() {
    const { auth, courses: { fromRoute: course } } = this.props;
    const {
      enrollmentSetup,
      sectionsPending,
      sectionActive,
      providers,
      sections,
      rosteringError,
    } = this.state;

    const providerData = course.isRostered && course.providers[0];
    const enrollmentDisabled = providerData?.provider === 'canvas-api';

    // check conditions for rostering
    const rosterEnabled = auth.enableRostering;
    const isOwner = course.facultyId === auth.user.id;
    const hasStudents = course.students.length > 0;
    const showRostering = providers && rosterEnabled && isOwner && !hasStudents;

    const cleverRostering = this.rosteringSupported('clever');
    const classlinkRostering = this.rosteringSupported('classlink');

    return (
      <React.Fragment>
        {!sectionActive
          && (
            <div className="enrollmentHeader">
              <div className="instructions">
                <h1>Enroll Students</h1>
              </div>
            </div>
          )}
        {sectionActive && providerData && !enrollmentDisabled
          && (
            <div>
              <h1>
                Refresh Roster from
                {' '}
                {providerData.provider.charAt(0).toUpperCase() + providerData.provider.slice(1)}
              </h1>
              <h2 className="syncStatus">
                Last Synced:
                {' '}
                {moment().calendar(providerData.updated_at)}
              </h2>
              <button type="button" className="lgButton" onClick={this.handleSync}>Refresh</button>
            </div>
          )}
        {sectionActive && providerData && enrollmentDisabled
        && <h1>Enrollment is disabled for linked classes</h1>}
        {enrollmentSetup
          && (
            <div className="enrollmentOptions">
              {showRostering
                && (
                  <div className="enrollmentCard">
                    <div className="cardHeading">
                      <h2>Automatic Rostering</h2>
                    </div>
                    <div className="cardBody">
                      <p className="heading">
                        Automatic rostering gives you the ability to import students
                        directly from your district&apos;s database.
                      </p>
                      <div className="content">
                        {classlinkRostering && (
                          <button
                            type="button"
                            className="lgButton"
                            onClick={() => this.handleProviderSelect('classlink')}
                          >
                            Roster With Classlink
                          </button>
                        )}
                        {cleverRostering && (
                          <button
                            type="button"
                            className="lgButton"
                            onClick={() => this.handleProviderSelect('clever')}
                          >
                            Roster With Clever
                          </button>
                        )}
                      </div>
                      {!cleverRostering && !classlinkRostering && (
                        <p>
                          <strong>
                            Your district allows automatic rostering.
                            Please contact AES Support to get started.
                          </strong>
                        </p>
                      )}
                    </div>
                  </div>
                )}
              <div className="enrollmentCard">
                <div className="cardHeading">
                  <h2>Student Self Enrollment</h2>
                </div>
                <div className="cardBody">
                  <div>
                    <ol>
                      <li>
                        Give your students the code for this class period:&nbsp;
                        <span className="enrollmentCode">{course.enrollmentCode}</span>
                      </li>
                      <li>
                        Have your students go to
                        {' '}
                        <a target="new" href="https://learn.aeseducation.com">learn.aeseducation.com</a>
                        .
                        <ul>
                          <li>
                            Click the
                            {' '}
                            <b>I am a student</b>
                            {' '}
                            button.
                          </li>
                          <li>
                            Click the
                            {' '}
                            <b>Enroll into a Class</b>
                            {' '}
                            button.
                          </li>
                          <li>Follow the instructions on the page.</li>
                        </ul>
                      </li>
                      <li>Close enrollment once your students are in to prevent cheating.</li>
                    </ol>

                    <div style={{ margin: '24px' }}>
                      <ToggleSlider
                        id="enrollmentSlider"
                        titleOn="Enrollment Open"
                        titleOff="Enrollment Closed"
                        onClick={() => course.setEnrollmentStatus(!course.enrollmentEnabled)}
                        active={course.enrollmentEnabled}
                      />
                      <div className="enrollVideo" style={{ marginTop: 24 }}>
                        <h2 style={{ marginBottom: '4px' }}>Want to see how it works?</h2>
                        <div
                          className={`wistia_embed wistia_async_i7669vjdct videoFoam=true ${this.embedOpts}`}
                          style={{ width: 240, height: 128 }}
                        />
                        <p className="enrollVideo__caption">Video Length: 3 minutes, 8 seconds</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="enrollmentCard">
                <div className="cardHeading">
                  <h2>Manual Enrollment</h2>
                </div>
                <div className="cardBody">
                  <p className="heading">Enroll a new student manually without opening enrollment.</p>
                  <div className="content">
                    <ManualEnrollment />
                  </div>
                </div>
              </div>
            </div>
          )}
        {sectionsPending && (
          <h2 className="pending">Setting up your class...</h2>
        )}
        {!sectionsPending && sections && sections !== null && (
          <div className="sections">
            {sections.map((section) => (
              <div className="sectionCard">
                <div className="cardHeading">
                  <h2>{section.section_name}</h2>
                  <button
                    type="button"
                    onClick={() => this.handleProvisionClass(section.provider_id)}
                  >
                    Setup Class
                  </button>
                </div>
                <div className="cardBody">
                  {section.period
                    && (
                      <React.Fragment>
                        <p className="heading">Period: </p>
                        <p>{section.period}</p>
                      </React.Fragment>
                    )}
                  {section.student_count
                    && (
                      <React.Fragment>
                        <p className="heading" style={{ marginLeft: 12 }}>
                          Students:
                        </p>
                        <p>
                          {section.student_count}
                          {' '}
                          students
                        </p>
                      </React.Fragment>
                    )}
                </div>
              </div>
            ))}
          </div>
        )}
        {rosteringError && (
          <div>
            <h2 className="pending">We were unable to connect your class to the provider.</h2>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Enrollment;
