import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from 'routes/error/404';
import Activities from './Activities';
import Certificates from './Certificates';
import Gradebook from './Gradebook';
import KeyboardingClass from './KeyboardingClass';
import KeyboardingGlobal from './KeyboardingGlobal';
import PrePostTest from './PrePostTest';
import Reset from './Reset';
import StudentDetail from './StudentDetail';
import StudentGrades from './StudentGrades';
import StudentList from './StudentList';
import QuestionAnalysis from './QuestionAnalysis';
import TimeOnline from './TimeOnline';
import './style.scss';

const Grades = () => (
  <div className="gradesPage">
    <Switch>
      <Route path="*/unit" component={StudentGrades} />
      <Route path="*/book" component={Gradebook} />
      <Route exact path="*/by-student" component={StudentList} />
      <Route path="*/by-student/:id(\d+)" component={StudentDetail} />
      <Route path="*/activities" component={Activities} />
      <Route path="*/pre-post" component={PrePostTest} />
      <Route path="*/reset" component={Reset} />
      <Route path="*/certificates" component={Certificates} />
      <Route path="*/keyboarding-global" component={KeyboardingGlobal} />
      <Route path="*/keyboarding-class" component={KeyboardingClass} />
      <Route path="*/question-analysis" component={QuestionAnalysis} />
      <Route path="*/timeonline" component={TimeOnline} />
      <Route component={NotFound} />
    </Switch>
  </div>
);

export default Grades;
