import React from 'react';
import {
  action, computed, observable, runInAction,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import Alert from '../Alert';
import Modal from '../Modal';
import CheckboxGroup from '../form/CheckboxGroup';

@inject('courses')
@observer
class ExamCopy extends React.Component {
  @observable selection = [];

  @observable status = 'idle';

  @action.bound onClickClose() {
    const { onDismiss } = this.props;

    if (this.status !== 'processing') {
      this.resetState();
    }

    onDismiss();
  }

  @computed get course() {
    const { courses: { fromRoute: course } } = this.props;
    return course;
  }

  @computed get compatibleCourses() {
    const {
      courses: {
        fromRoute: { id: courseId, hasLearnosity },
        list,
      },
    } = this.props;
    return list.filter((course) => (
      course.id !== courseId && course.hasLearnosity === hasLearnosity
    ));
  }

  @computed get incompatibleCourses() {
    const {
      courses: {
        fromRoute: { hasLearnosity },
        list,
      },
    } = this.props;
    return list.filter((course) => course.hasLearnosity !== hasLearnosity);
  }

  @computed get selectedCourses() {
    const { props: { courses }, selection } = this;
    return selection.map((id) => courses.byId.get(id));
  }

  @computed get courseOptions() {
    const {
      course: { id, title },
      compatibleCourses,
      incompatibleCourses,
    } = this;

    const selfOption = { key: id, value: `${title} (this class)` };
    const compatibleOptions = compatibleCourses.map((course) => ({
      key: course.id,
      value: course.title,
    }));
    const incompatibleOptions = incompatibleCourses.map((course) => ({
      key: course.id,
      value: course.title,
      disabled: true,
    }));

    return [
      selfOption,
      ...compatibleOptions,
      ...incompatibleOptions,
    ];
  }

  @computed get alertMessage() {
    const {
      course: { hasLearnosity },
      incompatibleCourses,
    } = this;
    if (incompatibleCourses.length > 0) {
      if (hasLearnosity) {
        return 'Note that this exam uses our new improved exam format and cannot be copied to classes that are in legacy mode.';
      }
      return 'Note that this exam uses our legacy exam format and can only be copied to classes that are in legacy mode.';
    }
    return null;
  }

  @action.bound setSelection(selection) {
    this.selection = selection;
  }

  @action resetState() {
    this.status = 'idle';
    this.selection = [];
  }

  @action.bound copyExam() {
    const {
      props: { exam },
      selectedCourses,
    } = this;
    const calls = [];

    this.status = 'processing';

    selectedCourses.forEach((course) => {
      calls.push(course.copyExam(exam));
    });

    Promise.all(calls)
      .finally(() => runInAction(() => {
        const { visible } = this.props;

        if (!visible) {
          this.resetState();
        } else {
          this.status = 'complete';
        }
      }));
  }

  render() {
    const {
      props: { visible },
      courseOptions,
      selectedCourses,
      status,
    } = this;

    return (
      <Modal
        title="Copy Exam"
        visible={visible}
        onDismiss={this.hideDuplicate}
        style={{ width: 400 }}
      >
        { status === 'idle'
          ? (
            <React.Fragment>
              { this.alertMessage
                ? <Alert type="info">{this.alertMessage}</Alert>
                : null }
              <p>
                Create a copy of this exam in the following classes:
              </p>
              <CheckboxGroup
                options={courseOptions}
                value={this.selection}
                onChange={this.setSelection}
              />
              <button type="button" onClick={this.copyExam}>Copy</button>
              <button type="button" onClick={this.onClickClose} className="secondary">
                Cancel
              </button>
            </React.Fragment>
          )
          : (
            <React.Fragment>
              { status === 'processing'
                ? (
                  <Alert type="info">
                    Copying the Exam...
                    <br />
                    <small>
                      You can close this dialog while the process runs it&apos;s course.
                    </small>
                  </Alert>
                )
                : <Alert type="success">Exam Copy Completed</Alert> }

              <h4>Classes:</h4>
              <ul>
                { selectedCourses.map(({ id, title }) => (
                  <li key={id}>{title}</li>
                ))}
              </ul>
              <button type="button" onClick={this.onClickClose}>Close</button>
            </React.Fragment>
          ) }
      </Modal>
    );
  }
}

export default ExamCopy;
