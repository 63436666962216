import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import ResourceLink from 'widgets/ResourceLink';

const ResourceList = ({
  title, moduleKey, unit, entry, resources, showQuiz, auth, router,
}) => (
  <div>
    <b>{title}</b>
    <ul>
      {resources.map((resource) => (
        <li key={resource.title + resource.unit_key}>
          <ResourceLink module={{ key: moduleKey }} unit={unit} resource={resource} />
        </li>
      ))}
      {showQuiz && unit.hasQuiz
      && (
      <li key={`quiz.${unit.key.toString()}`}>
        {(auth.site.isCustomer || (auth.enableAnswerKeys && auth.site.verified))
        && (
        <Link to={`${router.path}/answer-key/${entry ? entry.id : unit.key}`}>
          Quiz Answer Key
        </Link>
        )}
        {(!auth.site.isCustomer && (!auth.enableAnswerKeys || !auth.site.verified))
        && <ResourceLink module={{ key: moduleKey }} resource={{ name: 'Quiz Answer Key', title: 'Quiz Answer Key', url: '#' }} />}
      </li>
      )}
    </ul>
  </div>
);

export default inject('auth', 'router')(observer(ResourceList));
