import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Dropdown from 'widgets/form/Dropdown';
import Modal from 'widgets/Modal';

@inject('courses', 'teachers', 'auth', 'router') @observer
class TransferClass extends Component {
  @observable newTeacher

  @observable confirm = false

  @computed get teachers() {
    const {
      auth: { user },
      teachers: { list },
    } = this.props;
    return list
      .filter((t) => t.id !== user.id)
      .map(({ id, name }) => [id, name]);
  }

  @action.bound setNewTeacher = (id) => { this.newTeacher = id; }

  @action.bound showConfirm = (e) => {
    e.preventDefault();
    this.confirm = true;
  }

  @action.bound hideConfirm = () => { this.confirm = false; }

  @action.bound async transferCourse() {
    const { courses, router } = this.props;
    const { fromRoute: course } = courses;

    await courses.transfer(course, this.newTeacher);

    this.hideConfirm();
    router.push('/');
  }

  render() {
    const { courses: { fromRoute: course } } = this.props;
    return (
      <div>
        <h1>Transfer Class</h1>

        {course.isRostered
          && (
            <div>This class is rostered. Rostered classes cannot be transferred.</div>
          )}

        {!course.isRostered
          && (
            <React.Fragment>
              <p>Transfer ownership of this class to another teacher at your school.</p>

              <form onSubmit={(e) => this.showConfirm(e)}>
                <Dropdown
                  name="faculty"
                  label="New Owner:"
                  options={this.teachers}
                  value={this.newTeacher}
                  onChange={this.setNewTeacher}
                />

                <button type="submit">Transfer</button>
              </form>

              <Modal
                title="Are you sure?"
                visible={this.confirm}
              >
                <p>
                  You are about to transfer ownership of this class.
                  <b>You will lose access.</b>
                </p>
                <p>Are you sure?</p>

                <button type="button" onClick={this.transferCourse}>Transfer Class</button>
                <button type="button" className="secondary" onClick={this.hideConfirm}>Cancel</button>
              </Modal>
            </React.Fragment>
          )}
      </div>
    );
  }
}

export default TransferClass;
