/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { inject, observer } from 'mobx-react';
import GradeButtonCell from './GradeButtonCell';
import MaterialIcon from '../MaterialIcon';

const GradeEmpty = ({
  assignment, course, grades, router, student, work,
}) => {
  const handleSetupWork = () => {
    // onClick create null grade

    const module = assignment.moduleKey;
    const unit = assignment.unitKey;
    const gradePath = `/courses/${course.id}/grades/book/${module}/unit/${unit}/grade`;

    if (!work) {
      grades.assignNew({
        course,
        student,
        module,
        unit,
        points: null,
      }).then((res) => {
        router.push(`${gradePath}/${res.id}`);
      });
    } else {
      router.push(`${gradePath}/${work.id}`);
    }
  };

  return (
    <GradeButtonCell onClick={handleSetupWork}>
      <MaterialIcon toolTip="No work; click to assign a grade">add</MaterialIcon>
    </GradeButtonCell>
  );
};

export default inject('grades', 'router')(observer(GradeEmpty));
