import React from 'react';
import { inject, observer } from 'mobx-react';
import CourseCreator from './CourseCreator';

const CreateTemplate = ({ auth }) => (
  auth && auth.site
    ? <CourseCreator asTemplate />
    : null
);

export default inject('auth')(observer(CreateTemplate));
