import React from 'react';

const ToggleButton = (props) => {
  const {
    active,
    onClick,
    disabled,
    titleOn,
    subtitleOn,
    titleOff,
    subtitleOff,
    titleDisabled,
    subtitleDisabled,
    page,
  } = props;

  let state = active ? 'positive' : 'negative';
  let title = active ? titleOn : titleOff;
  let subtitle = active ? subtitleOn : subtitleOff;
  let overrideColor = '';

  if (disabled) {
    state = 'disabled';
    title = titleDisabled;
    subtitle = subtitleDisabled;
  }

  // page passed on AssignmentDetails screen
  if (page && page.includes('AssignmentDetails')) {
    overrideColor = 'assignmentDelete';
  }

  return (
    <button
      type="button"
      className={`lgButton ${state} ${overrideColor}`}
      onClick={() => onClick(!active)}
    >
      { page === 'AssignmentDetailsDelete'
          && <i className="fas fa-trash-alt" />}
      <span>{title}</span>
      <small>{subtitle}</small>
    </button>
  );
};

export default ToggleButton;
