import { action, computed, observable } from 'mobx';
import APIError from '../services/APIError';

const logRollbarError = (error) => {
  if ('Rollbar' in window) {
    window.Rollbar.error(error);
  }
};

class UIStateStore {
  @observable activeRequests = 0

  @observable errors = []

  @observable errorArchive = []

  @observable window = {
    width: window.innerWidth,
    height: window.innerHeight,
  }

  @observable localState = observable.map()

  // Dev options
  @observable devMode = false

  @observable showControls = true

  @observable pageRevisions = []

  @observable revision = ''

  @observable sidebarHidden = false

  @observable navHeight = 40;

  constructor(rootStore, api) {
    this.api = api;

    window.onresize = action(() => {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    });

    window.onunhandledrejection = (event) => {
      if (event && event.promise && event.promise.catch) {
        event.promise.catch((err) => {
          // Only catch our own errors
          if (err && !err.wasCaught && err instanceof APIError) {
            this.addError(err).catch(() => {});
          } else {
            logRollbarError(err);
          }
        });
      }

      return true;
    };
  }

  @computed get loading() {
    return this.activeRequests > 0;
  }

  getLocal(key) {
    if (!this.localState.has(key)) this.localState.set(key, observable.map());
    return this.localState.get(key);
  }

  @action setRevision(input) {
    this.revision = String(input);
  }

  @action setPageRevisions(input) {
    this.pageRevisions = input;
  }

  @action startRequest() {
    this.activeRequests += 1;
  }

  @action finishRequest() {
    this.activeRequests -= 1;
  }

  @action finishAllRequests() {
    this.activeRequests = 0;
  }

  @action addError(errorParam) {
    let error = errorParam;

    if (error?.wasCaught) {
      return Promise.reject(error);
    }

    if (!(error instanceof Error)) {
      error = new Error(JSON.stringify(errorParam));
    }

    Object.defineProperty(error, 'wasCaught', { value: true, enumerable: false });

    let severity = 'warn';
    const standardErrors = [
      error instanceof ReferenceError,
      error instanceof RangeError,
      error instanceof SyntaxError,
      error instanceof EvalError,
    ];

    // Ignored Errors
    if (error.message.match(/wistia/i)) {
      Object.defineProperty(error, 'wasCaught', { value: true, enumerable: false });
      return Promise.reject(error);
    }

    if (standardErrors.includes(true)) {
      severity = 'critical';
    }

    if (error.message.includes('unavailable') || error.message.includes('associated')) {
      logRollbarError(error);
      return Promise.reject(error);
    }

    if (error.code === 403) {
      return Promise.reject(error);
    }

    // push all other errors to error modal
    this.errors.push({
      severity,
      code: error.code || 1000,
      message: error.message,
      error,
    });

    logRollbarError(error);

    return Promise.reject(error);
  }

  @action clearErrors() {
    this.errorArchive = this.errorArchive.concat(this.errors);
    this.errors = [];
  }

  @action switchToOldUI(feedback) {
    return this.api.post('teacher/events', {
      event: {
        action: 'UI_SWITCH',
        details: `OLD~${feedback}`,
      },
    });
  }

  @action toggleSidebar() {
    this.sidebarHidden = !this.sidebarHidden;
  }

  @action setNavHeight(height) {
    this.navHeight = height;
  }
}

export default UIStateStore;
