import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Accordion from 'widgets/Accordion';
import { markText } from 'util/String';

const processAllResults = (allResults) => allResults.reduce((acc, result) => {
  const { unitKey, unitTitle, resources } = result;
  const resourcesHash = resources.reduce((rAcc, resource) => {
    const { url } = resource;
    // TODO: Remove after Summer V2 release

    /* eslint-disable no-param-reassign */
    rAcc[url] = resource;
    return rAcc;
  }, {});
  if (!acc[unitKey]) {
    acc[unitKey] = {
      unit: { key: unitKey, title: unitTitle },
      resources: resourcesHash,
    };
  } else {
    resources.forEach((resource) => {
      const { url } = resource;
      if (!acc[unitKey].resources[url]) {
        acc[unitKey].resources[url] = resource;
      }
    });
  }
  return acc;
}, {});

@inject('catalog', 'auth') @observer
class CatalogSearchAdvanced extends Component {
  renderResource(resource) {
    const { url, title } = resource;
    const { auth: { mediaBaseUrl } } = this.props;
    return (
      <div key={url}>
        <a href={mediaBaseUrl + url} rel="noreferrer" target="_blank">
          {title}
        </a>
      </div>
    );
  }

  renderUnit({ unit, resources }) {
    return (
      <div key={unit.key} className="catalogSearchUnitDetails">
        <h4>{`${unit.title} [${unit.key}]`}</h4>
        { Object.values(resources).map((resource) => this.renderResource(resource)) }
      </div>
    );
  }

  renderModule(searchResult) {
    const { catalog } = this.props;
    const { searchQuery } = catalog;
    const { allResults, attachmentText, moduleKey } = searchResult;
    const module = catalog.getModule(moduleKey);

    const include = catalog.searchModules.find((mk) => mk === moduleKey);
    if (!module || !include) {
      return null;
    }

    const units = processAllResults(allResults);

    return (
      <div className="catalogSearchModule" key={moduleKey}>
        <h2 style={{ marginTop: 30 }}>
          <span>{`${module.title} `}</span>
          <Link
            to={`/catalog/search/advanced/${moduleKey}`}
          >
            {`[${moduleKey}]`}
          </Link>
        </h2>
        <Accordion title="Top Result Text" closed>
          {/* eslint-disable-next-line react/no-danger */}
          <pre dangerouslySetInnerHTML={{ __html: markText(attachmentText, searchQuery) }} />
        </Accordion>
        <div className="catalogSearchModuleDetails">
          {Object.values(units).map((unit) => this.renderUnit(unit))}
        </div>
      </div>
    );
  }

  render() {
    const { catalog, auth: { site } } = this.props;
    if (!catalog.loaded || !site) {
      return <div />;
    }
    return (
      <React.Fragment>
        <h1>Search Results</h1>
        { catalog.searchResults.map((searchResult) => this.renderModule(searchResult)) }
      </React.Fragment>
    );
  }
}

export default CatalogSearchAdvanced;
