import {
  observable, computed, action, autorun, runInAction,
} from 'mobx';
import Fuse from 'fuse.js';
import Assignment from 'models/Assignment';
import Exam from 'models/Exam';

class AssignmentsStore {
  @observable byId = observable.map()

  @observable loaded = observable.map()

  @observable questionCounts = observable.map()

  @observable archivedExams = []

  constructor(rootStore, api) {
    this.api = api;
    this.rootStore = rootStore;
    autorun(() => {
      const options = {
        keys: ['title', 'module.description'],
        shouldSort: true,
        threshold: 0.499,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        includeScore: true,
      };

      this.fuse = new Fuse(this.list, options);
    });
  }

  @computed get list() {
    return Array.from(this.byId.values());
  }

  @computed get fromRoute() {
    const { router: { assignmentId } } = this.rootStore;
    return this.byId.get(assignmentId);
  }

  @action findOrCreateAssignment(assignmentId) {
    return this.byId.get(assignmentId) || new Assignment(this.rootStore);
  }

  @action quickSearch(query, courseId, advanced = false) {
    const raw = this.fuse.search(query.trim());
    const results = advanced ? raw : raw.map((r) => r.item);

    return results.filter((e) => e.item.courseId === courseId);
  }

  @action add(input) {
    const assgn = new Assignment(this.rootStore, input);
    this.byId.set(assgn.id, assgn);
    return assgn;
  }

  @action remove(id) {
    this.byId.delete(id);
  }

  @action reloadByCourse(id) {
    this.loaded.delete(+id);
    return this.loadByCourse(id);
  }

  @action loadByCourse(id) {
    if (!id) {
      return Promise.reject(new Error('Undefined Course ID'));
    }

    if (this.loaded.has(+id)) return Promise.resolve();

    this.loaded.set(+id, new Date());

    return this.api.get(`teacher/courses/${id}/assignments`)
      .then((json) => {
        this.doneLoadingAssignments(json);
      })
      .catch((e) => {
        this.loaded.delete(+id);
        if ('Rollbar' in window) {
          window.Rollbar.error(e);
        }
      });
  }

  @action loadByCourseAndId(course, id) {
    return this.api.get(`teacher/courses/${course}/assignments/${id}`)
      .then((json) => this.doneLoadingAssignments([json]));
  }

  @action doneLoadingAssignments(json) {
    json.forEach((data) => {
      const assignment = new Assignment(this.rootStore, data);
      this.byId.set(assignment.id, assignment);
    });
  }

  @action loadQuestionCounts(courseId) {
    const resource = `teacher/courses/${courseId}/assignments/item_summary`;
    return this.api.get(resource, undefined, { version: 'v2' })
      .then((response) => runInAction(() => {
        const { item_counts: counts } = response;
        this.questionCounts.merge(counts);
      }));
  }

  @action setArchivedExams(exams) {
    this.archivedExams = exams;
  }

  @action loadArchivedExams() {
    this.api.get('teacher/exams?archived=true')
      .then((result) => result.map((input) => new Exam(this.rootStore, input)))
      .then((exams) => this.setArchivedExams(exams));
  }

  @action async initializeExam({ courseId }) {
    const { assignments, courses } = this.rootStore;
    const course = courses.byId.get(courseId);

    if (course.moduleAssignments.length < 1) {
      await assignments.loadByCourse(courseId);
    }
    await this.loadQuestionCounts(courseId);

    const modules = course.moduleAssignments
      .map(({ module }) => module.key)
      .filter((moduleKey) => !!this.questionCounts.get(moduleKey));
    const questionCount = modules.length * 5;
    const input = { courseId, modules, questionCount };

    return new Exam(this.rootStore, input);
  }

  @action restoreExam(courseId, exam) {
    return this.api.post(
      `teacher/courses/${courseId}/assignments`,
      {
        assignment: {
          exam_id: exam.id || exam,
        },
      },
    ).then((result) => {
      this.add(exam);
      this.setArchivedExams(this.archivedExams.filter((value) => value.id !== exam.id));
      return result;
    });
  }

  @action loadAnswerKeyRequest(assignmentId, assignmentEntryId, showAnswers = true, seed = 'STD') {
    const { location: { hostname: host } } = window;
    const { courseId } = this.byId.get(+assignmentId);
    const sessionId = `ANSWER_KEY_${assignmentEntryId || assignmentId}_${seed}`;
    const uri = assignmentEntryId
      ? `teacher/courses/${courseId}/assignments/${assignmentId}/assignment_entries/${assignmentEntryId}/answer_key`
      : `teacher/courses/${courseId}/assignments/${assignmentId}/answer_key`;
    const params = {
      host,
      session_id: sessionId,
      show_answers: showAnswers,
    };
    const options = { version: 'v2', quiet: true };
    return this.api.get(uri, params, options)
      .then((response) => response.learnosity_request);
  }
}

export default AssignmentsStore;
