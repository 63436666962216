import {
  observable,
  action,
  computed,
  runInAction,
} from 'mobx';
import Learnosity from 'services/learnosity';
import LearnosityItem from 'models/LearnosityItem';

const V2_OPTIONS = { version: 'v2' };
const HOSTNAME = window.location.hostname;

class LearnosityItemsStore {
  @observable byReference = new Map()

  @observable previewReferences = [];

  @observable previewRequest = '';

  @observable previewExam = '';

  constructor(rootStore, api) {
    this.api = api;
    this.rootStore = rootStore;
  }

  @computed get byModule() {
    const result = new Map();

    this.byReference.forEach((item) => {
      const { moduleKey, isPublished } = item;
      if (isPublished) {
        if (result.has(moduleKey)) {
          result.get(moduleKey).push(item);
        } else {
          result.set(moduleKey, [item]);
        }
      }
    });

    return result;
  }

  @action add(input) {
    const {
      item_reference: reference,
      exam_id: examId,
    } = input;

    if (!examId && this.byReference.has(reference)) {
      return this.byReference.get(reference);
    }

    const item = new LearnosityItem(this.rootStore, input);
    this.byReference.set(reference, item);
    return item;
  }

  @action addItemToExam(examId, reference) {
    const item = this.byReference.get(reference);
    const uri = `teacher/exams/${examId}/exam_items`;
    const params = item.ownerType === 'AES'
      ? {
        action_type: 'add-aes-item',
        item_reference: reference,
        module_key: item.moduleKey,
        unit_key: item.unitKey,
        assessment_type: item.assessmentType,
      }
      : {
        action_type: 'add-custom-item',
        id: item.id,
      };

    return this.api.post(uri, params, V2_OPTIONS)
      .then((input) => this.add(input));
  }

  @action removeItemFromExam(examId, reference) {
    const uri = `teacher/exams/${examId}/exam_items/${reference}`;
    return this.api.delete(uri, undefined, V2_OPTIONS)
      .then(() => runInAction(() => {
        this.byReference.delete(reference);
      }));
  }

  @action createExamItem(examId, moduleKey) {
    const uri = `teacher/exams/${examId}/exam_items`;
    const params = {
      action_type: 'new-custom-item',
      module_key: moduleKey,
      host: HOSTNAME,
    };

    return this.api.post(uri, params, V2_OPTIONS)
      .then((response) => response.learnosity_request);
  }

  @action cloneExamItem(examId, reference) {
    const item = this.byReference.get(reference);
    const uri = `teacher/exams/${examId}/exam_items`;
    const params = {
      action_type: 'new-copy-from-aes',
      exam_item_id: item.id,
    };

    return this.api.post(uri, params, V2_OPTIONS)
      .then((response) => this.api.pollJob(response.job_id, 500, 45000, true))
      .then((response) => {
        const newReference = response.new_ref;
        if (newReference) {
          runInAction(() => this.byReference.delete(reference));
          return this.loadExamItemDetails(examId, newReference);
        }
        return Promise.reject(new Error(`Item clone failed: ${reference}`));
      });
  }

  @action saveExamItem(examId, moduleKey, unitKey, data) {
    const {
      item: { reference },
      questions: [
        {
          data: {
            stimulus,
            type,
          },
        },
      ],
    } = data;
    const uri = `teacher/exams/${examId}/exam_items/${reference}`;
    const params = {
      stimulus,
      question_type: type,
      module_key: moduleKey,
      unit_key: unitKey,
    };

    return this.api.put(uri, params, V2_OPTIONS)
      .then((item) => this.add(item));
  }

  @action loadExamItemDetails(examId, reference) {
    const uri = `teacher/exams/${examId}/exam_items/${reference}`;
    const params = { host: HOSTNAME };
    return this.api.get(uri, params, V2_OPTIONS)
      .then((response) => this.add(response));
  }

  @action loadExamItems(examId) {
    const uri = `teacher/exams/${examId}/exam_items`;
    const options = { version: 'v2' };
    return this.api.get(uri, {}, options)
      .then((items) => items.map((item) => this.add(item).reference));
  }

  @action loadAvailableItems(courseId, examId, moduleKey) {
    // TODO delete all this advanced test logic when legacy A&P is retired
    const isAdvanced = (() => {
      if (moduleKey === 'HSANTMYCO') {
        const { courses } = this.rootStore;
        const course = courses.byId.get(courseId);
        const assignment = course.assignmentsByModuleKey.get(moduleKey);
        return assignment?.testType === 1;
      }
      return false;
    })();
    const assessmentTypes = isAdvanced
      ? ['AdvancedTest', 'Test', 'Quiz']
      : ['Test', 'Quiz'];
    const uri = `teacher/exams/${examId}/search`;
    const params = {
      module_keys: moduleKey,
      assessment_type: assessmentTypes,
    };
    const options = { version: 'v2' };

    return this.api.get(uri, params, options)
      .then((items) => items.map((item) => this.add(item)));
  }

  @action loadExamItemPreview(examId, reference) {
    const uri = `teacher/exams/${examId}/exam_items/preview/${reference}`;
    const params = { host: HOSTNAME };
    const options = { version: 'v2' };
    this.resetPreview();
    this.previewExam = examId;

    if (!reference || !examId) {
      return Promise.resolve('');
    }

    this.previewReferences = [reference];

    return this.api.get(uri, params, options)
      .then((response) => runInAction(() => {
        const { learnosity_request: learnosityRequest } = response;
        this.previewRequest = learnosityRequest;

        // Only run initialize if we've not been reset before the response load
        if (this.previewReferences[0] === reference) {
          Learnosity.initializeItems(learnosityRequest);
          return learnosityRequest;
        }
        return '';
      }));
  }

  @action previewItem(reference) {
    this.previewReferences.push(reference);
    if (this.previewRequest) {
      Learnosity.previewItem(reference);
    } else {
      this.loadExamItemPreview(this.previewExam, reference);
    }
  }

  @action resetPreview() {
    this.previewReferences = [];
    this.previewRequest = '';
    this.previewExam = '';
    Learnosity.resetItems();
  }
}

export default LearnosityItemsStore;
