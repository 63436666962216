import React from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import TeacherPersona from 'widgets/teacher/TeacherPersona';
import AssignmentEditModules from 'widgets/assignment/AssignmentEditModules';
import { getBounds } from 'util/domUtil';
import { distance2D, collision2D } from 'util/math';
import { Prompt } from 'react-router-dom';
import './style.scss';
import AssignmentEditRow from './AssignmentEditRow';

const dragStartDistance = 5;
const autoScrollSize = 40;
const autoScrollSpeed = 2.5;

@inject('auth', 'courses', 'catalog', 'router', 'curriculum') @observer
class AssignmentEdit extends React.Component {
  @observable dragProps = {
    startX: 0,
    startY: 0,
    currentX: 0,
    currentY: 0,
    offsetX: 0,
    offsetY: 0,
    isClick: true,
    index: null,
    module: null,
    assignment: null,
    remove: false,
  }

  @observable isBlocking = true

  componentDidMount() {
    // Reset Catalog Editor Data
    this.resetCatalog();
  }

  componentWillUnmount() {
    this.endDrag();
  }

  @computed get dragDropTitle() {
    if (this.dragProps.assignment) return this.dragProps.assignment.title;
    if (this.dragProps.module) return this.dragProps.module.title;
    return '';
  }

  @computed get dragModuleStyle() {
    if (!this.dragProps) return undefined;

    const {
      currentX, currentY, offsetX, offsetY,
    } = this.dragProps;

    return {
      top: currentY - offsetY,
      left: currentX - offsetX,
    };
  }

  @action clearDragProps = () => { this.dragProps = null; }

  @action.bound startDrag(ev, module) {
    ev.preventDefault();
    ev.stopPropagation();

    const isTouch = !!ev.touches;
    const touch = isTouch ? ev.touches[0] : {};
    const targetBox = getBounds(ev.target);
    const clientX = isTouch ? touch.clientX : ev.clientX;
    const clientY = isTouch ? touch.clientY : ev.clientY;
    const offsetX = Math.min(400, clientX - targetBox.x);
    const offsetY = Math.min(40, clientY - targetBox.y);

    this.dragProps = {
      startX: clientX,
      startY: clientY,
      currentX: clientX,
      currentY: clientY,
      offsetX,
      offsetY,
      isClick: true,
      index: null,
      module,
      assignment: null,
      remove: false,
    };

    if (isTouch) {
      document.addEventListener('touchmove', this.moveDrag, false);
      document.addEventListener('touchend', this.endDrag, false);
    } else {
      document.addEventListener('mousemove', this.moveDrag, false);
      document.addEventListener('mouseup', this.endDrag, false);
    }
  }

  @action.bound moveDrag(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    try {
      const { isClick, startX, startY } = this.dragProps;

      const isTouch = !!ev.touches;
      const touch = isTouch ? ev.touches[0] : {};
      const x = isTouch ? touch.clientX : ev.clientX;
      const y = isTouch ? touch.clientY : ev.clientY;
      this.dragProps.currentX = x;
      this.dragProps.currentY = y;

      if (isClick) {
        if (distance2D(startX, startY, x, y) >= dragStartDistance) {
          this.dragProps.isClick = false;
        }
        return;
      }

      if (!this.assignmentList || !this.dragElement) {
        throw new Error('DOM error while dragging');
      }

      const dragging = getBounds(this.dragElement);
      const assignmentsArea = getBounds(this.assignmentList);

      if (collision2D(dragging, assignmentsArea)) {
        this.dragProps.remove = false;

        if (dragging.top < assignmentsArea.top + autoScrollSize) {
          if (!this.scrollTimer) {
            this.scrollTimer = setInterval(() => {
              this.assignmentList.scrollTop -= autoScrollSpeed;
            });
          }
        } else if (dragging.bottom > assignmentsArea.bottom - autoScrollSize) {
          if (!this.scrollTimer) {
            this.scrollTimer = setInterval(() => {
              this.assignmentList.scrollTop += autoScrollSpeed;
            });
          }
        } else if (this.scrollTimer) {
          clearTimeout(this.scrollTimer);
          this.scrollTimer = null;
        }

        const elements = [...this.assignmentList.querySelectorAll('.assignmentRow')];
        const boxes = elements.map(getBounds);

        let index = 0;

        for (let i = 0; i < boxes.length; i += 1) {
          const box = boxes[i];

          if (box.height && dragging.middle > box.middle) {
            index = i + 1;
          }
        }

        this.dragProps.index = index;
      } else {
        this.dragProps.index = null;
        this.dragProps.remove = false;
      }
    } catch (e) {
      this.endDrag(ev);
      throw e;
    }
  }

  @action.bound async endDrag(ev) {
    document.removeEventListener('touchmove', this.moveDrag);
    document.removeEventListener('touchend', this.endDrag);
    document.removeEventListener('mousemove', this.moveDrag);
    document.removeEventListener('mouseup', this.endDrag);
    if (this.scrollTimer) clearTimeout(this.scrollTimer);

    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    if (!this.dragProps) return;

    const { isClick, module, index } = this.dragProps;
    const { catalog } = this.props;

    if (!isClick && index != null) {
      const to = index;
      const from = catalog.selectedModules.findIndex((m) => m === module.key);
      await catalog.moveModule(to, from);
    }

    this.clearDragProps();
  }

  rowStyle(assignment) {
    if (this.dragProps && !this.dragProps.isClick) {
      return {
        display: assignment === this.dragProps.assignment ? 'none' : undefined,
      };
    }

    return undefined;
  }

  @action resetCatalog() {
    const { catalog, courses: { fromRoute: course } } = this.props;
    const { moduleKeys } = course;
    const selectGroup = catalog.groupsForCurrentUserAssignments[0];
    catalog.setCurrentTemplate(selectGroup, null);
    catalog.setSelectedModules(moduleKeys);
    catalog.editingClass = true;
  }

  @action addModules() {
    const { catalog, router, courses: { fromRoute: course } } = this.props;
    this.isBlocking = false;
    course.addModules(catalog.selectedModules)
      .then(() => router.push(`/courses/${course.id}/assignments/view`));
  }

  @action selectTemplate(e, templateKey) {
    e.preventDefault();
    const { catalog } = this.props;
    catalog.currentTemplate = templateKey;
  }

  render() {
    const { auth, catalog, curriculum } = this.props;
    const { isBlocking } = this;

    if (!auth || !auth.user) {
      return (<div />);
    }
    if (!auth.user.persona) {
      if (auth.site.isHealth) {
        action(() => {
          auth.user.persona = 'Health';
          auth.user.updatePersona('Health');
        })();
      } else {
        return (<TeacherPersona />);
      }
    }

    const space = <div key="space" style={{ height: 40, background: 'rgba(0, 0, 0, 0.1)' }} />;

    const assignmentList = catalog.selectedModules.reduce((acc, moduleKey) => {
      const module = curriculum.modulesByKey[moduleKey];
      if (module) {
        acc.push(
          <AssignmentEditRow
            key={moduleKey}
            module={module}
            startDrag={this.startDrag}
            removeModule={(key) => catalog.removeModule(key)}
            rowStyle={this.rowStyle(module)}
          />,
        );
      }
      return acc;
    }, []);

    if (this.dragProps && this.dragProps.index != null) {
      assignmentList.splice(this.dragProps.index, 0, space);
    }

    return (
      <div className="assignmentEditNew">
        {!catalog.currentTemplate && !catalog.myTemplate && !catalog.searchResults
          && (
          <div>
            <h1 style={{ marginBottom: 0 }}>Add Module Assignments</h1>
            <p style={{ marginTop: 0, width: 550 }}>
              Choose one of the items to see modules that can be assigned to your students.
            </p>
            <div className="arrow_box firstCoursePrompt" />
          </div>
          )}
        <div className="assignmentEditEditor">
          {(catalog.currentTemplate || catalog.searchModules)
            && (
            <AssignmentEditModules
              groupKey={catalog.currentGroup}
              templateKey={catalog.currentTemplate}
              addModule={(m) => catalog.addModule(m)}
              addMultipleModules={(array) => catalog.addMultipleModules(array)}
              removeModule={(m) => catalog.removeModule(m)}
              removeMultipleModules={(array) => catalog.removeMultipleModules(array)}
              selectedModules={catalog.selectedModules}
            />
            )}
        </div>
        <div className="assignmentEditSidebar">
          <h2>
            {catalog.selectedModules.length}
            {' '}
            Modules Selected
          </h2>
          <p className="center">
            <button
              type="button"
              className="createClass"
              onClick={() => this.addModules()}
            >
              {catalog.editingClass ? 'Save Changes' : 'Create Class'}
            </button>
          </p>
          <div className="selectedAssignments">
            <table ref={(el) => { this.assignmentList = el; }}>
              {assignmentList}
            </table>
            <div className="drag" />

            {this.dragProps && !this.dragProps.isClick
              && (
              <div
                ref={(el) => { this.dragElement = el; }}
                className="card assignmentRow arrange dragging"
                style={this.dragModuleStyle}
              >
                <div className="gripper" />
                <div className="info">
                  {this.dragDropTitle}
                </div>
              </div>
              )}
          </div>
        </div>
        <Prompt
          when={isBlocking}
          message="Are you sure you want to navigate away without saving?"
        />
      </div>
    );
  }
}

export default AssignmentEdit;
