import {
  extendObservable, computed, action, runInAction,
} from 'mobx';
import Module from 'models/Module';
import Unit from 'models/Unit';
import CurriculumPhase from 'models/CurriculumPhase';
import Question from './Question';

export default class AssignmentEntry {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    extendObservable(this, {
      id: +input.id || 0,
      courseId: +input.courseId || +input.course_id || 0,
      assignmentId: +input.assignmentId || +input.assignment_id || 0,
      sequence: +input.sequence || 0,
      type: input.type || input.assignment_entry_type || 0,
      typeName: input.typeName || input.type_name || 'unknown',
      open: !!input.open,
      quizOpen: !!input.quiz_open,
      removed: !!input.removed,
      moduleKey: input.moduleKey || input.module_key || '',
      unitKey: input.unitKey || input.unit_key || '',
      answerKey: [],
      answerKeyRequest: '',
    });

    if (this.isTest && !this.unitKey) {
      this.unitKey = `MT_${this.moduleKey}`;
    }
  }

  @computed get enabled() {
    return !this.removed;
  }

  @computed get lessonsOpen() {
    return this.open;
  }

  @computed get testOpen() {
    return this.open;
  }

  @computed get assignment() {
    const { assignments } = this.rootStore;
    return assignments.findOrCreateAssignment(this.assignmentId);
  }

  @computed get module() {
    const { curriculum } = this.rootStore;
    return curriculum.modulesByKey[this.moduleKey] || new Module(this.rootStore);
  }

  @computed get unit() {
    const { curriculum } = this.rootStore;

    if (this.unitKey.match(/^(PRE)?MT_/)) {
      const pre = !!this.unitKey.match(/^PREMT_/);

      return new Unit(this.rootStore, {
        key: `${pre ? 'PRE' : ''}MT_${this.moduleKey}`,
        title: `Module ${pre ? 'Pre-' : ''}Test`,
        showQuiz: true,
        questionCount: this.module.questionCount,
        hours: pre ? 0 : 1, // Module Test Always 1 Hour
      });
    }

    return curriculum.unitsByKey[this.unitKey] || new Unit(this.rootStore);
  }

  // number of questions from THIS unit that will ALWAYS appear on module exam
  @computed get minimumTestQuestionCount() {
    return this.unit.alwaysTestQuestionCount;
  }

  @computed get totalTestQuestionCount() {
    return this.unit.totalTestQuestionCount;
  }

  // number of unit quiz questions
  @computed get quizQuestionCount() {
    return this.unit.quizQuestionCount;
  }

  // number of unit worksheet questions
  @computed get worksheetQuestionCount() {
    return this.unit.worksheetQuestions;
  }

  @computed get phase() {
    return new CurriculumPhase(this.unit);
  }

  @computed get title() {
    if (this.isExam) return 'Exam';
    if (this.isTest) {
      if (this.unitKey.match(/^PREMT_/)) {
        return 'Module Pre-Test';
      }
      return 'Module Test';
    }

    return this.unit.title;
  }

  @computed get hours() {
    if (this.removed || !this.unit) {
      return 0;
    }
    return this.unit.hours;
  }

  @computed get hasLessons() {
    return !this.unit.teacherOnly;
  }

  @computed get isOpen() {
    return this.assignment.open && this.open;
  }

  @computed get hasOverride() {
    const { assessmentOpens } = this.rootStore;

    if (!assessmentOpens.byCourse.has(this.courseId)) {
      return false;
    }

    // eslint-disable-next-line consistent-return
    assessmentOpens.byCourse.get(this.courseId).forEach((open) => {
      if (open.assignmentEntryId === this.id) return true;
      if (this.isExam && open.assignmentId === this.assignment.id) return true;
    });

    return false;
  }

  @computed get openStatus() {
    if (this.removed || this.assignment.removed) return 'Removed';
    if (!this.quizOpen) return 'Quiz Closed';

    return this.isOpen ? 'Open' : 'Closed';
  }

  @computed get hasQuiz() {
    return this.unit.showQuiz;
  }

  @computed get isTest() {
    return this.type === 2 && !this.isExam;
  }

  @computed get isPreTest() {
    return !!this.unitKey.match(/^PREMT_/);
  }

  @computed get isGraded() {
    return !this.removed
      && this.isAssessment
      && !(this.isPreTest && !this.assignment.course.preTest);
  }

  @computed get isExam() {
    return !!this.unitKey.match(/^EXAM_/);
  }

  @computed get isAssessment() {
    return this.hasQuiz || this.isTest || this.isExam;
  }

  @computed get fullTitle() {
    if (!this.isAssessment) return 'Lesson';
    if (this.isExam) return 'Exam';
    if (this.isTest) return 'Module Test';
    return `${this.title} Quiz`;
  }

  @computed get prettyType() {
    if (!this.isAssessment) return 'Lesson';
    if (this.isExam) return 'Exam';
    if (this.isTest) return 'Module Test';
    return 'Quiz';
  }

  @action loadAnswerKeyRequest() {
    const { assignments } = this.rootStore;
    return assignments.loadAnswerKeyRequest(this.assignmentId, this.id)
      .then((learnosityRequest) => runInAction(() => {
        this.answerKeyRequest = learnosityRequest;
        return learnosityRequest;
      }));
  }

  // TODO: MOVE API calls to store.
  @action loadAnswerKey() {
    const { WebAPI } = this.rootStore;

    return WebAPI.fetch(
      'GET',
      [
        `teacher/courses/${this.courseId}`,
        `assignments/${this.assignmentId}`,
        `assignment_entries/${this.id}`,
        'answer_key.json',
      ].join('/'),
    )
      .then(action('loadedAnswerKey', (result) => {
        this.answerKey = result.map((data) => new Question(this.rootStore, data));
        return Promise.resolve(this.answerKey);
      }));
  }

  @action loadAnswerKeyPDF() {
    const { WebAPI } = this.rootStore;

    return WebAPI.get(
      [
        `teacher/courses/${this.courseId}`,
        `assignments/${this.assignmentId}`,
        `assignment_entries/${this.id}`,
        'answer_key.pdf',
      ].join('/'),
    )
      .then((result) => WebAPI.pollJob(result.job_id));
  }

  @action setOption(name, value) {
    const { WebAPI } = this.rootStore;

    return WebAPI.patch(
      [
        `teacher/courses/${this.courseId}`,
        `assignments/${this.assignmentId}`,
        `assignment_entries/${this.id}`,
      ].join('/'),
      {
        assignment_entry: {
          [name]: value,
        },
      },
    );
  }

  @action setEnabled(on) {
    this.removed = !on;
    this.setOption('removed', this.removed);
  }

  @action setOpen(on) {
    this.open = !!on;
    this.setOption('open', this.open);
  }

  @action setLessonsOpen(on) {
    this.setOpen(on);
  }

  @action setQuizOpen(on) {
    this.quizOpen = !!on;
    this.setOption('quiz_open', this.quizOpen);
  }
}
