import React, { useEffect, useRef } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import logo from 'data/img/aes-logo-reverse.svg';
import GettingStartedNav from './GettingStartedNav';
import CourseNav from './CourseNav';
import DashboardNav from './DashboardNav';
import CatalogMenu from './CatalogMenu';
import UserMenu from './UserMenu';
import HelpMenu from './HelpMenu';
import AlertBanner from './AlertBanner';
import MigrationWarning from './MigrationWarning';

const TopNav = ({ defaultCourseId, uiState }) => {
  const navRef = useRef();
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const { offsetHeight } = navRef.current;
      if (offsetHeight) {
        uiState.setNavHeight(offsetHeight);
      }
    });

    resizeObserver.observe(navRef.current);

    // disconnect ResizeObserver on effect destroy
    return () => {
      resizeObserver.disconnect();
    };
  }, [navRef.current]);

  return (
    <nav ref={navRef} className="topNav">
      <AlertBanner />
      <MigrationWarning />
      <Link to="/">
        <img src={logo} className="logo" alt="Return to Home" />
      </Link>

      <Switch>
        {!defaultCourseId
          && (
            <Route
              path="*"
              component={GettingStartedNav}
            />
          )}
        {defaultCourseId
          && (
            <Route
              path="/courses/:id(\d+)"
              render={(props) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <CourseNav {...props} />
              )}
            />
          )}
        {defaultCourseId
          && (
            <Route
              path="/"
              render={() => <DashboardNav />}
            />
          )}
      </Switch>

      <div style={{
        float: 'right',
        display: 'flex',
      }}
      >
        {(defaultCourseId)
          && (
            <Switch>
              <Route path="/courses/:id(\d+)" render={() => <CatalogMenu />} />
              <Route path="/courses" render={() => <CatalogMenu />} />
              <Route path="/catalog" render={() => <CatalogMenu />} />
              <Route path="/get-started" render={() => <CatalogMenu />} />
            </Switch>
          )}
        <HelpMenu />
        <UserMenu />
      </div>
    </nav>
  );
};

export default inject('uiState')(observer(TopNav));
