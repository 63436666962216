import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';
import FileButton from 'widgets/form/FileButton';
import TextBox from 'widgets/form/TextBox';

@inject('curriculum') @observer
class CustomModuleView extends Component {
  @observable file

  @observable resourceTitle = ''

  @observable resourceDesc = ''

  @observable error = ''

  @observable showRename = false

  @observable selectedResource = null

  @action.bound handleShowRename = (resource) => {
    this.showRename = true;
    this.selectedResource = resource;
  }

  @action.bound handleModalClose = () => {
    this.showRename = false;
    this.resourceTitle = '';
    this.resourceDesc = '';
    this.error = '';
    this.selectedResource = null;

    this.clearFile();
  }

  @action.bound handleResourceRename = (event) => {
    event.preventDefault();
    if (this.resourceTitle === '') {
      this.error = 'Resource title cannot be blank';
    } else {
      this.module.renameResource(this.selectedResource.id, this.resourceTitle, this.resourceDesc);
      this.handleModalClose();
    }
  }

  @action.bound async handleAddResource(event) {
    event.preventDefault();
    if (this.resourceTitle === '') {
      this.error = 'Resource title cannot be blank';
    } else {
      await this.module.addResource(this.file, this.resourceTitle, this.resourceDesc);
      this.handleModalClose();
    }
  }

  @action.bound setResourceTitle = (value) => {
    this.resourceTitle = value;
  }

  @action.bound setResourceDesc = (value) => {
    this.resourceDesc = value;
  }

  @action.bound setFile = (val) => { this.file = val; }

  @computed get module() {
    const { curriculum, id, moduleKey } = this.props;

    if (moduleKey) {
      return curriculum.customModules.find((m) => m.key === moduleKey);
    }

    return curriculum.customModules.find((m) => m.id === +id);
  }

  @computed get editMode() {
    const { edit } = this.props;
    return !!edit;
  }

  @action.bound clearFile = () => { this.file = null; }

  @action deleteResource(id) {
    return this.module.deleteResource(id);
  }

  render() {
    if (!this.module) return null;

    return (
      <div>
        <h2>
          &ldquo;
          {this.module.title}
          &rdquo;
        </h2>

        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Original Filename</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.module.resources.length === 0
              && (
                <tr>
                  <td colSpan={4}>
                    <i>This custom module doesn&rsquo;t contain any resources.</i>
                  </td>
                </tr>
              )}
            {this.module.resources.map((resource) => {
              const originalName = resource.path.match(/([^?/]+)\?\d+$/)[1];

              return (
                <tr key={resource.id}>
                  <td><b>{resource.title}</b></td>
                  <td>{resource.description || <i>None</i>}</td>
                  <td>{originalName}</td>
                  <td>
                    <a
                      className="button"
                      href={resource.path}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </a>
                    <button type="button" onClick={() => this.handleShowRename(resource)}>
                      Rename
                    </button>
                    {this.editMode
                      && (
                        <button type="button" onClick={() => this.deleteResource(resource.id)}>
                          Delete
                        </button>
                      )}
                  </td>
                </tr>
              );
            })}
            {this.editMode
              && (
                <tr>
                  <td colSpan={4}>
                    <FileButton onSelect={this.setFile}>+ Add Resource</FileButton>
                    <Modal
                      title="Name your resource"
                      visible={this.file}
                      onDismiss={this.handleModalClose}
                    >
                      <form
                        className="editClassForm"
                        onSubmit={(event) => this.handleAddResource(event)}
                      >
                        <p><b>Resource details</b></p>
                        <div className="formContent">
                          <TextBox
                            name="title"
                            label="Title:"
                            value={this.resourceTitle}
                            onChange={(value) => this.setResourceTitle(value)}
                            leftLabel
                          />

                          <TextBox
                            name="description"
                            label="Description:"
                            value={this.resourceDesc}
                            onChange={(value) => this.setResourceDesc(value)}
                            leftLabel
                          />
                          {this.error && <p className="error">{this.error}</p>}
                        </div>

                        <button type="submit">Add Resource</button>
                        <button type="button" className="secondary" onClick={this.handleModalClose}>
                          Cancel
                        </button>
                      </form>
                    </Modal>
                  </td>
                </tr>
              )}
          </tbody>
        </table>

        <Modal
          title="Rename Resource"
          visible={this.showRename}
          onDismiss={this.handleModalClose}
        >
          {this.selectedResource
            && (
              <form
                className="editClassForm"
                onSubmit={(event) => this.handleResourceRename(event)}
              >
                <div className="formHeading">
                  <h2>{this.selectedResource.title}</h2>
                  <p className="subtitle">What would you like to rename this resource?</p>
                </div>

                <div className="formContent">
                  <TextBox
                    name="title"
                    label="Title:"
                    value={this.resourceTitle}
                    onChange={(value) => this.setResourceTitle(value)}
                    leftLabel
                  />
                  <TextBox
                    name="description"
                    label="Description:"
                    value={this.resourceDesc}
                    onChange={(value) => this.setResourceDesc(value)}
                    leftLabel
                  />

                  {this.error && <p className="error">{this.error}</p>}
                </div>

                <button type="submit">Rename Resource</button>
                <button type="button" className="secondary" onClick={this.handleModalClose}>
                  Cancel
                </button>
              </form>
            )}
        </Modal>
      </div>
    );
  }
}

export default CustomModuleView;
