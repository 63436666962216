class APIResponse {
  constructor(apiInstance, request, response) {
    this.apiInstance = apiInstance;
    this.request = request;

    if (response.headers['x-pagination']) {
      const pagination = JSON.parse(response.headers['x-pagination']);
      this.page = 1 + Math.floor(pagination.offset / pagination.per_page);
      this.pagesTotal = Math.ceil(pagination.total / pagination.per_page);
      this.count = pagination.total;
    } else {
      this.page = 1;
      this.pagesTotal = 1;
      this.count = response.data.length || 1;
    }

    this.data = response.data;
  }

  getPage(page = 1, totalPages) {
    const { method, url, headers } = this.request;
    const params = this.request.data || this.request.params;

    if (page < 1 || page > this.pagesTotal) {
      return Promise.reject(new Error('No such page'));
    }

    return this.apiInstance.fetch(method, url, {
      ...params, page, totalPages,
    }, { headers, useResponse: true });
  }

  nextPage() {
    return this.getPage(this.page + 1, this.pagesTotal);
  }
}

export default APIResponse;
