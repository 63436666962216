import React, { Component } from 'react';
import {
  computed,
  action,
  observable,
  runInAction,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import DateRange from 'widgets/form/DateRange';
import DateTime from 'widgets/format/DateTime';
import moment from 'moment';
import Modal from '../../../widgets/Modal';

@inject('students', 'courses', 'router', 'teachers') @observer
class StudentActivity extends Component {
  @observable filters = {
    teacher: {
      id: '',
      name: null,
    },
    action: '',
    classes: {
      id: '',
      name: null,
    },
    date: {
      from: null,
      to: null,
    },
  }

  @observable revert = false

  @observable showExport = false;

  @observable exportFile = '';

  componentDidMount() {
    const {
      courses: { fromRoute: course },
      students: { fromRoute: student },
      teachers,

    } = this.props;
    teachers.loadForCurrentSite();
    student.loadActivity(course);
  }

  @computed get tableData() {
    const {
      students: { fromRoute: student },
    } = this.props;
    return student.activity;
  }

  @computed get filterData() {
    const { filters, revert } = this;
    const classId = parseInt(filters.classes.id, 10);
    const teacherId = parseInt(filters.teacher.id, 10);
    const data = this.tableData
      .filter(((entry) => (filters.action ? entry.action === filters.action : entry.action)))
      .filter(((entry) => (teacherId ? entry.teacher.id === teacherId : entry.teacher)))
      .filter(((entry) => (classId ? entry.course?.id === classId : entry.course)));
    if (revert) data.reverse();
    return data;
  }

  @computed get actions() {
    const { tableData } = this;

    return tableData.reduce((acc, { action: event }) => {
      if (event && !acc.find((el) => el === event)) {
        acc.push(event);
      }
      return acc;
    }, []);
  }

  @computed get teachers() {
    const { tableData } = this;
    return tableData.reduce((acc, { teacher }) => {
      if (teacher?.id && !acc.find((el) => el.id === teacher.id)) {
        acc.push(teacher);
      }
      return acc;
    }, []);
  }

  @computed get classes() {
    const { tableData } = this;

    return tableData.reduce((acc, { course }) => {
      if (course?.id && !acc.find((el) => el.id === course.id)) {
        acc.push(course);
      }
      return acc;
    }, []);
  }

  @action setFilters = (event) => {
    const { target: { name, value } } = event;
    const { filters } = this;
    const intValue = parseInt(value, 10);
    const label = this.tableData.find((entry) => entry[name].id === intValue);

    if (name !== 'course') {
      filters[name] = {
        id: value,
        name: label[name].name,

      };
    } else {
      filters.classes = {
        id: value,
        name: label[name].title,

      };
    }
  }

  @action setAction = (event) => {
    const { filters } = this;
    const { target: { name, value } } = event;
    filters[name] = value;
  }

  @action getDateRangeData = async () => {
    const {
      courses: { fromRoute: course },
      students: { fromRoute: student },
    } = this.props;
    const { filters: { date: { to, from } } } = this;
    const fromFormatted = from ? moment(from).format('YYYY-MM-DD') : null;
    const toFormatted = to ? moment(to).format('YYYY-MM-DD') : null;
    await student.loadActivity(course, fromFormatted, toFormatted);
    this.updateData();
  }

  @action cleanFilter = (filter) => {
    const { filters } = this;
    filters[filter] = '';
  }

  @action updateDate = (from, to) => {
    const { filters: { date } } = this;
    date.from = from;
    date.to = to;
  }

  @action updateData() {
    const { filters } = this;
    filters.teacher = {
      id: '',
      name: null,
    };
    filters.classes = {
      id: '',
      name: null,
    };
    filters.action = '';
  }

  @action loadMoreData() {
    const { students: { fromRoute: student } } = this.props;
    const { filters } = this;
    filters.teacher = {
      id: '',
      name: null,
    };
    filters.classes = {
      id: '',
      name: null,
    };
    filters.action = '';
    this.revert = false;
    student.loadMoreActivity();
  }

  @action resetfilters() {
    const { filters } = this;
    filters.teacher = {
      id: '',
      name: null,
    };
    filters.classes = {
      id: '',
      name: null,
    };
    filters.action = '';
    filters.date.from = null;
    filters.date.to = null;
    this.revert = false;
    this.getDateRangeData();
  }

  @action sortTable() {
    this.revert = !this.revert;
  }

  @action downloadPdf() {
    const {
      courses: { fromRoute: course },
      students: { fromRoute: student },
    } = this.props;
    const { filterData, filters } = this;
    const firstLine = filterData[0];
    const lastLine = filterData[filterData.length - 1];

    const params = {
      start_date: lastLine.time,
      end_date: firstLine.time,
      actions: filters.action ? [`${filters.action}`] : undefined,
      course_ids: filters.classes && filters.classes.id ? [filters.classes.id] : undefined,
      faculty_ids: filters.teacher && filters.teacher.id ? [filters.teacher.id] : undefined,
    };

    this.showExport = true;
    student.loadEventLogsPdf(course, params)
      .then((pdf) => runInAction(() => {
        this.exportFile = pdf.url;
      }));
  }

  @action hideExport() {
    this.showExport = false;
  }

  render() {
    const { router, students: { fromRoute: student } } = this.props;
    const {
      filters, filterData, tableData,
    } = this;

    return (
      <div>
        <Link
          to={`${router.path.replace('tracking', 'list')}`}
          className="button backButton"
        >
          &larr; Return to the student dashboard

        </Link>
        <div className="studentActivityHeader">

          <h1>
            Student Activity:
            {' '}
            {student.name}
          </h1>
          <button type="button" disabled={!(filterData.length > 0)} onClick={() => this.downloadPdf()}>Export PDF</button>
        </div>

        <form className="filters">
          <DateRange
            updateDate={this.updateDate}
            dateRef={filters.date}
            getDateRangeData={this.getDateRangeData}
          />

          {this.actions && this.actions.length > 1 && (
            <div className="selectContainer">
              <i className="material-icons arrow">arrow_drop_down</i>
              {filters && filters.action && (
                <div className="tip">
                  <p>{filters.action.replace('(The student was signed out due to inactivity)', '(Inactivity)')}</p>
                  <button type="button" onClick={() => this.cleanFilter('action')} className="material-icons">clear</button>
                </div>
              )}
              <select value={this.filters.action} name="action" onChange={(e) => this.setAction(e)}>
                <option className="default">All Actions</option>
                {this.actions.map((
                  event,
                ) => (<option key={event} value={event}>{event.replace('(The student was signed out due to inactivity)', '(Inactivity)')}</option>))}
              </select>
            </div>
          )}
          {this.teachers && this.teachers.length > 1 && (
            <div className="selectContainer">
              <i className="material-icons arrow">arrow_drop_down</i>
              {filters && filters.teacher.id && (
                <div className="tip">
                  <p>{filters.teacher && filters.teacher.name}</p>
                  <button type="button" onClick={() => this.cleanFilter('teacher')} className="material-icons">clear</button>
                </div>
              )}
              <select value={this.filters.teacher} name="teacher" onChange={(e) => this.setFilters(e)}>
                <option className="default">All Teachers</option>
                {this.teachers.map(({
                  id, name,
                }) => (<option key={id} value={id}>{name}</option>))}
              </select>
            </div>
          )}
          {this.classes && this.classes.length > 1 && (
            <div className="selectContainer">
              <i className="material-icons arrow">arrow_drop_down</i>
              {filters && filters.classes && filters.classes.id && (
                <div className="tip">
                  <p>{filters.classes && filters.classes.name}</p>
                  <button type="button" onClick={() => this.cleanFilter('classes')} className="material-icons">clear</button>
                </div>
              )}
              <select value={this.filters.classes && this.filters.classes.id} name="course" onChange={(e) => this.setFilters(e)}>
                <option className="default">All Classes</option>
                {this.classes.map(({
                  id, title,
                }) => (<option key={id} value={id}>{title}</option>))}
              </select>
            </div>
          )}
          {(filters.classes.id || filters.teacher.id || filters.action || filters.date.from) && <button type="button" onClick={() => this.resetfilters()} className="resetfilters">Reset Filters</button>}
        </form>

        <table className="recentActivity">
          <thead>
            <tr>
              <th>
                Date & Time
                <button type="button" onClick={() => this.sortTable()} className="material-icons sort">unfold_more</button>
              </th>
              <th>Action</th>
              <th>Details</th>
              <th>Class</th>
              <th>Teacher</th>
            </tr>
          </thead>
          <tbody>
            {filterData.map(({
              action: event, teacher: { name: teacherName }, id, time, course: { title: className }, desc = '',
            }) => (
              <tr key={id}>
                <td className="time">
                  <DateTime value={time} format="MM/DD/YYYY hh:mm a" />
                </td>
                <td className="event">
                  {event}
                </td>
                <td className="details">
                  {desc.split('\n')
                    .map((n) => <span key={n}>{n}</span>)}
                </td>
                <td>
                  {className}
                </td>
                <td>
                  {teacherName}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {filterData.length <= 0 && (
          <div className="empty">
            <h2>{tableData.length <= 0 ? 'No activity found with your Date Range' : 'No activity found with your selected filters'}</h2>
            <p>{tableData.length <= 0 ? 'Adjust your dates and try again' : 'Adjust your filters and try again'}</p>
          </div>
        )}
        <Modal
          title={`Activity Audit: ${student.firstName} ${student.lastName}`}
          visible={this.showExport}
          onDismiss={() => this.hideExport()}
        >
          <p style={{ margin: 0 }}>
            {'You can download the activity audit for '}
            {student.firstName}
            {' '}
            {student.lastName}
            {' using the link below.'}
          </p>
          {this.exportFile
            ? (
              <a
                href={this.exportFile}
                target="aes_activity_audit"
                onClick={() => this.hideExport()}
              >
                <p>Open Activity Audit PDF</p>
              </a>
            )
            : <p>Loading...</p>}
          <button type="button" onClick={() => this.hideExport()}>Close</button>
        </Modal>
      </div>
    );
  }
}

export default StudentActivity;
