/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import DateTime from 'widgets/format/DateTime';

@inject('auth') @observer
class Subscription extends Component {
  render() {
    const { auth: { site, user } } = this.props;

    const downloadsLeft = Math.max(0, site.maxDownloads - user.downloads);
    const daysLeft = Math.max(0, site.daysLeft);
    const percentLeft = (daysLeft / 30) * 100;

    return (
      <div>
        <h1>Subscription</h1>

        <div className="sitePackage">
          Package:
          {' '}
          {site.prettyPackage}
        </div>

        {site.isCustomer && (
          <div>
            <p>
              Thank you for being an AES customer!
              <br />
              <br />
              You have product access through
              {' '}
              <DateTime value={site.expiration} format="MMMM Do, YYYY" />
              .
            </p>
            {site.useStudentLicenses
              && (
                <p>
                  {`Your site is using ${site.activeStudents} student licenses out of ${site.maxStudents}.`}
                </p>
              )}
            <p>
              Questions about your subscription?
              {' '}
              <a href="javascript:Intercom('show')">Contact us!</a>
            </p>
          </div>
        )}

        {site.isTrial && (
          <div>
            <p>
              {!site.isExtendedTrial && (
                <div>
                  You have
                  {' '}
                  {daysLeft}
                  {' '}
                  days left in your free trial.

                  <div className="trialProgress">
                    <div
                      className={`bar${percentLeft <= 25 ? ' danger' : ''}`}
                      style={{ width: `${percentLeft}%` }}
                    />
                  </div>
                </div>
              )}

              {site.isExtendedTrial && (
                <div>
                  <span>Your trial will expire </span>
                  <DateTime value={site.expiration} format="MMMM Do, YYYY" />
                  .
                </div>
              )}

              <p>
                You can download
                {' '}
                {downloadsLeft}
                {' '}
                more resources.
              </p>
            </p>

            {daysLeft === 0
              && (
                <div>
                  <h2>Your Trial is Expired.</h2>
                  <p>
                    <span>Thanks for giving us a try! Want to continue using AES? </span>
                    <a href="javascript:Intercom('show')">Contact us!</a>
                  </p>
                </div>
              )}

            <h2>Frequently Asked Questions</h2>

            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.aeseducation.com/cost-of-curriculum-resources-for-cte"
              >
                How much does the subscription cost after the free trial is over?
              </a>
            </p>
            <p>
              <span>Don’t know how to get funding? Check out this </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.aeseducation.com/blog/how-to-get-cte-funding"
              >
                guide
              </a>
              !
            </p>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://help.aeseducation.com/your-subscription-or-trial-account/trials/resource-views-and-download-limits"
              >
                How do I access more resources?
              </a>
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default Subscription;
