import { action, observable } from 'mobx';
import axios from 'axios';
import APIError from './APIError';
import APIResponse from './APIResponse';

function getTimezone() {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    return 'America/New_York';
  }
}

const timeZone = getTimezone();

/**
 * Returns the base url for communicating with a named server
 * @private
 * @param {string} server - The canonical name of the server, e.g. 'production'
 * @return {string} - URI without trailing slash, e.g. http://localhost:3000
 */
export function baseURL(server) {
  const {
    REACT_APP_API_BASE_URL,
    REACT_APP_PRODUCTION_API_BASE_URL,
    REACT_APP_DEMO_API_BASE_URL,
    REACT_APP_STAGING_API_BASE_URL,
  } = process.env;

  if (REACT_APP_API_BASE_URL) {
    return REACT_APP_API_BASE_URL;
  }

  if (typeof server !== 'string') throw new Error('Server must be a string');

  switch (server.toLowerCase()) {
    case 'production':
      return REACT_APP_PRODUCTION_API_BASE_URL;
    case 'staging':
      return REACT_APP_STAGING_API_BASE_URL;
    case 'demo':
      return REACT_APP_DEMO_API_BASE_URL;
    case 'local':
    case 'localhost':
      return `http://${window.location.hostname}:3002`;
    default:
      throw new Error(`No such server: '${server}'`);
  }
}

export function triggerDownload(input, filename) {
  if (!(input instanceof Blob)) throw new Error('Can only download Blobs');

  // IE specific version
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(input, filename);
    return;
  }

  // Create a temporary anchor to use for the download
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  // Trigger download
  const dataUrl = window.URL.createObjectURL(input);
  a.href = dataUrl;
  a.download = filename;
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(dataUrl);
  document.body.removeChild(a);
}

export default class WebAPI {
  VERSION = process.env.REACT_APP_VERSION;

  @observable lastRequest = Date.now()

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action fetch(method, resource, params = {}, options = {}) {
    const { auth, uiState } = this.rootStore;
    if (!options.quiet) uiState.startRequest();
    this.lastRequest = Date.now();

    return this.fetchResource(method, resource, params, options)
      .then((result) => {
        if (params.totalPages > 1 && params.page) {
          if (params.page === params.totalPages) {
            uiState.finishAllRequests();
          }
        } else if (!options.quiet) uiState.finishRequest();

        return Promise.resolve(result);
      })
      .catch((err) => {
        if (!options.quiet) {
          uiState.finishRequest();
        }

        if (err.code === 403) {
          auth.handleError(err).catch(() => {
            // trap promise rejection
          });
        }

        return Promise.reject(err);
      });
  }

  get(resource, params, options) {
    return this.fetch('GET', resource, params, options);
  }

  post(resource, params, options) {
    return this.fetch('POST', resource, params, options);
  }

  put(resource, params, options) {
    return this.fetch('PUT', resource, params, options);
  }

  patch(resource, params, options) {
    return this.fetch('PATCH', resource, params, options);
  }

  delete(resource, params, options) {
    return this.fetch('DELETE', resource, params, options);
  }

  pollJob(jobId, intervalMs = 700, timeoutMs = 30000, showLoading) {
    const { uiState } = this.rootStore;
    return new Promise((resolve, reject) => {
      if (showLoading) {
        uiState.startRequest();
      }
      const poll = setInterval(() => {
        this.get(`jobs/${jobId}`, undefined, { quiet: true })
          .catch((err) => {
            if (showLoading) {
              uiState.finishRequest();
            }

            if (err.message === 'Not Found') {
              return Promise.resolve({ status: 'queued' });
            }
            return Promise.reject(err);
          })
          .then((res) => {
            if (res.status === 'completed') {
              if (showLoading) {
                uiState.finishRequest();
              }
              clearInterval(poll);
              resolve(res);
            }
          })
          .catch((err) => {
            clearInterval(poll);
            reject(err);
          });
      }, intervalMs);

      setTimeout(() => {
        clearTimeout(poll);
        reject(new Error('Server timed out'));
      }, timeoutMs);
    });
  }

  /**
   * Perform an actual fetch request and perform essential promise handling
   * @private
   * @param {string} method - HTTP method [GET, PUT, PATCH, ...]
   * @param {string} resource - The requested resource, either an absolute url
   *                            with protocol specifier
   *                            or an api relative resource name
   * @param {object} params - The request body or query params for GET
   * @return {Promise} A promise for an object containing the response body
   */
  fetchResource(type, resource, params = {}, options = {}) {
    const apiInstance = this;
    const method = type.toUpperCase();
    const { auth } = this.rootStore;
    const { server, token } = auth;
    const base = baseURL(server);
    const version = options.version || 'v1';

    if (!['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
      return Promise.reject(new Error(`Invalid method ${method}`));
    }

    const headers = options.headers || {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Auth-Token': token,
      'X-TZ': timeZone,
    };

    // This call fails if the custom header is provided
    if (type === 'POST' && resource === 'authenticate') {
      delete headers['X-Auth-Token'];
    } else if (!headers['X-Auth-Token'] && !resource.match(/https?:\/\//)) {
      const error = new Error('No authentication token provided');
      error.code = 403;
      return Promise.reject(error);
    }

    const request = {
      baseURL: `${base}/${version}/`,
      url: resource,
      method,
      headers,
      params: method === 'GET' ? params : undefined,
      data: method !== 'GET' ? params : undefined,
      responseType: options.binary ? 'blob' : 'json',
    };

    return axios.request(request)
      .then((response) => {
        if (!options.ignoreActivity) {
          auth.setActive();
        }

        if (options.useResponse) {
          return new APIResponse(apiInstance, request, response);
        }
        return response.data;
      })
      .catch((err) => {
        if (!err.response) throw err;

        throw new APIError(
          method,
          resource,
          err.response,
          err.response.data,
        );
      });
  }
}
