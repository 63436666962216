import React, { Component } from 'react';
import {
  action, autorun, computed, observable,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import ExamItemList from 'widgets/exam/ExamItemList';
import ExamItemEditor from 'widgets/exam/ExamItemEditor';
import TabControl from 'widgets/navigation/TabControl';

@inject('assignments', 'courses', 'learnosityItems') @observer
class ExamAddItems extends Component {
  @observable selectedModule = '';

  @observable editorVisible = false;

  componentDidMount() {
    const { exam } = this;
    autorun(() => {
      if (exam.id) {
        exam.getQuestions()
          .then(() => exam.loadPreview());
      }
    });
  }

  componentWillUnmount() {
    const { learnosityItems } = this.props;
    learnosityItems.resetPreview();
  }

  @action onSelectModule(event) {
    const { target: { value } } = event;
    this.selectedModule = value;
    if (value) {
      this.exam.loadAvailableItems(value);
    }
  }

  @action onClickAddItem(reference) {
    this.exam.addItem(reference);
  }

  @action onClickCreate() {
    this.editorVisible = true;
  }

  @computed get exam() {
    const { assignments: { fromRoute: assignment } } = this.props;
    return assignment?.exam || {};
  }

  @computed get moduleKeys() {
    const { courses: { fromRoute: { moduleKeys } } } = this.props;
    return moduleKeys;
  }

  @action hideEditor() {
    this.editorVisible = false;
  }

  render() {
    const {
      moduleKeys,
      exam,
      editorVisible,
    } = this;

    const {
      id,
      assignmentId,
      title,
      availableItems = [],
    } = exam;

    const aesItems = availableItems.filter((item) => item.ownerType === 'AES');
    const customItems = availableItems.filter((item) => item.ownerType !== 'AES' && item.examId === id);

    return (
      <div className="create-exam">
        <div className="formField">
          <Link to={`../${assignmentId}`} className="button">
            &larr; Return to Exam Details
          </Link>
        </div>

        <div className="heading">
          <h1>{`Add Questions to: ${title}`}</h1>
        </div>

        <TabControl>
          <div name="AES Question Bank">
            <ExamItemList
              items={aesItems}
              moduleKeys={moduleKeys}
              onSelectModule={(event) => this.onSelectModule(event)}
              onClickAdd={(reference) => this.onClickAddItem(reference)}
              requireModule
            />
          </div>
          <div name="Custom Questions">
            <div className="action create-question">
              <button type="button" onClick={() => this.onClickCreate()}>
                Create New
              </button>
            </div>
            { customItems.length > 0
              ? (
                <ExamItemList
                  items={customItems}
                  moduleKeys={moduleKeys}
                  onClickAdd={(reference) => this.onClickAddItem(reference)}
                />
              )
              : (
                <div className="empty-message">
                  <p>No questions to show yet.</p>
                </div>
              )}

          </div>
        </TabControl>

        <ExamItemEditor
          visible={editorVisible}
          onDismiss={() => this.hideEditor()}
        />
      </div>
    );
  }
}

export default ExamAddItems;
