/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

export default function ToggleModal({
  children, onDismiss, visible, className,
}) {
  const style = { display: visible ? 'block' : 'none' };
  return (
    <div className="overlay dropdowns" onClick={onDismiss} style={style}>
      <div className={`popup ${className}`} onClick={(ev) => ev.stopPropagation()}>

        <div className="content">
          {children}
        </div>
      </div>
    </div>
  );
}
