// eslint-disable-next-line max-classes-per-file
import { Channel, createCable } from '@anycable/web';

class BaseChannel extends Channel {
  async join(params) {
    return this.perform('join', params);
  }

  async leave(params) {
    return this.perform('leave', params);
  }

  receive(message) {
    if (message.action) {
      return this.emit('action', message);
    }
    return super.receive(message);
  }
}

class FacultyChannel extends BaseChannel {
  static identifier = 'FacultyChannel'
}

class FacultyCourseChannel extends BaseChannel {
  static identifier = 'FacultyCourseChannel'

  async join(courseId) {
    return super.join({ course_id: courseId });
  }

  async leave(courseId) {
    return super.leave({ course_id: courseId });
  }
}

export default class WebSocketDispatch {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  onAuthenticate() {
    const { auth } = this.rootStore;
    const {
      isLocal,
      isStaging,
      originalToken,
    } = auth;
    const getRoot = () => {
      if (isLocal) return 'ws://localhost:3334/cable';
      if (isStaging) return 'wss://api.staging.aeseducation.com/cable';
      return 'wss://api.aeseducation.com/cable';
    };

    if (!isStaging || isLocal) {
      /*
       * Return if this is the Jest environment, or running local.
       * Remove the second condition to allow connecting to a local cable instance for dev
       */
      return;
    }

    this.cable = createCable(`${getRoot()}?role=teacher&token=${originalToken}`);
    this.facultyChannel = new FacultyChannel();
    this.facultyCourseChannel = new FacultyCourseChannel();
    this.facultyCourseChannel.on('action', (message) => {
      this.rootStore.dispatchUpdate(message);
    });

    this.cable.subscribe(this.facultyChannel);
    this.cable.subscribe(this.facultyCourseChannel);
    this.cable.connect();
  }

  onCourseChange(courseId) {
    // Channel not initialized
    if (!this.facultyCourseChannel) {
      return;
    }

    // Already Subscribed
    if (this.courseId && this.courseId === courseId) {
      return;
    }

    if (this.courseId) {
      this.facultyCourseChannel.leave(courseId);
    }
    this.facultyCourseChannel.join(courseId);
    this.courseId = courseId;
  }
}
