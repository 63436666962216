/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import TeacherImpersonation from 'widgets/auth/TeacherImpersonation';

@inject('auth', 'router') @observer
class UserMenu extends Component {
  onSwitchToBeta() {
    const { auth } = this.props;
    auth.user.updateProfile({ beta: 'true' });
  }

  onSwitchToNormal() {
    const { auth } = this.props;
    auth.user.updateProfile({ beta: 'false' });
  }

  @computed get siteSubtitle() {
    const { auth } = this.props;

    if (!auth.site) return '';
    if (auth.site.isTrial) {
      const { daysLeft } = auth.site;

      if (daysLeft < 1) return 'Free Trial - EXPIRED';

      return `Free Trial - ${daysLeft} days left`;
    }

    return auth.site.name;
  }

  render() {
    const { auth } = this.props;

    return (
      <div className="userMenu" data-testid="userMenu">
        <div className="user">
          <div
            className="userAvatar avatar"
            style={{ backgroundImage: `url(${auth.user.avatarUrl})` }}
          />
          <div className="dropdown">
            <i className="material-icons">arrow_drop_down</i>
          </div>
        </div>
        <div className="dropdownMenu" data-testid="dropdownMenu">
          <TeacherImpersonation />
          <Link className="catalog" to="/catalog">
            <i className="material-icons">view_module</i>
            Catalog
          </Link>
          {auth.enableBetaReturn
            && (
            <a href={auth.normalVersionURL} onClick={() => this.onSwitchToNormal()}>
              <i className="material-icons">undo</i>
              Return to the current version
            </a>
            )}
          <Link to="/settings">
            <i className="material-icons">settings</i>
            Settings
          </Link>
          <Link to="/archive">
            <i className="material-icons">delete</i>
            Deleted Items
          </Link>
          <Link className="logout" to="/logout">
            <i className="material-icons">exit_to_app</i>
            Sign Out
          </Link>
        </div>
      </div>
    );
  }
}

export default UserMenu;
