import {
  observable,
  computed,
  action,
  autorun,
  when,
  runInAction,
} from 'mobx';
import Template from 'models/Template';
import ModuleGroup from 'models/ModuleGroup';
import Module from 'models/Module';
import Unit from 'models/Unit';
import Lesson from 'models/Lesson';
import CustomModule from 'models/CustomModule';
import states from 'data/states.json';
import moduleGroupData from 'data/module-groups.json';

const injectAPModuleGroup = (template) => {
  const injectAtIndex = (index) => template.moduleData.splice(index, 0, {
    template_key: 'HS',
    module_group_key: 'AP',
    sequence: index + 1,
  });

  switch (template.key) {
    case 'FR':
    case 'EM':
    case 'MD':
    case 'TNEMS':
      injectAtIndex(1);
      break;
    case 'HS':
      injectAtIndex(6);
      break;
    case 'CERT-NCHSE':
    case 'CERT-CCMA':
    case 'CERT-NOCTI-MA':
    case 'SC-5551-2016':
    case 'TNHS':
      injectAtIndex(5);
      break;
    case 'CERT-CET':
    case 'CERT-NOCTI-HA':
    case 'FL-8417110-2018-2019':
    case 'SC-5531':
      injectAtIndex(4);
      break;
    case 'CERT-CPT':
      injectAtIndex(2);
      break;
    case 'SC-5532':
    case 'SC-5533':
    case 'SC-5560':
      injectAtIndex(3);
      break;
    default:
      break;
  }
};

/* eslint-disable no-param-reassign */
class CurriculumStore {
  @observable templates = []

  @observable moduleGroups = []

  @observable modules = []

  @observable units = []

  @observable lessons = {}

  @observable loaded = false

  @observable customModules = []

  constructor(rootStore, api) {
    this.api = api;
    this.rootStore = rootStore;
    const { auth } = this.rootStore;

    when(() => auth.loggedIn, () => {
      const mode = auth.isProduction || auth.isDemo ? 'production' : 'staging';
      const baseUrl = `https://media.aeseducation.com/catalog/${mode}-`;
      const params = { version: this.api.VERSION };

      Promise.all([
        this.api.get(`${baseUrl}templates.json`, params, { headers: {} }),
        this.api.get(`${baseUrl}modules.json`, params, { headers: {} }),
        this.api.get(`${baseUrl}units.json`, params, { headers: {} }),
        this.api.get(`${baseUrl}page-sets.json`, params, { headers: {} }),
      ])
        .then(([templateData, moduleData, unitData, lessonData]) => {
          this.loadData(templateData, moduleData, unitData, lessonData);
        });
    });

    // reload custom modules whenever token changes i.e. impersonation
    autorun(() => {
      if (auth.token) {
        this.loadCustomModules();
      }
    });
  }

  @action loadData(templateData = [], moduleData = [], unitData = [], lessonData = []) {
    // Load json data
    this.templates = templateData.map((temp) => new Template(this.rootStore, temp));
    this.moduleGroups = moduleGroupData.map((group) => new ModuleGroup(this.rootStore, group));
    this.modules = moduleData.map((mod) => new Module(this.rootStore, mod));
    this.units = unitData.map((unit) => new Unit(this.rootStore, unit));

    const lessonEntries = Object.entries(lessonData);
    this.lessons = Object.fromEntries(lessonEntries.map(([key, values]) => (
      [key, new Lesson(this.rootStore, values)]
    )));

    // Cross Reference Mods/Units
    this.modules.forEach((mod) => {
      mod.units.forEach((unit) => {
        unit.modules.push(mod);
      });
    });

    // Cement aliases
    this.templates.forEach((template) => {
      if (template.alias) {
        const keys = template.alias.split(',');
        const refs = keys.map((k) => this.templatesByKey[k]);

        template.moduleKeys = refs.reduce(
          (all, templ) => all.concat(templ.moduleKeys), template.moduleKeys,
        );
      }

      // TODO: remove this when module groups are implemented as part of build process
      injectAPModuleGroup(template);
    });

    // HACK: Override values for Community Content
    const cc = this.templates.find((t) => t.key === 'CC');
    if (cc) {
      cc.state = 'ALL';
      cc.public = true;
    }

    this.loaded = true;
    // eslint-disable-next-line no-underscore-dangle
    window._appReady = true;
  }

  // Will load custom modules that are mine and custom modules for
  // courses that have been shared with me
  @action async loadCustomModules() {
    const result = await this.api.get('teacher/custom_modules') || [];

    action(() => {
      this.customModules = result.map((r) => new CustomModule(this.rootStore, r));
    })();
  }

  @action loadLessonDetails(moduleKey) {
    const module = this.modulesByKey[moduleKey];

    this.api.get(`teacher/lessons/${moduleKey}`, {}, { version: 'v2' })
      .then((response) => {
        if (response.length) {
          response.forEach((values) => {
            const lesson = this.lessons[values.page_set_key];

            if (lesson) {
              runInAction(() => {
                lesson.extra = values.page_set_extra;
              });
            }
          });
          runInAction(() => {
            module.lessonDetailsLoaded = true;
          });
        }
      });
  }

  @action async createCustomModule(title) {
    const result = await this.api.post(
      'teacher/custom_modules',
      { title },
    );
    const newModule = new CustomModule(this.rootStore, result);

    runInAction(() => {
      this.customModules.push(newModule);
    });

    return newModule;
  }

  @action searchCustomModule(moduleKey) {
    return this.api.get(`teacher/custom_modules/search/${moduleKey}`);
  }

  @action removeCustomModule(moduleKey) {
    this.customModules = this.customModules.filter((module) => module.key !== moduleKey);
  }

  @action deleteCustomModule(moduleKey) {
    return this.api.delete(`teacher/custom_modules/${moduleKey}`).then(() => {
      this.removeCustomModule(moduleKey);
    });
  }

  // Only mine - for the list that can be edited
  @computed get myCustomModules() {
    const { auth } = this.rootStore;

    return this.customModules
      && this.customModules.filter((m) => !m.facultyId || m.facultyId === auth.user.id);
  }

  @computed get visibleTemplates() {
    const { auth } = this.rootStore;
    const { site } = auth;
    const state = (site.state && site.state.length > 0) ? site.state : null;
    let visible = this.templates;

    // Filter Site Product Exclusions
    if (!site.enableHealth) {
      visible = visible.filter((t) => !(t.state === 'ALL' && t.subject === 'health'));
    }
    if (!site.enableBusiness) {
      visible = visible.filter((t) => !(t.state === 'ALL' && t.subject === 'business'));
    }

    // Site Specific and State
    visible = visible.filter((t) => {
      if (t.public) return true;
      if (site.templates.find((st) => st === t.key)) return true;
      if (state === t.state) return true;

      return false;
    });

    return visible;
  }

  @computed get stateTemplates() {
    const { auth } = this.rootStore;
    const { site } = auth;
    const state = (site.state && site.state.length > 0) ? site.state : null;
    const subject = site.isHealth ? 'health' : 'business';
    if (site.state === null) return [];
    return this.templates.filter((t) => state === t.state && subject === t.subject);
  }

  @computed get templatesByKey() {
    return this.templates.reduce(
      (obj, temp) => {
        obj[temp.key] = temp;
        return obj;
      },
      { XCT: this.customModulesTemplate },
    );
  }

  @computed get moduleGroupsByKey() {
    return this.moduleGroups.reduce(
      (acc, group) => {
        acc[group.key] = group;
        return acc;
      },
      {},
    );
  }

  @computed get modulesByKey() {
    const byKey = (obj, mod) => {
      obj[mod.key] = mod;
      return obj;
    };

    return this.modules.reduce(
      byKey,
      this.customModules.reduce(byKey, {}),
    );
  }

  @computed get unitsByKey() {
    return this.units.reduce(
      (obj, unit) => {
        obj[unit.key] = unit;
        return obj;
      },
      {},
    );
  }

  @computed get customModulesTemplate() {
    return new Template(this.rootStore, {
      id: -1,
      key: 'XCT',
      title: 'My Custom Modules',
      description: 'Modules created from scratch using the custom modules feature',
      template_modules: this.myCustomModules.map((m) => m.key),
    });
  }

  @computed get defaultSections() {
    const { auth } = this.rootStore;
    const { site } = auth;
    const sections = [];
    const addSection = (section) => (section.templates.length > 0) && sections.push(section);

    const visible = this.visibleTemplates;
    const stateTemplates = this.stateTemplates.map((t) => t.key);
    const customNotLegacy = visible
      .filter((t) => t.isCustomNotLegacy && t.state !== site.state)
      .map((t) => t.key);
    const health = visible.filter((t) => t.isHealth).map((t) => t.key);
    const business = visible.filter((t) => t.isBusiness).map((t) => t.key);
    const legacy = visible.filter((t) => t.isCustomLegacy).map((t) => t.key);

    // State Templates
    addSection({
      title: `${states[auth.site.state]} Templates`,
      subject: 'state',
      templates: stateTemplates,
    });

    // Custom Templates First (Except Legacy)
    addSection({
      title: `${site.name} Site Templates`,
      subject: 'custom',
      templates: [...customNotLegacy, 'XCT'],
    });

    // Default Product Next - Sorted by Site Product
    if (site.isHealth) {
      addSection({
        title: 'HealthCenter21 Templates',
        subject: 'health',
        templates: health,
      });
      addSection({
        title: 'Business&ITCenter21 Templates',
        subject: 'business',
        templates: business,
      });
    } else {
      addSection({
        title: 'Business&ITCenter21 Templates',
        subject: 'business',
        templates: business,
      });
      addSection({
        title: 'HealthCenter21 Templates',
        subject: 'health',
        templates: health,
      });
    }

    // Legacy Templates
    addSection({
      title: 'Legacy Templates',
      subject: 'custom',
      templates: legacy,
    });

    return sections;
  }
}

export default CurriculumStore;
