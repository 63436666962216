import { computed, extendObservable } from 'mobx';

const createUrl = (extra = '', version = '1', origin = '') => {
  const parts = extra.split('~');
  const path = parts.length > 1
    ? parts[1]
    : parts[0];

  if (parts[0] === 'KEYBOARDING') {
    return `${origin}/keyboarding/index.html`;
  }

  return origin + path.replace('/media', `/${version}/media`);
};

class Lesson {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    extendObservable(this, {
      key: input.page_set_key,
      unitKey: input.unit_key,
      title: input.title,
      sequence: input.sequence,
      extra: '',
    });
  }

  @computed get unit() {
    const { curriculum } = this.rootStore;

    return curriculum.unitsByKey[this.unitKey];
  }

  @computed get version() {
    const { auth } = this.rootStore;

    return auth.isProduction
      ? this.unit.versionProduction
      : this.unit.versionStaging;
  }

  @computed get url() {
    const { auth } = this.rootStore;

    return this.extra && createUrl(this.extra, this.version, auth.lessonOrigin);
  }
}

export default Lesson;
