import React from 'react';
import { Link } from 'react-router-dom';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import PrePostDetails from 'widgets/grade/PrePostDetails';

@inject('courses', 'router') @observer
class PrePostAll extends React.Component {
  @observable details

  @action showDetails = (id) => { this.details = id; }

  @action hideDetails = () => { this.details = null; }

  render() {
    const { courses: { fromRoute: course } } = this.props;
    const assignments = course.assignments.filter((a) => a.hasTest);
    const columns = assignments.length;
    const testSettings = `/courses/${course.id}/assignments/settings/quizzes-tests`;

    return (
      <React.Fragment>
        {!course.preTest
          && (
          <p className="noPrint prePostInfo">
            <b>Module pre-tests are not currently enabled.</b>
            <br />
            <span>
              <Link to={testSettings}>Click here</Link>
              {' '}
              to visit the settings and turn them on.
            </span>
          </p>
          )}

        <p className="noPrint prePostInfo">
          See the change in test score between pre-test and
          {' '}
          <br />
          post-test for students who have completed both.
        </p>

        <table className="worksheetGrid">
          <thead>
            <tr>
              <th>Student</th>
              {assignments.map((assignment) => (
                <th className="assignmentTitle" key={assignment.id}>
                  <span>{assignment.title}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            { course.students.length === 0
              && (
              <tr>
                <td />
                <td />
                <td colSpan={columns}>
                  No students currently in this class.
                </td>
              </tr>
              )}

            { course.grades.length < 0
              && (
              <tr>
                <td />
                <td />
                <td colSpan={columns}>
                  Loading results...
                </td>
              </tr>
              )}

            { course.students.map((student) => {
              const grade = student.gradesByCourse.get(course.id);

              return (
                <tr key={student.id}>
                  <td className="studentName">
                    <Link to={`/courses/${course.id}/grades/pre-post/${student.id}`}>
                      {student.formatName}
                    </Link>
                  </td>
                  { assignments.map((assignment) => {
                    const pretest = grade && grade.workByUnit[`PREMT_${assignment.moduleKey}`];
                    const postest = grade && grade.workByUnit[`MT_${assignment.moduleKey}`];
                    const diff = pretest && postest
                      ? (postest.percentEarned - pretest.percentEarned) : null;
                    const linkClass = () => {
                      if (diff == null) return 'anchor';
                      if (diff > 0) return 'anchor positive';
                      return 'anchor negative';
                    };
                    let content = '-';

                    if (!assignment.hasTest) {
                      content = 'N/A';
                    } else if (pretest && postest) {
                      if (pretest.isComplete && postest.isComplete) {
                        content = (
                          <span>
                            {diff > 0 && '+'}
                            {diff && diff.toFixed(1)}
                            %
                          </span>
                        );
                      }
                    }

                    if (course.enableBeta) {
                      return (
                        <td key={assignment.id} className="prepostResult">
                          { content === '-'
                            ? content
                            : (
                              <Link
                                to={`/courses/${course.id}/grades/pre-post/${student.id}/${assignment.id}`}
                                className={linkClass()}
                              >
                                {content}
                              </Link>
                            ) }
                        </td>
                      );
                    }

                    const id = `${student.id}.${assignment.id}`;
                    return (
                      <td key={assignment.id} className="prepostResult">
                        <button
                          type="button"
                          className={linkClass()}
                          onClick={() => this.showDetails(id)}
                        >
                          {content}
                        </button>
                        <PrePostDetails
                          visible={this.details === id}
                          onDismiss={this.hideDetails}
                          student={student}
                          assignment={assignment}
                          grade={grade}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default PrePostAll;
