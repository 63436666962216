// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory, createMemoryHistory } from 'history';
import { matchPath } from 'react-router';
import { extendObservable, computed, action } from 'mobx';

class RouterStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.history = process.env.NODE_ENV === 'test'
      ? createMemoryHistory()
      : createBrowserHistory();
    extendObservable(this, { location: this.history.location });

    // Initial location
    this.navigateTo(this.history.location);

    // Listen for future changes
    this.history.listen((location) => this.navigateTo(location));
  }

  @computed get path() {
    return this.location.pathname;
  }

  @computed get query() {
    if (!this.location || !this.location.search) return {};

    const props = {};

    const search = this.location.search.replace(/^\?/, '');
    const vars = search.split('&');
    vars.forEach((str) => {
      if (str === '') return;

      const parts = str.split('=');
      props[parts[0]] = decodeURIComponent(parts.slice(1).join('='));
    });

    return props;
  }

  @computed get courseId() {
    const match = matchPath(this.path, '*/courses/:courseId');

    if (match) {
      return parseInt(match.params.courseId, 10);
    }

    return null;
  }

  @computed get studentId() {
    const match = matchPath(this.path, {
      path: [
        '*/list/:id/*',
        '*/list/:id',
        '*/by-student/:id',
        '*/tracking/:id',
        '*/pre-post/:id/*',
        '*/pre-post/:id',
      ],
      exact: true,
    });

    if (match) {
      return parseInt(match.params.id, 10);
    }
    return null;
  }

  @computed get assignmentId() {
    const match = matchPath(this.path, '*/assignments/view/:id');
    if (match) {
      const { params: { id } } = match;
      return parseInt(id, 10);
    }
    return null;
  }

  @action navigateTo(location) {
    const { auth } = this.rootStore;
    this.location = location;

    const match = this.path.match(/\/courses\/(\d+)/);
    if (match) {
      const newCourseId = match && match[1];
      if (newCourseId !== this.currentCourseId) {
        this.currentCourseId = newCourseId;
        this.rootStore.onCourseChange(newCourseId);
      }
    }

    if (this.query.token && auth.token !== this.query.token) {
      auth.setToken(this.query.token || auth.token);
      auth.setServer(this.query.server || auth.server);
      this.initialCourseId = this.query.courseId;
    }

    if (auth.loggedIn) {
      const sanitizedUrl = this.path.replace(/\/\d+/g, '/single');
      /* eslint-disable no-undef */
      ga('set', 'page', sanitizedUrl);
      ga('send', 'pageview');
      /* eslint-enable no-undef */
      auth.setActive();
      return;
    }

    if (auth.token && !auth.verified) {
      // eslint-disable-next-line no-undef
      ga('set', 'page', this.path);
      auth.verifyLogin().then(() => {
        if (!auth.loggedIn) {
          this.push('/login');
        } else if (this.initialCourseId) {
          this.push(`/courses/${this.initialCourseId}/dashboard/overview`);
        } else if (this.path === '/login') {
          this.push(this.query.next || '/');
        }

        // eslint-disable-next-line no-undef
        ga('send', 'pageview');
      });
    } else if (location.pathname !== '/login') {
      this.replace('/login');
    }
  }

  push(location) {
    this.history.push(location);
  }

  replace(location) {
    this.history.replace(location);
  }

  go(n) {
    this.history.go(n);
  }

  goBack() {
    this.history.goBack();
  }

  goForward() {
    this.history.goForward();
  }
}

export default RouterStore;
