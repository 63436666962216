import { extendObservable, computed } from 'mobx';
import moment from 'moment';

export default class AssessmentOpen {
  constructor(input = {}) {
    extendObservable(this, {
      id: +input.id || 0,
      courseId: +input.courseId || +input.course_id || 0,
      studentId: +input.studentId || +input.student_id || 0,
      assignmentId: +input.assignmentId || +input.assignment_id || 0,
      assignmentEntryId: +input.assignmentEntryId || +input.assignment_entry_id || 0,
      expiration: input.expiration || input.expires || null,
    });
  }

  @computed get isExpired() {
    const expiration = moment(this.expiration);
    return !expiration.isValid() || expiration.isBefore(moment());
  }

  @computed get relativeExpiration() {
    return moment(this.expiration).calendar();
  }
}
