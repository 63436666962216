import React from 'react';
import { v4 as uuid } from 'uuid';
import RadioButton from './RadioButton';

class RadioButtons extends React.Component {
  uid = uuid()

  onChange = (key) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(key);
    }
  }

  render() {
    const { options, value, inline } = this.props;
    let entries = [];

    if (typeof options === 'object') {
      if (Array.isArray(options)) {
        entries = options.map((v) => [v, v]);
      } else {
        entries = Object.entries(options);
      }
    }

    // HACK: map function isn't tracked? No initial check unless value is used here
    return (
      <div className={`formField${inline ? ' inline' : ''}`}>
        <ul className="radiobuttons">
          {entries.map(([key, label], i) => (
            <li key={key}>
              <RadioButton
                group={this.uid}
                name={key + i}
                label={label}
                checked={key == value} // eslint-disable-line
                onChange={() => this.onChange(key)}
                inline
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default RadioButtons;
