import React from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import DateTime from 'widgets/format/DateTime';
import Modal from '../../widgets/Modal';
import ClassCopyProcessing from '../course/dashboard/ClassCopyProcessing';

@inject('courses') @observer
class Courses extends React.Component {
  @observable processingCourseId = 0;

  @computed get processingCourse() {
    const { courses } = this.props;
    return courses.byId.get(this.processingCourseId);
  }

  @computed get restoreComplete() {
    return this.processingCourse && !this.processingCourse.deletedAt;
  }

  @computed get restoreModalTitle() {
    if (this.restoreComplete) {
      return 'Class restored';
    }
    return `Restoring ${this.processingCourse?.title}`;
  }

  @action restoreCourse(course) {
    const { courses } = this.props;
    courses.restore(course.id);
    this.processingCourseId = course.id;
  }

  @action.bound dismissRestoreModal() {
    this.processingCourseId = 0;
  }

  renderCourse = (course) => (
    <div className="courseOuter" key={course.id}>
      <div className="courseInner">
        <h2>
          <div className="actions">
            <button
              type="button"
              onClick={() => this.restoreCourse(course)}
              disabled={!!course.upgradeJobId}
            >
              { course.upgradeJobId
                ? 'Loading...'
                : 'Restore' }
            </button>
          </div>
          {course.title}
        </h2>
        <div className="info">
          <span>
            {course.assignmentCount}
            {' '}
            Assignments
          </span>
          <span>
            { course.upgradeJobId
              ? 'Restoring class...'
              : (
                <React.Fragment>
                  Archived:
                  <DateTime value={course.deletedAt} />
                </React.Fragment>
              ) }
          </span>
        </div>
      </div>
    </div>
  )

  render() {
    const { courses } = this.props;

    return (
      <div className="courseDashboard courseArchive">
        <h1>Deleted Classes</h1>
        <div className="courseList">
          {Object.keys(courses.groupedArchive).map((group) => (
            <div key={`group_${group}`}>
              <h2>{group}</h2>
              {courses.groupedArchive[group].map((course) => this.renderCourse(course))}
            </div>
          ))}
        </div>
        { this.processingCourse
          ? (
            <Modal
              title={this.restoreModalTitle}
              onDismiss={this.dismissRestoreModal}
              visible
            >
              { this.restoreComplete
                ? (
                  <React.Fragment>
                    <p>{`${this.processingCourse.title} restored successfully`}</p>
                    <button type="button" onClick={this.dismissRestoreModal}>
                      OK
                    </button>
                  </React.Fragment>
                )
                : <ClassCopyProcessing copyPhrasing="restored" onClickClose={this.dismissRestoreModal} /> }
            </Modal>
          )
          : null }
      </div>
    );
  }
}

export default Courses;
