import React, { Component } from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Route, NavLink, withRouter } from 'react-router-dom';
import './style.scss';
import { matchPath } from 'react-router';

const renderGroup = (key, group) => {
  if (!group || !group.templates || group.templates.length === 0) {
    return null;
  }
  return (
    <li key={key}>
      <NavLink exact to={`/catalog/${key}`}>{group.title}</NavLink>
      <Route path={`/catalog/${key}`}>
        <ul className="catalogGroup">
          {group.templates.map((t) => (t
            && (
              <li key={t.templateKey}>
                <NavLink
                  to={`/catalog/${key}/${t.templateKey}`}
                >
                  {t.title.replace('Computer Applications - ', 'Microsoft ')}
                </NavLink>
              </li>
            )
          ))}
        </ul>
      </Route>
    </li>
  );
};

@inject('auth', 'catalog', 'router') @withRouter @observer
class CatalogMenu extends Component {
  constructor(props) {
    const { catalog: { searchQuery } } = props;
    super(props);
    this.state = { searchQuery };
  }

  @action runSearch(e) {
    const advancedPath = '/catalog/search/advanced';
    const standardPath = '/catalog/search';
    const { router, catalog } = this.props;
    const { searchQuery } = this.state;
    const searchPath = matchPath(router.path, advancedPath)
      ? advancedPath
      : standardPath;
    e.stopPropagation();
    e.preventDefault();
    catalog.search(searchQuery);
    if (router.path !== searchPath) {
      router.push(searchPath);
    }
  }

  @action updateQuery(value) {
    this.setState({ searchQuery: value });
  }

  render() {
    const { catalog } = this.props;
    const { searchQuery } = this.state;
    if (!catalog.loaded) {
      return <div />;
    }
    return (
      <Route path="/catalog">
        <div className="curriculumBrowser">
          <h3>Getting Started</h3>
          <ul className="catalogTop">
            <li>
              <NavLink exact to="/catalog/welcome">Welcome Videos</NavLink>
            </li>
          </ul>
          <h3>Catalog</h3>
          <ul className="catalogTop">
            {catalog.groupsForCurrentUserCatalog.map((g) => renderGroup(g, catalog.groups[g]))}
          </ul>
          <div style={{ marginLeft: 8, marginTop: 16 }}>
            <form onSubmit={(e) => this.runSearch(e)}>
              <input
                style={{
                  width: 200, height: 30, marginRight: 8, marginBottom: 8,
                }}
                placeholder="Search"
                type="text"
                value={searchQuery}
                onChange={(e) => this.updateQuery(e.target.value)}
              />
              <button type="submit">Search</button>
            </form>
          </div>
        </div>
      </Route>
    );
  }
}

export default CatalogMenu;
