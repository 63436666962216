import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import './style.scss';

import Profile from './Profile';
import Security from './Security';
import SingleSignon from './SingleSignon';
import Students from './Students';
import Subscription from './Subscription';
import Teachers from './Teachers';
import LicenseUsage from './LicenseUsage';

const Settings = () => (
  <Switch>
    <Redirect exact from="*/teacher" to="profile" />
    <Route path="*/teacher/profile" component={Profile} />
    <Route path="*/teacher/security" component={Security} />
    <Route path="*/site/teachers" component={Teachers} />
    <Route path="*/site/students" component={Students} />
    <Route path="*/site/subscription" component={Subscription} />
    <Route path="*/site/single-signon" component={SingleSignon} />
    <Route path="*/site/license-usage" component={LicenseUsage} />
  </Switch>
);
export default Settings;
