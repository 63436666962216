import React from 'react';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

const CourseNav = ({ courses: { fromRoute: course } }) => (
  <ul className="tabs">
    <li className="myClasses"><NavLink exact to="/courses">My Classes</NavLink></li>
    { course && (
      <React.Fragment>
        <li><NavLink to={`/courses/${course.id}/dashboard`}>Overview</NavLink></li>
        <li><NavLink to={`/courses/${course.id}/assignments`}>Assignments</NavLink></li>
        {!course.template && (
          <React.Fragment>
            <li><NavLink to={`/courses/${course.id}/students`}>Students</NavLink></li>
            <li><NavLink to={`/courses/${course.id}/grades`}>Grades</NavLink></li>
          </React.Fragment>
        )}
      </React.Fragment>
    )}

  </ul>
);

export default inject('courses')(observer(CourseNav));
