import React from 'react';

const FormErrors = ({ errors = [] }) => errors && errors.filter((v) => v)
  .map((error, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <div className="formField" key={`${index}_${error}`}>
      <span className="error">{error}</span>
    </div>
  ));

export default FormErrors;
