import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const StudentName = ({ student, router }) => (
  <Link to={`${router.path.replace('/grades/book', '')}/grades/by-student/${student.id}`}>
    {student.formatName}
  </Link>
);

export default inject('router')(observer(StudentName));
