import React from 'react';
import GradePercent from 'widgets/grade/GradePercent';
import GradePoints from 'widgets/grade/GradePoints';
import GradeEmpty from 'widgets/grade/GradeEmpty';
import WorksheetEmpty from 'widgets/grade/WorksheetEmpty';
import WorksheetGrade from './WorksheetGrade';

const StudentUnitGrade = (props) => {
  const {
    assignment,
    course,
    grade,
    points,
    showGrades,
    showWorksheets,
    student,
  } = props;

  const work = grade && grade.workByUnit[assignment.unitKey];

  if ((!work || !work.isComplete) && showGrades) {
    return (
      <div className="gradeGroup">
        { showGrades
          && (
            <GradeEmpty
              assignment={assignment}
              course={course}
              student={student}
              work={work}
            />
          )}
        { showWorksheets
          && !assignment.isTest
          && assignment.worksheetQuestionCount > 0
          && student
          && work
          && (
            <WorksheetGrade
              assignment={assignment}
              student={student}
              grade={grade}
              work={work}
              showWorksheets={showWorksheets}
            />
          ) }
        { showWorksheets
          && !assignment.isTest
          && assignment.worksheetQuestionCount > 0
          && (!student || !work)
          && <WorksheetEmpty /> }
      </div>
    );
  }

  const display = points
    ? <GradePoints key="grade" assignment={assignment} grade={work} />
    : <GradePercent key="grade" assignment={assignment} grade={work} />;

  return (
    <div>
      <div className="gradeGroup">
        {showGrades && <div>{display}</div>}
        { showWorksheets
          && !assignment.isTest
          && assignment.worksheetQuestionCount > 0
          && student
          && (
            <WorksheetGrade
              assignment={assignment}
              student={student}
              grade={grade}
              work={work}
              showWorksheets={showWorksheets}
            />
          ) }
      </div>
    </div>
  );
};

export default StudentUnitGrade;
