/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
class Table extends Component {
  @observable sort

  @observable reverse = false

  componentDidMount() {
    const { defaultSort } = this.props;
    if (defaultSort) {
      this.setSort({ key: defaultSort });
    }
  }

  @action setSort(column) {
    const { sortable } = this.props;
    if (!sortable) return;
    if (column.sortable != null && !column.sortable) return;

    if (this.sort && this.sort.key === column.key) {
      if (this.reverse) {
        this.sort = null;
      } else {
        this.reverse = true;
      }
    } else {
      this.sort = column;
      this.reverse = false;
    }
  }

  render() {
    const {
      data, columns, sortable, fullWidth,
    } = this.props;

    let rows = data && data.length ? [...data] : [];

    if (!rows.length) return null;

    const cols = columns || Object.keys(rows[0]).map((key) => ({ key }));

    if (sortable && this.sort) {
      const { key, value, sortValue } = this.sort;

      rows.sort((a, b) => {
        if (typeof sortValue === 'function') {
          return sortValue(a) < sortValue(b) ? -1 : 1;
        }

        if (typeof value === 'function') {
          return value(a) < value(b) ? -1 : 1;
        }

        return a[key] < b[key] ? -1 : 1;
      });

      if (this.reverse) rows = rows.reverse();
    }

    const columnData = (col) => (col.title != null ? col.title : col.key);

    return (
      <table style={{ width: fullWidth ? '100%' : 'auto' }}>
        <thead>
          <tr>
            {cols.map((col, i) => (
              <th
                onClick={() => this.setSort(col)}
                key={`${col.title || col.key}_${i}`}
                width={col.width || 'auto'}
              >
                {columnData(col)}
                {col.sortable && <i className="fa fa-fw fa-sort" />}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              {cols.map(({ key, value }, n) => {
                if (value) return <td key={`${key}_${n}`}>{value(row, i + 1)}</td>;
                return <td key={`${key}_${n}`}>{row[key]}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default Table;
