import React from 'react';
import { inject, observer } from 'mobx-react';
import AnswerKeyLearnosity from '../course/assignments/AnswerKeyLearnosity';
import Learnosity from '../../services/learnosity';

const CatalogAnswerKey = (props) => {
  const {
    match: {
      path,
      params: {
        moduleKey,
        unitKey,
      },
    },
    catalog,
    courses: { fromRoute: course },
  } = props;
  const returnUrl = () => `../../${moduleKey}`;
  const extraParams = path.includes('-advanced')
    ? { advanced_test: true }
    : {};

  const loadAnswers = (showAnswers, randomSeed) => {
    Learnosity.resetItems();

    const requestAction = catalog.loadAnswerKeyRequest(
      moduleKey,
      unitKey,
      showAnswers,
      randomSeed,
      extraParams,
    );

    return requestAction;
  };

  const title = () => {
    if (unitKey) {
      const unit = catalog.getUnit(unitKey);
      if (unit) {
        return `${unit.title} : Quiz`;
      }
    } else if (moduleKey) {
      const module = catalog.getModule(moduleKey);
      if (module) {
        return `${module.title} : Module Test`;
      }
    }

    return null;
  };

  return (
    <AnswerKeyLearnosity
      loadAnswers={loadAnswers}
      courseName={course.title}
      title={title()}
      returnUrl={returnUrl()}
    />
  );
};

export default inject('auth', 'catalog', 'courses')(observer(CatalogAnswerKey));
