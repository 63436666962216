/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';

export default class Dropdown extends Component {
  uid = uuid()

  onChange = (ev) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(ev.target.value);
    }
  }

  getOptions = () => {
    const { options } = this.props;

    if (!options || typeof options !== 'object') return [];

    if (Array.isArray(options)) {
      return options.map(
        (value) => {
          if (Array.isArray(value)) {
            return { key: value[0], value: value[1] };
          }
          if (typeof value === 'object') {
            return { key: value.key, value: value.value };
          }

          return { key: value, value };
        },
      );
    }

    if (typeof options === 'object') {
      return Object
        .entries(options)
        .map(([key, value]) => ({ key, value }));
    }

    return [];
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const {
      label, inline, noEmpty, value, error, onChange, ...rest
    } = this.props;

    return (
      <div className={`formField dropdown${inline ? ' inline' : ''}`}>
        {label && <label htmlFor={`${this.uid}_label`}>{label}</label>}
        <div style={{ display: 'inline-block', position: 'relative' }}>
          <select
            {...rest}
            value={value != null ? value : ''}
            id={`${this.uid}_select`}
            onChange={this.onChange}
          >
            {!noEmpty && <option key="noValue" value="" />}
            {this.getOptions().map((option) => (
              <option value={option.key} key={`${this.uid}_${option.key}`}>
                {option.value}
              </option>
            ))}
          </select>

          {error
            && <div className="fieldError"><span className="error">{error}</span></div>}
        </div>
      </div>
    );
  }
}
