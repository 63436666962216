import React from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import DateTime from 'widgets/format/DateTime';
import DuplicateClass from 'routes/course/dashboard/DuplicateClass';
import ToggleSlider from '../ToggleSlider';
import CourseMigrated from '../../routes/course/dashboard/CourseMigrated';

@inject('auth', 'courses') @observer
class CourseTable extends React.Component {
  @observable activeCourse = {};

  @observable keyAsc = true;

  @observable sortKey = 'title';

  @observable showDialog = false;

  @computed get sortedCourses() {
    const { keyAsc, sortKey } = this;
    const { courseData } = this.props;
    const list = courseData.slice(0);
    const lastActivity = (c) => new Date(c.last_student_activity).valueOf();
    const mult = keyAsc ? 1 : -1;

    if (sortKey === 'last_student_activity') {
      return list.sort((a, b) => (lastActivity(a) < lastActivity(b) ? -1 : 1) * mult);
    }
    return list.sort((a, b) => (a[sortKey] > b[sortKey] ? 1 : -1) * mult);
  }

  @action onSort = (sortKey) => {
    // if selected sort key was already clicked
    if (this.sortKey === sortKey) {
      this.keyAsc = !this.keyAsc;
    } else {
      this.keyAsc = true;
      this.sortKey = sortKey;
    }
  }

  @action showPopup(name, course) {
    const { courses } = this.props;
    this.dialog = name;
    const localCourse = courses.list.find((c) => c.id === course.id);

    if (localCourse) {
      this.activeCourse = localCourse;
    } else {
      this.activeCourse = course;
    }

    if (!this.activeCourse) {
      throw new Error(`Could not find course '${course.title}' (#${course.id})`);
    }
  }

  @action.bound closePopup() {
    this.activeCourse = {};
    this.dialog = null;
  }

  renderCourseHeader() {
    const { shared, template } = this.props;
    return (
      <tr>
        {template
          && (
            <React.Fragment>
              <th
                onClick={() => this.onSort('title')}
                className="columnSort titleHeader"
              >
                Template
                <i className="fa fa-fw fa-sort" />
              </th>
              <th
                onClick={() => this.onSort('ownerName')}
                className="columnSort"
              >
                Owner
                <i className="fa fa-fw fa-sort" />
              </th>
              <th
                onClick={() => this.onSort('assignmentCount')}
                className="columnSort"
              >
                Assignments
                <i className="fa fa-fw fa-sort" />
              </th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th />
            </React.Fragment>
          )}
        {!template
          && (
            <React.Fragment>
              <th
                onClick={() => this.onSort('title')}
                className={`columnSort ${shared ? 'titleHeader' : 'standardHeader'}`}
              >
                Class
                <i className="fa fa-fw fa-sort" />
              </th>
              {shared
                && (
                  <th
                    onClick={() => this.onSort('ownerName')}
                    className="columnSort"
                  >
                    Owner
                    <i className="fa fa-fw fa-sort" />
                  </th>
                )}
              <th
                onClick={() => this.onSort('assignmentCount')}
                className="columnSort"
              >
                Assignments
                <i className="fa fa-fw fa-sort" />
              </th>
              <th
                onClick={() => this.onSort('studentCount')}
                className="columnSort"
              >
                Students
                <i className="fa fa-fw fa-sort" />
              </th>
              <th
                onClick={() => this.onSort('lastStudentActivity')}
                className="columnSort"
              >
                Last
                Activity
                <i className="fa fa-fw fa-sort" />
              </th>
            </React.Fragment>
          )}
      </tr>
    );
  }

  renderCourseRow(course) {
    const { template, auth } = this.props;
    const {
      courseMigrated,
      migrationStatus,
      courseMigrationNotSupported,
    } = course;
    const courseOwner = course.facultyId === auth.user.id;
    const showOwner = !courseOwner && (course.isShared || course.sharedTemplate);

    return (
      <React.Fragment key={course.id}>
        {
          (template ? course.template : !course.template)
          && (
            <tr>
              <td className="title">
                {(courseOwner || (!courseOwner && !course.template))
                  ? (
                    <>
                      {courseMigrated ? (
                        <div className="migrated-links">
                          {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                          <div
                            className="migrated-course-link"
                            onClick={() => this.showPopup('Migrated', course)}
                            onKeyDown={() => this.showPopup('Migrated', course)}
                          >
                            {course.title}
                          </div>
                          <div className="chip migrated">
                            Migrated
                          </div>
                          {migrationStatus === 3 ? (
                            <div className="chip exams-outstanding">
                              Exams Outstanding
                            </div>
                          )
                            : null }
                        </div>
                      )
                        : (
                          <Link to={`/courses/${course.id}`}>{course.title}</Link>
                        )}

                      { course.upgradeJobId
                        ? (
                          <div className="chip upgrading">
                            Loading exams...
                          </div>
                        )
                        : null }
                      { courseMigrationNotSupported
                        ? (
                          <div className="chip not-supported">
                            Not Supported
                          </div>
                        )
                        : null }
                    </>
                  )
                  : null }
                {!courseOwner && course.template && <span>{course.title}</span>}
                {course.template && courseOwner
                  ? (
                    <ToggleSlider
                      active={course.sharedTemplate}
                      onClick={(active) => course.setDistrictTemplate(active)}
                      titleOn="Published"
                      titleOff="Unpublished"
                    />
                  )
                  : null}
                {course.template && !courseOwner
                  && (
                    <span className="templatePublished">
                      {course.sharedTemplate ? 'Published' : 'Unpublished'}
                    </span>
                  )}
              </td>
              {(course.shared_template || course.template || showOwner)
                && <td>{course.ownerName}</td>}
              <td>{course.assignmentCount}</td>
              {!course.template
                && (
                  <React.Fragment>
                    <td>{course.studentCount}</td>
                    <td><DateTime value={course.lastStudentActivity} /></td>
                  </React.Fragment>
                )}
              {course.template
                && (
                  <td className="templateActions">
                    <button
                      type="button"
                      className="createClass"
                      onClick={() => this.showPopup('Duplicate', course)}
                    >
                      Create Class from this Template
                    </button>
                  </td>
                )}
            </tr>
          )
        }
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="courseTable" data-testid="courseTable">
        <table>
          <tbody>
            {this.renderCourseHeader()}
            {this.sortedCourses.map((course) => this.renderCourseRow(course))}
          </tbody>
        </table>
        <DuplicateClass
          create
          onDismiss={this.closePopup}
          style={{ height: 310 }}
          selectedCourse={this.activeCourse}
          title={`Copy Class: ${this.activeCourse.title}`}
          visible={this.dialog === 'Duplicate'}
          noRedirect
        />
        <CourseMigrated
          onDismiss={this.closePopup}
          selectedCourse={this.activeCourse}
          title="Warning"
          visible={this.dialog === 'Migrated'}
        />
      </div>
    );
  }
}

export default CourseTable;
