import React from 'react';
import { Route, Switch } from 'react-router';
import NotFound from 'routes/error/404';
import DailyQuizChallenge from './DailyQuizChallenge';
import PacingSequencing from './PacingSequencing';
import QuizzesTests from './QuizzesTests';
import Worksheets from './Worksheets';

export default function Settings() {
  return (
    <Switch>
      <Route exact path="*/quizzes-tests" component={QuizzesTests} />
      <Route path="*/pacing-sequencing" component={PacingSequencing} />
      <Route path="*/worksheets" component={Worksheets} />
      <Route path="*/daily-quiz-challenge" component={DailyQuizChallenge} />
      <Route component={NotFound} />
    </Switch>
  );
}
