import React from 'react';
// TODO: find out if used

const Students = () => (
  <div className="introContent">
    <div className="heroSection">
      <h1>Keep Up With All of Your Students</h1>
      <h2>Tools to help you manage your classroom.</h2>
      <div className="learnMore">
        <div className="video" />
        <h3>Learn more</h3>
      </div>
    </div>
    <h3>
      Ready to get started?
      <br />
      &larr; Click a class over here.
    </h3>
  </div>
);

export default Students;
