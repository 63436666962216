import { observable, computed } from 'mobx';

// See plt-776
function pickMostRecentGrades(work) {
  const hash = {};
  const time = (v) => new Date(v).valueOf();

  work.forEach((grade) => {
    const saved = hash[grade.unitKey];

    if (!saved || time(saved.updatedAt) < time(grade.updatedAt)) {
      hash[grade.unitKey] = grade;
    }
  });

  return Object.values(hash);
}

/* Grades for a single student in a single course */
export default class StudentCourseGrade {
  @observable list = []

  @observable courseId

  @observable studentId

  constructor(rootStore, work, courseId, studentId) {
    this.rootStore = rootStore;
    this.list = pickMostRecentGrades(work);
    this.courseId = courseId;
    this.studentId = studentId;
  }

  @computed get work() {
    return this.list.filter((work) => work.stillInCourse);
  }

  @computed get course() {
    const { courses } = this.rootStore;

    return courses.byId.get(this.courseId);
  }

  @computed get workByUnit() {
    return this.work.reduce(
      (obj, grade) => {
        // eslint-disable-next-line no-param-reassign
        obj[grade.unitKey] = grade;
        return obj;
      },
      {},
    );
  }

  @computed get workByModule() {
    return this.work.reduce(
      (obj, grade) => {
        // eslint-disable-next-line no-param-reassign
        if (!obj[grade.moduleKey]) obj[grade.moduleKey] = [];

        if (grade.isAssessment) {
          obj[grade.moduleKey].push(grade);
        }

        return obj;
      },
      {},
    );
  }

  @computed get moduleAverage() {
    return Object.entries(this.workByModule).reduce(
      (obj, [key, scores]) => {
        const total = scores.reduce(
          (t, m) => (m.includeInAverage ? t + m.percentEarned : t),
          0,
        );
        const contributors = scores.filter((s) => s.includeInAverage).length;
        const average = total / contributors;

        if (!Number.isNaN(average)) {
          // eslint-disable-next-line no-param-reassign
          obj[key] = `${average.toFixed(1)}%`;
        }

        return obj;
      },
      {},
    );
  }

  @computed get moduleTotal() {
    return Object.entries(this.workByModule).reduce(
      (obj, [key, scores]) => {
        const included = scores.filter((s) => s.includeInAverage);
        const earned = Math.round(included.reduce((t, m) => t + m.totalEarned, 0));
        const possible = included.reduce((t, m) => t + m.totalPossible, 0);

        // Don't show 0/0 grades - They should be a +

        if (possible > 0) {
          // eslint-disable-next-line no-param-reassign
          obj[key] = `${earned}/${possible}`;
        }

        return obj;
      },
      {},
    );
  }

  @computed get moduleFinal() {
    return Object.entries(this.workByModule).reduce(
      (obj, [key, scores]) => {
        const modules = this.course.gradeAssignments;
        const currentModule = modules.find((m) => m.moduleKey === key);
        if (!currentModule) {
          return obj;
        }
        const included = scores.filter((s) => s.includeInTotal);
        const totalEarned = included.reduce((t, m) => (
          (m.unit.isTest)
            ? t + (m.assignment.totalTestQuestionCount * m.quizGrade)
            : t + m.totalEarned
        ), 0);
        const totalPossible = currentModule.totalPossibleModulePoints;
        const totalPercent = included.reduce((t, m) => t + m.quizGrade, 0);
        const { gradeCount } = currentModule;

        const score = {
          earned: totalEarned,
          possible: totalPossible,
          percent: gradeCount === 0
            ? '0.0'
            : `${((totalPercent / gradeCount) * 100).toFixed(1)}%`,
          points: `${Math.round(totalEarned)}/${totalPossible}`,
        };
        // eslint-disable-next-line no-param-reassign
        obj[key] = score;

        return obj;
      },
      {},
    );
  }

  @computed get moduleProgress() {
    return Object.entries(this.workByModule).reduce(
      (completedByModule, [moduleKey, scores]) => {
        const completedCount = scores.reduce((total, m) => (
          m.isGraded && m.isComplete
            ? total + 1
            : total
        ), 0);
        const assignment = this.course.assignments.find((a) => a.moduleKey === moduleKey);

        if (assignment) {
          const total = assignment ? assignment.gradeEntries.length : 0;
          // eslint-disable-next-line no-param-reassign
          completedByModule[moduleKey] = completedCount / total;
        } else {
          // eslint-disable-next-line no-param-reassign
          completedByModule[moduleKey] = 0;
        }

        return completedByModule;
      }, {},
    );
  }

  @computed get quizzesDone() {
    return Object.values(this.workByUnit)
      .reduce((count, work) => (
        work.isComplete
          ? count + 1
          : count
      ), 0);
  }

  /*
   total average is calculated as average of module averages, not raw points/possible
   See SC-6545
   */
  @computed get average() {
    const values = Object.values(this.moduleAverage)
      .map((percentString) => parseFloat(percentString));
    const average = values.reduce((sum, grade) => (sum + grade), 0) / values.length;
    if (Number.isNaN(average)) {
      return undefined;
    }
    return `${(average).toFixed(1)}%`;
  }

  @computed get total() {
    const included = this.work.filter((s) => s.includeInAverage);
    const earned = included.reduce((result, grade) => result + grade.totalEarned, 0);
    const total = included.reduce((result, grade) => result + grade.totalPossible, 0);

    if (Number.isNaN(earned) || !total) return undefined;
    return `${earned.toFixed(0)}/${total}`;
  }

  // student final grade
  @computed get final() {
    const { course, moduleFinal } = this;
    const modules = course.gradeAssignments;
    const sumPossible = modules.reduce((result, grade) => (
      result + grade.totalPossibleModulePoints
    ), 0);

    const moduleFinalValues = Object.values(moduleFinal)
      .filter((value) => !!value);

    const sumEarned = moduleFinalValues.reduce((sum, { earned }) => (sum + earned), 0);

    /* final course percentage is calculated as the average of all module grades,
       NOT raw value of earned/possible; this is to be in-line with module percent calculations
       See SC-6545
     */
    const averagePercent = sumPossible
      ? moduleFinalValues
        .reduce((sum, { percent }) => (sum + Number.parseFloat(percent)), 0)
        / modules.length
      : 0;

    return {
      earned: sumEarned,
      possible: sumPossible,
      percent: `${averagePercent.toFixed(1)}%`,
      points: `${Math.round(sumEarned)}/${sumPossible}`,
    };
  }

  @computed get completed() {
    return Object.entries(this.workByModule).reduce(
      (count, [key, scores]) => {
        const done = scores.reduce((t, m) => (m.isGraded && m.isComplete ? t + 1 : t), 0);
        const assignment = this.course.assignments.find((a) => a.moduleKey === key);
        const expected = assignment ? assignment.gradeEntries.length : 0;
        if (done >= expected) {
          return count + 1;
        }
        return count;
      },
      0,
    );
  }

  @computed get worksheetsDone() {
    return this.work.reduce(
      (total, grade) => {
        const worksheet = grade.worksheet && grade.worksheet.worksheet_questions;
        const done = worksheet && worksheet.worksheet_completed === worksheet.worksheet_questions;

        return (done ? total + 1 : total);
      },
      0,
    );
  }

  @computed get worksheet() {
    return this.work.reduce(
      (obj, grade) => {
        // eslint-disable-next-line no-param-reassign
        obj[grade.unitKey] = {
          id: grade.id,
          ...grade.worksheet,
        };
        return obj;
      },
      {},
    );
  }

  @computed get worksheetByModule() {
    return this.work.reduce(
      (obj, grade) => {
        let moduleWorksheet = obj[grade.moduleKey];

        if (!moduleWorksheet) {
          moduleWorksheet = {
            started: 0,
            completed: 0,
          };
          // eslint-disable-next-line no-param-reassign
          obj[grade.moduleKey] = moduleWorksheet;
        }

        const { entry, worksheet } = grade;

        // check for unit deletion && filter out worksheets for units that are deleted
        if (entry && entry.removed === false && worksheet && worksheet.worksheet_completed) {
          moduleWorksheet.started += 1;
          if (worksheet.worksheet_completed === worksheet.worksheet_questions) {
            moduleWorksheet.completed += 1;
          }
        }

        return obj;
      },
      {},
    );
  }

  getStudentOverall(moduleKey) {
    const { auth: { user } } = this.rootStore;
    const usePoints = !!user.getOption('usePoints');
    const useInProgress = !!user.getOption('showInProgress');

    if (moduleKey) {
      if (useInProgress) { // show in-progress for single module
        // show unit average
        return usePoints
          ? this.moduleTotal[moduleKey]
          : this.moduleAverage[moduleKey];
      }

      return usePoints
        ? this.moduleFinal[moduleKey]?.points
        : this.moduleFinal[moduleKey]?.percent;
    }

    if (useInProgress) { // show in-progress for all modules
      return usePoints ? this.total : this.average;
    }

    // show final for all modules
    return usePoints ? this.final.points : this.final.percent;
  }
}
