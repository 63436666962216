import React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import CourseList from 'widgets/dashboard/CourseList';
import CourseTable from 'widgets/dashboard/CourseTable';
import DashboardTemplates from './DashboardTemplates';
import DashboardSideBar from './DashboardSideBar';

@inject('auth', 'courses') @observer
class DashboardCourses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isListView: false,
    };
  }

  // setting saved view preference if exists (tile vs list view)
  componentDidMount() {
    const { auth } = this.props;

    let preference;
    try {
      preference = auth.user.options.dashboardView;
    } catch (e) {
      preference = false;
    }
    if (preference) {
      this.setState({ isListView: preference !== 'tile' });
    }
  }

  // set dashboard view preference for teacher
  @action.bound async switchView() {
    const { auth } = this.props;
    const { isListView } = this.state;

    this.setState({ isListView: !isListView });
    const value = !isListView ? 'list' : 'tile';
    await auth.user.setOption('dashboardView', value);
  }

  render() {
    const { isListView } = this.state;
    const { auth, courses } = this.props;
    const { site, user, enableCreateTemplates } = auth;

    return (
      <div className="courseDashboard">
        <div className="courseList">
          <div className="courseHeader">
            <h1>My Classes</h1>
            <div className="courseHeaderActions">
              <Link to="/courses/new">
                <button type="button">+ New Class</button>
              </Link>
              {enableCreateTemplates && (
                <Link to="/courses/new/template">
                  <button type="button">+ New Template</button>
                </Link>
              )}

              {!isListView && (
                <button type="button" onClick={() => this.switchView()} data-testid="toggleView">
                  Switch to List View
                </button>
              )}
              {isListView && (
                <button type="button" onClick={() => this.switchView()}>
                  Switch to Tile View
                </button>
              )}
            </div>
          </div>

          {!isListView && (
            <CourseList
              courseData={courses.active}
              showNew
            />
          )}

          {isListView && (
            <CourseTable courseData={courses.active} />
          )}

          {courses.shared.length > 0 && !isListView && (
            <div>
              <h1>Shared Classes</h1>
              <CourseList
                courseData={courses.shared}
                shared
              />
            </div>
          )}

          {courses.shared.length > 0 && isListView && (
            <div>
              <h1>Shared Classes</h1>
              <CourseTable
                courseData={courses.shared}
                shared
              />
            </div>
          )}

          <DashboardTemplates listView={isListView} />
        </div>
        <DashboardSideBar site={site} user={user} />
      </div>
    );
  }
}

export default DashboardCourses;
