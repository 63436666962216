import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import CustomModuleView from 'widgets/assignment/CustomModuleView';
import ToggleButton from 'widgets/ToggleButton';

@inject('router', 'curriculum') @observer
class CustomModuleDetails extends Component {
  render() {
    const { router, assignment } = this.props;

    return (
      <div>
        <div className="formField">
          <Link
            to={`${router.path.replace(/\/\d+$/, '')}`}
            className="button"
          >
            &larr; Return to Assignment List
          </Link>
        </div>

        <div className="row">
          <div className="col2">
            <h1>Custom Module Details</h1>
          </div>

          <div className="col2" style={{ textAlign: 'right' }}>
            <ToggleButton
              active={!assignment.open}
              onClick={(value) => assignment.setStatus(!value)}
              titleOn="Open Module"
              titleOff="Close Module"
            />
          </div>
        </div>

        <CustomModuleView moduleKey={assignment.moduleKey} />
      </div>
    );
  }
}

export default CustomModuleDetails;
