import React from 'react';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';

const AuthModal = ({ auth }) => {
  const { showSessionWarning } = auth;

  if (showSessionWarning) {
    const verify = () => auth.refresh()
      .then(() => auth.setActive());
    return (
      <Modal
        data-testid="auth-modal"
        id="auth-modal"
        title="Are you still there?"
        visible
      >
        <p>
          Your session is about to time out due to inactivity.
          If you would like to continue working click below.
        </p>

        <button type="button" onClick={verify}>Keep Working</button>
      </Modal>
    );
  }

  return null;
};

export default inject('auth')(observer(AuthModal));
