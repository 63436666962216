import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { triggerDownload } from 'services/WebAPI';
import Dropdown from 'widgets/form/Dropdown';

@inject('courses') @observer
class Students extends Component {
  @observable selected = '_ALL'

  @action.bound setSelected = (val) => { this.selected = val; }

  @action.bound async generateReport() {
    const { courses } = this.props;
    const allCourses = this.selected === '_ALL';
    const course = allCourses ? courses.list[0] : courses.byId.get(+this.selected);

    if (!course) {
      throw new Error('Can\'t generate a time online report without a course');
    }

    const data = await course.generateExcel(
      'time online',
      { allCourses },
    );

    triggerDownload(
      data,
      `Student Time Online - ${allCourses ? 'All Courses' : course.title}.xls`,
    );
  }

  render() {
    const { courses } = this.props;

    return (
      <div>
        <h1>Students</h1>

        <section>
          <h2>Time Online Report</h2>
          <p>Download an excel summary of the amount of time students have spend online.</p>

          <Dropdown
            label="Generate for:"
            options={[['_ALL', 'All Courses'], ...courses.list.map((c) => [c.id, c.title])]}
            value={this.selected}
            onChange={this.setSelected}
            noEmpty
          />

          <button type="button" className="lgButton" onClick={this.generateReport}>
            <span>Generate Report</span>
            <small>This may take some time.</small>
          </button>
        </section>
      </div>
    );
  }
}

export default Students;
