import React from 'react';
import { Switch, Route } from 'react-router';
import NotFound from 'routes/error/404';
import Preview from 'routes/course/dashboard/Preview';
import Settings from './settings';
import AssignmentList from './AssignmentList';
import AssignmentAnswerKey from './AssignmentAnswerKey';
import AssignmentSingle from './AssignmentSingle';
import AssignmentEdit from './AssignmentEdit';
import CreateExamLearnosity from './CreateExamLearnosity';
import CustomModules from './CustomModules';
import ExamAddItems from './ExamAddItems';
import './style.scss';

const Assignments = () => (
  <Switch>
    <Route exact path="*/view" component={AssignmentList} />
    <Route path="*/view/:id(\d+)/answer-key/:entryId?" component={AssignmentAnswerKey} />
    <Route path="*/view/:id/add-items" component={ExamAddItems} />
    <Route path="*/view/:id(\d+)" component={AssignmentSingle} />
    <Route path="*/preview" component={Preview} />
    <Route path="*/edit" component={AssignmentEdit} />
    <Route path="*/create-exam" component={CreateExamLearnosity} />
    <Route path="*/custom-modules" component={CustomModules} />
    <Route path="*/settings" component={Settings} />
    <Route component={NotFound} />
  </Switch>
);

export default Assignments;
