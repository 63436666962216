import React from 'react';
import { Switch, Route } from 'react-router';

import Assignments from './Assignments';
import Dashboard from './Dashboard';
import NotFound from '../error/404';
import Students from './Students';

import './style.scss';

const Home = () => (
  <Switch>
    <Route path="*/dashboard" component={Dashboard} />
    <Route path="*/courses" component={Dashboard} />
    <Route path="*/get-started" component={Dashboard} />
    <Route path="*/assignments" component={Assignments} />
    <Route path="*/students" component={Students} />
    <Route path="*/:page" component={NotFound} />
    <Route component={Dashboard} />
  </Switch>
);

export default Home;
