import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch } from 'react-router';

import NotFound from 'routes/error/404';
import StudentDashboard from './StudentDashboard';
import StudentActivity from './StudentActivity';

const StudentSingle = (params) => {
  const {
    courses: { fromRoute: course },
    router: { studentId },
  } = params;
  const student = course && course.findStudent(studentId);

  if (!student) return <NotFound />;

  return (
    <Switch>
      <Route path="*/list" component={StudentDashboard} />
      <Route path="*/tracking" component={StudentActivity} />
    </Switch>
  );
};

export default inject('courses', 'router')(observer(StudentSingle));
