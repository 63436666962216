import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';

class Checkbox extends Component {
  uid = uuid()

  onChange = (ev) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') {
      onChange(ev.target.checked);
    }
  }

  render = () => {
    const {
      checked, value, noMargin, inline, label, subtitle, onChange, ...props
    } = this.props;

    return (
      <div className={`${noMargin || inline ? '' : 'formField '}checkbox`}>
        {/* eslint-disable react/jsx-props-no-spreading */}
        {/* TODO figure out what ...props is passing, if anything of value, and pass explicitly */}
        <input
          type="checkbox"
          id={this.uid}
          checked={checked || !!value}
          onChange={this.onChange}
          {...props}
        />
        {/* eslint-enable react/jsx-props-no-spreading */}

        {label
          && (
          <label htmlFor={this.uid}>
            <span>{label}</span>
            {subtitle && <small>{subtitle}</small>}
          </label>
          )}
      </div>
    );
  }
}

export default Checkbox;
