import React from 'react';
import MaterialIcon from '../MaterialIcon';

const GradeButtonCell = ({ children, isComplete, onClick }) => (
  <div>
    <button type="button" className="anchor" onClick={onClick}>
      { children }
    </button>
    { isComplete
      ? (
        <MaterialIcon toolTip="Student completed entire module" className="completedIcon md-12">
          check
        </MaterialIcon>
      )
      : null }
  </div>
);

export default GradeButtonCell;
