import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import GradeTable from 'widgets/grade/GradeTable';

@inject('courses', 'auth') @observer
class Gradebook extends Component {
  @computed get embedOpts() {
    const { auth } = this.props;

    return [
      'popover=true',
      'popoverAnimateThumbnail=false',
      'popoverSize=750x417',
      `email=${auth.user.email}`,
    ].join(' ');
  }

  render() {
    const { courses: { fromRoute: course } } = this.props;

    if (!course.students.length) {
      return (
        <div>
          <h1>Gradebook</h1>
          <div style={{ width: '450px' }}>
            <p>
              There are no students enrolled in this class.
              <br />
              <br />
              Watch the video
              below to learn more about the Grades tab, or
              go to the
              {' '}
              <b>Students</b>
              {' '}
              tab and click
              {' '}
              <b>Enroll Students</b>
              {' '}
              to
              start the enrollment process.
            </p>
            <div style={{ width: 400, height: 220 }}>
              <div
                className={`wistia_embed wistia_async_wt3njpz1y5 videoFoam=true ${this.embedOpts}`}
                style={{ width: 400, height: 220 }}
              />
            </div>
          </div>
        </div>
      );
    }

    return <GradeTable />;
  }
}

export default Gradebook;
