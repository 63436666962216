/* eslint-disable no-param-reassign */
export function pluralize(input) {
  return input.concat(input.map(v => v + 's')) // eslint-disable-line
}

export function scrubResourceType(resource) {
  const match = resource.resource_type.match(/\w\.\s(.+)/);

  if (match) {
    const { 1: value } = match;
    resource.resource_type = value;
  }

  return resource;
}
