import React, { Component } from 'react';
import { inject } from 'mobx-react';
import FormErrors from 'widgets/form/FormErrors';
import TextBox from 'widgets/form/TextBox';
import { Optional, Password, PasswordVerify } from 'util/FormValidation';

@inject('auth')
class Security extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      editSignin: false,
    };
  }

  updateLoginDetails() {
    const { auth } = this.props;
    const { password1: password } = this.state;
    const hideEditSignin = () => this.setState({ editSignin: false });

    // No change?
    if (!password) {
      hideEditSignin();
      return Promise.resolve();
    }

    // Call API to finalize
    return auth.updateSignon({ password })
      .then(hideEditSignin);
  }

  render() {
    const { auth } = this.props;
    const { password1, password2, editSignin } = this.state;

    return (
      <div>
        <h1>Security</h1>
        <section>
          <h2>Login Details</h2>
          { editSignin
            ? (
              <form>
                <p>
                  <span>Email Address:</span>
                  <br />
                  <strong>{auth.user.email}</strong>
                </p>

                <span>Password Change:</span>

                <TextBox
                  name="password"
                  value={password1}
                  placeholder="New Password"
                  type="password"
                  leftLabel
                  style={{ width: '250px' }}
                  validate={[Optional, Password]}
                  onChange={(value) => this.setState({ password1: value })}
                />
                <TextBox
                  name="password2"
                  value={password2}
                  placeholder="New Password Again"
                  type="password"
                  leftLabel
                  style={{ width: '250px' }}
                  validate={[PasswordVerify('password')]}
                  onChange={(value) => this.setState({ password2: value })}
                />

                { password1 !== password2
                  && <p style={{ color: 'red' }}>Passwords must match.</p>}

                <FormErrors />

                <button
                  type="submit"
                  onClick={() => this.updateLoginDetails()}
                  disabled={password1 !== password2}
                >
                  Save Changes
                </button>
                <button type="button" className="secondary" onClick={() => this.setState({ editSignin: false })}>
                  Cancel
                </button>
              </form>
            )
            : (
              <div>
                <p>
                  <i className="material-icons">warning</i>
                  &nbsp;
                  <b>Changing these settings will change how you log in to the system.</b>
                </p>
                <button type="button" onClick={() => this.setState({ editSignin: true })}>
                  Edit My Login Details
                </button>
              </div>
            ) }
        </section>
      </div>
    );
  }
}

export default Security;
