import React, { Component } from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import WorksheetGrade from './WorksheetGrade';
import GradeButtonCell from './GradeButtonCell';
import MaterialIcon from '../MaterialIcon';

@observer
class StudentModuleGrade extends Component {
  componentDidMount() {
    const { assignment: { isExam } } = this.props;

    if (isExam) {
      this.loadExam();
    }
  }

  @action.bound async loadExam() {
    const { assignment: { exam } } = this.props;

    if (!exam.questionsLoaded) {
      await exam.getQuestions();
    }
  }

  render() {
    const {
      assignment,
      grade,
      onClick,
      showGrades,
      showWorksheets,
      useFinal,
      usePoints,
    } = this.props;

    const { isExam, moduleKey, worksheetCount } = assignment;

    if (!grade) {
      return (
        <div className="gradeGroup">
          {showGrades
            && (
              <GradeButtonCell onClick={onClick}>
                <MaterialIcon>add</MaterialIcon>
              </GradeButtonCell>
            )}
          {showWorksheets
            && !isExam
            && (
              <GradeButtonCell onClick={onClick}>
                {`0/${worksheetCount}`}
              </GradeButtonCell>
            )}
        </div>
      );
    }

    const progress = grade.moduleProgress[moduleKey] || 0;

    let content;

    if (progress) {
      if (useFinal) {
        const final = grade.moduleFinal[moduleKey] || {};
        content = usePoints
          ? final.points
          : final.percent;
      } else {
        content = usePoints
          ? grade.moduleTotal[moduleKey]
          : grade.moduleAverage[moduleKey];
      }
    }

    if (!content) {
      content = <MaterialIcon>add</MaterialIcon>;
    }

    return (
      <div className="gradeGroup">
        {showGrades
          && (
            <GradeButtonCell onClick={onClick} isComplete={progress === 1}>
              {content}
            </GradeButtonCell>
          )}
        {showWorksheets
          && !assignment.isExam
          && (
            <WorksheetGrade
              assignment={assignment}
              grade={grade}
              onClick={onClick}
              worksheetCount={worksheetCount}
            />
          )}
      </div>
    );
  }
}

export default StudentModuleGrade;
