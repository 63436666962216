import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'widgets/Modal';
import { action } from 'mobx';
import { inject } from 'mobx-react';
import MaterialIcon from '../../../widgets/MaterialIcon';

@inject('auth')
class CourseMigrated extends Component {
  @action.bound closeDialog() {
    const { onDismiss } = this.props;
    onDismiss();
  }

  render() {
    const {
      auth, selectedCourse, title, visible,
    } = this.props;
    const courseId = selectedCourse.id;
    const { icevCourseCode, migrationStatus, icevMigratedDate } = selectedCourse;
    const migratedDate = icevMigratedDate ? new Date(icevMigratedDate).toLocaleDateString() : null;
    const iCEVURL = auth.iCEVRedirectUrl;
    const { migrationURL } = auth;
    const icevRedirect = () => (icevCourseCode ? `${iCEVURL}/mycourses/${icevCourseCode}` : icevCourseCode);

    return (
      <Modal
        onDismiss={this.closeDialog}
        title={title}
        visible={visible}
      >
        <h1>This course has been migrated</h1>
        {migrationStatus === 3
          ? (
            <h4>
              This course was migrated, excluding custom exams, to the iCEV platform on
              {' '}
              {migratedDate}
              .
            </h4>
          )
          : (
            <h4>
              This course was migrated to the iCEV platform on
              {' '}
              {migratedDate}
              .
            </h4>
          )}
        <h4>
          While all screens and functionality remain available in this course,
          {' '}
          we strongly advise that you continue to iCEV if you want to make any changes.
        </h4>
        <h4>
          Any changes made to this version after migration will not be reflected on the iCEV
          {' '}
          platform, and cannot be copied across.
        </h4>
        {migrationStatus === 3
          ? (
            <div className="custom-exam-warning">
              <MaterialIcon>info</MaterialIcon>
              <p>
                Custom exams are now available for migration. Read the
                {' '}
                <a
                  href="https://help.aeseducation.com/en/articles/7042757-aes-icev-transition-faq"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Course Migration FAQ
                </a>
                {' '}
                for more information or click on the link to complete your migration:
                {' '}
                <a
                  href={migrationURL}
                  rel="noreferrer noopener"
                >
                  Complete my Migration
                </a>
              </p>
            </div>
          )
          : null }
        <a href={icevRedirect()} className="button">
          Open this course in iCEV
        </a>
        <Link
          className="button migratedCourse"
          to={`/courses/${courseId}/grades`}
        >
          Continue in AES
        </Link>
      </Modal>
    );
  }
}

export default CourseMigrated;
