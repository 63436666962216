import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import RadioButtons from 'widgets/form/RadioButtons';

@observer
class GradeDisplayOptions extends Component {
  handleClose = () => {
    const { onDismiss } = this.props;
    onDismiss();
  }

  changePoints(value) {
    const { usePoints, togglePoints } = this.props;
    if (value === 'points' && !usePoints) {
      togglePoints();
    } else if (value === 'percentages' && usePoints) {
      togglePoints();
    }
  }

  changeInProgress(value) {
    const { showInProgress, toggleInProgress } = this.props;
    if (value === 'inprogress' && !showInProgress) {
      toggleInProgress();
    } else if (value === 'final' && showInProgress) {
      toggleInProgress();
    }
  }

  changeWorksheets(value) {
    const {
      showGrades, showWorksheets,
      toggleGrades, toggleWorksheets,
    } = this.props;

    if (value === 'both') {
      if (!showGrades) toggleGrades();
      if (!showWorksheets) toggleWorksheets();
    } else if (value === 'worksheets') {
      if (showGrades) toggleGrades();
      if (!showWorksheets) toggleWorksheets();
    } else {
      if (!showGrades) toggleGrades();
      if (showWorksheets) toggleWorksheets();
    }
  }

  render() {
    const {
      showGrades,
      showWorksheets,
      showInProgress,
      usePoints,
    } = this.props;

    const currentWorksheetSetting = () => {
      if (showWorksheets && showGrades) {
        return 'both';
      } if (showWorksheets) {
        return 'worksheets';
      }
      return 'grades';
    };

    return (
      <Fragment>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 40 }}>
            <h2>Display</h2>
            <RadioButtons
              options={{
                points: 'Points',
                percentages: 'Percentages',
              }}
              value={usePoints ? 'points' : 'percentages'}
              onChange={(value) => this.changePoints(value)}
            />
          </div>
          <div style={{ marginRight: 40 }}>
            <h2>Calculation</h2>
            <RadioButtons
              options={{
                inprogress: 'In-Progress',
                final: 'Final',
              }}
              value={showInProgress ? 'inprogress' : 'final'}
              onChange={(value) => this.changeInProgress(value)}
            />
          </div>
          <div style={{ marginRight: 40 }}>
            <h2>Worksheets</h2>
            <RadioButtons
              options={{
                both: 'Shown',
                grades: 'Hidden',
              }}
              value={currentWorksheetSetting()}
              onChange={(value) => this.changeWorksheets(value)}
            />
          </div>
        </div>
        <button type="button" onClick={this.handleClose}>Close</button>
      </Fragment>
    );
  }
}

export default GradeDisplayOptions;
