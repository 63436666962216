import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  observable,
  action,
  computed,
  autorun,
  runInAction,
} from 'mobx';
import NotValidated from 'widgets/NotValidated';
import TextBox from 'widgets/form/TextBox';
import RadioButtons from 'widgets/form/RadioButtons';
import CheckboxTable from 'widgets/form/CheckboxTable';
import './newStyle.scss';

@inject('assignments', 'courses', 'router', 'auth') @observer
class CreateExamLearnosity extends Component {
  @observable exam;

  @observable examError = '';

  componentDidMount() {
    const {
      assignments,
      courses: {
        fromRoute: {
          id: courseId,
        },
      },
    } = this.props;

    assignments.initializeExam({ courseId })
      .then((exam) => runInAction(() => {
        this.exam = exam;
      }));

    // Force valid question count
    this.disposeAutoRun = autorun(() => {
      const { exam } = this;
      if (exam?.maxQuestions && exam.numQuestions > exam.maxQuestions) {
        this.setQuestionCount(exam.maxQuestions);
      }
    });
  }

  componentWillUnmount() {
    this.disposeAutoRun();
  }

  @computed get course() {
    const { courses: { fromRoute: course } } = this.props;
    return course;
  }

  @computed get courseModules() {
    const { assignments: { questionCounts } } = this.props;
    return this.course.moduleAssignments
      .map((assignment) => {
        const { module: { key }, title: label } = assignment;
        const questionCount = questionCounts.get(key) || 0;
        return {
          key, label, disabled: !questionCount, extra: [questionCount],
        };
      });
  }

  @action.bound setTitle = (value) => {
    this.exam.title = value;
  }

  @action.bound setCustom = (value) => {
    this.exam.customized = value === 'custom';
  }

  @action.bound setIncludedModules = (modules = []) => {
    const { assignments: { questionCounts } } = this.props;
    this.exam.modules = modules.filter((key) => !!questionCounts.get(key));
  }

  @action.bound setQuestionCount = (input) => {
    const value = parseInt(input, 10);
    this.exam.numQuestions = Number.isNaN(value) ? 0 : value;
  }

  @action createExam() {
    const { router } = this.props;
    if (!this.exam.title) {
      const main = document.querySelector('main');
      main.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.examError = 'Please enter a title for the exam';
      return;
    }

    this.course.createExam(this.exam)
      .then((assignment) => {
        router.push(router.path.replace('create-exam', `view/${assignment.id}`));
      });
  }

  render() {
    const { auth } = this.props;
    const { exam, examError, courseModules } = this;

    if (!auth.site.hasAnswerKeys) {
      return <NotValidated />;
    }

    if (!auth.site.hasExams) {
      return (
        <p>
          Your package doesn&rsquo;t provide access to custom assessments.
          <br />
          Please&nbsp;
          {/* eslint-disable-next-line no-undef */}
          <button type="button" className="anchor" onClick={() => Intercom('show')}>
            contact our support team
          </button>
          {' '}
          if you would like to evaluate this feature.
        </p>
      );
    }

    if (!exam) {
      return null;
    }

    return (
      <div className="create-exam" ref={this.container}>
        <h1>Create a New Exam</h1>
        <div>
          <div>
            <strong>Give your Exam a title</strong>
            <TextBox
              value={exam.title}
              error={examError}
              onChange={this.setTitle}
              leftLabel
            />
          </div>
          <div className="formField">
            <strong>How do you want to begin?</strong>
            <RadioButtons
              options={{ auto: 'Generated', custom: 'Clean Slate' }}
              value={exam.customized ? 'custom' : 'auto'}
              onChange={this.setCustom}
              inline
            />
          </div>

          { exam.customized
            ? <p>Your exam will initially be empty, but you can add questions in the next step.</p>
            : (
              <div className="questionCount">
                { exam.modules.length > 0
                  ? (
                    <div className="formField">
                      {'Add '}
                      <TextBox
                        size="3"
                        max={exam.maxQuestions}
                        value={exam.numQuestions}
                        onChange={this.setQuestionCount}
                        inline
                      />
                      {' out of '}
                      <TextBox
                        size="3"
                        value={exam.maxQuestions}
                        onChange={this.setQuestionCount}
                        inline
                        disabled
                      />
                      {' questions from the following modules:'}
                    </div>
                  )
                  : <p>Select modules to generate questions:</p> }
                <CheckboxTable
                  headers={['Module', 'Questions']}
                  options={courseModules}
                  value={exam.modules}
                  onChange={(modules) => this.setIncludedModules(modules)}
                />
              </div>
            ) }
        </div>

        <button
          type="button"
          className="lgButton"
          onClick={action('CreateExam', () => this.createExam())}
        >
          <span>Create Exam</span>
          <small>It will be added to your assignments</small>
        </button>
      </div>
    );
  }
}

export default CreateExamLearnosity;
