import React from 'react';
import { inject, observer } from 'mobx-react';
import QuickAccess from 'widgets/navigation/QuickAccess';
import SubNavigation from 'widgets/navigation/SubNavigation';
import NavToggle from 'widgets/navigation/NavToggle';

const MainNav = ({ uiState }) => (
  // eslint-disable-next-line
  <nav
    className={`leftNav ${uiState.sidebarHidden ? 'collapsed' : ''}`}
    style={{ paddingTop: uiState.navHeight + 8 }}
  >
    <QuickAccess />
    <SubNavigation />
    <NavToggle />
  </nav>
);

export default inject('uiState')(observer(MainNav));
