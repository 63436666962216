import React from 'react';
import { Link } from 'react-router-dom';

const GradePoints = ({ assignment, grade }) => {
  let gradeViewUrl = '';

  if (assignment) {
    gradeViewUrl = `/courses/${assignment.courseId}/grades/book/${assignment.moduleKey}/unit/${assignment.unitKey}/grade/${grade.id}`;
  }

  if (!grade) return '-';

  return (
    <span>
      <Link to={gradeViewUrl}>{grade.points}</Link>
    </span>
  );
};

export default GradePoints;
