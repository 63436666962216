import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Modal from '../Modal';
import './LessonViewer.scss';

const LessonViewer = ({ auth, url, onDismiss }) => {
  // Start listening to messages on mount, destroy listener on unmount
  useEffect(() => {
    const messageListener = ({ data, origin }) => {
      const { scope, method } = data;
      if (scope === 'LESSON_VIEWER' && origin === auth.lessonOrigin) {
        switch (method) {
          case 'Terminate':
            onDismiss();
            break;
          default:
            // eslint-disable-next-line no-console
            console.log(`SCORM MESSAGE: ${JSON.stringify(data)}`);
            auth.setActive();
            break;
        }
      }
    };
    window.addEventListener('message', messageListener);
    return () => window.removeEventListener('message', messageListener);
  }, []);

  const frameSrc = `${auth.lessonOrigin}/lesson_viewer.html?lesson_url=${url}`;

  return (
    <Modal id="lesson_viewer" visible={!!url} onDismiss={onDismiss}>
      { url
        ? <iframe src={frameSrc} title="Lesson Content" />
        : null }
    </Modal>
  );
};

export default inject('auth')(observer(LessonViewer));
