import React from 'react';
import moment from 'moment';
import MaterialIcon from '../../widgets/MaterialIcon';
import TooltipButton from '../../widgets/TooltipButton';

const UsageStudent = ({ student }) => {
  const {
    fullName,
    email,
    enrollmentDate,
    lastLogon,
    numberOfCourses,
    coursesByTeacher,
    isLicensed,
  } = student;

  const licensedClass = isLicensed ? 'Yes' : 'No';
  const enrollment = moment(enrollmentDate).format('MM/DD/YYYY');
  const inactive = moment().diff(moment(lastLogon), 'days');
  const courseTeachers = Array.from(coursesByTeacher.values());

  return (
    <tr>
      <td className="name">{fullName}</td>
      <td className="email">{email}</td>
      <td className="enrollment-date">{enrollment}</td>
      <td className="inactive">{inactive}</td>
      <td className="courses">
        <TooltipButton buttonText={numberOfCourses}>
          {courseTeachers.map(({
            teacher,
            courses,
          }) => (
            <div key={`teacher_${teacher.id}`}>
              <h4>{teacher.name}</h4>
              <ul>
                { courses.map((course) => (
                  <li key={`course_${course.id}`}>
                    {course.title}
                  </li>
                )) }
              </ul>
            </div>
          ))}
        </TooltipButton>
      </td>
      <td className={`is-licensed ${licensedClass}`}>
        {licensedClass}
        {' '}
        { isLicensed
          ? ''
          : (
            <TooltipButton buttonText={<MaterialIcon>info</MaterialIcon>} tooltipClass="left">
              <h4>Tip</h4>
              <p>
                Want to remove a student that was enrolled
                <br />
                but not participating for whatever reason?
              </p>
              <p>
                <a
                  href="https://help.aeseducation.com/en/articles/2413412-remove-a-student-from-a-class"
                  target="aes_help"
                  rel="noreferrer noopener"
                >
                  How To: Remove a Student from a Class
                </a>
              </p>
            </TooltipButton>
          ) }
      </td>
    </tr>
  );
};

export default UsageStudent;
