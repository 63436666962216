import React, { Component } from 'react';
import {
  action, autorun, observe, observable,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Table from 'widgets/Table';

@inject('auth', 'router', 'students', 'courses') @observer
class Search extends Component {
  @observable query = ''

  @observable results = { students: [] }

  @observable loading = { students: false }

  @observable error = { students: null }

  componentDidMount() {
    const cancel1 = observe(this, 'query', () => {
      this.searchStudents();
    });

    const cancel2 = autorun(() => {
      const { router: { query: { q } } } = this.props;
      this.setQuery(q);
    });

    this.cancelObservers = () => {
      cancel1();
      cancel2();
    };
  }

  componentWillUnmount() {
    this.cancelObservers();
  }

  @action setQuery = (val) => { this.query = val; }

  @action setError(type, error) {
    this.loading[type] = false;
    this.error[type] = error.message;

    if ('Rollbar' in window) {
      window.Rollbar.error(error);
    }
  }

  @action searchStudents() {
    const { students } = this.props;
    this.error.students = null;
    this.loading.students = true;
    this.results.students = [];

    students.fullSearch(this.query)
      .then(this.loadStudentResults)
      .catch((err) => this.setError('students', err));
  }

  @action.bound loadStudentResults(data) {
    this.results.students = data;
    this.loading.students = false;
  }

  @action viewStudent(student, courseId) {
    const { router } = this.props;

    router.push(`/courses/${courseId}/students/list/${student.id}`);
  }

  @action async addStudentToClass(student) {
    const { auth, courses: { fromRoute: course } } = this.props;

    await course.addExistingStudent(student);

    student.courses.push({
      id: course.id,
      title: course.title,
      faculty: auth.user.name,
    });
    this.forceUpdate();
  }

  render() {
    const { courses } = this.props;
    const course = courses.fromRoute;
    const courseIds = courses.list.map((c) => c.id);

    const StudentActions = ({ student }) => {
      const studentIds = student.courses.map((c) => +c.id);
      const myCourse = courses.list.find((c) => studentIds.includes(c.id));
      const inThisCourse = studentIds.includes(course.id);

      return (
        <div className="studentActions">
          {myCourse
            && (
          // eslint-disable-next-line react/no-this-in-sfc
            <button type="button" onClick={() => this.viewStudent(student, myCourse.id)}>
              View Student
            </button>
            )}
          {!inThisCourse && !course.isRostered
            && (
          // eslint-disable-next-line react/no-this-in-sfc
            <button type="button" onClick={() => this.addStudentToClass(student)}>
              Add to This Class
            </button>
            )}
        </div>
      );
    };

    const columns = [
      {
        title: 'Name',
        key: 'name',
      },
      {
        title: 'Email/ID',
        key: 'email',
      },
      {
        title: 'Courses',
        key: 'courses',
        value: (student) => student.courses.map((c) => {
          const isMyCourse = courseIds.includes(c.id);

          return (
            <div>
              {isMyCourse
                && (
                <Link to={`/courses/${c.id}/students/list`}>
                  {c.faculty}
                  {' '}
                  -
                  {c.title}
                </Link>
                )}
              {!isMyCourse
                && (
                <div>
                  {c.faculty}
                  {' '}
                  -
                  {' '}
                  {c.title}
                </div>
                )}
            </div>
          );
        }),
      },
      {
        title: '',
        key: 'actions',
        value: (s) => <StudentActions course={course} student={s} />,
      },
    ];

    return (
      <div>
        <h1>
          Search Results: &ldquo;
          {this.query}
          &rdquo;
        </h1>

        <h2>Students</h2>
        {this.loading.students && <div>Loading results...</div>}
        {this.error.students && <div>Could not load search results.</div>}

        {this.results.students.length !== 0
          && <Table data={this.results.students} columns={columns} />}
        {this.results.students.length === 0
          && <div><i>No results.</i></div>}

      </div>
    );
  }
}

export default Search;
