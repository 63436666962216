import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import 'react-day-picker/lib/style.css';
import moment from 'moment';
import ToggleModal from '../ToggleModal';

@observer
class DateRange extends React.Component {
  @observable modal = false;

  @observable month = moment().subtract(1, 'months').toDate();

  @observable date = {
    from: null,
    to: null,
  }

  @observable dateLabel = 'Last 30 days'

  @action.bound handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.date);
    const { from, to } = range;
    this.date.from = from;

    if (to) {
      this.date.to = Date.parse(to) === Date.parse(from) ? null : to;
    }
  }

  @action.bound handleDateChange(from, to) {
    const { date } = this;
    date.from = from;
    date.to = to;
    this.getData();
  }

  @action.bound handleToday() {
    const first = new Date();
    this.handleDateChange(first);
    this.dateLabel = 'Today';
  }

  @action.bound handleYesterday() {
    const d = new Date();
    const first = new Date(d.setDate(d.getDate() - 1));
    const last = new Date();
    this.handleDateChange(first, last);
    this.dateLabel = 'Yesterday';
  }

  @action.bound handleThisWeek() {
    const first = moment().day(0).toDate();
    const last = moment().day(6).toDate();
    this.handleDateChange(first, last);
    this.dateLabel = 'This Week';
  }

  @action.bound handleLastWeek() {
    const first = moment().day(-7).toDate();
    const last = moment().day(-1).toDate();
    this.handleDateChange(first, last);
    this.dateLabel = 'Last Week';
  }

  @action.bound handleThisMonth() {
    const current = moment().date();
    const first = moment().date(1).toDate();
    const last = moment().date(current).toDate();
    this.handleDateChange(first, last);
    this.dateLabel = 'This Month';
  }

  @action.bound handleLastMonth() {
    const d = new Date();
    const n = d.getMonth();
    const first = moment().month(n - 1).date(1).toDate();
    const last = moment().month(n - 1).date(31).toDate();
    this.handleDateChange(first, last);
    this.dateLabel = 'Last Month';
  }

  @action.bound handleThisSchoolYear() {
    const first = moment().month(6).date(1).toDate();
    const last = new Date();
    this.handleDateChange(first, last);
    this.dateLabel = 'This school Year';
  }

  @action.bound getData() {
    const { getDateRangeData, updateDate } = this.props;
    const { date: { from, to } } = this;
    const { dateRef: { from: prevFrom, to: prevTo } } = this.props;
    if (from !== prevFrom || to !== prevTo) {
      updateDate(from, to);
      getDateRangeData();
      this.dateLabel = `${moment(from).format('MM/DD/YYYY')}${to ? ` to ${moment(to).format('MM/DD/YYYY')}` : ''}`;
    }
    this.openModal();
  }

  @action.bound resetRange() {
    const { dateRef: { from, to } } = this.props;
    const { date } = this;
    date.from = from;
    date.to = to;
  }

  @action.bound openModal() {
    this.resetRange();
    this.modal = !this.modal;
  }

  @action.bound defaultFilter() {
    const { date } = this;
    date.from = null;
    date.to = null;
    this.getData();
  }

  render() {
    const {
      modal, month, date: { from, to }, dateLabel,
    } = this;
    const { dateRef: { from: fromRef } } = this.props;
    const modifiers = { start: from, end: to };

    return (
      <>
        <div className="dateRangeInputContainer">
          <button type="button" onClick={() => this.openModal()}>
            <i className="material-icons calendar">date_range</i>

            {fromRef && (
              <div className="tip">
                <p>{dateLabel}</p>

              </div>
            )}
            <input disabled value="Last 30 days" />
          </button>

        </div>
        <ToggleModal
          visible={modal}
          title="Date Range Search"
          onDismiss={() => this.getData()}

        >
          <div className="rangeData">
            <DayPicker
              className="Selectable"
              numberOfMonths={2}
              month={month}
              selectedDays={[from, { from, to }]}
              modifiers={modifiers}
              pagedNavigation
              fixedWeeks
              onDayClick={this.handleDayClick}
            />
            <div className="periods" style={{ order: -1 }}>
              <button type="button" onClick={() => this.handleToday()}>Today</button>
              <button type="button" onClick={() => this.handleYesterday()}>Yesterday</button>
              <button type="button" onClick={() => this.handleThisWeek()}>This Week</button>
              <button type="button" onClick={() => this.handleLastWeek()}>Last Week</button>
              <button type="button" onClick={() => this.handleThisMonth()}>This Month</button>
              <button type="button" onClick={() => this.handleLastMonth()}>Last Month</button>
              <button type="button" onClick={() => this.handleThisSchoolYear()}>This School Year</button>
            </div>
          </div>

        </ToggleModal>
      </>
    );
  }
}
export default DateRange;
