import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject('auth') @observer
class CatalogWelcome extends Component {
  @computed get embedOpts() {
    const { auth } = this.props;
    return [
      `email=${auth.user.email}`,
    ].join(' ');
  }

  render() {
    return (
      <div className="introContent">
        <div className="heroSection">
          <h1 style={{ marginBottom: '0px' }}>How Does AES Structure Curriculum?</h1>
          <div style={{ height: 420, width: 800, position: 'relative' }}>
            <span
              key={Date.now()}
              className={`wistia_embed wistia_async_ag5arld9sd ${this.embedOpts}`}
              style={{
                width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, bottom: 0, right: 0,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CatalogWelcome;
