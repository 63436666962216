import React from 'react';
import { inject, observer } from 'mobx-react';
import CourseTable from 'widgets/dashboard/CourseTable';
import CourseList from 'widgets/dashboard/CourseList';

const DashboardTemplates = ({ auth, courses, listView }) => {
  const { enableCreateTemplates, isAdmin } = auth;
  const { publishedTemplates, allTemplates } = courses;
  const courseData = isAdmin ? allTemplates : publishedTemplates;

  if (enableCreateTemplates && courseData.length > 0) {
    return (
      <div>
        <h1>District Templates</h1>
        { listView
          ? <CourseTable courseData={courseData} template />
          : <CourseList courseData={courseData} template />}
      </div>
    );
  }

  return null;
};

export default inject('auth', 'courses')(observer(DashboardTemplates));
