import React from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

const PreviewLink = (props) => {
  const {
    courses: {
      fromRoute: {
        id,
        enableBeta,
        previewUrl,
      },
    },
    path,
  } = props;

  const openPreview = () => window.open(previewUrl, 'aes_course_preview');

  if (enableBeta) {
    return (
      <li>
        <button type="button" className="anchor" onClick={openPreview}>
          View as Student
        </button>
      </li>
    );
  }

  // TODO: Remove after all courses are updated to V2
  return (
    <li>
      <NavLink to={`/courses/${id}/${path}`}>
        View as Student
      </NavLink>
    </li>
  );
};

export default inject('courses')(observer(PreviewLink));
