import React from 'react';
import { inject, observer } from 'mobx-react';
import CourseCreator from './CourseCreator';

const CreateCourse = ({ auth }) => {
  if (!auth?.site) {
    return null;
  }

  return (
    <CourseCreator />
  );
};

export default inject('auth')(observer(CreateCourse));
