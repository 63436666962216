import React from 'react';
import {
  action, observable, when,
} from 'mobx';
import { observer } from 'mobx-react';
import Learnosity from 'services/learnosity';
import './newStyle.scss';
import { Link } from 'react-router-dom';
import ToggleSlider from '../../../widgets/ToggleSlider';
import MaterialIcon from '../../../widgets/MaterialIcon';
import logo from '../../../data/img/aes-logo.svg';

const LEARNOSITY_DIV_ID = 'learnosity_answer_key';

@observer
class AnswerKeyLearnosity extends React.Component {
  @observable showAnswers = true;

  @observable randomSeed = undefined;

  @observable questionNumbers = 0;

  constructor(props) {
    super(props);
    this.learnosityContainer = React.createRef();
  }

  componentDidMount() {
    const { title } = this.props;

    when(() => !!title,
      () => {
        this.loadAnswerKey();
      });
  }

  componentDidUpdate(prevProps) {
    const { title } = this.props;

    if (title !== prevProps.title) {
      this.loadAnswerKey();
    }
  }

  componentWillUnmount() {
    Learnosity.resetItems();
  }

  @action.bound getQuestionNumbers(learnosityRequest) {
    this.questionNumbers = Learnosity.getQuestionCount(learnosityRequest);
  }

  @action.bound shuffle() {
    this.randomSeed = Date.now();
    this.loadAnswerKey();
  }

  @action.bound toggleAnswers() {
    this.showAnswers = !this.showAnswers;
    this.loadAnswerKey();
  }

  // MAKE FUNCTION THAT REQUIRES NO PARAMS FROM PARENT FUNCTION

  @action loadAnswerKey() {
    const { loadAnswers } = this.props;

    const {
      showAnswers,
      randomSeed,
    } = this;
    Learnosity.resetItems();

    return loadAnswers(showAnswers, randomSeed).then((learnosityRequest) => {
      this.initializeLearnosity(learnosityRequest);
    });
  }

  createLearnosityNode() {
    const existingDiv = document.getElementById(LEARNOSITY_DIV_ID);
    if (!existingDiv) {
      const { current } = this.learnosityContainer;
      const learnosityDiv = document.createElement('div');
      learnosityDiv.id = LEARNOSITY_DIV_ID;
      current.append(learnosityDiv);
    }
  }

  initializeLearnosity(learnosityRequest) {
    this.createLearnosityNode();
    Learnosity.initializeItems(learnosityRequest, `#${LEARNOSITY_DIV_ID}`);
    this.getQuestionNumbers(learnosityRequest);
  }

  render() {
    const { showAnswers, questionNumbers } = this;

    const {
      courseName,
      title,
      returnUrl,
    } = this.props;

    if (!title) {
      return null;
    }

    return (
      <div className="answer-key-learnosity">
        <div className="heading">
          <Link to={returnUrl} className="button">
            &larr; Return to Assignment
          </Link>
          <div className="actions">
            <ToggleSlider
              active={showAnswers}
              onClick={this.toggleAnswers}
              titleOn="Answers Visible"
              titleOff="Answers Hidden"
            />
            <button type="button" className="lightButton" onClick={this.shuffle}>
              <span>Shuffle</span>
              <MaterialIcon>shuffle</MaterialIcon>
            </button>
            <button type="button" onClick={window.print}>
              <span>Print</span>
              <MaterialIcon>print</MaterialIcon>
            </button>
          </div>
        </div>
        <h1>
          <img src={logo} className="logo" alt="Applied Education Services" />
          <div className="answer-key-info">
            { title }
            { questionNumbers
              ? ` (${questionNumbers} questions)`
              : null}
            <br />
            <span className="course-name">
              { courseName || null }
            </span>
          </div>
        </h1>
        <div id="learnosity_container" ref={this.learnosityContainer} />
        <div className="answer-key-footer">
          This answer key for
          {' '}
          { title }
          {' '}
          was printed from the Applied Educational Systems, Inc. platform.
        </div>
      </div>
    );
  }
}

export default AnswerKeyLearnosity;
