import React from 'react';
import { Link } from 'react-router-dom';
import UsageTeacher from './UsageTeacher';

const UsageTeacherList = ({ finalTeacherList, search, teachersCreated }) => {
  if (finalTeacherList.length > 0) {
    return finalTeacherList.map((t) => (
      <UsageTeacher teacherData={t} key={t.teacher.id} search={search} />
    ));
  }

  if (!teachersCreated) {
    return (
      <table className="no-teachers">
        <tbody>
          <tr>
            <td>
              No teachers have been created for this site yet.
              {' '}
              <Link to="/settings/site/teachers">
                Create some teachers
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  return null;
};

export default UsageTeacherList;
