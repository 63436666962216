import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import './TooltipButton.scss';

const TooltipButton = ({
  buttonClass = 'anchor',
  tooltipClass = 'center',
  buttonText,
  children,
}) => {
  const [tipId] = useState(uuid());
  const [tipVisible, setTipVisible] = useState(false);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setTipVisible(true)}
      onMouseLeave={() => setTipVisible(false)}
    >
      <button
        type="button"
        aria-describedby={tipId}
        className={buttonClass}
        onClick={() => setTipVisible(!tipVisible)}
      >
        {buttonText}
      </button>
      <div id={tipId} className={`tooltip-content ${tooltipClass} ${tipVisible ? 'show' : 'hide'}`}>
        {children}
      </div>
    </div>
  );
};

export default TooltipButton;
