export function join(arr, sep) {
  if (!Array.isArray(arr)) return [];

  const result = [];

  arr.forEach((item) => {
    result.push(item);
    result.push(sep);
  });

  result.splice(-1);

  return result;
}

// https://medium.com/@nitinpatel_20236/how-to-shuffle-correctly-shuffle-an-array-in-javascript-15ea3f84bfb
export function shuffle(arr) {
  const array = arr.slice(0);
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * i);
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export function dedupe(arr) {
  return arr.filter((item, index) => (
    arr.indexOf(item) === index
  ));
}
