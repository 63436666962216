import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Checkbox from 'widgets/form/Checkbox';

const DailyQuizChallenge = ({ courses: { fromRoute: course } }) => {
  const ap = course.quizChallengeAP;
  const mt = course.quizChallengeMT;
  const ph = course.quizChallengePH;
  useEffect(() => {
    if (!ap && !mt && !ph) {
      course.setQuizChallengeEnabled(false);
    }
  }, [ap, mt, ph]);

  return (
    <div>
      <h1>Daily Quiz Challenge</h1>

      <p>
        Daily quiz challenges are short refreshers that students may
        optionally take once a day when they sign in.
      </p>

      <Checkbox
        label="Students will take the daily quiz challenge."
        checked={course.quizChallenge}
        onChange={(value) => course.setQuizChallengeEnabled(value)}
        noMargin
      />
      <div className="formGroup" style={{ opacity: course.quizChallenge ? 1 : 0.5 }}>
        <Checkbox
          label="Include questions from Anatomy &amp; Physiology."
          checked={course.quizChallengeAP}
          onChange={(value) => course.setQuizChallengeEnabledAP(value)}
          disabled={!course.quizChallenge}
          noMargin
        />
        <Checkbox
          label="Include questions from Medical Terminology."
          checked={course.quizChallengeMT}
          onChange={(value) => course.setQuizChallengeEnabledMT(value)}
          disabled={!course.quizChallenge}
          noMargin
        />
        <Checkbox
          label="Include questions from Pharmacy Tech."
          checked={course.quizChallengePH}
          onChange={(value) => course.setQuizChallengeEnabledPH(value)}
          disabled={!course.quizChallenge}
          noMargin
        />
      </div>
    </div>
  );
};

export default inject('courses')(observer(DailyQuizChallenge));
