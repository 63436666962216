import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Modal from 'widgets/Modal';

class StudentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exportModal: false,
      downloadLink: '',
    };
  }

  // export status report
  getLessonStatusReport = (jobId) => {
    const { courses: { fromRoute: course } } = this.props;
    course.getLessonStatusReportData(jobId)
      .then((result) => {
        this.setState({
          downloadLink: result.url,
        });
      })
      // eslint-disable-next-line no-console
      .catch((res) => console.log('Error getting report: ', res));
  }

  handleExportStatusReport = () => {
    const { courses: { fromRoute: course } } = this.props;
    course.getLessonStatusReport(course.id).then((res) => {
      this.getLessonStatusReport(res.job_id);
      this.setState({ exportModal: true });
    });
  }

  handleClose = () => {
    this.setState({
      exportModal: false,
    });
  }

  render() {
    const { courses: { fromRoute: course }, router } = this.props;
    const { downloadLink, exportModal } = this.state;

    const totalQuizzes = course.moduleAssignments.reduce(
      (total, assignment) => {
        const quizzes = assignment.module.units.reduce(
          (subtotal, unit) => subtotal + (unit.showQuiz ? 1 : 0),
          0,
        );
        return total + quizzes;
      },
      0,
    );

    return (
      <div className="statusReport">
        <div className="pageHeader">
          <h1>Status Report</h1>
          <button type="button" onClick={this.handleExportStatusReport}>Export Status Reports</button>
        </div>
        <table>
          <thead>
            <tr>
              <th width={200}>Student Name</th>
              <th>Average</th>
              <th>Modules</th>
              <th>Quizzes</th>
              <th>Worksheets</th>
            </tr>
          </thead>

          <tbody>
            {course.students.map(
              (student) => {
                const noGrade = {
                  average: null,
                  completed: 0,
                  quizzesDone: 0,
                  worksheetsDone: 0,
                };
                const grade = student.gradesByCourse.get(course.id) || noGrade;

                return (
                  <tr key={student.id}>
                    <td>
                      <Link to={`${router.path}/${student.id}`}>{student.formatName}</Link>
                    </td>
                    <td>{grade.average || '-'}</td>
                    <td>
                      {grade.completed}
                      /
                      {course.assignments.length}
                    </td>
                    <td>
                      {grade.quizzesDone}
                      /
                      {totalQuizzes}
                    </td>
                    <td>
                      {grade.worksheetsDone}
                      /
                      {totalQuizzes}
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
        <Modal
          title="Status Report"
          visible={exportModal}
          onDismiss={this.handleClose}
        >
          <p style={{ margin: 0 }}>
            You can download the status report for all students using the link below
          </p>
          {downloadLink
            ? (
              <a
                href={downloadLink}
                rel="noopener noreferrer"
                onClick={this.handleClose}
                download="Lesson Status Report"
              >
                <p>Download Status Report</p>
              </a>
            )
            : <p>Loading...</p>}
          <button type="button" onClick={this.handleClose}>Close</button>
        </Modal>
      </div>
    );
  }
}

export default inject('courses', 'router')(observer(StudentList));
