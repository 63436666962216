import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('router')
@observer
class OAuthComplete extends React.PureComponent {
  componentDidMount() {
    const { router: { location: { search } } } = this.props;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    const state = params.get('state');
    if (code && state && window.opener) {
      window.opener.postMessage({ oauthStatus: 'complete', code, state }, window.location.origin);
    } else {
      window.opener.postMessage({ oauthStatus: 'error' }, window.location.origin);
    }
  }

  render() {
    return (
      <div className="preloader">
        <div className="preloader-spinner" />
      </div>
    );
  }
}

export default OAuthComplete;
