import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

function StudentAverage({
  moduleKey, grade, router, student,
}) {
  const score = grade?.getStudentOverall(moduleKey);
  const url = [
    '/courses/',
    router.courseId,
    '/grades/by-student/',
    student.id,
  ].join('');

  return (
    <Link to={url}>
      {score || <i className="material-icons md-18">add</i>}
    </Link>
  );
}

export default inject('router')(observer(StudentAverage));
