import React, { Component } from 'react';
import {
  action, observable, reaction, runInAction,
} from 'mobx';
import { inject, observer } from 'mobx-react';

import { Link } from 'react-router-dom';
import moment from 'moment';
import { triggerDownload } from 'services/WebAPI';
import Dropdown from 'widgets/form/Dropdown';
import Modal from 'widgets/Modal';
import Table from 'widgets/Table';
import DatePicker from 'widgets/form/DatePicker';

@inject('courses', 'router') @observer
class TimeOnline extends Component {
  @observable startTime = moment().subtract(1, 'month').toDate();

  @observable endTime = moment().toDate();

  @observable popup = false

  @observable selected = '_ALL'

  @observable timeOnlineRows = []

  columns = [
    {
      title: 'Student',
      key: 1,
      value: (r) => {
        const { router: { courseId } } = this.props;
        return (
          <Link to={`/courses/${courseId}/students/list/${r[0]}`}>{r[1]}</Link>
        );
      },
    },
    {
      title: 'Time Online',
      key: 2,
    },
    {
      title: 'Total Minutes Online',
      key: 3,
    },
  ]

  componentDidMount() {
    const { courses: { fromRoute: course } } = this.props;
    runInAction(() => {
      this.selected = course.id;
    });
    reaction(
      () => [this.startTime, this.endTime],
      async () => {
        const rows = await course.getStudentTimeOnline(this.startTime, this.endTime);
        this.populateRows(rows);
      },
      { fireImmediately: true },
    );
  }

  componentDidUpdate({ courses: { fromRoute: prevCourse } }) {
    const { courses: { fromRoute: course } } = this.props;
    if (prevCourse.id !== course.id) {
      runInAction(() => { this.selected = course.id; });
    }
  }

  @action setStartTime = (val) => { this.startTime = val; }

  @action setEndTime = (val) => { this.endTime = val; }

  @action.bound showPopup = () => { this.popup = true; }

  @action.bound hidePopup = () => { this.popup = false; }

  @action.bound setSelected = (val) => { this.selected = val; }

  @action populateRows = (data) => { this.timeOnlineRows = data; }

  @action.bound async generateReport() {
    const { courses } = this.props;
    const allCourses = this.selected === '_ALL';
    const course = allCourses ? courses.fromRoute : courses.byId.get(+this.selected);
    const startDate = this.startTime;
    const endDate = this.endTime;

    if (!course) {
      throw new Error('Can\'t generate a time online report without a course');
    }

    const data = await course.generateExcel(
      'time online',
      { allCourses, startDate, endDate },
    );

    triggerDownload(
      data,
      `Student Time Online - ${allCourses ? 'All Courses' : course.title}.xls`,
    );
  }

  render() {
    const { courses } = this.props;
    const { fromRoute: course } = courses;

    return (
      <div>
        <h1>Time Online Report</h1>

        <div>
          <span>
            <span>From </span>
            <DatePicker
              placeholder="No Start Date"
              value={this.startTime}
              onChange={(val) => this.setStartTime(val)}
            />
            <span> to </span>
            <DatePicker
              placeholder="No End Date"
              value={this.endTime}
              onChange={(val) => this.setEndTime(val)}
            />
          </span>
          <div style={{ float: 'right' }}>
            <button type="button" onClick={this.showPopup}>Export to Excel</button>
          </div>

          {!this.timeOnlineRows.length
            && (
            <p>
              <i>No student time online for this time period.</i>
            </p>
            )}
          <Table data={this.timeOnlineRows} columns={this.columns} />
          <p>
            <i>
              Showing time spent in the class:
              {' '}
              {course.title}
              .
            </i>
            <br />
            <br />
            <a
              href="https://help.aeseducation.com/en/articles/2372347-student-time-online-report"
              target="_blank"
              rel="noreferrer noopener"
            >
              <i className="material-icons">help</i>
              &nbsp;Time Online Report Help
            </a>
          </p>
        </div>

        <Modal
          title="Export to Excel"
          visible={this.popup}
          onDismiss={this.hidePopup}
        >
          <Dropdown
            label="Generate for:"
            options={[['_ALL', 'All Courses'], ...courses.list.map((c) => [c.id, c.title])]}
            value={this.selected}
            onChange={this.setSelected}
            noEmpty
          />
          <p>
            Export Data from&nbsp;
            {moment(this.startTime).format('MM/DD/YYYY')}
            &nbsp;to&nbsp;
            {moment(this.endTime).format('MM/DD/YYYY')}
          </p>
          <button type="button" className="lgButton" onClick={this.generateReport}>
            <span>Generate Report</span>
            <small>This may take some time.</small>
          </button>
        </Modal>
      </div>
    );
  }
}

export default TimeOnline;
