import React from 'react';
import { inject, observer } from 'mobx-react';

const KeyboardingGlobal = ({ courses: { fromRoute: course } }) => {
  const leaders = course.globalKeyboardingLeaders();
  return (
    <div className="keyboarding">
      <h1>Keyboarding Global Leaderboard</h1>
      <p><i>All Leaderboards Reset Monthly</i></p>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>State</th>
            <th>School</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          { leaders && leaders.map((score, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{score.name}</td>
              <td>{score.state}</td>
              <td>{score.school}</td>
              <td>{score.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default inject('courses')(observer(KeyboardingGlobal));
