import { action, computed, extendObservable } from 'mobx';

export default class CustomModule {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;
    this.isCustom = true;
    this.imageUrl = 'CUSTOM';

    extendObservable(this, {
      id: +input.id || 0,
      key: input.key || input.module_key || 'XXX',
      title: input.title || '',
      createdAt: input.created_at,
      facultyId: input.faculty_id,
      teacherModuleResources: input.teacherModuleResources || input.teacher_module_resources || [],
      image: '/system/custom.png',
    });
  }

  @computed get resources() {
    const bySequence = (a, b) => a.sequence - b.sequence;

    return this.teacherModuleResources
      .filter((v) => v)
      .sort(bySequence);
  }

  @action async rename(title) {
    const { WebAPI } = this.rootStore;

    await WebAPI.patch(
      `teacher/custom_modules/${this.id}`,
      { title },
    );

    action(() => {
      this.title = title;
    })();
  }

  @action async addResource(file, title, description) {
    if (!file || !(file instanceof File)) {
      throw new Error('Cannot add a non-file resource');
    }

    const { WebAPI } = this.rootStore;
    const data = new FormData();

    data.append('teacher_module_resource[attachment]', file);
    data.append('teacher_module_resource[title]', title);
    data.append('teacher_module_resource[description]', description);
    data.append('teacher_module_resource[sequence]', this.resources.length + 1);

    const result = await WebAPI.post(
      `teacher/custom_modules/${this.id}/custom_module_resources`,
      data,
    );

    action(() => {
      this.teacherModuleResources.push(result);
    })();
  }

  @action async renameResource(id, title, description) {
    const { WebAPI } = this.rootStore;
    const data = new FormData();
    data.append('teacher_module_resource[title]', title);
    data.append('teacher_module_resource[description]', description);

    await WebAPI.patch(
      `teacher/custom_modules/${this.id}/custom_module_resources/${id}`,
      data,
    ).then((response) => {
      this.updateResource(response);
    });
  }

  @action updateResource = (dataToUpdate) => {
    this.teacherModuleResources = this.teacherModuleResources.filter((resource) => {
      if (resource.id === dataToUpdate.id) {
        /* eslint-disable no-param-reassign */
        resource.title = dataToUpdate.title;
        resource.description = dataToUpdate.description;
        /* eslint-enable no-param-reassign */
      }
      return resource;
    });
  }

  @action async deleteResource(id) {
    const { WebAPI } = this.rootStore;

    await WebAPI.delete(
      `teacher/custom_modules/${this.id}/custom_module_resources/${id}`,
    );

    action(() => {
      this.teacherModuleResources = this.teacherModuleResources.filter((r) => r.id !== id);
    })();
  }

  // STUB to match Module API
  // eslint-disable-next-line class-methods-use-this
  loadLessonDetails() {}
}
