import {
  action, computed, extendObservable, runInAction,
} from 'mobx';
import { DueDate } from 'util/DateTime';
import Module from 'models/Module';
import AssignmentEntry from 'models/AssignmentEntry';
import Exam from 'models/Exam';

export default class Assignment {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;

    extendObservable(this, {
      id: +input.id || 0,
      courseId: +input.courseId || +input.course_id || 0,
      sequence: +input.sequence || 0,
      due: input.due || '',
      examId: +input.examId || +input.exam_id || 0,
      type: +input.type || +input.assignment_type || 1,
      typeName: input.type_name || 'module',
      open: !!input.open,
      moduleKey: input.moduleKey || input.module_key || '',
      $entries: input.entries || [],
      exam: new Exam(rootStore, input.exam || {}),
      testType: input.test_type,
    });

    if (input.assignment_entries && input.assignment_entries.length) {
      this.$entries = input.assignment_entries.map((e) => new AssignmentEntry(this.rootStore, e));
    }

    if (this.examId) this.moduleKey = `EXAM_${this.examId}`;
  }

  @computed get course() {
    const { courses } = this.rootStore;
    return courses.findOrCreateCourse(this.courseId);
  }

  @computed get module() {
    const { curriculum } = this.rootStore;
    if (this.isExam) return new Module(this.rootStore);
    if (this.isCustomModule) {
      return curriculum.customModules.find((m) => m.key === this.moduleKey)
        || new Module(this.rootStore);
    }

    return curriculum.modulesByKey[this.moduleKey] || new Module(this.rootStore);
  }

  @computed get imageUrl() {
    if (this.isCustomModule) {
      return 'CUSTOM';
    }
    if (this.isExam) {
      return 'EXAM';
    }
    return this.module.imageUrl;
  }

  @computed get title() {
    switch (this.typeName) {
      case 'exam': return this.exam.title;
      case 'module':
      case 'teacher_module':
        return this.module.title ? this.module.title : `<Couldn't Load Module ${this.moduleKey}>`;
      default: return `Unknown Assignment ${this.typeName} ${this.moduleKey} ${this.id}`;
    }
  }

  @computed get key() {
    return this.moduleKey;
  }

  @computed get isExam() {
    return !!this.examId;
  }

  @computed get isCustomModule() {
    return this.type === 3;
  }

  @computed get isModule() {
    return !this.isExam && !this.isCustomModule;
  }

  @computed get entries() {
    if (this.isExam) {
      return [new AssignmentEntry(this.rootStore, {
        id: this.examId,
        courseId: this.courseId,
        assignmentId: this.id,
        moduleKey: `EXAM_${this.examId}`,
        unitKey: `EXAM_${this.examId}`,
        type: 2,
        typeName: 'exam',
        open: true,
      })];
    }

    if (this.course.preTest) {
      const list = [...this.$entries];
      const testEntry = list.find((a) => a.typeName === 'test');
      const hasTest = this.module.hasTest && testEntry && !testEntry.removed;
      if (hasTest) {
        list.splice(-1, 0, new AssignmentEntry(this.rootStore, {
          courseId: this.courseId,
          assignmentId: this.id,
          moduleKey: this.moduleKey,
          unitKey: `PREMT_${this.moduleKey}`,
          type: 2,
          typeName: 'test',
          open: true,
        }));
      }
      return list;
    }

    return this.$entries;
  }

  @computed get hasTest() {
    return !!this.entries.find((e) => e.isTest);
  }

  @computed get gradeEntries() {
    return this.entries.filter((e) => e.isGraded);
  }

  @computed get activeGradeEntries() {
    return this.gradeEntries.filter((entry) => !entry.removed);
  }

  @computed get hasActiveQuizzes() {
    return this.activeGradeEntries.filter((e) => e.fullTitle !== 'Module Test').length;
  }

  @computed get gradeCount() {
    return this.activeGradeEntries.filter((e) => e.isGraded && !e.isPreTest).length;
  }

  @computed get pretest() {
    if (this.typeName === 'exam') return this;
    return this.entries.find((e) => e.unitKey.match(/^PREMT_/));
  }

  @computed get test() {
    if (this.typeName === 'exam') return this;
    return this.entries.find((e) => e.unitKey.match(/^MT_/));
  }

  // total possible quiz questions from each unit
  @computed get totalQuizQuestionCount() {
    return this.activeGradeEntries
      .reduce((total, entry) => (total + (entry.quizQuestionCount || 0)), 0);
  }

  // test question count on module test
  @computed get totalTestQuestionCount() {
    const minCount = this.activeGradeEntries
      .reduce((total, entry) => (total + (entry.minimumTestQuestionCount || 0)), 0);
    const maxCount = this.activeGradeEntries
      .reduce((total, entry) => (total + (entry.totalTestQuestionCount || 0)), 0);
    // Default count is hard-coded on the backend, has nothing to do with the number in modules.json
    const defaultCount = 25;

    if (maxCount < defaultCount) {
      return maxCount;
    }
    if (minCount < defaultCount) {
      return defaultCount;
    }
    return minCount;
  }

  @computed get totalPossibleModulePoints() {
    if (this.isExam) {
      return this.exam.questionCount;
    }

    const testEntry = this.$entries.find((a) => a.typeName === 'test');
    const hasTest = this.module.hasTest && testEntry && !testEntry.removed;

    if (hasTest) {
      return this.totalQuizQuestionCount + this.totalTestQuestionCount;
    }

    return this.totalQuizQuestionCount;
  }

  @computed get hours() {
    if (this.isCustomModule) return '-';
    if (!this.module || this.entries.length === 0) return 0;
    return this.entries.map((e) => e.hours).reduce((sum, entry) => sum + entry);
  }

  @computed get dueDate() {
    const dueDate = new DueDate(this.due);
    if (dueDate.valid) {
      return dueDate.value;
    }
    return null;
  }

  @computed get entriesById() {
    return this.entries.reduce(
      (result, entry) => {
        // eslint-disable-next-line no-param-reassign
        result[entry.id] = entry;
        return result;
      },
      {},
    );
  }

  @computed get hasEntries() {
    return !!this.entries.length;
  }

  // return worksheet count based on units that:
  // have quiz, have worksheetQuestionCount, and are not deleted
  @computed get worksheetCount() {
    return this.$entries.filter(
      (entry) => entry.hasQuiz
        && entry.worksheetQuestionCount > 0
        && !entry.removed
        && !entry.isTest
        && !entry.isPreTest,
    ).length;
  }

  @action setStatus(open) {
    const { WebAPI } = this.rootStore;
    if (this.open !== open) {
      this.open = !!open;
      WebAPI.patch(
        `teacher/courses/${this.courseId}/assignments/${this.id}`,
        {
          assignment: {
            open: this.open,
          },
        },
      );
    }
  }

  @action setTestEnabled(enabled) {
    this.test.setEnabled(enabled);
  }

  @action setTestStatus(open) {
    if (this.examId) this.setStatus(open);
    else this.test.setOpen(open);
  }

  @action toggleTestType() {
    const { WebAPI } = this.rootStore;
    const testType = (this.testType === 1 ? 0 : 1);
    WebAPI.patch(
      `teacher/courses/${this.courseId}/assignments/${this.id}`,
      {
        assignment: {
          test_type: testType,
        },
      },
    ).then(() => {
      runInAction(() => {
        this.testType = testType;
      });
    });
  }

  @action setDueDate(value, open) {
    const { WebAPI } = this.rootStore;
    const dueDate = new DueDate(value);
    const due = dueDate.stringValue;

    WebAPI.patch(
      `teacher/courses/${this.courseId}/assignments/${this.id}`,
      {
        assignment: { due, open },
      },
    ).then(() => runInAction(() => {
      this.due = due;
      this.open = open;
    }));
  }
}
