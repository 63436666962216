import { action, computed, observable } from 'mobx';
import moment from 'moment'; // TODO: remove this when mock is removed
import StudentLicense from '../models/StudentLicense';

class LicenseUsageStore {
  @observable totalLicenses = 0;

  @observable totalStudents = 0;

  @observable unlicensedStudents = 0;

  @observable byTeacher = observable.map();

  @observable students = [];

  @observable isLoading = false;

  constructor(rootStore, api) {
    this.api = api;
    this.rootStore = rootStore;
  }

  @computed get isLoaded() {
    return this.byTeacher.size > 0;
  }

  @action async loadReport() {
    const { teachers } = this.rootStore;
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    if (!teachers.loaded) {
      await teachers.loadForCurrentSite();
    }

    /* TODO: replace mock when API work is completed
    this.api.get('teacher/reports/license-usage')
    .then((result) => result.job_id)
    .then((jobId) => this.api.pollJob(jobId))
    .then((response) => this.processResponse(response));
    */

    // Mock loading
    window.setTimeout(() => {
      this.processResponse(this.MockResponse());
    }, 3000);
  }

  @action processResponse(response) {
    const { teachers } = this.rootStore;
    const {
      total_licenses: totalLicenses,
      total_students: totalStudents,
      unlicensed_students: unlicensedStudents,
      by_teacher: byTeacher,
      students,
    } = response;

    this.totalLicenses = totalLicenses;
    this.totalStudents = totalStudents;
    this.unlicensedStudents = unlicensedStudents;
    this.students = students.map((input) => new StudentLicense(this.rootStore, input));

    byTeacher.forEach((values) => {
      const teacherId = values.teacher_id;
      const teacher = teachers.byId.get(teacherId);
      this.byTeacher.set(teacherId, {
        teacher,
        totalStudents: values.total_students,
        unlicensedStudents: values.unlicensed_students,
        students: [],
      });
    });

    this.students.forEach((studentLicense) => {
      studentLicense.teacherIds.forEach((teacherId) => {
        const teacher = this.byTeacher.get(teacherId);
        if (teacher) {
          teacher.students.push(studentLicense);
        }
      });
    });

    this.isLoading = false;
  }

  // TODO: Remove this function after API is implemented
  /*
  {
    "total_licenses": 10,
    "total_students": 15,
    "unlicensed_students": 5,
    "by_teacher": [
      {
        "teacher_id": 274,
        "total_students": 5,
        "unlicensed_students": 2,
      },
      {...},
      {...}
    ],
    "students": [
      {
        "student_id": 5209,
        "first_name": "Jake",
        "last_name": "Whetstone",
        "is_licensed": true,
        "last_logon": "2022-04-14T12:36:00.000Z",
        "enrollment_date": "2018-10-11T14:08:42.000Z",
        "enrollments": [
          {
            "course_id": 1312,
            "teacher_id": 274,
            "created_at": "2018-10-11T14:08:42.000Z"
          },
          {}
        ]
      },
      {...},
      {...}
    ]
  }
  */
  MockResponse() {
    const { auth: { isAdmin, originalUser }, teachers } = this.rootStore;
    const teacherArray = isAdmin
      ? teachers.list
      : [teachers.byId.get(originalUser.id)];
    const students = [];
    const byTeacher = teacherArray.map((teacher) => {
      const { courses } = teacher;

      if (courses.length) {
        const enrollmentDays = Math.floor(Math.random() * 70);
        const logonDays = Math.floor(Math.random() * 7);
        const enrollmentDate = moment().subtract(enrollmentDays, 'days').format();
        const lastLogon = moment().subtract(logonDays, 'days').format();
        const enrollments = courses.slice(-2).map((course) => ({
          teacher_id: teacher.id,
          course_id: course.id,
          created_at: enrollmentDate,
        }));
        const lastTwoStudents = students.slice(-2);
        const nextThreeStudents = [
          {
            student_id: teacher.id + 1000,
            first_name: 'FirstName',
            last_name: `LastName${teacher.id}`,
            email: `FirstName.LastName${teacher.id}@email.com`,
            last_logon: lastLogon,
            enrollment_date: enrollmentDate,
            is_licensed: true,
            enrollments: [...enrollments],
          },
          {
            student_id: teacher.id + 2000,
            first_name: 'FirstName',
            last_name: `LastName${teacher.id + 1}`,
            email: `FirstName.LastName${teacher.id + 1}@email.com`,
            last_logon: lastLogon,
            enrollment_date: enrollmentDate,
            is_licensed: true,
            enrollments: [...enrollments],
          },
          {
            student_id: teacher.id + 3000,
            first_name: 'FirstName',
            last_name: `LastName${teacher.id + 2}`,
            email: `FirstName.LastName${teacher.id + 2}@email.com`,
            last_logon: lastLogon,
            enrollment_date: enrollmentDate,
            is_licensed: false,
            enrollments: [...enrollments],
          },
        ];
        const totalStudents = lastTwoStudents.length
          ? 5
          : 3;
        const unlicensedStudents = lastTwoStudents.length
          ? 2
          : 1;

        lastTwoStudents.forEach((student) => {
          student.enrollments.push(...enrollments);
        });

        students.push(...nextThreeStudents);

        return ({
          teacher_id: teacher.id,
          total_students: totalStudents,
          unlicensed_students: unlicensedStudents,
        });
      }
      return undefined;
    }).filter((teacher) => !!teacher);

    const totalStudents = students.length;
    const unlicensedStudents = students.filter((student) => !student.is_licensed).length;
    const totalLicenses = totalStudents + 10;

    return ({
      total_licenses: totalLicenses,
      total_students: totalStudents,
      unlicensed_students: unlicensedStudents,
      by_teacher: byTeacher,
      students,
    });
  }
}

export default LicenseUsageStore;
