import React from 'react';
import { Route, Redirect as OldRedirect } from 'react-router-dom';
import { compile } from 'path-to-regexp';

export default function Redirect({
  exact, from, push, to,
}) {
  const pathTo = compile(to);
  return (
    <Route
      exact={exact}
      path={from}
      component={({ match: { params } }) => (
        <OldRedirect to={pathTo(params)} push={push} />
      )}
    />
  );
}
