import { extendObservable, computed, action } from 'mobx';

export default class Teacher {
  constructor(input = {}) {
    this.update(input);
  }

  @action update(input) {
    extendObservable(this, {
      id: +input.id || 0,
      firstName: input.firstName || input.first_name || 'Invalid',
      lastName: input.lastName || input.last_name || 'Teacher',
      email: input.email || '',
      role: input.role || '',
      isPending: input.isPending || input.pending || false,
      lastLogon: input.lastLogon || input.last_logon || 0,
      createdAt: input.createdAt || input.created_at || 0,
      school: input.school,
      courses: input.courses || [],
    });
  }

  @computed get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed get displayName() {
    return this.name;
  }

  @computed get prettyRole() {
    switch (this.role) {
      case '':
      case 'INSTRUCTOR': return 'Teacher';
      case 'ADMIN': return 'Administrator';
      case 'AES': return 'AES Admin';
      case 'STUDENT': return 'Student';
      default: return 'Unknown';
    }
  }

  @computed get courseTitles() {
    const entries = this.courses.map(({ id, title }) => [id, title]);
    return new Map(entries);
  }
}
