import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Modal from 'widgets/Modal';
import Highlight from 'widgets/format/Highlight';
import Dropdown from 'widgets/form/Dropdown';

@inject('router', 'students', 'courses', 'auth') @observer
class StudentSearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchString: '', results: null };
  }

  componentDidMount() {
    const { router: { query: { q } } } = this.props;
    this.setState({ searchString: q });
    this.runSearch(q);
  }

  onSubmit(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    const { searchString } = this.state;
    const { noResults, router } = this.props;
    if (noResults) {
      router.push(`/dashboard/search?q=${encodeURIComponent(searchString)}`);
    } else {
      this.runSearch(searchString);
    }
  }

  onEnrollStudent() {
    const {
      enrollStudentId,
      enrollCourseId,
    } = this.state;

    const {
      courses,
      router,
    } = this.props;

    const course = courses.byId.get(+enrollCourseId);

    course.addExistingStudent(enrollStudentId).then(() => {
      router.push(`/courses/${enrollCourseId}/students/list/${enrollStudentId}`);
    });
  }

  runSearch = (query) => {
    const { students } = this.props;
    const keywords = query && query.replace(/[^a-zA-Z0-9()-_ ]+/g, '').split(' ');
    students.fullSearch(query).then((results) => this.setState({ results, keywords }));
  }

  // Group by Teacher
  renderCourses = (student) => {
    const { courses } = this.props;
    const courseIds = courses.list.filter((c) => !c.template).map((c) => c.id);

    if (student.courses.length === 0) {
      return (<i>Not Enrolled</i>);
    }
    return student.courses.reduce(
      (teachers, course) => {
        const row = teachers.find((t) => t[0] === course.faculty);
        if (row) {
          row[1].push(course);
        } else {
          teachers.push([course.faculty, [course]]);
        }
        return teachers;
      },
      [],
    ).map((r) => (
      <div key={`${r[0]}`}>
        <p>{r[0]}</p>
        <ul>
          {r[1].map((c) => (
            <li key={c.id}>
              {
                courseIds.find((cid) => c.id === cid)
                  ? (
                    <Link to={`/courses/${c.id}/students/list/${student.id}`}>
                      {c.title}
                    </Link>
                  )
                  : c.title
              }
            </li>
          ))}
        </ul>
      </div>
    ));
  }

  render() {
    const {
      noResults,
      courses,
    } = this.props;

    const {
      searchString = '',
      results,
      keywords,
      enrollStudentId,
      enrollStudentName,
      enrollCourseId,
    } = this.state;

    return (
      <div className="studentSearchBox">
        <h2>Student Search</h2>
        <form onSubmit={(ev) => this.onSubmit(ev)}>
          <label htmlFor="searchString">
            <input
              name="searchString"
              id="searchString"
              value={searchString}
              onChange={(e) => this.setState({ searchString: e.target.value })}
              placeholder="Search for Student"
            />
          </label>
          <button id="studentSearch" type="submit" onClick={(ev) => this.onSubmit(ev)}>
            Search
          </button>
          {!noResults && results && (
            <table className="dashboardSearchResults">
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Class Periods</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {results.map((r) => (
                  <tr key={r.id}>
                    <td>
                      <p>
                        <Highlight text={r.name} keywords={keywords} />
                      </p>
                      <p className="email">
                        <Highlight text={r.email} keywords={keywords} />
                      </p>
                    </td>
                    <td>{this.renderCourses(r)}</td>
                    <td>
                      <button
                        className="lightButton"
                        type="button"
                        onClick={() => this.setState({
                          enrollStudentId: r.id,
                          enrollStudentName: r.name,
                        })}
                      >
                        Add To Class
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </form>
        <Modal
          title="Enroll a Student"
          visible={enrollStudentId}
          onDismiss={() => this.setState({ enrollStudentId: null })}
        >
          <h3>
            Enroll Student:
            {' '}
            {enrollStudentName}
          </h3>
          <p>
            Choose a class period:
          </p>
          <Dropdown
            name="classPeriod"
            label="Class Period:"
            value={enrollCourseId}
            onChange={(cid) => this.setState({ enrollCourseId: cid })}
            options={courses.available.map((c) => [c.id, c.title])}
          />
          <button
            type="button"
            onClick={() => this.onEnrollStudent()}
          >
            Enroll Student
          </button>
        </Modal>
      </div>
    );
  }
}

export default StudentSearchBox;
