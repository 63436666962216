import { extendObservable, computed, action } from 'mobx';

const msPerDay = 86400000;

export default class Site {
  constructor(input = {}) {
    extendObservable(this, {
      id: +input.id || 0,
      name: input.name || 'Unknown Site',
      type: input.type || input.site_type || 'SAAS',
      inactive: !!input.inactive,
      createdAt: input.created_at || null,
      expiration: input.expiration || null,
      last_activity: input.last_activity || null,
      skin: input.skin || null,
      package: input.package || 'STUDENT_LICENSE',
      students: 0,
      activeStudents: +input.activeStudents || input.active_students_count || 0,
      maxStudents: +input.max_students || 0,
      maxDownloads: input.maximum_total_downloads != null ? input.maximum_total_downloads : 10,
      state: input.state || '',
      verified: !!input.verified,
      validated: !!input.validated,
      features: input.features || [],
      templates: input.templates || [],
      campaign: input.campaign || null,
      salesOwner: input.salesforce_owner,
      salesOpportunity: input.salesforce_opportunity,
      salesContractDate: input.salesforce_contract_start_date,
      schoolStartDate: input.school_start_date,
      enableHealth: input.enable_health != null ? input.enable_health : true,
      enableBusiness: input.enable_business != null ? input.enable_business : true,
    });
  }

  @computed get prettyType() {
    if (this.isAES) return 'Internal';
    return this.isTrial ? 'Trial' : 'Customer';
  }

  @computed get prettyPackage() {
    if (this.isTrial) {
      if (this.daysTotal > 30) return 'Free Trial';
      return '30 Day Free Trial';
    }

    if (this.isAES) return 'Unlimited';

    switch (this.package) {
      case 'TEACHER_STARTER': return 'Teacher - Starter';
      case 'TEACHER_MASTER': return 'Teacher - Master';
      case 'SCHOOL_STARTER': return 'School - Starter';
      case 'SCHOOL_MASTER': return 'School - Master';
      case 'STUDENT_LICENSE': return 'Student Licenses';
      case 'BIT_CAMPUS_2019': return 'Campus';
      case 'BIT_CLASSROOM_2019': return 'Classroom';
      default: return 'Unknown';
    }
  }

  @computed get useStudentLicenses() {
    return this.package === 'STUDENT_LICENSE'
      || this.package === 'BIT_CAMPUS_2019'
      || this.package === 'BIT_CLASSROOM_2019';
  }

  // handles allow student overage feature
  @computed get allowOverage() {
    return this.features.includes('allow_overage');
  }

  @computed get templatesCustomized() {
    return this.templates.length > 0;
  }

  // handles sso feature
  @computed get hasSingleSignOn() {
    return !!this.features.find((f) => f === 'single_sign_on')
      || !!this.features.find((f) => f === 'sso_google')
      || !!this.features.find((f) => f === 'sso_clever')
      || !!this.features.find((f) => f === 'sso_classlink');
  }

  @computed get hasSingleSignOnGoogle() {
    return !!this.features.find((f) => f === 'sso_google');
  }

  @computed get hasSingleSignOnClasslink() {
    return !!this.features.find((f) => f === 'sso_classlink');
  }

  @computed get hasSingleSignOnClever() {
    return !!this.features.find((f) => f === 'sso_clever');
  }

  @computed get hasCanvas() {
    return !!this.features.find((f) => f === 'lti_canvas');
  }

  @computed get isTrial() {
    return this.type === 'EVAL';
  }

  @computed get isExtendedTrial() {
    return this.isTrial && this.daysLeft > 30;
  }

  @computed get isCustomer() {
    return this.type === 'SAAS';
  }

  @computed get isAES() {
    return this.type === 'SALES' || this.type === 'AES';
  }

  @computed get isHealth() {
    return this.skin === 'health';
  }

  @computed get isBusiness() {
    return this.skin === 'bcit';
  }

  @computed get isMaster() {
    return !this.package.match(/STARTER$/);
  }

  // Trial or Master Package
  @computed get hasExams() {
    return this.isMaster;
  }

  // Trial or Master Package
  @computed get hasAdvancedPacing() {
    return this.isMaster;
  }

  // Trial or Master Package
  @computed get hasCourseSharing() {
    return this.isMaster;
  }

  // Validated Trials or Customers
  @computed get hasAnswerKeys() {
    return this.validated || this.isCustomer || this.isAES;
  }

  // Validated Trials or Customers
  @computed get hasEnrollment() {
    return this.validated || this.isCustomer || this.isAES;
  }

  @computed get daysTotal() {
    const dateOnly = (string) => new Date(new Date(string).toDateString()).valueOf();
    const started = dateOnly(this.createdAt);
    const expiration = dateOnly(this.expiration);
    const total = expiration - started;

    return Math.ceil(total / msPerDay);
  }

  @computed get daysLeft() {
    const expiration = new Date(this.expiration).valueOf();
    const timeLeft = expiration - Date.now();

    return Math.max(0, Math.ceil(timeLeft / msPerDay));
  }

  @computed get intercomStatus() {
    if (this.isAES) return 'AES';
    if (this.isCustomer) return 'SAAS';
    return 'Trial';
  }

  @action setType(type) {
    this.type = type;
  }

  @action setMaxDownloads(n) {
    this.maxDownloads = n;
  }

  @action addFeature(feature) {
    if (!this.features.find((f) => f === feature)) {
      this.features.push(feature);
    }
  }

  @action removeFeature(feature) {
    this.features = this.features.filter((f) => f !== feature);
  }

  @action expire() {
    this.expiration = new Date(Date.now() - 84000000);
  }
}
