import React from 'react';
// TODO: Find out if used

const Assignments = () => (
  <div className="introContent">
    <div className="heroSection">
      <h1>Engaging Content, Better Results</h1>
      <h2>Web-based interactive lessons and quizzes.</h2>
      <div className="learnMore">
        <div className="video" />
        <h3>Learn more</h3>
      </div>
    </div>
    <h3>
      Ready to get started?
      <br />
      &larr; Click a class over here.
    </h3>
  </div>
);

export default Assignments;
