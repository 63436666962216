import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Unit from 'widgets/curriculum/Unit';
import ResourceLink from 'widgets/ResourceLink';
import ModuleIcon from 'widgets/assignment/ModuleIcon';
import './style.scss';
import LessonViewer from '../../widgets/curriculum/LessonViewer';

const CatalogModule = (props) => {
  const {
    curriculum,
    auth,
    catalog,
    router,
    match: {
      params: {
        group, moduleKey, templateKey, moduleGroupKey,
      },
    },
  } = props;

  const module = curriculum.modulesByKey[moduleKey];
  useEffect(() => module?.loadLessonDetails(), [module]);

  const [lessonViewerURL, setLessonViewerURL] = useState('');
  const showLessonViewer = (url) => setLessonViewerURL(url);
  const dismissLessonViewer = () => setLessonViewerURL('');

  if (!curriculum.loaded) {
    return <div />;
  }

  const template = catalog.getTemplate(templateKey);
  const approved = !!catalog.permittedModuleKeys[moduleKey] && auth.enableAnswerKeys;

  const moduleUnits = module.units || [];
  const moduleResources = moduleUnits.filter((u) => u.phase === 'MODULE');
  const exploreUnits = moduleUnits.filter((u) => u.phase === 'EXPLORE');
  const learnPracticeUnits = moduleUnits.filter((u) => u.phase === 'LP');
  const reflectUnits = moduleUnits.filter((u) => u.phase === 'REFLECT');
  const reinforceUnits = moduleUnits.filter((u) => u.phase === 'REINFORCE');

  let unitNumber = 0;

  const moduleGroup = curriculum.moduleGroupsByKey[moduleGroupKey];

  const Phase = ({
    title, description, color, units, displayUnits,
  }) => (
    <div className="curriculumPhase">
      <div className="phaseHeader">
        <div className={`phaseNumber ${color}`}>{title}</div>
        <div className="phaseTitle">
          <p>{description}</p>
        </div>
      </div>
      <ol>
        {units && units.map((u) => {
          if (displayUnits) {
            unitNumber += 1;
          }
          return (
            <Unit
              key={u.key}
              unit={u}
              approved={approved}
              phaseTitle={title}
              unitNumber={unitNumber}
              moduleKey={moduleKey}
              showLessonViewer={showLessonViewer}
            />
          );
        })}
      </ol>
    </div>
  );

  const ModuleResources = () => (
    <Phase
      title="Module Resources"
      color="blue"
      description="Information to review before starting the module"
      units={moduleResources}
      approved={approved}
    />
  );

  const Explore = () => (
    <Phase
      title="Explore"
      color="blue"
      description="Instructor led activities to introduce topics &amp; engage students"
      units={exploreUnits}
      approved={approved}
    />
  );

  const LearnPractice = () => (
    <Phase
      title="Learn &amp; Practice"
      color="green"
      description="Interactive lessons &amp; quizzes for students"
      units={learnPracticeUnits}
      approved={approved}
      displayUnits
    />
  );

  const Reflect = () => (
    <Phase
      title="Reflect"
      color="orange"
      description="Instructor led activities to help students see the big picture"
      units={reflectUnits}
      approved={approved}
    />
  );

  const Reinforce = () => (
    <Phase
      title="Reinforce"
      color="red"
      description="Projects &amp; Instructor led classroom activities to reinforce learning"
      units={reinforceUnits}
      approved={approved}
    />
  );

  const ModuleTest = () => (
    <div className="curriculumPhase">
      <div className="phaseHeader">
        <div className="phaseNumber red">Assessment</div>
        <div className="phaseTitle">
          <p>Assessment of everything the student learned in this module</p>
        </div>
      </div>

      <ol>
        <li className="curriculumUnit">
          <div className="desc">
            <p style={{ marginBottom: 0, marginTop: 0 }}>
              <b>Module Test</b>
              {' '}
              - 1 hour est.
              <br />
              In this summative assessment, students will be tested on everything
              that they have learned in the module.
            </p>
            {module.key === 'HSANTMYCO' && (
              <p>
                Anatomy &amp; Physiology comes with a standard test and an advanced
                test. The standard test has 25 questions and the advanced test has
                100 questions.
              </p>
            )}
          </div>
          {approved && (
            <div className="resources">
              <b>Teacher Resources</b>
              <ul>
                <li>
                  {auth.enableAnswerKeys
                    && (
                      <Link to={`${router.path}/answer-key/`}>
                        Test Answer Key
                      </Link>
                    )}
                  {!auth.enableAnswerKeys && (
                    <ResourceLink
                      module={module}
                      resource={{
                        name: 'Test Answer Key',
                        title: 'Test Answer Key',
                        url: '#',
                      }}
                    />
                  )}
                </li>
                {module.key === 'HSANTMYCO' && (
                  <li>
                    {auth.enableAnswerKeys && (
                      <Link to={`${router.path}/answer-key-advanced/`}>
                        Advanced Test Answer Key
                      </Link>
                    )}
                    {!auth.enableAnswerKeys && (
                      <ResourceLink
                        module={module}
                        resource={{
                          name: 'Advanced Test Answer Key',
                          title: 'Advanced Test Answer Key',
                          url: '#',
                        }}
                      />
                    )}
                  </li>
                )}
              </ul>
            </div>
          )}
        </li>
      </ol>
    </div>
  );

  if (!module) {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="catalogModuleDetails">
      {!!moduleGroupKey && !!moduleGroup
          && (
          <Link
            to={`/catalog/${group}/${templateKey}/group/${moduleGroupKey}`}
            className="button moduleGroup-button"
          >
            &larr; Return To
            {' '}
            {moduleGroup.title}
          </Link>
          )}
      <h1>{module.title}</h1>
      {module.isCustom && <h2>Resources:</h2>}
      {module.isCustom && module.teacherModuleResources.length > 0

        && module.teacherModuleResources.map((resource) => (
          <div className="hyperlinks" key={resource.path}>
            <a href={resource.path} target="blank">{resource.title}</a>
          </div>
        ))}
      {template && (
        <p className="subheading">
          {`From the ${template.title} Template`}
        </p>
      )}
      <p className="description">
        <ModuleIcon title={module.title} image={module.imageUrl} />
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: module.description }} />
      </p>
      {module.alert
        && (
          <div className="moduleAlert">
            <i className="material-icons md-36">warning</i>
            <p className="description">{module.alert}</p>
          </div>
        )}
      <p>
        {(!approved && auth.enableAnswerKeys)
          && <i>This module is not included in your contract.</i>}
      </p>
      {moduleResources.length > 0 && <ModuleResources />}
      {exploreUnits.length > 0 && <Explore />}
      {learnPracticeUnits.length > 0 && <LearnPractice />}
      {reflectUnits.length > 0 && <Reflect />}
      {reinforceUnits.length > 0 && <Reinforce />}
      {!!module.hasTest && <ModuleTest approved={approved} />}
      <LessonViewer url={lessonViewerURL} onDismiss={dismissLessonViewer} />
    </div>
  );
};

export default inject('curriculum', 'catalog', 'auth', 'router')(observer(CatalogModule));
