import React from 'react';

const EmptyStatusReportRow = ({ id }) => (
  <table key={`assignment_entry_${id}`}>
    <tbody>
      <tr>
        <th id="emptyModule">Nothing to show</th>
      </tr>
    </tbody>
  </table>
);

export default EmptyStatusReportRow;
