import React from 'react';
import DateTime from 'widgets/format/DateTime';
import { Link } from 'react-router-dom';
import StudentSearchBox from './StudentSearchBox';

const InfoSideBar = ({ site, user }) => {
  const percentLeft = (site.daysLeft / 30) * 100;
  const downloadsLeft = Math.max(0, site.maxDownloads - user.downloads);

  return (
    <div className="courseSidebar">
      <h2>Welcome!</h2>
      {site.isTrial && (
        <div>
          <p>
            Welcome to our community of teachers! If you need anything, you can get in touch
            with us with the chat button at the bottom of the page.
          </p>
          {!site.isExtendedTrial && (
            <div>
              <h2>
                Your trial expires in
                {' '}
                {site.daysLeft}
                {' '}
                days.
              </h2>
              <div className="trialProgress">
                <div
                  className={`bar${percentLeft <= 25 ? ' danger' : ''}`}
                  style={{ width: `${percentLeft}%` }}
                />
              </div>
            </div>
          )}
          {site.isExtendedTrial && (
            <h2>
              Your trial expires on
              {' '}
              <DateTime value={site.expiration} format="MM/DD/YYYY" />
              .
            </h2>
          )}
          <Link to="/settings/site/subscription">My Subscription</Link>
          <p>
            You can download
            {' '}
            {downloadsLeft}
            {' '}
            more resources.
          </p>
        </div>
      )}

      {!site.isTrial && (
        <div>
          <p>
            Welcome to our community of teachers! If you need anything, you can get
            in touch with us with the chat button at the bottom of the page.
          </p>
          <h2>What&apos;s New with AES?</h2>
          <p>
            We&apos;ve made some big changes to the system since last school year!
          </p>
          <a
            className="button"
            href="https://www.aeseducation.com/updates"
            rel="noopener noreferrer"
            target="_blank"
          >
            View Updates
          </a>
        </div>
      )}

      <h2>Stuck? Let us help!</h2>
      <ul>
        <li>
          Check out our
          {' '}
          <a
            href="https://help.aeseducation.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            help center
          </a>
        </li>
        <li>
          <button
            type="button"
            className="anchor"
            onClick={() => {
              // eslint-disable-next-line
              Intercom('show');
            }}
          >
            Send us a chat
          </button>
        </li>
        <li>Give us a ring! 800-220-2175x2</li>
      </ul>
      <StudentSearchBox noResults />
    </div>
  );
};

export default InfoSideBar;
