import React from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import DateTime from 'widgets/format/DateTime';
import Modal from 'widgets/Modal';
import RenameCourse from '../../routes/course/dashboard/RenameCourse';
import DuplicateClass from '../../routes/course/dashboard/DuplicateClass';
import ResetClass from '../../routes/course/dashboard/ResetClass';
import ArchiveCourse from '../../routes/course/dashboard/ArchiveCourse';
import CourseMigrated from '../../routes/course/dashboard/CourseMigrated';
import ToggleSlider from '../ToggleSlider';

@inject('auth', 'courses', 'router', 'teachers') @observer
class CourseList extends React.Component {
  @observable activeCourse = {}

  @observable dialog = null

  @observable template = false

  @observable create = false

  @action showPopup(name, course, template, create) {
    const { courses } = this.props;

    this.dialog = name;
    this.template = template;
    this.create = create;
    const localCourse = courses.list.find((c) => c.id === course.id);

    if (localCourse) {
      this.activeCourse = localCourse;
    } else {
      this.activeCourse = course;
    }

    if (!this.activeCourse) {
      throw new Error(`Could not find course '${course.title}' (#${course.id})`);
    }
  }

  @action.bound closeDialog() {
    this.activeCourse = {};
    this.dialog = null;
    this.template = false;
    this.create = false;
  }

  render() {
    const {
      auth,
      courseData,
      shared,
      showNew,
      template,
    } = this.props;

    return (
      <div className="courses">
        {courseData.map((course) => {
          const courseOwner = course.facultyId === auth.user.id;
          const showOwner = !courseOwner && (course.isShared || course.sharedTemplate);
          const {
            courseMigrated,
            icevCourseCode,
            migrationStatus,
            courseMigrationNotSupported,
          } = course;
          const iCEVURL = auth.iCEVRedirectUrl;
          const icevRedirect = () => (icevCourseCode ? `${iCEVURL}/mycourses/${icevCourseCode}` : icevCourseCode);
          const showAssignments = (courseOwner || !template) && !courseMigrated;
          const showExamsOutstanding = migrationStatus === 3;
          // TODO: fix alignment
          return (
            <div className="courseOuter" key={course.id}>
              <div className="courseInner" data-testid="courseListInner">
                <h2>
                  { courseMigrated
                    ? (
                      <div className="migration-badges">
                        <div className="chip migrated">
                          Migrated
                        </div>
                        {showExamsOutstanding && (
                        <div className="chip exams-outstanding">
                          Exams Outstanding
                        </div>
                        ) }
                      </div>
                    )
                    : (
                      <div className="actions">
                        {courseOwner
                              && (
                              <button
                                type="button"
                                className="edit"
                                data-testid="renameButton"
                                onClick={() => this.showPopup('Rename', course)}
                              >
                                Rename
                              </button>
                              )}
                        {courseOwner
                              && (
                              <button
                                type="button"
                                className="copy"
                                onClick={() => this.showPopup('Copy', course, template)}
                              >
                                Copy
                              </button>
                              )}
                        {shared
                              && (
                              <button
                                type="button"
                                className="copy"
                                onClick={() => this.showPopup('Copy', course)}
                              >
                                Copy
                              </button>
                              )}
                        {courseOwner && !template
                              && (
                              <button
                                type="button"
                                className="reset"
                                onClick={() => this.showPopup('Reset', course)}
                              >
                                Reset
                              </button>
                              )}
                        {courseOwner
                              && (
                              <button
                                type="button"
                                className="delete"
                                onClick={() => this.showPopup('Delete', course)}
                              >
                                Delete
                              </button>
                              )}
                      </div>
                    )}
                  <div className="title">
                    {courseMigrated && (
                    /* eslint-disable jsx-a11y/no-static-element-interactions */
                    <div
                      onClick={() => this.showPopup('Migrated', course)}
                      onKeyDown={() => this.showPopup('Migrated', course)}
                    >
                      {course.title}
                    </div>
                    )}
                    {(!template && !courseMigrated) && <Link to={`/courses/${course.id}`}>{course.title}</Link>}
                    {(template && !courseMigrated) && <p>{course.title}</p>}
                    { course.upgradeJobId
                      ? (
                        <div className="chip upgrading">
                          Loading exams...
                        </div>
                      )
                      : null }
                    { courseMigrationNotSupported
                      ? (
                        <div className="chip not-supported">
                          Not Supported
                        </div>
                      )
                      : null }
                  </div>
                </h2>

                {showOwner
                  && (
                  <h3>
                    <span style={{ color: '#888888' }}>Owner:</span>
                    {' '}
                    {course.ownerName}
                  </h3>
                  )}
                <div className="info">
                  <span>
                    {course.assignmentCount}
                    {' '}
                    Assignments
                  </span>
                  {!course.template
                    && (
                    <React.Fragment>
                      <span>
                        {course.studentCount}
                        {' '}
                        Students
                      </span>
                      <span>
                        Last Activity:
                        <DateTime value={course.lastStudentActivity} />
                      </span>
                    </React.Fragment>
                    )}
                  { course.template && courseOwner
                    ? (
                      <ToggleSlider
                        active={course.sharedTemplate}
                        onClick={(active) => course.setDistrictTemplate(active)}
                        titleOn="Published"
                        titleOff="Unpublished"
                      />
                    )
                    : null}
                  { course.template && !courseOwner
                    && (
                    <span className="templatePublished">
                      {course.sharedTemplate ? 'Published' : 'Unpublished'}
                    </span>
                    )}
                </div>
                <div className="separator">
                  {!courseMigrated
                    ? <span>Shortcuts</span>
                    : <span> </span>}
                </div>
                <div className="actionsContainer">
                  <div className="courseActions">
                    {
                      showAssignments
                      && (
                      <Link to={`/courses/${course.id}/assignments`}>
                        Assignments
                      </Link>
                      )
                    }
                    {
                      !template
                      && (
                      <React.Fragment>
                          {courseMigrated
                            ? (
                              <div className="migrated-links">
                                <a href={icevRedirect()}>
                                  Open this course in iCEV
                                </a>
                                {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                                <div
                                  onClick={() => this.showPopup('Migrated', course)}
                                  onKeyDown={() => this.showPopup('Migrated', course)}
                                  className="migrated-course-link"
                                >
                                  View Grades
                                </div>
                              </div>
                            )
                            : (
                              <>
                                <Link to={`/courses/${course.id}/students`}>
                                  Students
                                </Link>
                                <Link to={`/courses/${course.id}/grades`}>
                                  Grades
                                </Link>
                                <Link to={`/courses/${course.id}/students/enroll`}>
                                  Enrollment
                                </Link>
                              </>
                            )}
                      </React.Fragment>
                      )
                    }
                  </div>
                  {template
                    && (
                    <div className="templateActions">
                      <button
                        type="button"
                        className="createClass"
                        onClick={() => this.showPopup('Copy', course, false, true)}
                      >
                        Create Class from this Template
                      </button>
                    </div>
                    )}
                </div>
              </div>
            </div>
          );
        })}
        { showNew
          && (
          <div className="courseOuter">
            <div className="courseInner newCourse">
              <Link to="/courses/new">+ Start a New Class</Link>
            </div>
          </div>
          )}
        <RenameCourse
          onDismiss={this.closeDialog}
          selectedCourse={this.activeCourse}
          template={this.template}
          title={`Editing ${this.activeCourse.title}`}
          visible={this.dialog === 'Rename'}
        />

        <DuplicateClass
          create={this.create}
          onDismiss={this.closeDialog}
          selectedCourse={this.activeCourse}
          template={this.template}
          title={this.create ? `New Class Title: ${this.activeCourse.title}` : `Copy Class: ${this.activeCourse.title}`}
          visible={this.dialog === 'Copy'}
          noRedirect
        />

        <ResetClass
          onDismiss={this.closeDialog}
          selectedCourse={this.activeCourse}
          title={`Reset ${this.activeCourse.title}`}
          visible={this.dialog === 'Reset'}
        />

        <ArchiveCourse
          onDismiss={this.closeDialog}
          selectedCourse={this.activeCourse}
          template={this.activeCourse.template}
          title={`Delete ${this.activeCourse.title}`}
          visible={this.dialog === 'Delete'}
        />

        <CourseMigrated
          onDismiss={this.closeDialog}
          selectedCourse={this.activeCourse}
          title="Warning"
          visible={this.dialog === 'Migrated'}
        />

        {/* TODO: find out if used */}
        <Modal
          title={`Share ${this.activeCourse.title}`}
          visible={this.dialog === 'Share'}
          onDismiss={this.closeDialog}
        >
          <h2>Share Class</h2>
          <p>You can share your class with another teacher in your school</p>
        </Modal>
      </div>
    );
  }
}

export default CourseList;
