import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';

@inject('auth', 'teachers') @observer
class TeacherRemove extends Component {
  @observable show = false

  @action.bound showModal = () => { this.show = true; }

  @action.bound hideModal = () => { this.show = false; }

  @action.bound confirmChange() {
    const { teachers, teacher } = this.props;

    teachers.removeFromSite(teacher.id);
  }

  render() {
    const { auth, teacher } = this.props;

    return (
      <React.Fragment>
        <button type="button" onClick={this.showModal}>Remove</button>
        <Modal
          id="teacher-edit"
          visible={this.show}
          onDismiss={this.hideModal}
          title="Remove Teacher"
        >
          <p>
            Are you sure you want to remove
            {teacher.name}
            {' '}
            from
            {auth.site.name}
            ?
          </p>

          <div>
            <button type="button" onClick={this.confirmChange}>Remove Teacher</button>
            <button type="button" className="secondary" onClick={this.hideModal}>Cancel</button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default TeacherRemove;
