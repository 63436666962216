/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

@inject('courses', 'router') @observer
class QuickAccess extends React.Component {
  @observable popout = false;

  @computed get popoutStyle() {
    return {
      display: this.popout ? 'block' : 'none',
    };
  }

  setCallback() {
    if (this.callback) return;

    this.callback = () => {
      this.close();
      this.clearCallback();
    };

    document.addEventListener('touchstart', this.callback);
  }

  @action.bound open = () => {
    this.popout = true;
  };

  @action.bound close = () => {
    this.popout = false;
  };

  @action.bound touch() {
    this.open();
    this.setCallback();
  }

  @action.bound switchClass() {
    this.clearCallback();
  }

  clearCallback() {
    if (!this.callback) return;
    document.removeEventListener('touchstart', this.callback);
    this.callback = undefined;
  }

  render() {
    const { courses, router } = this.props;
    const { fromRoute: activeCourse } = courses;

    return (
      <div className="quickAccess" data-testid="quickAccess">
        {activeCourse && (
          <div
            className="classSwitcher"
            onMouseOver={this.open}
            onMouseOut={this.close}
            onTouchStart={this.touch}
          >
            <span
              className="currentClass"
              title={activeCourse && activeCourse.title}
            >
              {activeCourse ? activeCourse.title : 'No Class Selected'}
              <i className="material-icons md-18">play_arrow</i>
            </span>
            <ul style={this.popoutStyle}>
              {courses.available.length === 0
              && (
                <li>
                  <p>You have no classes yet.</p>
                  <p>Ready to start planning? Click below to create a class.</p>
                </li>
              )}
              {courses.available.map((course) => {
                let path = `/courses/${course.id}/`;

                if (!router.path.match(/archive\/courses/)) {
                  if (router.path.match(/courses/)) {
                    path = router.path.replace(/\/courses\/\d+\//, `/courses/${course.id}/`);
                    path = path.replace(/(students\/list|grades\/by-student|grades\/pre-post|grades\/book|assignments\/view)\/.+/, '$1');
                    path = path.replace(/search(\?.*)?$/, '');
                  }
                }
                const { template } = course;
                return (
                  <React.Fragment key={course.id}>
                    {
                      !template
                      && (
                        <li
                          key={course.id}
                          className={course === activeCourse ? 'hasIcon' : undefined}
                          title={course === activeCourse ? 'You are viewing this class' : undefined}
                        >
                          <Link to={path} onClick={this.close} onTouchStart={this.switchClass}>
                            {course === activeCourse
                            && <i className="material-icons md-18">bookmark</i>}
                            {course.title}
                          </Link>
                        </li>
                      )
                    }
                  </React.Fragment>
                );
              })}
              <li className="meta hasIcon">
                <Link to="/courses/new">
                  <i className="material-icons md-18">add</i>
                  Start a New Class
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default QuickAccess;
