import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import NotFound from 'routes/error/404';
import Dashboard from './dashboard';
import Assignments from './assignments';
import Students from './students';
import Grades from './grades';
import Search from './Search';
import './style.scss';

const Course = ({ courses: { fromRoute: course }, auth }) => {
  const { courseMigrated, migrationStatus } = course;
  const { migrationURL } = auth;
  const hasExamsForMigration = migrationStatus === 3;
  if (!course) return <NotFound />;
  return (
    <>
      {courseMigrated ? (
        <div className="migrated-banner">
          This course has been migrated and any changes made
          {' '}
          here will not be reflected on the iCEV platform.
          {' '}
          <a href="https://login.icevonline.com/login" target="new">
            Open this course in iCEV
          </a>
        </div>
      )
        : null}
      {hasExamsForMigration ? (
        <div className="exams-for-migration-banner">
          Custom exams are now available for migration. Read the
          {' '}
          <a
            href="https://help.aeseducation.com/en/articles/7042757-aes-icev-transition-faq"
            target="_blank"
            rel="noreferrer noopener"
          >
            Course Migration FAQ
          </a>
          {' '}
          for more information or click on the link to complete your migration:
          {' '}
          <a
            href={migrationURL}
            rel="noreferrer noopener"
          >
            Complete my Migration
          </a>
        </div>
      )
        : null}
      <Switch>
        <Redirect exact from="*/dashboard" to="dashboard/overview" />
        <Route path="*/dashboard" component={Dashboard} />
        <Redirect exact from="*/assignments" to="assignments/view" />
        <Route path="*/assignments" component={Assignments} />
        <Redirect exact from="*/students" to="students/list" />
        <Route path="*/students" component={Students} />
        <Redirect exact from="*/grades" to="grades/book" />
        <Route path="*/grades" component={Grades} />
        <Route path="*/search" component={Search} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default inject('courses', 'auth')(observer(Course));
