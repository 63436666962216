import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import Redirect from 'RedirectWithParams';
import Home from 'routes/home';
import Settings from 'routes/settings';
import Course from 'routes/course';
import Archive from 'routes/archive';
import CreateCourse from 'routes/course/new/CreateCourse';
import CreateTemplate from 'routes/course/new/CreateTemplate';
import NotFound from 'routes/error/404';
import Catalog from 'routes/catalog';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoadingOverlay from './LoadingOverlay';

import './style.scss';

@inject('auth', 'courses', 'router', 'uiState') @observer
class Layout extends Component {
  constructor(props) {
    super(props);

    // Scroll the main div to the top when routes change
    this.mainRef = React.createRef();
    const { history } = this.props;
    if (history) {
      history.listen(() => {
        const { current } = this.mainRef;
        if (current && current.scrollTop !== 0) {
          current.scrollTop = 0;
        }
      });
    }
  }

  @computed get classNames() {
    const { auth, router } = this.props;
    const classNames = ['layout'];

    if (router.path.startsWith('/catalog')) {
      classNames.push('catalog');
    }

    let page = router.path
      .replace(/^\//, '') // Remove initial /
      .replace(/\d+\//, '') // Remove ids
      .replace(/\//g, '-'); // Hyphenate remaining /s

    if (page.endsWith('choose-') || page.endsWith('upgrade-')) {
      page = 'beta-upgrade';
    }

    if (page.startsWith('courses-new')) {
      if (auth.user.persona) {
        page = 'courses-new-prototype';
      } else {
        page = 'courses-new-prototype-persona';
      }
    } else if (page === 'courses-assignments-edit') {
      if (auth.user.persona) {
        page = 'courses-assignments-edit-prototype';
      } else {
        page = 'courses-assignments-edit-prototype-persona';
      }
    }

    if (page === '') page = 'home';
    classNames.push(page);

    return classNames.join(' ');
  }

  render() {
    const {
      courses,
      uiState,
    } = this.props;

    const { defaultCourseId, loaded } = courses;
    const homeRoute = courses.coursesOrTemplatesExist ? '/courses' : '/get-started';
    const mainStyle = { paddingTop: uiState.navHeight + 26 };

    if (!loaded) {
      return (
        <div className={this.classNames} data-testid={this.classNames}>
          <TopNav />
          <MainNav />
          <main ref={this.mainRef} style={mainStyle} />
        </div>
      );
    }

    return (
      <div className={this.classNames}>
        <TopNav defaultCourseId={defaultCourseId} />
        <MainNav />
        <main ref={this.mainRef} style={mainStyle}>
          <Switch>
            <Route exact path="/courses" component={Home} />
            <Route path="/catalog" component={Catalog} />
            <Route path="/courses/new/template" component={CreateTemplate} />
            <Route path="/courses/new" component={CreateCourse} />
            <Redirect exact from="/archive" to="/archive/courses" />
            <Route path="/archive" component={Archive} />
            <Redirect exact from="/courses/:courseId(\d+)" to="/courses/:courseId/dashboard" />
            <Route path="/courses/:courseId(\d+)" component={Course} />
            <Redirect exact from="/settings" to="/settings/teacher/profile" />
            <Route path="/settings" component={Settings} />
            <Redirect exact from="/" to={homeRoute} />
            <Route path="/:page?" component={Home} />
            <Route component={NotFound} />
          </Switch>
        </main>

        {uiState.loading && <LoadingOverlay />}
      </div>
    );
  }
}

export default Layout;
