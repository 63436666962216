import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import GradeDetails from 'widgets/grade/GradeDetails';

@inject('courses', 'students', 'grades', 'questions', 'router') @observer
class StudentGrades extends Component {
  @computed get moduleKey() {
    const { router } = this.props;
    if (router.location.pathname.match(/\/grades\/by-student/)) {
      return router.location.pathname.split('/')[7];
    }
    return router.location.pathname.split('/')[5];
  }

  @computed get unitKey() {
    const { router } = this.props;
    if (router.location.pathname.match(/\/grades\/by-student/)) {
      return router.location.pathname.split('/')[9];
    }
    return router.location.pathname.split('/')[7];
  }

  render = () => {
    const { courses: { fromRoute: course }, grades, work } = this.props;
    const { students } = course;

    return (
      <GradeDetails
        grades={grades}
        course={course}
        work={work}
        students={students}
        moduleKey={this.moduleKey}
        unitKey={this.unitKey}
        filter={this.filter}
      />
    );
  }
}
export default StudentGrades;
