import { extendObservable, computed } from 'mobx';

class StudentLicense {
  constructor(rootStore, input = {}) {
    this.rootStore = rootStore;
    extendObservable(this, {
      studentId: input.student_id,
      firstName: input.first_name,
      lastName: input.last_name,
      email: input.email,
      isLicensed: !!input.is_licensed,
      enrollments: input.enrollments || [],
      enrollmentDate: input.enrollment_date || '',
      lastLogon: input.last_logon || '',
    });
  }

  @computed get fullName() {
    return `${this.lastName}, ${this.firstName}`;
  }

  @computed get numberOfCourses() {
    return this.enrollments.length;
  }

  /**
   * Returns a Map keyed by teacherId containing Objects that contain the teacher and a list of
   * courses that this student is enrolled in belonging to that teacher
   * @returns {Map<Number, Object>}
   */
  @computed get coursesByTeacher() {
    const { teachers } = this.rootStore;
    const teacherValues = new Map();
    this.enrollments.forEach((enrollment) => {
      const {
        teacher_id: teacherId,
        course_id: courseId,
        created_at: enrollmentDate,
      } = enrollment;
      const teacher = teachers.byId.get(teacherId);
      const title = teacher.courseTitles.get(courseId);
      const course = { id: courseId, title, enrollmentDate };

      if (teacherValues.has(teacherId)) {
        const { courses } = teacherValues.get(teacherId);
        courses.push(course);
      } else {
        const courses = [course];
        teacherValues.set(teacherId, { teacher, courses });
      }
    });

    return teacherValues;
  }

  @computed get teacherIds() {
    return Array.from(this.coursesByTeacher.keys());
  }
}

export default StudentLicense;
