/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';
import NotValidated from 'widgets/NotValidated';

@inject('auth', 'curriculum', 'WebAPI') @observer
class ResourceLink extends Component {
  @observable showLimit = false

  @observable showValidation = false

  @observable showVerified = false

  @action.bound onClick(ev) {
    if (this.blocked === 'NOTVERIFIED') {
      return this.showVerifiedDialog(ev);
    }
    if (this.blocked === 'NOTVALIDATED') {
      return this.showValidationDialog(ev);
    }
    if (this.blocked === 'LIMIT') {
      return this.showLimitDialog(ev);
    }

    return this.countDownload(ev.target.href);
  }

  @computed get version() {
    const { auth, unit } = this.props;
    if (!unit) {
      return null;
    } if (auth.isProduction) {
      return unit.versionProduction;
    }
    return unit.versionStaging;
  }

  @computed get blocked() {
    const { auth, resource } = this.props;

    const isAnswerKey = resource.title.match(/Answer|Sample/);

    if (auth.site.isCustomer) {
      return false;
    }
    if (!auth.site.verified) {
      // not verified - gets blocked no matter what
      return 'NOTVERIFIED';
    }
    if (!auth.site.hasAnswerKeys && isAnswerKey) {
      // not validated - only gets blocked if it's an answer key
      return 'NOTVALIDATED';
    }
    if (auth.isTrial && (auth.user.downloads >= auth.site.maxDownloads)) {
      return 'LIMIT';
    }

    return false;
  }

  @action.bound hideLimitDialog = () => { this.showLimit = false; }

  @action.bound hideValidationDialog = () => { this.showValidation = false; }

  @action.bound hideVerifiedDialog = () => { this.showVerified = false; }

  @action.bound showLimitDialog(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.showLimit = true;
  }

  @action.bound showValidationDialog(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.showValidation = true;
  }

  @action.bound showVerifiedDialog(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.showVerified = true;
  }

  @action.bound countDownload(title, url) {
    const { auth, module, resource } = this.props;
    // Trigger on next tick otherwise MobX updates the URL before click handler finishes
    setTimeout(() => auth.user.addDownload(url, module, resource, false));
  }

  @action.bound async resendEmail() {
    await this.resendVerificationEmail();
  }

  @action resendVerificationEmail() {
    const { WebAPI } = this.props;

    return WebAPI.get('teacher/profile/resend_verification')
      .then(() => {
        this.hideVerifiedDialog();
      })
      .catch((error) => {
        if (error.message.includes('409')) {
          this.hideVerifiedDialog();
        } else {
          throw new Error(error.message);
        }
      });
  }

  render() {
    const { unit, resource, auth } = this.props;

    // FIXME: Resources should be their own class
    let resourceUrl = resource.url;
    if (this.version && resource.url.match(/^\/units\//)) {
      const key = unit.key.toLowerCase();
      resourceUrl = resourceUrl.replace(
        `/units/${key}/`,
        `/units/${key}/${this.version}/`,
      );
    }
    if (resourceUrl.match(/^\//)) { // Not absolute?
      resourceUrl = auth.mediaURL(resourceUrl);
    }
    if (resourceUrl.match(/^none$/i)) {
      resourceUrl = null;
    }

    return (
      <span className="links">
        {resourceUrl
          && (
          <a
            className="resourceLink"
            target="_blank"
            rel="noopener noreferrer"
            href={!this.blocked ? resourceUrl : '#'}
            onClick={this.onClick}
          >
            {resource.title}
          </a>
          )}
        {!resourceUrl
          && (
          <span>
            <i>{resource.title}</i>
          </span>
          )}

        <Modal
          visible={this.showLimit}
          title="Out of downloads!"
          id="downloads-modal"
          onDismiss={this.hideLimitDialog}
        >
          <p>
            You&rsquo;ve run out of free resource downloads. If you need more, feel free to
            reach out to us and chat!
          </p>
          <a
            className="button"
            href="javascript:Intercom('show')"
            style={{ float: 'right' }}
          >
            Start a Chat
          </a>
        </Modal>

        <Modal
          visible={this.showValidation}
          id="not-validated"
          title="Time to prove you are a teacher!"
          onDismiss={this.hideValidationDialog}
          style={{ width: 700 }}
        >
          <NotValidated auth={auth} />
        </Modal>

        <Modal
          visible={this.showVerified}
          id="not-validated"
          title="Time to verify you are a person!"
          onDismiss={this.hideVerifiedDialog}
          style={{ width: 500 }}
        >
          {auth.admin
          && (
          <div className="notVerified">
            <h1>
              Hi,
              {' '}
              {auth.user.firstName}
              {' '}
              - it looks like you haven&apos;t verified your email address yet.
            </h1>

            <p>
              You should&apos;ve gotten an email in your inbox with a link that you can click
              to verify you&apos;re using an active email address.
            </p>
            <p>If you don&apos;t see the email, try checking your spam folder.</p>
            <p>
              If you still don&apos;t see it,
              you can get another email by clicking the button below.
            </p>

            <button type="button" onClick={this.resendEmail}>Resend Email</button>
          </div>
          )}
        </Modal>
      </span>
    );
  }
}

export default ResourceLink;
