import React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import DateTime from 'widgets/format/DateTime';

@inject('courses') @observer
class ArchivedTemplates extends React.Component {
  @action restoreCourse(course) {
    const { courses } = this.props;
    courses.restore(course.id);
  }

  render() {
    const { courses } = this.props;

    return (
      <div className="courseDashboard courseArchive">
        <h1>Deleted Templates</h1>

        <div className="courseList">
          {courses.archived.filter((course) => course.template).map((course) => (
            <div className="courseOuter" key={course.id}>
              <div className="courseInner">
                <h2>
                  <div className="actions">
                    <button type="button" onClick={() => this.restoreCourse(course)}>
                      Restore
                    </button>
                  </div>
                  {course.title}
                </h2>
                <div className="info">
                  <span>
                    {course.assignment_count}
                    {' '}
                    Assignments
                  </span>
                  <span>
                    {course.student_count}
                    {' '}
                    Students
                  </span>
                  <span>
                    Archived:
                    <DateTime value={course.deleted_at} />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ArchivedTemplates;
