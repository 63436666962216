import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

const PrePostSingle = ({ courses, students }) => {
  const { fromRoute: course } = courses;
  const { fromRoute: student } = students;
  const assignments = course.assignments.filter((a) => a.hasTest);
  const grade = student?.gradesByCourse.get(course.id);

  return (
    <div className="prePostSingleResult">
      {student
        ? (
          <React.Fragment>
            <h2>
              Student:
              {student.lastName}
              ,
              {student.firstName}
            </h2>
            <table>
              <thead>
                <tr>
                  <th>Module</th>
                  <th>Before</th>
                  <th>After</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                {assignments.map((assignment) => {
                  const pretest = grade && grade.workByUnit[`PREMT_${assignment.moduleKey}`];
                  const postest = grade && grade.workByUnit[`MT_${assignment.moduleKey}`];
                  const diff = pretest && postest
                    ? (postest.percentEarned - pretest.percentEarned) : null;
                  return (
                    <tr key={assignment.id}>
                      <td>
                        { course.enableBeta && pretest && postest
                          ? (
                            <Link to={`/courses/${course.id}/grades/pre-post/${student.id}/${assignment.id}`}>
                              {assignment.title}
                            </Link>
                          )
                          : <span>{assignment.title}</span> }
                      </td>
                      {pretest
                        ? <td>{pretest.percent}</td>
                        : <td>Not Taken</td>}
                      {postest
                        ? <td>{postest.percent}</td>
                        : <td>Not Taken</td>}
                      {diff
                        ? (
                          <td>
                            {diff.toFixed(1)}
                            %
                          </td>
                        )
                        : <td>--</td>}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </React.Fragment>
        )
        : null}
    </div>
  );
};

export default inject('courses', 'students')(observer(PrePostSingle));
