import React from 'react';
import { inject, observer } from 'mobx-react';
import NotValidated from 'widgets/NotValidated';
import AnswerKeyLearnosity from './AnswerKeyLearnosity';
import Learnosity from '../../../services/learnosity';

const AssignmentAnswerKey = (props) => {
  const {
    match,
    assignments,
    auth: { site: { hasAnswerKeys } },
    courses: {
      fromRoute: course,
    },
    match: {
      params: {
        id,
        entryId,
      },
    },
  } = props;

  const returnUrl = () => `../../${id}`;

  const loadAnswers = (showAnswers, randomSeed) => {
    Learnosity.resetItems();

    return assignments.loadAnswerKeyRequest(id, entryId, showAnswers, randomSeed);
  };

  const title = () => {
    const assignment = assignments.byId.get(+id);
    if (assignment) {
      const entry = assignment.entriesById[+entryId];

      return entry
        ? `${assignment.title} : ${entry.fullTitle}`
        : assignment.title;
    }

    return null;
  };

  if (!hasAnswerKeys) return <NotValidated />;

  return (
    <AnswerKeyLearnosity
      match={match}
      loadAnswers={loadAnswers}
      courseName={course.title}
      title={title()}
      returnUrl={returnUrl()}
    />
  );
};

export default inject('courses', 'auth', 'assignments')(observer(AssignmentAnswerKey));
