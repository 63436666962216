import { computed, extendObservable } from 'mobx';

export default class SearchResult {
  constructor(rootStore, input) {
    const {
      _source: {
        module_key: moduleKey,
        unit_key: unitKey,
        teacher_resource: singleResource,
        resources: resourcesList,
        attachment,
      },
      fields,
      inner_hits: innerHits,
    } = input;

    const hits = innerHits?.top_results.hits.hits;
    const resources = singleResource
      ? [singleResource]
      : resourcesList.map((item) => item.teacher_resource);
    const attachmentText = attachment ? attachment.content : '';

    this.rootStore = rootStore;

    extendObservable(this, {
      // eslint-disable-next-line no-underscore-dangle
      _source: input._source,
      attachmentText,
      resources,
      moduleKey,
      unitKey,
      fields,
      hits,
    });
  }

  @computed get allResults() {
    return this.hits?.map((input) => new SearchResult(this.rootStore, input));
  }

  @computed get moduleTitle() {
    const { catalog } = this.rootStore;
    const module = catalog.getModule(this.moduleKey);
    return module.title;
  }

  @computed get unitTitle() {
    const { catalog } = this.rootStore;
    const unit = catalog.getUnit(this.unitKey);
    return unit.title;
  }
}
