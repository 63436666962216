/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import DateTime from 'widgets/format/DateTime';

import ProgressBar from 'widgets/ProgressBar';
import StudentActions from 'widgets/student/StudentActions';

@inject('auth', 'courses', 'students', 'teachers', 'router') @observer
class StudentList extends Component {
  @observable editing = null;

  @observable editMenu = false

  componentDidMount() {
    const { courses: { fromRoute: course }, students } = this.props;
    if (course.students.length === 0 && students) {
      students.loadByCourse(course.id, null, true);
    }
  }

  @computed get embedOpts() {
    const { auth } = this.props;
    return [
      'popover=true',
      'popoverAnimateThumbnail=false',
      'popoverSize=750x417',
      `email=${auth.user.email}`,
    ].join(' ');
  }

  @computed get teachers() {
    const { teachers } = this.props;
    return teachers.list;
  }

  @computed get student() {
    const { students: { fromRoute: student } } = this.props;
    return student;
  }

  @action openEditMenu = (id) => {
    const last = this.editing;
    this.editMenu = !this.editMenu;

    if (last === id) {
      this.editing = null;
      return;
    }

    this.editing = id;
  }

  @action.bound nextPage() {
    const { router: { courseId }, students } = this.props;
    this.page += 1;
    return students.loadByCourse(courseId, this.page);
  }

  @action closeMenu() {
    this.editMenu = false;
    this.editing = null;
  }

  render() {
    const { auth, courses: { fromRoute: course }, router } = this.props;

    return (
      <div style={{ maxWidth: 1100 }}>
        <div className="studentsHeader">
          <h1>Student Roster</h1>
          {course.students.length > 0 && !course.isRostered
            && <Link className="button" to="enroll">Add a Student</Link>}
          {course.isRostered && !auth.hasCanvasAPI
            && (
              <div>
                <h2 className="studentsProvider">
                  Provisioned by:
                  {
                    course.providers[0].provider.charAt(0).toUpperCase()
                    + course.providers[0].provider.slice(1)
                  }
                </h2>
                <p className="studentsSync">
                  Last Synced:
                  {moment().calendar(course.providers[0].updated_at)}
                </p>
              </div>
            )}
        </div>
        {course.students.length === 0
          && (
            <div style={{ width: '450px' }}>
              <p>There are no students enrolled in this class.</p>
              <p>
                Watch the video below to learn more about the Students tab, or click
                {' '}
                <b>Enroll Students</b>
                {' '}
                to start the enrollment process.
              </p>
              <div style={{ width: 400, height: 220 }}>
                <div
                  className={`wistia_embed wistia_async_xxpicdle9a videoFoam=true ${this.embedOpts}`}
                  style={{ width: 400, height: 220 }}
                />
              </div>
            </div>
          )}
        {course.students.length !== 0 && (
          <table className="studentList">
            <thead>
              <tr>
                { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                {!course.isRostered && <th className="studentListEdit" />}
                <th className="studentListName">
                  Student Name
                  <span className="printOnly">
                    <br />
                    (Student Username/Email)
                  </span>
                </th>
                <th className="noPrint">Username/Email</th>
                <th>Last Logon</th>
                <th style={{ width: '25%' }}>Progress</th>
                <th>Grade</th>
              </tr>
            </thead>
            <tbody>
              {course.students.map((student) => {
                const grade = student.gradesByCourse.get(course.id);

                return (
                  <tr key={student.id}>
                    {!course.isRostered
                      && (
                        <td
                          className={`studentListEdit${this.editing === student.id ? ' active' : ''}`}
                          title="Change this student"
                        >
                          <div
                            onClick={() => this.openEditMenu(student.id)}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            Edit
                            <i className="material-icons md-18">arrow_drop_down</i>
                          </div>

                          {this.editing === student.id
                            && this.editMenu && (
                              <StudentActions student={student} type="list" onClick={() => this.closeMenu()} />
                          )}
                        </td>
                      )}
                    <td className="studentListName">
                      <Link to={`${router.path}/${student.id}`}>{student.formatName}</Link>
                      <div className="printOnly">
                        <br />
                        (
                        {student.email}
                        )
                      </div>
                    </td>
                    <td className="noPrint">{student.email}</td>
                    <td><DateTime value={student.lastLogonForCourse(course)} /></td>
                    <td>
                      <ProgressBar
                        value={(grade && grade.completed) || 0}
                        max={course.assignments.length}
                      />
                    </td>
                    <td>{grade ? grade.average : '-'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default StudentList;
