import React from 'react';
import { inject, observer } from 'mobx-react';

const NavToggle = ({ uiState }) => (
  <button
    type="button"
    className={`navToggle ${uiState.sidebarHidden ? 'collapsed' : ''}`}
    onClick={() => uiState.toggleSidebar()}
  >
    { uiState.sidebarHidden
      ? (
        <span>
          Show Sidebar
          <i className="material-icons">arrow_right</i>
        </span>
      )
      : (
        <span>
          <i className="material-icons">arrow_left</i>
          Hide Sidebar
        </span>
      ) }
  </button>
);

export default inject('uiState')(observer(NavToggle));
