import {
  observable, observe, computed, action, autorun, runInAction,
} from 'mobx';
import Course from 'models/Course';
import moment from 'moment';

class CoursesStore {
  @observable byId = observable.map()

  @observable loadedCurrentUser = false

  @observable loadedShared = false

  @observable loadedDistrict = false

  @observable jobAttempts = 0;

  constructor(rootStore, api) {
    this.api = api;
    this.rootStore = rootStore;
    const { auth } = this.rootStore;

    observe(auth, 'user', () => {
      if (auth.apiAccess) {
        this.loadForCurrentUser();
      }
    });

    // loads course data when viewing course
    autorun(() => {
      const {
        assignments,
        assessmentOpens,
        grades,
        students,
        router,
      } = this.rootStore;
      const match = router && router.path.match(/\/courses\/(\d+)/);
      const id = match && match[1];
      const course = this.byId.get(+id);

      if (auth.apiAccess && this.loaded && match && course) {
        assignments.loadByCourse(id);
        // Don't load the details for templates
        if (!course.template) {
          assessmentOpens.loadByCourse(id);
          students.loadByCourse(id);
          grades.loadByCourse(id);
        }
      }
    });

    autorun(() => {
      const { router } = this.rootStore;
      const match = router && router.path.match(/\/archive\/courses/);
      if (auth.apiAccess && match) {
        this.loadArchived();
      }
    });
  }

  @computed get loaded() {
    return this.loadedCurrentUser && this.loadedShared && this.loadedDistrict;
  }

  // All Courses
  @computed get list() {
    return Array.from(this.byId.values());
  }

  @computed get fromRoute() {
    const { router: { courseId } } = this.rootStore;

    return this.byId.get(courseId) || new Course(this.rootStore);
  }

  @computed get coursesOrTemplatesExist() {
    const { auth } = this.rootStore;

    const myCoursesAndTemplates = this.list
      .filter((course) => !course.archived)
      .filter((course) => !course.template || course.facultyId === auth.originalUser.id);
    return myCoursesAndTemplates.length > 0;
  }

  // Not Archived
  @computed get active() {
    return this.list.filter((course) => (
      !course.archived && !course.isShared && !course.sharedTemplate && !course.template
    )).sort((courseA, courseB) => {
      if (courseA.title < courseB.title) { return -1; }
      if (courseA.title > courseB.title) { return 1; }
      return 0;
    });
  }

  // get All templates
  @computed get allTemplates() {
    return this.list.filter((course) => course.template && !course.archived);
  }

  // district templates
  @computed get publishedTemplates() {
    return this.list.filter((course) => (
      !course.archived && course.template && course.sharedTemplate
    ));
  }

  // Shared by another teacher
  @computed get shared() {
    return this.list.filter((course) => (
      !course.archived && course.isShared && !course.sharedTemplate && !course.template
    ));
  }

  // Archived
  @computed get archived() {
    return this.list.filter((course) => course.deletedAt);
  }

  // Available Courses - Active + Shared
  @computed get available() {
    return this.list.filter((course) => (
      !course.archived && !course.template && !course.sharedTemplate
    ));
  }

  @computed get defaultCourseId() {
    if (this.active.length) {
      return this.active[0].id;
    }
    if (this.shared.length) {
      return this.shared[0].id;
    }
    if (this.publishedTemplates.length) {
      return this.publishedTemplates[0].id;
    }
    if (this.allTemplates.length) {
      return this.allTemplates[0].id;
    }
    return null;
  }

  // Has upgraded one or more courses to Beta
  @computed get hasUpgraded() {
    return this.list.filter((course) => course.enableBeta).length > 0;
  }

  @action findOrCreateCourse(courseId) {
    return this.byId.get(courseId) || new Course(this.rootStore);
  }

  @action add(input) {
    const course = new Course(this.rootStore, input);
    this.byId.set(course.id, course);
    return course;
  }

  @action remove(course) {
    const courseId = Object(course) === course ? course.id : course;
    const foundCourse = this.byId.get(courseId);
    if (foundCourse) {
      foundCourse.deletedAt = Date.now();
    }
  }

  @action clear() {
    this.byId.clear();
  }

  @action copy(title, copyCourseId, isReset) {
    const { curriculum } = this.rootStore;
    const course = {
      title,
      copy_course_id: copyCourseId,
    };

    return this.api.post('teacher/courses', { course }, { version: 'v2' })
      .then((response) => this.api.pollJob(response.job_id, 1000, 600000))
      .then((response) => response.course_id)
      .then((courseId) => {
        curriculum.loadCustomModules();
        if (isReset) {
          this.archive(copyCourseId);
        }
        return this.loadSingle(courseId);
      })
      .catch(() => {});
  }

  @action create(title, modules, duplicateId, saveAsTemplate) {
    const params = { title, enable_beta: true };

    if (duplicateId) {
      params.copy_course_id = duplicateId;
    } else {
      params.modules = modules;
    }

    // sets params to save course if passed in
    if (saveAsTemplate) {
      if (duplicateId) {
        params.save_as_template = true;
      } else {
        params.template = true;
      }
    }

    return this.api.post('teacher/courses', { course: params })
      .then((result) => {
        const course = this.add(result);
        // if this is a copy of a shared course, there will be new custom modules to load
        if (duplicateId) {
          const { curriculum } = this.rootStore;
          curriculum.loadCustomModules();
        }

        return course;
      });
  }

  @action archive(course) {
    const courseId = typeof course === 'object' ? course.id : course;
    this.api.delete(`teacher/courses/${courseId}`).then(() => this.remove(courseId));
  }

  // eslint-disable-next-line class-methods-use-this
  @action async restore(courseId) {
    const course = this.byId.get(courseId);

    if (!course) {
      return;
    }

    const result = await this.api.patch(
      `teacher/courses/${courseId}`,
      {
        course: {
          deleted_at: null,
        },
      },
    );

    if (result?.id === courseId) {
      this.populateCourses([result]);
    }
  }

  @action async loadForCurrentUser(params) {
    this.clear();
    this.loadedCurrentUser = false;
    const courses = await this.api.get('teacher/courses', params || {});
    this.populateCourses(courses);
    action(() => {
      this.loadedCurrentUser = true;
    })();
    this.loadShared();
    this.loadDistrictTemplates();
  }

  @action loadSingle(courseId) {
    return this.api.get(`teacher/courses/${courseId}`, {}, { quiet: true })
      .then((data) => this.populateCourses([data]))
      .then(() => this.byId.get(courseId));
  }

  @action async loadShared() {
    this.loadedShared = false;
    const shared = await this.api.get('teacher/courses/shared');
    this.populateCourses(shared);
    action(() => {
      this.loadedShared = true;
    })();
  }

  @action async loadDistrictTemplates() {
    this.loadedDistrict = false;
    const district = await this.api.get('teacher/courses/district');
    this.populateCourses(district);
    action(() => {
      this.loadedDistrict = true;
    })();
  }

  @action async loadArchived() {
    const archived = await this.api.get('teacher/courses/archived');
    this.populateCourses(archived);
  }

  // populates courses based on list
  @action populateCourses(list) {
    list.forEach((data) => {
      const course = new Course(this.rootStore, data);
      this.byId.set(course.id, course);
      if (course.upgradeJobId) {
        this.pollCourseUpgrade(course.id, course.upgradeJobId);
      }
    });
  }

  @action async transfer(course, teacher) {
    const courseId = Object(course) === course ? course.id : course;
    const teacherId = Object(teacher) === teacher ? teacher.id : teacher;

    await this.api.patch(
      `teacher/courses/${courseId}`,
      {
        course: {
          reassign_course: teacherId,
        },
      },
    );

    this.remove(course);
  }

  @action reloadCourseData(courseId) {
    const {
      assignments,
      assessmentOpens,
      students,
      grades,
    } = this.rootStore;

    assignments.reloadByCourse(courseId);
    assessmentOpens.loadByCourse(courseId);
    students.loadByCourse(courseId, undefined, true);
    grades.reloadByCourse(courseId);
    // TODO: enable line when lesson status view is implemented.
    // lessonStatus.reload(courseId, gradeFilter);
  }

  // Group archived courses by Year/Month. Sorting isn't needed because
  // the API will return them in the correct order.
  @computed get groupedArchive() {
    return this.archived.filter((course) => !course.template).reduce(
      (groups, course) => {
        const key = `Archived ${moment(course.deletedAt).format('MMMM, YYYY')}`;
        if (groups[key]) {
          groups[key].push(course);
        } else {
          // eslint-disable-next-line no-param-reassign
          groups[key] = [course];
        }
        return groups;
      },
      {},
    );
  }

  @action linkCanvasCourse(courseId, canvasCourse) {
    const course = this.byId.get(courseId);
    course.processingIntegration = true;

    this.api.post('/teacher/integrations', {
      action_type: 'canvas-api-course-link',
      course_id: courseId,
      ...canvasCourse,
    }).then((result) => {
      if (result.job_id) {
        this.pollCourseJob(result.job_id);
      }
    });
  }

  @action pollCourseJob(jobId) {
    this.jobAttempts += 1;
    this.api.get(`/jobs/${jobId}`).then(({ status }) => {
      if (status === 'completed') {
        this.loadForCurrentUser();
      } else if (this.jobAttempts < 30) {
        setTimeout(() => { this.pollCourseJob(jobId); }, 2000);
      }
    });
  }

  // TODO: Remove function after Summer V2 release

  /* eslint-disable no-param-reassign */
  @action pollPublishExam(exam, jobId) {
    this.jobAttempts += 1;
    this.api.get(`/jobs/${jobId}`, {}, { quiet: true }).then(({ status }) => {
      if (status === 'completed') {
        runInAction(() => {
          exam.learnosityStatus = 'published';
        });
      } else if (this.jobAttempts < 30) {
        setTimeout(() => { this.pollPublishExam(exam, jobId); }, 1000);
      }
    });
  }

  // TODO: Remove function after Summer V2 release
  @action publishExam(exam) {
    exam.learnosityStatus = 'publishing';
    this.api.post(`/teacher/exams/${exam.id}/publish`, {}, { quiet: true })
      .then((result) => {
        if (result.job_id) {
          setTimeout(() => { this.pollPublishExam(exam, result.job_id); }, 1000);
        }
      });
  }

  @action upgradeCourse(courseId) {
    const course = this.byId.get(courseId);
    const params = { target_version: '2' };
    const options = { quiet: true };

    return this.api.post(`/teacher/courses/${courseId}/upgrade`, params, options)
      .then(({ job_id: jobId }) => {
        runInAction(() => {
          course.upgradeJobId = jobId;
        });
        this.pollCourseUpgrade(courseId, jobId);
      });
  }

  @action pollCourseUpgrade(courseId, jobId) {
    return this.api.pollJob(jobId, 1000, 600000, false)
      .then(() => {
        this.loadSingle(courseId);
      })
      .catch(() => {});
  }
}

export default CoursesStore;
