import React, { Component } from 'react';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'widgets/Modal';
import Dropdown from 'widgets/form/Dropdown';

@inject('auth', 'router', 'teachers') @observer
class TeacherImpersonation extends Component {
  @observable show = false

  @observable loading = false

  @observable selected = null

  @computed get otherTeachers() {
    const { auth: { user: { id: myId } }, teachers } = this.props;

    return teachers.list
      .filter((t) => t.id !== myId)
      .map((t) => ({
        id: t.id,
        key: t.id,
        value: `${t.lastName}, ${t.firstName}`,
      }));
  }

  @action selectTeacher = (value) => { this.selected = value; }

  @action.bound showModal = () => { this.show = true; }

  @action.bound hideModal = () => { this.show = false; }

  @action.bound startLoading = () => { this.loading = true; }

  @action.bound finishLoading = () => { this.loading = false; }

  @action.bound async openList() {
    const { teachers } = this.props;

    if (!teachers.loaded) {
      this.startLoading();
      await teachers.loadForCurrentSite();
      this.finishLoading();
    }

    if (!this.selected && this.otherTeachers.length) {
      this.selectTeacher(this.otherTeachers[0].id);
    }

    this.showModal();
  }

  @action.bound performImpersonation() {
    const { auth } = this.props;

    if (auth.originalUser.id === +this.selected) {
      auth.endImpersonation();
      this.completeImpersonation();
    } else {
      auth.impersonate(this.selected)
        .then(this.completeImpersonation);
    }
  }

  @action.bound completeImpersonation() {
    const { router } = this.props;
    router.push('/');
    this.hideModal();
  }

  @action.bound endImpersonation() {
    const { auth, router } = this.props;

    auth.endImpersonation()
      .then(() => router.push('/'));
  }

  render() {
    const { auth } = this.props;

    if (!auth.isAdmin && !auth.isImpersonation) return null;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }} data-testid="teacherImpersonation">
        {auth.isImpersonation
          && (
            <button type="button" className="anchor" onClick={this.endImpersonation}>
              <div className="user">
                <div
                  className="userAvatar avatar"
                  style={{ backgroundImage: `url(${auth.originalUser.avatarUrl})` }}
                  role="presentation"
                />
                <div className="userInfo">
                  <div className="userName">{auth.originalUser.name}</div>
                  <div className="quickLinks">Return to My Account</div>
                </div>
              </div>
            </button>
          )}

        {this.otherTeachers.length > 0 && (
          <button type="button" className="anchor" onClick={this.openList}>
            <i className="material-icons">people_outline</i>
            View as Another Teacher
          </button>
        )}

        <Modal
          visible={this.show}
          onDismiss={this.hideModal}
          title="Switch Accounts"
        >
          {this.loading && <p>Loading teacher list...</p>}
          {!this.loading && (
            <div>
              <p>View Classes and Settings for:</p>

              <Dropdown
                options={this.otherTeachers}
                value={this.selected}
                onChange={this.selectTeacher}
                noEmpty
              />

              <div>
                <button type="button" onClick={this.performImpersonation}>Switch</button>
                <button type="button" className="secondary" onClick={this.hideModal}>Cancel</button>
              </div>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

export default TeacherImpersonation;
