import React from 'react';
import { Link } from 'react-router-dom';

const GradePercent = ({ assignment, entry, grade }) => {
  let gradeViewUrl = '';

  if (entry) {
    gradeViewUrl = `/courses/${entry.courseId}/grades/book/${entry.moduleKey}/unit/${entry.unitKey}/grade/${grade.id}`;
  } else if (assignment) {
    gradeViewUrl = `/courses/${assignment.courseId}/grades/book/${assignment.moduleKey}/unit/${assignment.unitKey}/grade/${grade.id}`;
  }

  if (!grade) return '-';
  return (
    <span>
      <Link to={gradeViewUrl} className="fixedWidth">{grade.percent}</Link>
    </span>
  );
};

export default GradePercent;
