import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import GradeActions from 'widgets/grade/GradeActions';
import DateTime from 'widgets/format/DateTime';

function byCompleted(a, b) {
  const left = new Date(a.completed).valueOf();
  const right = new Date(b.completed).valueOf();

  // Ordered for most recent first
  if (left > right) return -1;
  if (left < right) return 1;
  return 0;
}

@inject('students', 'courses') @observer
class RecentGrades extends Component {
  render() {
    const {
      students: { fromRoute: student },
      courses: { fromRoute: course },
    } = this.props;
    const grade = student.gradesByCourse.get(course.id);
    const allWork = (grade && grade.work) || [];
    const work = allWork.filter((w) => w.isComplete).sort(byCompleted).slice(0, 50);

    return (
      <div className="gradeSummary">
        {work && work.length === 0
          && <p><i>No graded work done.</i></p>}
        {work && work.length > 0
          && (
            <table>
              <thead>
                <tr>
                  <th>Completed</th>
                  <th>Module</th>
                  <th>Quiz</th>
                  <th>Grade</th>
                </tr>
              </thead>
              <tbody>
                {work.map((w) => (
                  <tr key={w.id}>
                    <td><DateTime value={w.completed} /></td>
                    <td>{w.examId ? w.assignment.exam.title : w.module.title}</td>
                    <td>{w.examId ? 'Exam' : w.unit.title}</td>
                    <td className="percent">{w.percent}</td>
                    <td><GradeActions courseId={course.id} grade={w} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
      </div>
    );
  }
}

export default RecentGrades;
