import React, { Component } from 'react';

export default class CheckboxTable extends Component {
  onChange(newValues) {
    const { onChange = () => {} } = this.props;
    onChange(newValues);
  }

  onCheck({ target: { id: key, checked: isChecked } }) {
    const { value = [] } = this.props;
    const newValues = value.filter((v) => v !== key);
    if (isChecked) {
      newValues.push(key);
    }
    this.onChange(newValues);
  }

  onCheckAll({ target: { checked: isChecked } }) {
    if (isChecked) {
      const keys = this.getSelectableOptions()
        .map((option) => option.key);
      this.onChange(keys);
    } else {
      this.onChange([]);
    }
  }

  getSelectableOptions() {
    const { options } = this.props;
    return options
      .filter((option) => !option.disabled);
  }

  render() {
    const { headers, options, value: checked } = this.props;

    return (
      <table className="checkbox">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={checked.length === this.getSelectableOptions().length}
                onChange={(e) => this.onCheckAll(e)}
              />
            </th>
            { headers.map(((text) => <th key={text}>{text}</th>)) }
          </tr>
        </thead>
        <tbody>
          { options.map((option) => (
            <tr key={option.key}>
              <td>
                <input
                  type="checkbox"
                  id={option.key}
                  checked={checked.includes(option.key)}
                  onChange={(e) => this.onCheck(e)}
                  disabled={option.disabled}
                />
              </td>
              <td>
                <label htmlFor={option.key} className={option.disabled ? 'disabled' : undefined}>
                  {option.label}
                </label>
              </td>
              { option.extra && option.extra.map((value) => (
                <td key={`${option.key}_${value}`}>{value}</td>
              )) }
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
