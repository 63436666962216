/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment } from 'react';
import { action, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Unit from 'widgets/curriculum/Unit';
import ResourceLink from 'widgets/ResourceLink';
import DatePicker from 'widgets/form/DatePicker';
import { DueDate } from 'util/DateTime';
import Modal from 'widgets/Modal';
import Alert from 'widgets/Alert';
import ToggleSlider from 'widgets/ToggleSlider';
import ModuleIcon from 'widgets/assignment/ModuleIcon';
import LessonViewer from '../../../widgets/curriculum/LessonViewer';

@inject('auth', 'router')
@observer
class AssignmentDetails extends React.Component {
  @observable lessonViewerUrl = '';

  @observable headsUpHidden = true;

  componentDidMount() {
    const { assignment } = this.props;
    this.loadLessonDetails();
    this.disposer = reaction(
      () => assignment.hasActiveQuizzes,
      (hasActiveQuizzes) => {
        if (!hasActiveQuizzes) { this.disableAssessment(); }
      },
    );
  }

  componentDidUpdate() {
    this.loadLessonDetails();
  }

  componentWillUnmount() {
    this.disposer();
  }

  loadLessonDetails() {
    const { assignment: { module } } = this.props;
    module.loadLessonDetails();
  }

  disableAssessment() {
    const { assignment } = this.props;
    assignment.setTestStatus(false);
    if (assignment.test.enabled) {
      assignment.setTestEnabled(false);
      this.enableHeadsUp();
    }
  }

  @action.bound enableHeadsUp() {
    this.headsUpHidden = false;
  }

  @action.bound hideHeadsUp() {
    this.headsUpHidden = true;
  }

  @action.bound updateDueDate(assignment, value) {
    this.assignment = assignment;
    this.newDate = new DueDate(value);

    if (this.newDate.isFuture && !assignment.open) {
      this.prompt = 'open';
    } else {
      this.keepState();
    }
  }

  @action.bound keepState() {
    this.assignment.setDueDate(this.newDate.value, this.assignment.open);
    this.prompt = null;
  }

  @action.bound changeState() {
    this.assignment.setDueDate(this.newDate.value, this.prompt === 'open');
    this.prompt = null;
  }

  @action.bound showLessonViewer(url) {
    this.lessonViewerUrl = url;
  }

  @action.bound dismissLessonViewer() {
    this.lessonViewerUrl = '';
  }

  render() {
    const { assignment, auth, router } = this.props;
    const { module } = assignment;

    if (module.key === 'XXX') {
      return null;
    }

    const sectionReducer = (list, entry) => {
      if (!list.find((s) => s.key === entry.phase.key)) {
        const entries = assignment.entries
          .filter((e) => e.phase.key === entry.phase.key);

        list.push({
          ...entry.phase,
          entries,
        });
      }
      return list;
    };

    const sections = assignment.entries
      .filter((e) => e.type === 1)
      .reduce(sectionReducer, []);

    const moduleResources = !!assignment.module.resources.length;
    let unitNumber = 0;

    const activeQuizzes = assignment.hasActiveQuizzes;

    const disableTest = !activeQuizzes > 0 && !assignment.test?.enabled;

    return (
      <Fragment>
        <div className="assignmentDetails">
          <div>
            <Link
              to={`${router.path.replace(/\/\d+$/, '')}`}
              className="button"
            >
              &larr; Return to Assignment List
            </Link>
          </div>

          <div className="moduleHeading">
            <div className="moduleTitle">
              <ModuleIcon title={module.title} image={module.imageUrl} />
              <div>
                <h1>{module.title}</h1>
                {module.version && (
                  <small>
                    (For
                    {' '}
                    {module.version}
                    )
                  </small>
                )}
              </div>
            </div>
            <div className="moduleActions noPrint">
              {assignment
                && (
                  <div className="unitControls">
                    <ToggleSlider
                      id={`assignment${assignment.id}`}
                      titleOn="Module Open"
                      titleOff="Module Closed"
                      onClick={() => assignment.setStatus(!assignment.open)}
                      active={assignment.open}
                    />
                  </div>
                )}
              {assignment && assignment.test && assignment.test.enabled
                && (
                  <div className="unitControls">
                    <ToggleSlider
                      id={`test${assignment.id}`}
                      titleOn="Test Open"
                      titleOff="Test Closed"
                      onClick={() => assignment.setTestStatus(!assignment.test.open)}
                      active={assignment.test.open}
                    />
                  </div>
                )}
              <div className="dueDateEdit assignmentDueDate">
                <label>Due Date:</label>
                <div className="editField">
                  <DatePicker
                    placeholder="No Due Date"
                    value={assignment.dueDate}
                    onChange={(value) => this.updateDueDate(assignment, value)}
                  />
                  <button
                    type="button"
                    className="native"
                    onClick={() => assignment.setDueDate(null, assignment.open)}
                  >
                    X
                  </button>
                </div>
                <Modal
                  title="Change Due Date"
                  id="due-prompt"
                  visible={this.prompt}
                  onDismiss={this.keepState}
                >
                  {this.prompt === 'open'
                    && (
                      <div>
                        <p>
                          The new due date has not passed but this assignment is currently closed
                          to students. Would you like to open it?
                        </p>
                        <button type="button" onClick={this.changeState}>Open the Assignment</button>
                        <button type="button" className="secondary" onClick={this.keepState}>
                          Leave it Closed
                        </button>
                      </div>
                    )}
                  {this.prompt === 'close'
                    && (
                      <div>
                        <p>
                          The new due date has passed but this assignment is currently open.
                          Would you like to close it?
                        </p>

                        <button type="button" onClick={this.changeState}>Close the Assignment</button>
                        <button type="button" className="secondary" onClick={this.keepState}>
                          Leave it Open
                        </button>
                      </div>
                    )}
                </Modal>
              </div>
            </div>
          </div>

          <div className="moduleDescription">
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: module.description }} />
            <p>
              {`Estimated ${assignment.hours} hours of learning`}
            </p>
          </div>

          {module.alert
            && (
              <div className="moduleAlert">
                <i className="material-icons md-36">warning</i>
                <p className="description">{module.alert}</p>
              </div>
            )}

          {moduleResources
            && (
              <div className="curriculumPhase">
                <div className="phaseHeader">
                  <div className="phaseNumber blue">Module Resources</div>
                  <div className="phaseTitle">
                    <p>Information to review before starting the module</p>
                  </div>
                </div>

                <ol>
                  <li className="curriculumUnit">
                    <div className="title">
                      <b>Module Introduction</b>
                    </div>
                    <div className="desc" />
                    <div className="resources">
                      <div>
                        <b>Resources</b>
                        <ul>
                          {assignment.module.resources.map((resource) => (
                            <li key={resource.id}>
                              <ResourceLink module={assignment.module} resource={resource} />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            )}

          {sections.map(({
            key, color, title, subtitle, entries,
          }) => (
            <div key={title} className="curriculumPhase">
              <div className="phaseHeader">
                <div className={`phaseNumber ${color}`}>{title}</div>
                <div className="phaseTitle">
                  <p>{subtitle}</p>
                </div>
              </div>

              <ol>
                {entries.map((entry) => {
                  if (key !== 'EXPLORE' && key !== 'MODULE') {
                    unitNumber += 1;
                  }
                  return (
                    <Unit
                      approved
                      key={entry.id}
                      checkTest={assignment.hasTest}
                      entry={entry}
                      unit={entry.unit}
                      phaseTitle={title}
                      page="AssignmentDetails"
                      unitNumber={unitNumber}
                      moduleKey={assignment.moduleKey}
                      showLessonViewer={this.showLessonViewer}
                    />
                  );
                })}
              </ol>
            </div>
          ))}

          {assignment.test
            && (
              <div className="curriculumPhase">
                <div className="phaseHeader">
                  <div className="phaseNumber red">Assessment</div>
                  <div className="phaseTitle">
                    <p>Assessment of everything the student learned in this module</p>
                  </div>
                </div>

                {activeQuizzes === 0
                  ? (
                    <Alert>
                      {`Test ${disableTest ? '' : 'should be '}disabled because all required units are excluded. `}
                      Include at least one unit from Learn & Practice to enable the Module Test.
                      {' '}
                      <a
                        href="https://help.aeseducation.com/en/articles/2121734-remove-or-restore-units"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        More information
                      </a>
                    </Alert>
                  )
                  : null}
                <ol>
                  <li className="curriculumUnit">
                    <div className="desc" style={assignment.test.enabled ? null : { opacity: 0.5 }}>
                      <p style={{ marginBottom: 0, marginTop: 0 }}>
                        <b>Module Test</b>
                        {' '}
                        - 1 hour est.
                        <br />
                        In this summative assessment, students will be tested on everything
                        that they have learned in the module.
                      </p>
                      {assignment.moduleKey === 'HSANTMYCO'
                        && (
                          <React.Fragment>
                            <p>
                              Anatomy &amp; Physiology comes with a standard test and an advanced
                              test. The standard test has 25 questions and the advanced test has
                              100 questions.
                            </p>
                            <p>
                              <label htmlFor="advancedTest">
                                <input
                                  id="advancedTest"
                                  name="advancedTest"
                                  type="checkbox"
                                  checked={assignment.testType === 1}
                                  onChange={() => assignment.toggleTestType()}
                                />
                                <span>Use the Advanced Test</span>
                              </label>
                            </p>
                          </React.Fragment>
                        )}
                    </div>
                    <div className="resources" style={assignment.test.enabled ? null : { opacity: 0.5 }}>
                      <b>Teacher Resources</b>
                      <ul>
                        <li>
                          {assignment.test && auth.enableAnswerKeys && activeQuizzes > 0
                            && (
                              <Link to={`${router.path}/answer-key/${assignment.test.id}`}>
                                Test Answer Key
                              </Link>
                            )}
                          {assignment.test && auth.enableAnswerKeys && activeQuizzes === 0
                            && (
                              <div style={{ textDecoration: 'underline' }}>
                                Test Answer Key
                              </div>
                            )}
                          {assignment.test && !auth.enableAnswerKeys && (
                            <ResourceLink
                              module={assignment.module}
                              resource={{ name: 'Test Answer Key', title: 'Test Answer Key', url: '#' }}
                            />
                          )}
                        </li>
                      </ul>
                    </div>
                    <div className="resources" />
                    <div className="options noPrint">
                      <div className="unitControls" style={disableTest ? { opacity: 0.5 } : null}>
                        <ToggleSlider
                          id={`testIncluded${assignment.id}`}
                          titleOn="Test Included"
                          titleOff="Test Excluded"
                          onClick={() => assignment.setTestEnabled(!assignment.test.enabled)}
                          active={!!assignment.test.enabled}
                          disable={disableTest}
                        />
                      </div>
                      <br />
                      {assignment && assignment.test && assignment.test.enabled
                        && (
                          <div className="unitControls">
                            <ToggleSlider
                              id={`testBottom${assignment.id}`}
                              titleOn="Test Open"
                              titleOff="Test Closed"
                              onClick={() => assignment.setTestStatus(!assignment.test.open)}
                              active={assignment.test.open}
                            />
                          </div>
                        )}
                    </div>
                  </li>
                </ol>
              </div>
            )}
        </div>
        <LessonViewer url={this.lessonViewerUrl} onDismiss={this.dismissLessonViewer} />
        <Modal
          id="heads-up"
          title="Heads Up!"
          visible={!this.headsUpHidden}
        >
          <p>The module test has been disabled because all required units are excluded.</p>
          <p>
            Include at least one unit from Learn & Practice to enable the Module Test.
            {' '}
            More information about
            {' '}
            <a
              href="https://help.aeseducation.com/en/articles/2121734-remove-or-restore-units"
              target="_blank"
              rel="noreferrer noopener"
            >
              how excluding units affect tests
            </a>
          </p>
          <button type="button" onClick={() => this.hideHeadsUp()}>
            OK
          </button>
        </Modal>
      </Fragment>
    );
  }
}

export default AssignmentDetails;
