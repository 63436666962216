import { observable, action, computed } from 'mobx';
import Question from 'models/Question';

class QuestionsStore {
  @observable byId = new Map()

  @observable loaded = new Map()

  constructor(rootStore, api) {
    this.api = api;
    this.rootStore = rootStore;
  }

  @computed get byModule() {
    const result = new Map();

    this.byId.forEach((question) => {
      if (!result.has(question.moduleKey)) result.set(question.moduleKey, []);
      if (question.type !== 'FIB') result.get(question.moduleKey).push(question);
    });

    return result;
  }

  @action add(input) {
    const question = new Question(this.rootStore, input);
    this.byId.set(question.key, question);
    return question;
  }

  @action loadByGrade(grade) {
    const entry = grade.assignment.entries.find((e) => e.unitKey === grade.unitKey);

    return this.loadByEntry(entry);
  }

  @action loadByEntry(assignmentEntry) {
    const apiEndpoint = [
      'teacher/courses/',
      assignmentEntry.courseId,
      '/assignments/',
      assignmentEntry.assignmentId,
      '/assignment_entries/',
      assignmentEntry.id,
      '/answer_key.json',
    ].join('');

    return this.api.get(apiEndpoint)
      .then((result) => this.addQuestions(assignmentEntry.moduleKey, result));
  }

  @action loadByModule(moduleKey, filter) {
    const requests = [];

    if (filter !== 'TCH') {
      requests.push(this.api.get('teacher/questions', { module_key: moduleKey, per_page: 1000 }));
    }
    if (filter === 'TCH' || filter === 'ALL') {
      const request = this.api.get('teacher/questions', {
        module_key: moduleKey,
        per_page: 1000,
        pool: 'teacher',
      }).then((questions) => questions.map((q) => ({ ...q, isTeacher: true })));
      requests.push(request);
    }

    return Promise.all(requests)
      .then(action('loadQuestions', (results) => {
        const list = results.reduce(
          (final, pool) => {
            const mapped = pool.map((q) => new Question(this.rootStore, {
              module_key: moduleKey,
              ...q,
            }));

            return final.concat(mapped);
          },
          [],
        );

        list.forEach((q) => this.byId.set(q.key, q));
      }));
  }

  @action loadByExam(exam) {
    return this.api.get(`teacher/exams/${exam.id}/exam_questions`)
      .then(action('loadExamQuestions', (results) => results.map((res) => {
        const question = new Question(this.rootStore, res);
        this.byId.set(question.key, question);
        return question;
      })));
  }

  @action.bound addQuestions(moduleKey, pool) {
    const list = pool.map((q) => new Question(this.rootStore, { module_key: moduleKey, ...q }));

    this.byModule.set(moduleKey, list);
    list.forEach((q) => this.byId.set(q.key, q));

    return list;
  }
}

export default QuestionsStore;
