import React, { Component } from 'react';
import Modal from 'widgets/Modal';

class ExportElectronicWorksheets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      running: false,
      url: null,
      error: null,
      student: null,
    };
  }

  startDownload = () => {
    const { course } = this.props;
    const { student, moduleKey } = this.state;

    const sid = student === 'all' ? null : student;
    const mkey = moduleKey === 'all' ? null : moduleKey;

    this.setState({ running: true });
    course.generateWorksheets({ student_id: sid, module_key: mkey })
      .then((result) => {
        if (result.count === 0) {
          this.setState({ running: false, error: 'No student worksheets were found' });
        } else {
          this.setState({ running: false, url: result.url });
        }
      });
  }

  onDismiss = () => {
    const { onDismiss } = this.props;
    this.setState({
      running: false, url: null, student: null, error: null,
    });
    onDismiss();
  }

  render() {
    const { course: { students, assignments }, showExportWorksheets } = this.props;
    const {
      running, url, student, moduleKey, error,
    } = this.state;
    const modules = assignments.filter((a) => a.isModule);

    return (
      <Modal
        title="Export Student Worksheets"
        visible={showExportWorksheets}
        onDismiss={this.onDismiss}
      >
        {!running && !url && (
          <div className="exportWorksheetsModal">
            <label htmlFor="student">
              <span>Select Student:</span>
              <select
                id="student"
                value={student}
                onChange={(ev) => this.setState({ student: ev.target.value })}
              >
                <option value="all">All Students</option>
                {students.map((s) => (
                  <option value={s.id} key={s.id}>
                    {`${s.lastName}, ${s.firstName}`}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="module">
              <span>Select Module:</span>
              <select
                id="module"
                value={moduleKey}
                onChange={(ev) => this.setState({ moduleKey: ev.target.value })}
              >
                <option value="all">All Modules</option>
                {modules.map((m) => (
                  <option value={m.moduleKey} key={m.moduleKey}>
                    {m.title}
                  </option>
                ))}
              </select>
            </label>
            {error && (
              <p className="error">{error}</p>
            )}
            <button
              type="button"
              onClick={this.startDownload}
            >
              Export Worksheets
            </button>
          </div>
        )}
        {running && (
          <div>
            Preparing your PDF file, please wait...
          </div>
        )}
        {url && (
          <div>
            <button
              type="button"
              onClick={() => {
                window.open(url);
                this.onDismiss();
              }}
            >
              Open PDF Worksheet
            </button>
          </div>
        )}
      </Modal>
    );
  }
}

export default ExportElectronicWorksheets;
