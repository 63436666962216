import WebAPI from 'services/WebAPI';
import AssessmentOpensStore from './AssessmentOpensStore';
import AssignmentsStore from './AssignmentsStore';
import AuthStore from './AuthStore';
import CatalogStore from './CatalogStore';
import CoursesStore from './CoursesStore';
import CurriculumStore from './CurriculumStore';
import GamesStore from './GamesStore';
import GradesStore from './GradesStore';
import LessonStatusStore from './LessonStatusStore';
import QuestionsStore from './QuestionsStore';
import RouterStore from './RouterStore';
import StudentsStore from './StudentsStore';
import TeachersStore from './TeachersStore';
import UIStateStore from './UIStateStore';
import WebSocketDispatch from '../services/WebSocketDispatch';
import LearnosityItemsStore from './LearnosityItemsStore';
import LicenseUsageStore from './LicenseUsageStore';

class RootStore {
  constructor() {
    const api = new WebAPI(this);

    // TODO move all WebAPI calls exclusively into stores, then remove this property
    this.WebAPI = api;

    // Web Socket Interface
    this.webSocketDispatch = new WebSocketDispatch(this);

    // Stores
    this.uiState = new UIStateStore(this, api);
    this.assessmentOpens = new AssessmentOpensStore(this, api);
    this.assignments = new AssignmentsStore(this, api);
    this.auth = new AuthStore(this, api);
    this.catalog = new CatalogStore(this, api);
    this.courses = new CoursesStore(this, api);
    this.curriculum = new CurriculumStore(this, api);
    this.games = new GamesStore(this, api);
    this.grades = new GradesStore(this, api);
    this.lessonStatus = new LessonStatusStore(this, api);
    this.learnosityItems = new LearnosityItemsStore(this, api);
    this.questions = new QuestionsStore(this, api);
    this.router = new RouterStore(this, api);
    this.students = new StudentsStore(this, api);
    this.teachers = new TeachersStore(this, api);
    this.licenseUsage = new LicenseUsageStore(this, api);
  }

  onAuthenticate() {
    this.webSocketDispatch.onAuthenticate();
  }

  onCourseChange(courseId) {
    this.webSocketDispatch.onCourseChange(courseId);
  }

  dispatchUpdate(data) {
    // Dispatch to any store that's interested
    this.grades.dispatchUpdate(data);
  }
}

export default RootStore;
