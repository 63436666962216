import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, runInAction } from 'mobx';
import { Link } from 'react-router-dom';
import Modal from 'widgets/Modal';
import TextBox from 'widgets/form/TextBox';
import FormErrors from 'widgets/form/FormErrors';
import DateTime from 'widgets/format/DateTime';
import CustomModuleView from 'widgets/assignment/CustomModuleView';

@inject('curriculum', 'router', 'auth', 'courses') @observer
class CustomModules extends React.Component {
  @observable name = ''

  @observable module = null

  @observable showCreate = false

  @observable showDelete = false

  @observable showRename = false

  @observable error = ''

  @observable message = ''

  @observable activeCourses = null

  moduleDeletionWarning = 'Are you sure you want to delete this module?';

  @action.bound handleModuleDelete = () => {
    const { curriculum } = this.props;
    curriculum.deleteCustomModule(this.moduleKey).then(() => {
      this.handleModuleClose();
    });
  }

  @action.bound handleModuleCreate = () => {
    const { curriculum } = this.props;
    curriculum.createCustomModule(this.name).then(() => {
      this.handleModuleClose();
    });
  }

  @action.bound handleModuleRename = (event) => {
    event.preventDefault();
    if (this.name === '') {
      this.error = 'Module title cannot be blank';
    } else {
      this.module.rename(this.name).then(() => {
        this.handleModuleClose();
      });
    }
  }

  @action.bound handleShowDelete = (mod) => {
    this.showDelete = true;
    this.moduleKey = mod.key;
  }

  @action.bound handleModuleSearch = (mod) => {
    const { curriculum, courses } = this.props;

    curriculum.searchCustomModule(mod.key).then((res) => {
      this.handleShowDelete(mod);
      runInAction(() => {
        if (res.status === 'Error') {
          this.activeCourses = res.assignments.reduce((acc, assignment) => {
            const c = courses.byId.get(assignment.course_id);
            return c ? acc.concat(c) : acc;
          }, []);
          this.setMessage(this.activeCourses?.length > 0
            ? 'This module cannot be deleted because it is used in the following classes:'
            : this.moduleDeletionWarning);
        } else {
          this.setMessage(this.moduleDeletionWarning);
        }
      });
    });
  }

  @action.bound handleModuleClose() {
    this.activeCourses = null;
    this.error = '';
    this.message = '';
    this.module = null;
    this.name = '';
    this.showCreate = false;
    this.showDelete = false;
    this.showRename = false;
  }

  @action.bound setMessage = (message) => {
    this.message = message;
  }

  @action.bound showCreateModal = () => {
    this.showCreate = true;
    this.module = null;
    this.name = '';
  }

  @action.bound viewModule = (mod) => {
    const { router } = this.props;
    router.push(`${router.path}?edit=${mod.id}`);
  }

  @action.bound setName = (name) => {
    this.name = name;
  }

  @action.bound showRenameModal = (mod) => {
    this.showRename = true;
    this.module = mod;
  }

  render() {
    const { auth, curriculum, router } = this.props;
    const modules = curriculum.myCustomModules;

    if (!auth.enableMasterFeatures) {
      return (
        <div>
          <h1>Custom Modules</h1>
          Your package doesn&rsquo;t provide access to custom modules.
          <br />
          Please
          {/* eslint-disable-next-line no-undef */}
          <button type="button" className="anchor" onClick={() => Intercom('show')}>
            contact our support team
          </button>
          {' '}
          if
          you would like to evaluate this feature.
        </div>
      );
    }

    if (router.query.edit) {
      const { router: { path } } = this.props;
      return (
        <div>
          <h1>Custom Modules</h1>

          <div className="formField">
            <Link to={path}>
              <button type="button">&larr; Return to Custom Modules</button>
            </Link>
          </div>

          <CustomModuleView id={router.query.edit} edit />
        </div>
      );
    }

    return (
      <div>
        <h1>Custom Modules</h1>
        <p>
          Custom Modules allow you to add your own lesson files such as
          presentations, documents, and videos
          <br />
          to integrate into your class
          as learning modules.
        </p>

        <table>
          <thead>
            <tr>
              <th colSpan={5}>Title</th>
              <th colSpan={1}>Resources</th>
              <th colSpan={1}>Created</th>
              <th colSpan={1}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {(!modules || modules.length === 0) && (
              <tr>
                <td colSpan={8}>
                  <i>You have no custom modules yet.</i>
                </td>
              </tr>
            )}
            {modules.map((mod) => (
              <tr key={mod.id}>
                <td colSpan={5}>{mod.title}</td>
                <td colSpan={1}>{mod.resources.length}</td>
                <td colSpan={1}><DateTime value={mod.createdAt} noValue="Unknown" /></td>
                <td colSpan={1}>
                  <button type="button" onClick={() => this.viewModule(mod)}>Edit</button>
                  <button type="button" onClick={() => this.showRenameModal(mod)}>Rename</button>
                  <button type="button" onClick={() => this.handleModuleSearch(mod)}>Delete</button>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={5}>
                <button type="button" onClick={() => this.showCreateModal()}>+ Create a Custom Module</button>
              </td>
            </tr>
          </tbody>
        </table>

        <Modal
          title="Create a Custom Module"
          visible={this.showCreate}
          onDismiss={this.handleModuleClose}
        >
          <form>
            <p>What would you like students to see in their module list?</p>

            <TextBox
              name="title"
              label="Title:"
              value={this.name}
              onChange={(value) => this.setName(value)}
              leftLabel
            />

            <FormErrors />

            <button type="button" onClick={() => this.handleModuleCreate()}>Create Module</button>
            <button type="button" className="secondary" onClick={this.handleModuleClose}>
              Cancel
            </button>
          </form>
        </Modal>

        <Modal
          title="Rename Custom Module"
          visible={this.showRename}
          onDismiss={this.handleModuleClose}
        >
          {module
            && (
              <form
                className="editClassForm"
                onSubmit={(event) => this.handleModuleRename(event)}
              >
                <h2>{module.title}</h2>
                <p>What would you like to rename this custom module?</p>

                <div className="formContent">
                  <TextBox
                    name="title"
                    label="Title:"
                    value={this.name}
                    onChange={(value) => this.setName(value)}
                    leftLabel
                  />

                  {this.error && <p className="error">{this.error}</p>}
                </div>

                <button type="submit">Rename Module</button>
                <button type="button" className="secondary" onClick={this.handleModuleClose}>
                  Cancel
                </button>
              </form>
            )}
        </Modal>
        <Modal
          title="Delete Custom Module"
          visible={this.showDelete}
          onDismiss={this.handleModuleClose}
        >
          <div className="heading">
            {this.message && <p className="message">{this.message}</p>}
            {this.activeCourses?.length > 0
              ? (
                <>
                  <div className="activeCourses">
                    {this.activeCourses.map(({ id, title }) => (
                      <li>
                        <Link className="activeCourse" to={`/courses/${id}/assignments`}>
                          {title}
                        </Link>
                      </li>
                    ))}
                  </div>
                  <p className="deleteModuleText">
                    You must remove the module from the listed class(es) before it can be deleted.
                  </p>
                  <button type="button" onClick={this.handleModuleClose}>OK</button>
                </>
              ) : (
                <div className="actionButtons">
                  <button type="button" onClick={this.handleModuleDelete}>Delete</button>
                  <button type="button" className="secondary" onClick={this.handleModuleClose}>Cancel</button>
                </div>
              )}
          </div>

        </Modal>
      </div>
    );
  }
}

export default CustomModules;
