import React from 'react';
import Spinner from 'react-spinkit';

const LoadingOverlay = () => (
  <div className="loadingOverlay">
    <Spinner name="cube-grid" fadeIn="none" />
  </div>
);

export default LoadingOverlay;
