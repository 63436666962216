import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject('curriculum')
@observer
class ExamItemTagSelector extends React.Component {
  @computed get modules() {
    const {
      curriculum: { modulesByKey },
      moduleKeys,
    } = this.props;
    return moduleKeys.map((key) => modulesByKey[key])
      .filter((module) => !!module);
  }

  @computed get units() {
    const {
      curriculum: { modulesByKey },
      selectedModule,
    } = this.props;

    return selectedModule
      ? modulesByKey[selectedModule].learnAndPracticeUnits
      : [];
  }

  @computed get showUnits() {
    const { units, props: { hideUnits } } = this;
    return !hideUnits && units?.length > 0;
  }

  render() {
    const {
      modules,
      units,
      showUnits,
    } = this;

    const {
      selectedModule,
      selectedUnit,
      requireModule,
      moduleEmptyLabel = 'All Modules',
      unitEmptyLabel = 'All Units',
      onSelectModule,
      onSelectUnit,
    } = this.props;

    return (
      <React.Fragment>
        <select className="module" value={selectedModule} onChange={(event) => onSelectModule(event)}>
          <option value="" disabled={requireModule}>{moduleEmptyLabel}</option>
          { modules.map(({ key, title }) => (
            <option key={key} value={key}>{title}</option>
          )) }
        </select>
        { showUnits
          ? (
            <select className="unit" value={selectedUnit} onChange={(event) => onSelectUnit(event)}>
              <option value="">{unitEmptyLabel}</option>
              { units.map(({ key, title }) => (
                <option key={key} value={key}>{title}</option>
              )) }
            </select>
          )
          : null }
      </React.Fragment>
    );
  }
}

export default ExamItemTagSelector;
